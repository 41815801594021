import { Injectable } from '@angular/core';
import * as BrM from 'br-masks';
import * as _ from 'lodash';


@Injectable()
export class Mascaras {

    constructor() {

    }
    aplicaMascaraTelefone(numero, ddd) {
        let telefone: string;
        if (numero > 0 && ddd != null) {
            telefone = ddd + numero.toString();
        }else{
            telefone = numero;
        }
        return BrM.phone(telefone);
    }
    aplicaMascaraReais(valor: number) {
        return BrM.finance(valor, 2, ',', '.');
    }
    aplicaMascaraUnidade(valor: number) {
        return BrM.finance(valor, 3, '.', '.');
    }
    aplicaMascaraUnidade2(valor: number) {
        return BrM.finance(valor, '.');
    }
    aplicaMascaraCep(valor) {
        return BrM.cep(valor);
    }
    aplicaMascaraDocumento(documento: string, tipo: number) {

        if (documento !== undefined) {
            documento = documento.replace(new RegExp(' ', 'g'), '0');

            // console.log(documentos2.length);
            if (tipo === 1) {
                if (!documento.startsWith('x')){
                return BrM.cpf(_.padStart(documento, 11, '0'));
                }
                else
                return documento;
            }
            else if (tipo === 2) {
                return BrM.cnpj(_.padStart(documento, 14, '0'));
            }
        } else {
            return '';
        }
    }
    validaCpfCnpj(documento) {
        documento = documento.toString().replace(/[^\d]+/g, '');
        if (documento.length === 14) {
            var cnpj = documento;
            if (cnpj == '')
                return false;
            if (cnpj == '99999999999999')
                return true;
            // Elimina CNPJs invalidos conhecidos
            if (cnpj == "00000000000000" ||
                cnpj == "11111111111111" ||
                cnpj == "22222222222222" ||
                cnpj == "33333333333333" ||
                cnpj == "44444444444444" ||
                cnpj == "55555555555555" ||
                cnpj == "66666666666666" ||
                cnpj == "77777777777777" ||
                cnpj == "88888888888888")
                return false;
            // Valida DVs
            var tamanho = cnpj.length - 2;
            var numeros = cnpj.substring(0, tamanho);
            var digitos = cnpj.substring(tamanho);
            var soma = 0;
            var pos = tamanho - 7;
            for (var i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0))
                return false;
            tamanho = tamanho + 1;
            numeros = cnpj.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (var i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1))
                return false;
            return true;
        }
        else if (documento.length === 11) {
            var cpf = documento;
            if (cpf == '')
                return false;
            // Elimina CPFs invalidos conhecidos
            if (cpf.length != 11 || cpf == "00000000000" || cpf == "11111111111" || cpf == "22222222222" || cpf == "33333333333" || cpf == "44444444444" || cpf == "55555555555" || cpf == "66666666666" || cpf == "77777777777" || cpf == "88888888888" || cpf == "99999999999")
                return false;
            // Valida 1o digito
            var add = 0;
            for (let i = 0; i < 9; i++)
                add += parseInt(cpf.charAt(i)) * (10 - i);
            var rev = 11 - (add % 11);
            if (rev == 10 || rev == 11)
                rev = 0;
            if (rev != parseInt(cpf.charAt(9)))
                return false;
            // Valida 2o digito
            var add = 0;
            for (let i = 0; i < 10; i++)
                add += parseInt(cpf.charAt(i)) * (11 - i);
            var rev = 11 - (add % 11);
            if (rev == 10 || rev == 11)
                rev = 0;
            if (rev != parseInt(cpf.charAt(10)))
                return false;
            return true;
        }
        else {
            return false;
        }
    }

    aplicaMascaraNIS(v: string) {

        v = v.replace(/\D/g, '');                                     //Remove tudo o que não é dígito
        v = v.replace(/^(\d{3})(\d)/, '$1.$2');                       //Coloca ponto entre o terceiro e o quarto dígitos
        v = v.replace(/^(\d{3})\.(\d{5})(\d)/, '$1.$2.$3');            //Coloca ponto entre o quinto e o sexto dígitos
        v = v.replace(/(\d{3})\.(\d{5})\.(\d{2})(\d)/, '$1.$2.$3-$4'); //Coloca ponto entre o décimo e o décimo primeiro dígitos
        return v;
    }

    validateNIS(pis: string) {
        const multiplicadorBase = '3298765432';
        let total = 0;
        let resto = 0;
        let multiplicando = 0;
        let multiplicador = 0;
        let digito = 99;

        // Retira a mascara
        const numeroPIS = pis.replace(/[^\d]+/g, '');
        if (numeroPIS.length !== 11 ||
            numeroPIS === '00000000000' ||
            numeroPIS === '11111111111' ||
            numeroPIS === '22222222222' ||
            numeroPIS === '33333333333' ||
            numeroPIS === '44444444444' ||
            numeroPIS === '55555555555' ||
            numeroPIS === '66666666666' ||
            numeroPIS === '77777777777' ||
            numeroPIS === '88888888888' ||
            numeroPIS === '99999999999') {
            return false;
        } else {
            for (let i = 0; i < 10; i++) {
                multiplicando = parseInt(numeroPIS.substring(i, i + 1));
                multiplicador = parseInt(multiplicadorBase.substring(i, i + 1));
                total += multiplicando * multiplicador;
            }
            resto = 11 - total % 11;
            resto = resto === 10 || resto === 11 ? 0 : resto;
            digito = parseInt("" + numeroPIS.charAt(10));
            return resto === digito;
        }
    }
}
