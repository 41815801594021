import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { UserService } from '../../@core/user.service';
import { AlertService } from '../../@core/alert.service';
import { ParceriaLogon } from './parceriaLogon';
import { LogonApi } from '../../apis/logonApi';
import { ActivatedRoute, Router } from '@angular/router';
import { Mascaras } from '../../@shared/components/Mascaras';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { Utils } from 'src/app/@shared/utils';
import { AuditorLogon } from 'src/app/liberacaoLogon/auditor/auditorLogon';
import { DetalheParceriaPortalApi } from 'src/app/apis/detalheParceriaPortalApi';

@Component({
  selector: 'app-exibe-parceria-usuario',
  templateUrl: './exibeParceriaPorUsuario.component.html',
  styleUrls: ['./../../modulo2/detalheParceria/detalheParceria.component.scss']
})

export class ExibeParceriaPorUsuarioComponent implements OnInit {

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  private notLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public notLoading$ = this.notLoading.asObservable().pipe(share());
  private temPermissao: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public temPermissao$ = this.temPermissao.asObservable().pipe(share());
  private temPermissaoPMPA: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public temPermissaoPMPA$ = this.temPermissaoPMPA.asObservable().pipe(share());

  private temPermissaoAuditor: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public temPermissaoAuditor$ = this.temPermissaoAuditor.asObservable().pipe(share());
  selectedParceriaId: number = null;
  isFiscal: boolean;
  isConsulta: boolean;
  @Input()
  isAuditorExterno: boolean;
  nomeEntidade: string;
  parceriaLogonArray: Array<ParceriaLogon>;
  dropdownOrgaoList = [];
  dropdownOSCList = [];
  auditorLogonArray: Array<AuditorLogon>;
  orgao: string;

  constructor(private alertService: AlertService,
    private logonApi: LogonApi,
    public keycloakService: KeycloakService,
    private router: Router,
    private mascaras: Mascaras,
    public userService: UserService,
    private utils: Utils,
    private route: ActivatedRoute,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi
  ) {
  }

  async ngOnInit() {
    this.detalheParceriaPortalApi.getUp().subscribe()
    this.notLoading.next(false);
    this.temPermissao.next(true);
    this.isFiscal = this.userService.isFiscal();
    this.isConsulta = this.userService.isConsulta();
    this.parceriaLogonArray = [];
    this.auditorLogonArray = [];
    if (this.isFiscal || this.isConsulta) {
      this.router.navigate(['/execucao/exibeParceriasM2']);
    } else if (this.keycloakService['_keycloakInstance'].realm === 'pmpa' &&
      this.keycloakService['_keycloakInstance'].tokenParsed.resource_access['sgp'] === undefined) {
      this.temPermissaoPMPA.next(false);
      this.temPermissao.next(true);
    } else {
      this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
        this.isAuditorExterno = (params['isAuditorExterno'] === undefined || params['isAuditorExterno'].toLowerCase() === 'false' ? false : true)
      });

      if (!this.isFiscal && !this.isConsulta) {
        if (this.isAuditorExterno === undefined || this.isAuditorExterno.valueOf() === true) {
          await this.getParceriasPorAuditorExternoLogon();
        }
        else {
          this.getParceriasPorLogon();
        }
      }
    }
  }


  // async getParceriasPorLogon() {
  //   this.loading.next(true);
  //   //console.log(this.userService.userInfo);
  //   this.parceriaLogonArray =  await this.logonApi.getParceriaLogon(this.keycloakService['_keycloakInstance'].tokenParsed.email).toPromise().then( response => null)
  //   .catch(error => error
  //     temPermissao$ = this.temPermissao.next(false));

  //   if (this.parceriaLogonArray.length === 1) {
  //       this.router.navigate(['/execucao/detalheParceriaM2/', this.parceriaLogonArray[0].parId]);
  //   }
  //   else {
  //      this.carregaComboOrgao();
  //   }

  //   this.loading.next(false);
  // }

  getParceriasPorLogon() {
    this.loading.next(true);
    //console.log(this.userService.userInfo);
    this.logonApi.getParceriaLogon(this.keycloakService['_keycloakInstance'].tokenParsed.email)
      .subscribe((response) => {
        if (response) {
          this.parceriaLogonArray = response as Array<ParceriaLogon>;
          //console.log(this.parceriaLogonArray);
          this.temPermissao.next(false);
          if (this.parceriaLogonArray.length === 1) {
            this.router.navigate(['/execucao/detalheParceriaM2/', this.parceriaLogonArray[0].parId]);
          }
          else {
            this.carregaComboOrgao();
          }
          this.notLoading.next(false);
        }

        this.loading.next(false);

      }, (response) => {
        this.temPermissao.next(true);
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
        this.notLoading.next(true);
      });
  }

  carregaComboOrgao() {
    this.dropdownOrgaoList = [];
    if (this.isAuditorExterno.valueOf() != true) {
      this.parceriaLogonArray.forEach((element) => {
        if (!this.dropdownOrgaoList.includes(element.orgao)) {
          this.dropdownOrgaoList.push(element.orgao);
        }
      });
      //console.log(this.dropdownOrgaoList);
      if (this.dropdownOrgaoList.length === 1) {
        this.orgao = this.dropdownOrgaoList[0].toString();
      }
      this.carregaComboOsc();
    }
    else {
      this.auditorLogonArray.forEach((element) => {
        if (!this.dropdownOrgaoList.includes(element.orgaoNome)) {
          this.dropdownOrgaoList.push(element.orgaoNome);
        }
      });
      //console.log(this.dropdownOrgaoList);
      if (this.dropdownOrgaoList.length === 1) {
        this.orgao = this.dropdownOrgaoList[0].toString();
      }
      this.carregaComboOsc();
    }
  }

  selectOrgao(event) {
    this.orgao = event.currentTarget.value;
    this.carregaComboOsc();
  }

  carregaComboOsc() {

    this.dropdownOSCList = [];
    if (this.isAuditorExterno.valueOf() != true) {
      this.parceriaLogonArray.forEach((element) => {
        if (element.orgao === this.orgao) {
          this.dropdownOSCList.push({ id: element.parId, name: element.parLogonNome + " - " + element.parNum + " - " + element.modalidade });
        }
      });
    }
    else {
      this.auditorLogonArray.forEach((element) => {
        if (element.orgaoNome === this.orgao) {
          this.dropdownOSCList.push({ id: element.parId, name: element.parceriaNome + " - " + element.parNum });
        }
      });
    }

    //console.log(this.dropdownOSCList);
    if (this.dropdownOSCList.length === 1) {
      this.router.navigate(['/execucao/detalheParceriaM2/', this.dropdownOSCList[0].id]);
    }
  }

  selectOSC(event) {
    this.router.navigate(['/execucao/detalheParceriaM2/', event.currentTarget.value]);
  }

  aplicaMascaraCNPJ(doc) {
    return this.mascaras.aplicaMascaraDocumento(doc, 2);
  }

  getStatusParceira(cod) {
    return this.utils.getStatusParceira(cod);
  }


  async getParceriasPorAuditorExternoLogon() {
    this.loading.next(true);
    //console.log(this.userService.userInfo);
    this.auditorLogonArray = await this.logonApi.getAuditorLogon(this.keycloakService['_keycloakInstance'].tokenParsed.email).toPromise();

    if (this.auditorLogonArray.length == 0) {
      this.isAuditorExterno = false;
      await this.getParceriasPorLogon();  // não é auditor; vê se tem acesso de OSC
    } else {
      this.isAuditorExterno = true;
      this.temPermissao.next(true);
      this.temPermissaoPMPA.next(false);
      if (this.auditorLogonArray.length == 0) {
        this.temPermissaoAuditor.next(false);
      }
      else if (this.auditorLogonArray.length === 1) {
        this.router.navigate(['/execucao/detalheParceriaM2/', this.auditorLogonArray[0].parId]);
        this.temPermissaoAuditor.next(true);
      }
      else {
        this.carregaComboOrgao();
        this.temPermissaoAuditor.next(true);
      }
    }
    this.loading.next(false);
  }
}
