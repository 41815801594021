import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class ParceriaMonitoraAvalia {

    public parId: number;
    public monitoraId: number;
    public monitoraDocumentoArquivo: string;
    public monitoraDocArqOriginal: string;
    public monitoraDocumentoData: Date;
    public monitoraDocumentoTipo: string;
    public monitoraDocumentoPublicar: number;
    public monitoraDocumento: Blob;
    public monitoraDocumentoDataFormat: NgbDateStruct;
    
}