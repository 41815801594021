<div w3-include-html="../../@shared/components/headerPortal/header.component.html">
    <div class="container-fluid mb-3" style="min-height: 450px;">
        <div class="infoGeral container-fluid">
            <h2>Relatório de Valores Pagos às Parcerias</h2>
            <h3>Filtros de pesquisa</h3>
            <p><em>Você pode filtrar os resultados preenchendo um ou combinando mais de um dos campos abaixo:</em></p>
            <br>
            <div class="form-row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Órgão ou entidade (PMPA)</label>
                        <div class="select-wrapper">
                            <select [disabled]="isFiscal && !isSMTC" [ngModel]="busca.orgao" (change)="selectOrgaoParceria($event)" class="form-control form-select">
                                <option [value]="option.id" *ngFor="let option of this.utils.secretariasOptions">{{option.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="nomeOrg">Nome Parcerias</label>
                        <input style="width: 100% " id="nomeOrg" name="busca.nomeOrg" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.nomeOrg " [value]="busca.nomeOrg" (keydown.enter)="geraArquivo('XLS')" class="form-control"
                            placeholder="informe o nome">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="cnpj">CNPJ Parcerias</label>
                        <input style="width: 100% " id="cnpj" name="busca.cnpj" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.cnpj " [value]="busca.cnpj" (keydown.enter)="geraArquivo('XLS')" class="form-control" placeholder="informe o CNPJ" [textMask]="mascaraCNPJ">
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="nroTermo">Número do termo</label>
                        <input style="width: 100% " id="nroTermo" name="busca.numeroTermo" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.numeroTermo " [value]="busca.numeroTermo" (keydown.enter)="geraArquivo('XLS')" class="form-control"
                            placeholder="informe o nº do termo">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Modalidade</label>
                        <div class="select-wrapper">
                            <select [ngModel]="busca.natureza" (change)="selectStatusParceria($event)" class="form-control form-select custom-select">
                                <option [value]="option.id" *ngFor="let option of this.utils.modalidadeOptions">{{option.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label for="dataIni">Data Início Pagamentos</label>
                    <input class="form-control" placeholder="data inicial" maxlength="10" size="10"
                        name="dataIni" #dataIni="ngModel" [(ngModel)]="dataInicialFormat" 
                        appMaskInput [appMaskValue]="dataInicialFormat"
                        ngbDatepicker #dI="ngbDatepicker"/>
                   <i class="fa fa-calendar icon_input_date" (click)="dI.toggle()" title="calendário"></i>
                </div>
                <div class="form-group col-md-4">
                    <label for="dataFim">Data Fim Pagamentos</label>
                    <input class="form-control" placeholder="data final" maxlength="10" size="10"
                                    name="dataFim" #dataFim="ngModel" [(ngModel)]="dataFinalFormat"
                                    appMaskInput  [appMaskValue]="dataFinalFormat" ngbDatepicker #dp="ngbDatepicker"
                                    ngbDatepicker #dF="ngbDatepicker"/>
                                <i class="fa fa-calendar icon_input_date" (click)="dF.toggle()" title="calendário"></i>
                </div>
                
            </div>
            <div class="form-row">
                <div class="col-md-4 btn-inline">
                    <div class="form-group">

                        <div class="select-wrapper" style="padding-top: 38px;">
                            <button class="btn btn-primary" (click)="limparFiltros()">Limpar</button>
                           <!-- <button class="btn btn-primary" (click)="geraArquivo('PDF')">Gerar PDF</button>-->
                            <button class="btn btn-primary" (click)="geraArquivo('XLS')">Gerar Excel</button>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div [hidden]="!(loading$ | async) ">
                <div class="loading-overlay "></div>
                <div class="loading-info ">
                    <i class="fa fa-spinner fa-4x fa-spin "></i>
                    <div id="carregando ">Aguarde</div>
                </div>
            </div>
        </div>
    </div>
</div>