import { Component, EventEmitter, Input, Output } from '@angular/core'
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { now } from 'lodash'
import { DateConverter } from 'src/app/@core/date-converter'
import { NgbDateBRParserFormatter } from './ ngbdate-br-parser-formatter.service'
import { DatetimeRange } from './datatime-range'

@Component({
  selector: 'selector-datetime-range',
  templateUrl: './selector-datatime-range.component.html',
  styleUrls: ['./selector-datetime-range.component.scss'],
  providers: [ {provide: NgbDateParserFormatter, useClass: NgbDateBRParserFormatter} ]
})
export class SelectorDateTimeRangeComponent {
  @Output() novoPeriodo = new EventEmitter<DatetimeRange>()

  @Input()
  isOnlyDataInicio: boolean;

  @Input()
  showLabelData: boolean;
  
  // minDate: NgbDate = { day: 1, month: 1, year: 2000 }
  hoveredDate: NgbDate | null = null

  fromDate: NgbDate | null
  toDate: NgbDate | null

  fromDateStr: string | null
  toDateStr: string | null

  constructor(
    private readonly calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public dateConverter: DateConverter
  ) {}

  onDateSelection(ngbDatepicker: any, date: NgbDate): void {
    if (date) {

      if (!this.fromDate && !this.toDate) {
        this.fromDate = date
        this.fromDateStr = this.formatDate(date)
      } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
        this.toDate = date
        this.toDateStr = this.formatDate(date)
      } else {
        this.toDate = null
        this.toDateStr = null
        this.fromDate = date
        this.fromDateStr = this.formatDate(date)
      }

      if (this.fromDate != null && this.toDate != null) {
        this.emitPeriodo()
        ngbDatepicker.close()
      }
    }
  }

  emitPeriodo() {

      if (this.fromDate === undefined || this.fromDate === null){
        this.fromDate = this.dateConverter.fromDateToNgbDate(new Date());
      }
      const fromDate = new Date(
        this.fromDate.year,
        this.fromDate.month - 1,
        this.fromDate.day,
      )
      if (this.toDate === undefined || this.toDate === null){
        this.toDate = this.dateConverter.fromDateToNgbDate(new Date());
      }
      const toDate = new Date(
        this.toDate.year,
        this.toDate.month - 1,
        this.toDate.day,
      )

      this.novoPeriodo.emit({
        fromNgbDate: this.fromDate,
        toNgbDate: this.toDate,
        fromDate: this.dateConverter.fromNgbDateStructToTimestamp(this.fromDate),
        toDate: this.dateConverter.fromNgbDateStructToTimestamp(this.toDate),
        fromTimestamp: fromDate.getTime(),
        toTimestamp: toDate.getTime(),
        fromIso8601: this.dateToIso8601(fromDate),
        toIso8601: this.dateToIso8601(toDate),
      })
  }

  dateToIso8601(date: Date) {
    let iso = date.getFullYear().toString() + '-'
    const month = date.getMonth() + 1
    iso += month.toString().padStart(2, '0') + '-'
    iso += date.getDate().toString().padStart(2, '0') + 'T'
    iso += date.getHours().toString().padStart(2, '0') + ':'
    iso += date.getMinutes().toString().padStart(2, '0') + ':'
    iso += date.getSeconds().toString().padStart(2, '0')

    return iso
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    )
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate)
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    )
  }

  parseInput(value: string): NgbDateStruct | null {
    const parsed = this.formatter.parse(value)

    if (parsed && this.calendar.isValid(NgbDate.from(parsed))) {
      return parsed
    }

    return null
  }

  isValidDate(value: string): boolean {
    const parsed = this.formatter.parse(value)
    const date = NgbDate.from(parsed)

      if (this.calendar.isValid(date)) { {
        return !date.after(this.calendarEndDay)
      }
    }

    return true
  }

  formatDate(date: NgbDateStruct): string {
    if (date) {
      return this.formatter.format(date)
    }

    return null
  }

  atualizarToDate(dateStr: string) {
    if (this.isValidDate(dateStr)) {
      const parsed = this.formatter.parse(dateStr)
      this.toDate = NgbDate.from(parsed)
    }
  }

  atualizarFromDate(dateStr: string) {
    if (this.isValidDate(dateStr)) {
      const parsed = this.formatter.parse(dateStr)
      this.fromDate = NgbDate.from(parsed)
    }
  }

  limparDatas(): void {
    this.toDate = null
    this.toDateStr = null
    this.fromDate = null
    this.fromDateStr = null
  }

  focusDate(ngbDatepicker: any, date: NgbDate): void {
    ngbDatepicker.focusDate(date)
    ngbDatepicker.focusSelect()
  }

  //isDisabled = (date: NgbDate, current: {month: number}) => date.after(this.calendarEndDay)

  get today(): NgbDate {
    return this.calendar.getToday()
  }

  get calendarStartDay(): NgbDate {
    if (this.fromDate) {
      return this.fromDate
    }

    if (this.toDate) {
      return this.calendar.getPrev(this.toDate, 'm', 2)
    }

    return this.calendar.getPrev(this.today, 'm', 2)
  }

  get calendarEndDay(): NgbDate {
    return this.today
  }

}