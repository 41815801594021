import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../@core/user.service';
import { ParceriaInfo } from '../../modulo2/detalheParceria/parceriaInfo';
;

import { KeycloakService } from '@procempa/ngx-keycloak';
import { BehaviorSubject, Subscription } from 'rxjs';
import { share } from 'rxjs/operators';
import { EnvioDadoService } from 'src/app/@shared/components/filtroBusca/envioDado.service';
import { DetalheParceriaPortalApi } from 'src/app/apis/detalheParceriaPortalApi';


@Component({
  selector: 'app-exibe-parceria-mt',
  templateUrl: './exibeParceriasMT.component.html',
  styleUrls: ['./exibeParceriasMT.component.scss']
})

export class ExibeParceriasMTComponent implements OnInit, OnDestroy {

  parceriaArray: Array<ParceriaInfo>;
  countParcerias: number;
  currentPage: number;
  isFiscal: boolean;
  isConsulta: boolean;
  isSMTC: boolean;
  subscription: Subscription;
  collectionSizeParceria: number;

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());

  constructor(public userService: UserService,
    private router: Router,
    private envioDadoService: EnvioDadoService,
    public keycloakService: KeycloakService,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
  ) {
  }

  ngOnInit() {
    this.detalheParceriaPortalApi.getUp().subscribe()
    this.isFiscal = this.userService.isFiscal();
    this.isConsulta = this.userService.isConsulta();
    if (!(this.isFiscal || this.isConsulta)) {
      this.router.navigate(['notAuthorized']);
    }

    this.subscription = this.envioDadoService.enviaParceriaArray$.subscribe((message) => {
      this.parceriaArray = message;
    });

    // this.collectionSizeParceria = 188;
    // this.countParcerias = 469;
    // this.currentPage = 1;

    this.envioDadoService.enviaParceriaArraySize$.subscribe((count) => {
      this.countParcerias = count;
      this.collectionSizeParceria = Math.ceil((count / 25) * 10);
     
    });
    this.envioDadoService.enviaCurrentPage$.subscribe((count) => {
      this.currentPage = count;
      
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onPageChange(pageNumber) {
    if (isNaN(pageNumber)) {
      return;
    }
    this.envioDadoService.changePagina(pageNumber);
  }

}
