import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from '../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../../apis/detalheParceriaApi';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { Mascaras } from '../../@shared/components/Mascaras';
import { UserService } from '../../@core/user.service';
import { ParceriaPrestaContaFinal } from '../prestacaoDeContasFinal/parceriaPrestaContaFinal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from 'src/app/@core/date-converter';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';


@Component({
  selector: 'app-prestacao-final-parceria',
  templateUrl: './../prestacaoDeContasFinal/prestacaoFinal.component.html',
  styleUrls: ['./../../modulo2/detalheParceria/detalheParceria.component.scss']
})
export class PrestacaoContasFinalComponent implements OnInit {

  @Input()
  public  parceriaInfo: ParceriaInfo;
  @Input()
  public  isPortal: boolean;
  @Output() valueChange = new EventEmitter();
  prestaContaFinal: Array<ParceriaPrestaContaFinal> = [];
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  modalEdicaoReference: any;
  prestacaoContasFinalSend: ParceriaPrestaContaFinal;
  showUpload: boolean = false;

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private parceriaDespesaDevolApi: PublicoPrivadaApi,
    public userService: UserService,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private mascaras: Mascaras) {

    }

  ngOnInit() {
    this.setupInterface();
  }


  logout() {
    this.userService.logout();
  }

  setupInterface() {
 
    this.loading.next(true);

    this.route.paramMap.pipe(
        switchMap(() =>
         this.parceriaDespesaDevolApi.getPrestaContaFinal(this.parceriaInfo.parId, this.isPortal))
         ).subscribe((response) => {
          if (response) {
            this.prestaContaFinal = response as Array<ParceriaPrestaContaFinal>;
            // console.log(this.valoresLiberados);
          } else {
            this.alertService.warning('Nenhum registro encontrado');
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));

  }
  
  getArquivo(parId, id, tipo, nome) {
    this.loading.next(true);
    this.parceriaDespesaDevolApi.getArquivo(parId, id, tipo, this.isPortal)
      .subscribe((response) => {
        if (response) {
          console.log(response);
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(response.body);
          a.href = url;
          a.download = nome;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          this.alertService.warning('Nenhum arquivo encontrado');
        }
      }, (response) => {
        // if (response.status >= 500) {
        //   this.alertService.danger(response.error);
        // } else {
        //   this.alertService.warning(response.error);
        // }
        this.alertService.danger('Erro ao buscar arquivo.');
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  updatePublica(event) {
    const newVal = event.currentTarget.value;
    this.prestacaoContasFinalSend.prestaFinalDocumentoPublicar = newVal;
  }

  setPrestacaoContasFinal(doc) {
    if (doc) {
      this.prestacaoContasFinalSend = doc;
      this.showUpload = this.prestacaoContasFinalSend.prestaFinalDocumentoArquivo ? false : true;
      this.prestacaoContasFinalSend.prestaFinalDocumentoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.prestacaoContasFinalSend.prestaFinalData));
    } else {
      this.prestacaoContasFinalSend = new ParceriaPrestaContaFinal();
      this.showUpload = true;
      this.prestacaoContasFinalSend.parId = this.parceriaInfo.parId;
      this.prestacaoContasFinalSend.prestaFinalId = -1;
      this.prestacaoContasFinalSend.prestaFinalDocumentoTipo = '';
    }

  }

  salvaPrestacaoContasFinal() {


    if (this.prestacaoContasFinalSend.prestaFinalDocumento === null || this.prestacaoContasFinalSend.prestaFinalDocumento === undefined) {
      this.alertService.danger('Dado obrigatório, realize o upload do arquivo.');
      return;
    }
    // tslint:disable-next-line:max-line-length
    if ( (this.prestacaoContasFinalSend.prestaFinalDocumentoArquivo != null && this.prestacaoContasFinalSend.prestaFinalDocumentoArquivo.length > 50)) {
      this.alertService.danger('Nome do arquivo superior a 50 caracteres, por favor renomeie antes de fazer upload.');
      return;
    }
    // tslint:disable-next-line:max-line-length
    this.prestacaoContasFinalSend.prestaFinalData = this.dateConverter.fromNgbDateStructToDate(this.prestacaoContasFinalSend.prestaFinalDocumentoDataFormat);
    let data = JSON.stringify(this.prestacaoContasFinalSend, this.replacerDocComplementar);
    this.detalheParceriaApi.updatePrestacaoFinalConta(data).subscribe(async (response) => {
      if (response) {
        this.setupInterface();
        this.setPrestacaoContasFinal(undefined);
        this.modalEdicaoReference.close();
        //await this.getParceriaInfo(this.prestacaoContasFinalSend.parId);
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }

  excluirPrestacaoContasFinal() {
    this.detalheParceriaApi.excluirPrestacaoFinalConta(this.prestacaoContasFinalSend).subscribe((response) => {
      if (response) {
        this.setupInterface();
        this.setPrestacaoContasFinal(undefined);
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }

  replacerDocComplementar(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['prestaFinalDocumentoDataFormat'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  openEdicao(content, doc) {
    this.setPrestacaoContasFinal(doc);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false, windowClass: "modalProcesso" });
  }

  openExcluir(content, doc) {
    this.setPrestacaoContasFinal(doc);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false });
  }

  onFileDocChanged(event) {
    const fileList: FileList = event.currentTarget.files;
    console.log(fileList);
    const reader = new FileReader();


    if (fileList.length > 0) {
      const array = fileList[0].type.split('/');
      const fileSize = fileList[0].size / 1024 / 1024; // in MB
      if (fileSize > 1) {
        this.alertService.danger('Tamanho de arquivo superior ao limite de 1MB. Esse arquivo possui ' + fileSize.toPrecision(1)+"MB");
        return;
      }
      if (array[1] === 'jpeg' || array[1] === 'jpg' || array[1] === 'gif'
        || array[1] === 'png' || array[1] === 'pdf') {

        reader.readAsDataURL(fileList[0]);
        reader.onload = () => {
          this.prestacaoContasFinalSend.prestaFinalDocumento = ((reader.result).toString().split(',')[1]) as unknown as Blob;
          this.prestacaoContasFinalSend.prestaFinalDocumentoTipo = array[1];
          this.prestacaoContasFinalSend.prestaFinalDocumentoArquivo = fileList[0].name;
        };
      } else {
        event.currentTarget.value = '';
        this.alertService.warning('Formato de arquivo não suportado!');
      }
    }
  }
}

