import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PaginationInstance } from "ngx-pagination";
import { BehaviorSubject } from "rxjs";
import { share, switchMap } from "rxjs/operators";
import { AlertService } from "src/app/@core/alert.service";
import { UserService } from "src/app/@core/user.service";
import { Utils } from "src/app/@shared/utils";
import { DetalheParceriaApi } from "src/app/apis/detalheParceriaApi";
import { RelatoriosApi } from "src/app/apis/relatoriosApi";
import { BuscaPriorizacaoCompletaCampos } from "./buscaPriorizacaoCompletaCampos";
import { PriorizacaoCompletaDTO } from "./priorizacaoCompletaDto";

@Component({
    selector: 'app-auditoria-priorizacao-completa',
    templateUrl: './priorizacaoCompleta.component.html',
    styleUrls: ['./priorizacaoCompleta.component.scss']
  })
  export class PriorizacaoCompletaAuditoriaComponent{
    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable().pipe(share());
    busca: BuscaPriorizacaoCompletaCampos; 
    
    isFiscal: boolean;
    isSMTC: boolean;
    isConsulta: boolean;
    orgaoNome: string;
    priorizacaoCompletaArray: Array<PriorizacaoCompletaDTO>;
    countPriorizacao: number;
    collectionSizePriorizacao: number = 0;
    public config: PaginationInstance = {
      id: 'custom',
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.collectionSizePriorizacao,
    };

    constructor(private route: ActivatedRoute,
      private alertService: AlertService,
      private detalheParceriaApi: DetalheParceriaApi,
      public userService: UserService,
      private relatorioApi: RelatoriosApi,
      public utils: Utils
      ) {
       
        this.busca = new BuscaPriorizacaoCompletaCampos();
        this.busca.limite = 10;
        this.busca.nivelTipo='F';
        this.busca.cnpjNome='';
        this.busca.orgao=0;
        this.busca.pagina=1;
        this.orgaoNome = this.utils.getSecretariaStr("0");
        this.setupInterface();
      }

      ngOnInit() {
        this.isFiscal = this.userService.isFiscal();
        this.isSMTC = this.userService.isSMTC();
        this.isConsulta = this.userService.isConsulta();

      }

      setupInterface() {

        this.loading.next(true);
        this.busca.limite = 10;
        this.route.paramMap.pipe(
            switchMap(() =>
             this.detalheParceriaApi.getPriorizacaoCompleta(this.busca))
             ).subscribe(async (response) => {
              if (response) {
                this.priorizacaoCompletaArray = response as Array<PriorizacaoCompletaDTO>;             
                // console.log(this.valoresLiberados);
                await this.getCountPriorizacaoCompleta();
              } else {
                this.alertService.warning('Nenhum registro encontrado');
              }
            }, (response) => {
              if (response.status >= 500) {
                this.alertService.danger(response.error);
              } else {
                this.alertService.warning(response.error);
              }
              this.loading.next(false);
            }, () => this.loading.next(false));
    
      }

      async getCountPriorizacaoCompleta() {
        this.loading.next(true);
        //console.log(this.userService.userInfo);
        this.detalheParceriaApi.getCountPriorizacaoCompleta(this.busca)
          .subscribe((response) => {
            if (response) {
              this.countPriorizacao = response as number;
              this.collectionSizePriorizacao = Math.ceil((this.countPriorizacao / 10.0));
              this.config.totalItems = this.countPriorizacao;
            } else {
              this.alertService.warning('Nenhum registro encontrado');
              this.countPriorizacao = 0;
              this.collectionSizePriorizacao =0;
              this.config.totalItems = this.countPriorizacao;
            }
          }, (response) => {
            if (response.status >= 500) {
              this.alertService.danger(response.error);
            } else {
              this.alertService.warning(response.error);
            }
            this.loading.next(false);
          }, () => this.loading.next(false));
    
      }

      selectNaoInspecionadas() {
        this.setupInterface();
      }
      selectInspecionadas() {
        this.setupInterface();
      }
      selectNivelTipo(opcao) {
          this.busca.nivelTipo = opcao;
      }
      selectCNPJNome() {
        this.setupInterface();
      }
      selectCNPJ(){}

      selectOrgaoParceria(event){
        const newVal = event.currentTarget.value;
        this.busca.orgao = newVal;
        this.setupInterface();
      }

      pageChanged(event) {
        //console.log(event);
        this.config.currentPage = event;
        this.busca.pagina = event;
        this.setupInterface();
      }

      getFile() {
        this.loading.next(true);
        this.busca.limite = 0;
        this.relatorioApi.getPriorizacaoPlanilha(this.busca)
          .subscribe((response) => {
            if (response) {
              const a = document.createElement('a');
              const url = window.URL.createObjectURL(response);
              a.download = 'matriz_risco_parceria .xls';
              a.href = url;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
    
            } else {
              this.alertService.warning('Nenhum resultado foi obtido, por favor refaça a busca com outros critérios.');
            }
          }, (response) => {
            if (response.status >= 500) {
              this.alertService.danger(response.error);
            } else {
              this.alertService.warning(response.error);
            }
            this.loading.next(false);
          }, () => this.loading.next(false));
      }
  } 

