import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class KeycloakInterceptor implements HttpInterceptor {

  constructor(public auth: KeycloakService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log(request);
    const url = window.location.origin.split("//");
    let logoutUrl = '';
    if (url[1] === 'localhost:4200') {
      logoutUrl = environment.keycloak.urlLogin;
    } else {
      const urlIs = url[1].split(".");
      console.log('UrlIs..............', urlIs[0]);
      if (urlIs[0] === 'sgp' || urlIs[0] === 'sgp-hom') {
        logoutUrl = environment.acessoPoa.urlLogin;
      }
      if (urlIs[0] === 'sgp-admin' || urlIs[0] === 'sgp-admin-hom') {
        logoutUrl = environment.keycloak.urlLogin;
      }
      else {
        logoutUrl = environment.acessoPoa.urlLogin;
      }
    }

    if (this.auth.authenticated() && this.auth.isExpired()) {
      //this.auth.login();
      this.autoRefreshToken();
    }
    return from(this.auth.getToken())
      .pipe(
        catchError(error => {
          console.log("Error on KC interceptor: ", error);
          if (!window.location.pathname.includes('Portal')) {
            this.auth.logout(logoutUrl);
          }
          return of(null);
        }),
        switchMap(token => {
          if (request.url.includes('rest/secure')) {
            return next.handle(request.clone({ setHeaders: { Authorization: `Bearer ${token}` }, withCredentials: true }));
          }
          else {
            return next.handle(request.clone());
          }
        }));
  }

  autoRefreshToken() {
    setTimeout(function () {
      if (this.auth && this.auth.token) {
        this.auth.updateToken(240).success(function () {
          console.log('Token auto refreshed');
        }).error(function (err) {
          console.error('Error trying to renew token: ' + err);
        });
      }
      this.autoRefreshToken();
    }, 10 * 30000);
  }

}
