import { DatePipe } from "@angular/common";
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '../../../@core/alert.service';
import { ParceriaInfo } from '../../detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../../../apis/detalheParceriaApi';
import { UserService } from '../../../@core/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Notificacao } from "../../../notificacoes/notificacao";
import { NotificacaoItem } from "../../../notificacoes/notificacaoItem";
import { PesquisaData } from "../../pesquisaData";

@Component({
    selector: 'app-notificacoes',
    templateUrl: './notificacoes.component.html',
    styleUrls: ['./../detalheParceria.component.scss'],
    providers: [DatePipe]
})
export class NotificacoesComponent {
    @Input()
    public parceriaInfo: ParceriaInfo;
    @Input()
    private isPortal: boolean;
    @Input()
    pesquisaData: PesquisaData;
    @Output() valueChange = new EventEmitter();
    notificacoes: Array<Notificacao> = [];
    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable().pipe(share());
    modalEdicaoReference: any;
    showUpload: boolean = false;
    notificacaoSend: Notificacao;
    isFiscal: boolean;
    isSMTC: boolean;
    isConsulta: boolean;
    inValidCpf: boolean = false;
    verJustificativa: boolean = false;
    @Input()
    isAuditorExterno: boolean;
    username: string;

    constructor(private route: ActivatedRoute,
        private alertService: AlertService,
        private detalheParceriaApi: DetalheParceriaApi,
        public userService: UserService,
        private modalService: NgbModal,
        private datePipe: DatePipe) {
    }

    ngOnInit() {
        this.userService.userInfo.subscribe(user => this.username = user.username);
        this.isFiscal = this.userService.isFiscal();
        this.isSMTC = this.userService.isSMTC();
        this.isConsulta = this.userService.isConsulta();
        if (this.pesquisaData === undefined || this.pesquisaData.dataInicial === undefined) {
            const myDate = new Date(new Date().getFullYear(), (Number(new Date().getMonth())), 1, 0, 0, 0);
            this.pesquisaData = new PesquisaData();
            this.pesquisaData.dataInicial = myDate;
            this.pesquisaData.dataFinal = new Date();
        }
        this.setupInterface();
    }

    setupInterface() {
        this.loading.next(true);
        const myDate = new Date(new Date().getFullYear(), (Number(new Date().getMonth())), 1, 0, 0, 0);
        if (this.pesquisaData === undefined || this.pesquisaData.dataInicial === undefined) {
            this.pesquisaData = new PesquisaData();
            this.pesquisaData.dataInicial = myDate;
            this.pesquisaData.dataFinal = new Date();
            this.pesquisaData.tipo = "1";
        }
        this.route.paramMap.pipe(
            switchMap(() =>
                this.detalheParceriaApi.getNotificacoes(this.parceriaInfo.parId, this.datePipe.transform(this.pesquisaData.dataInicial, 'dd/MM/yyyy'),
                    this.datePipe.transform(this.pesquisaData.dataFinal, 'dd/MM/yyyy'), (this.isFiscal || this.isSMTC)))
        ).subscribe((response) => {
            if (response) {
                this.notificacoes = response as Array<Notificacao>;
                this.notificacoes.forEach(element => {
                    element.situacaoDataStr = this.datePipe.transform(element.situacaoData, 'dd/MM/yyyy');
                });
            } else {
                this.alertService.warning('Nenhum registro encontrado');
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));
    }

    setNotificacao(doc) {
        if (doc) {
            this.notificacaoSend = doc;
            this.notificacaoSend.textoPeriodo = "de " + this.datePipe.transform(this.notificacaoSend.dataInicio, 'dd/MM/yyyy') + " a " + this.datePipe.transform(this.notificacaoSend.dataFim, 'dd/MM/yyyy');
            if (this.notificacaoSend.id == -1) {
                this.notificacaoSend.situacaoUsuario = this.username;
            }
        } else {
            this.notificacaoSend = new Notificacao();
            this.notificacaoSend.id = -1;
            this.notificacaoSend.situacaoId = 1;
            this.notificacaoSend.notificacaoItens = [];
            this.notificacaoSend.situacaoUsuario = this.username;
        }
    }

    openEdicao(content, doc) {
        this.verJustificativa = false;
        if (doc == undefined) {
            if (!this.pesquisaData.dataFinal) {
                this.pesquisaData.dataFinal = new Date();
            }
            if (!this.username) {
                this.userService.userInfo.subscribe(user => this.username = user.username);
            }
            console.log(this.pesquisaData);
            this.detalheParceriaApi.novaNotificacao(this.parceriaInfo.parId, this.pesquisaData.dataInicial, this.pesquisaData.dataFinal, this.username).subscribe((response) => {
                if (response) {
                    this.setNotificacao(response);
                    if (response.notificacaoItens.length == 0) {
                        this.adicionarItem();
                    }
                }
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));
        } else {
            this.detalheParceriaApi.getNotificacao(doc.id).subscribe((response) => {
                if (response) {
                    this.setNotificacao(response);
                }
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));
        }
        this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: "modalProcesso" });
    }

    abreJustificativa() {
        this.verJustificativa = true;
        setTimeout(function () {
            document.getElementById("justificativa").scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });
        }, 0);
    }

    fechaJustificativa() {
        this.verJustificativa = false;
    }

    adicionarItem() {
        var notificacaoItem = new NotificacaoItem();
        notificacaoItem.id = -1;
        notificacaoItem.notifId = this.notificacaoSend.id;
        notificacaoItem.parId = this.notificacaoSend.parId;
        notificacaoItem.situacaoId = 1;
        this.notificacaoSend.notificacaoItens.push(notificacaoItem);
    }

    selecionaSituacao(event, notifItem) {
        if (event === undefined) {
            notifItem.situacaoId = 1;
        } else {
            notifItem.situacaoId = event.situacaoId;
        }
    }

    updateTextAreaApontamento(valor, notificacaoItem) {
        notificacaoItem.apontamento = valor;
    }

    updateTextAreaObservacaoOsc(valor, notificacaoItem) {
        notificacaoItem.observacaoOsc = valor;
    }

    updateTextAreaObservacaoAnalista(valor, notificacaoItem) {
        notificacaoItem.observacaoAnalista = valor;
    }

    updateTextAreaJustificativa(valor) {
        this.notificacaoSend.justificaReabertura = valor;
    }

    isBlank(str) {
        return (!str || /^\s*$/.test(str));
    }

    validaForm() {
        let validoApt = true;
        if (this.isBlank(this.notificacaoSend.nome)) {
            this.alertService.danger('Nome da notificação deve ser informado.');
            return false;
        }
        if (this.notificacaoSend.diasPrazo === null) {
            this.alertService.danger('Prazo de resposta deve ser informado.');
            return false;
        }
        if (this.notificacaoSend.notificacaoItens.length == 0) {
            this.alertService.danger('Notificação deve ter pelo menos um item.')
            return false;
        }
        this.notificacaoSend.notificacaoItens.forEach(element => {
            if (this.isBlank(element.apontamento)) {
                this.alertService.danger('Apontamento deve ser preenchido.')

                return validoApt = false;
            }
        });

        if (validoApt)
            return true;
        else
            return false;
    }

    salvaNotificacao() {
        if (this.validaForm()) {
            this.notificacaoSend.situacaoId = (this.isFiscal || this.isSMTC) ? 1 : 6;
            this.atualizaNotificacao();
        }
    }

    enviaNotificacao() {
        if (this.validaForm()) {
            this.notificacaoSend.situacaoId = 2;
            this.atualizaNotificacao();
        }
    }

    respondeNotificacao() {
        if (this.validaForm()) {
            this.notificacaoSend.situacaoId = 3;
            this.atualizaNotificacao();
        }
    }

    concluiNotificacao() {
        if (this.validaForm()) {
            this.notificacaoSend.situacaoId = 4;
            this.atualizaNotificacao();
        }
    }

    reabreNotificacao() {
        if (this.validaForm()) {
            if (this.isBlank(this.notificacaoSend.justificaReabertura)) {
                this.alertService.danger('Justificativa de reabertura deve ser informada.');
                return;
            }
            this.notificacaoSend.situacaoId = 5;
            this.atualizaNotificacao();
        }
    }

    replacerNotificacao(key, value) {
        //atributos que só existem no view e não no servidor
        const ignoreList = ['situacaoDataStr', 'textoPeriodo'];
        if (ignoreList.indexOf(key) > -1) {
            return undefined;
        } else {
            return value;
        }
    }

    atualizaNotificacao() {
        this.loading.next(true);
        if (!this.username) {
            this.userService.userInfo.subscribe(user => this.username = user.username);
        }
        this.notificacaoSend.situacaoUsuario = this.username;
        let data = JSON.stringify(this.notificacaoSend, this.replacerNotificacao);
        this.detalheParceriaApi.updateNotificacao(data).subscribe(async (response) => {
            if (response) {
                this.setupInterface();
                this.setNotificacao(undefined);
                this.modalEdicaoReference.close();
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
            this.modalEdicaoReference.close();
        }, () => this.loading.next(false));
    }

    excluirNotificacaoItem(notificacaoItem) {
        const index: number = this.notificacaoSend.notificacaoItens.indexOf(notificacaoItem);
        if (index !== -1) {
            this.notificacaoSend.notificacaoItens.splice(index, 1);
        }
    }
}