import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ParceriaLogon } from '../modulo2/exibeParceriaPorUsuario/parceriaLogon';
import { AuditorLogon } from '../liberacaoLogon/auditor/auditorLogon';


const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8')
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class LogonApi {


    constructor(private http: HttpClient) {
    }

    getParceriaLogon(email) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<ParceriaLogon>>(`${environment.serverHost}/secure/${environment.realm}/logonApis/logon?email=${email}`);
    }

    getListaParceriasLogon(email) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<Array<String>>>(`${environment.serverHost}/secure/${environment.realm}/logonApis/listaParceriasLogon?email=${email}`);
    }

    getListaParceriasOrgao(orgao) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<Array<String>>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/listaParceriasOrgao?orgao=${orgao}`);
    }

    getParceriaCNPJ(email) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/secure/${environment.realm}/logonApis/cnpj?email=${email}`, { headers, responseType: 'text' });
    }

    getParceriasByCnpj(cnpj) {
        return this.http.get<Array<Array<String>>>(`${environment.serverHost}/secure/${environment.realm}/parcerias/getParceriasByCnpj?cnpj=${cnpj}`);
    }
    getParceriasByBuscaCampos(campos) {
        return this.http.post<Array<ParceriaLogon>>(`${environment.serverHost}/secure/${environment.realm}/logonApis/buscaParceriaLogon`, campos);
    }
    getCountParceriaLogon(campos) {
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/logonApis/countParceriaLogon`, campos);
    }
    excluirLogon(parLogonSend: ParceriaLogon) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/logonApis/logon/${parLogonSend.parLogonId}/${parLogonSend.parId}/${parLogonSend.parLogonOperAtu}`,
            { observe: 'response', headers: httpOptions.headers, body: parLogonSend });
    }
    getNomeParceira(cnpj) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/secure/${environment.realm}/parcerias/nomeParceria?cnpj=${cnpj}`, { headers, responseType: 'text' });
    }
    updateParceriaLogon(parLogonSend) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/logonApis/logonUpdate`,
            { observe: 'response', headers: httpOptions.headers, body: parLogonSend });
    }

    getAuditorLogon(email) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<AuditorLogon>>(`${environment.serverHost}/secure/acessopoa/logonApis/logonAuditor?email=${email}`);
    }


    getAuditoresLogon(campos) {
        // tslint:disable-next-line:max-line-length
        return this.http.post<Array<AuditorLogon>>(`${environment.serverHost}/secure/${environment.realm}/logonApis/listaAuditores`, campos);
    }


    updateAuditorLogon(auditorLogonSend) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/logonApis/logonAuditorUpdate`,
            { observe: 'response', headers: httpOptions.headers, body: auditorLogonSend });
    }

    deleteAuditorLogon(auditorLogonSend: AuditorLogon) {
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/logonApis/logonAuditor/${auditorLogonSend.idAuditor}/${auditorLogonSend.operador}`,
            { observe: 'response', headers: httpOptions.headers, body: auditorLogonSend });
    }
}
