<div class="container">
    <h1 class="principal">Visitação</h1>
    <p>Informe o órgão, o nº do termo e o ano em que ocorreu a visitação para inclui-lo na listagem de parcerias
        inspecionadas:</p>
    <div class="form-row">
        <div class="form-group col-lg-5 col-md-6">
            <label for="orgao">Órgão</label>
            <select class="form-control form-select custom-select" [(ngModel)]="orgao">
                <option value="10">Secretaria Municipal da Cultura</option>
                <option value="15">Secretaria Municipal de Educação</option>
                <option value="18">Secretaria Municipal de Saúde</option>
                <option value="31">Departamento Municipal de Habitação</option>
                <option value="60">Fundação de Assistencia Social e Cidadania</option>
                <option value="73">Secretaria Municipal de Desenvolvimento Social</option>
                <option value="75">Secretaria Municipal do Meio Ambiente, Urbanismo e Sustentabilidade</option>
                <option value="80">Secretaria Municipal de Governança Local e Coordenação Política</option>
                <option value="84">Secretaria Municipal de Esporte, Lazer e Juventude</option>
            </select>
        </div>
        <div class="form-group col-lg-3 col-md-6">
            <label for="numTermo">Termo de colaboração</label>
            <input type="text" class="form-control" id="parNum" placeholder="informe o nº do termo"
                [(ngModel)]="parNum">
        </div>
        <div class="form-group col-lg-2 col-md-6">
            <label for="anoVisitacao">Ano da visitação</label>
            <input type="text" class="form-control" id="anoVisitacao" placeholder="informe o ano"
                [(ngModel)]="anoVisitacao">
        </div>
        <div class="col-lg-1 col-md-4 input-sem-titulo">
            <button class="btn btn-primary" (click)="salvaVisitacao()">Incluir</button>
        </div>
    </div>
    <!-- tabela priorização financeira -->
    <table class="table table-hover" *ngIf="visitacoes.length>0">
        <thead>
            <tr>
                <th>Órgão</th>
                <th>Nº do termo</th>
                <th>Parceria</th>
                <th>Visitação</th>
                <th>Expiração<i class="fa fa-info-circle help"
                        ngbTooltip="Ano em que a parceria retorna à lista de priorizações"></i></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let visitacao of visitacoes">
                <td>{{visitacao.nomeOrgao}}</td>
                <td>{{visitacao.parNum}}</td>
                <td>{{visitacao.parOrgNome}}</td>
                <td>{{visitacao.anoVisitacao}}</td>
                <td>{{visitacao.anoExpiracao}}</td>
                <td><i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true"
                        (click)="openExcluir(contentExcluir, visitacao)"></i></td>
            </tr>
        </tbody>
    </table>
    <div class="row linha-select" *ngIf="visitacoes.length==0">
        <span class="mensagem-vazio">Nenhuma visitação encontrada para os critérios selecionados.</span>
    </div>
</div>
<ng-template #contentExcluir let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Excluir Visitação</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Tem certeza que deseja excluir a visitação abaixo?</p>
        Órgão: <b>{{visitacaoSend.nomeOrgao}}</b><br />
        Termo: <b>{{visitacaoSend.parNum}}</b><br />
        Parceria: <b>{{visitacaoSend.parOrgNome}}</b><br />
        Ano visitação: <b>{{visitacaoSend.anoVisitacao}}</b><br />
        Ano expiração: <b>{{visitacaoSend.anoExpiracao}}</b>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">Fechar</button>
        <button type="button" class="btn btn-secondary" (click)="excluirVisitacao()" title="Excluir">Excluir</button>
    </div>
</ng-template>