export class ResumoFluxoFinanceiro {

    public execTotVlrConsumo: number;
    public execTotVlrPF: number;
    public execTotVlrPJ: number;
    public execTotVlrPerm: number;
    public execTotVlrOutros: number;
    public totalDesembolsos: number;
    public execDevolRecursos: number;
    public execDevolAplic: number;
    public execDevolParceria: number;
    public execTotIngressos: number;
    public execSaldoFinal: number;
    public execDevolPoupanca: number;
    public execDevolOutrasContas: number;
}

