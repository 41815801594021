import { AbstractControl, ValidatorFn } from '@angular/forms';


// validation function
export function inputZeroFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    let isValid = c.value && c.value > 0;

    if (isValid) {
      return null;
    } else {
      return {
        inputZero: {
          valid: false
        }
      };
    }

  }
}
