import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share, filter } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from './../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../../apis/detalheParceriaApi';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { Mascaras } from '../../@shared/components/Mascaras';
import { UserService } from '../../@core/user.service';
import { ParceriaValoresLiberados } from './parceriaValoresLiberados';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ZPagamentoParcerias } from './zPagamentoParcerias';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';


@Component({
  selector: 'app-valores-pagos-parceria',
  templateUrl: './../valoresPagos/valoresPagos.component.html',
  styleUrls: ['./../../modulo2/detalheParceria/detalheParceria.component.scss']
})
export class ValoresPagosComponent implements OnInit {

  @Input()
  public parceriaInfo: ParceriaInfo;
  @Input()
  private isPortal: boolean;
  @Output() valueChange = new EventEmitter();
  valoresLiberados: Array<ParceriaValoresLiberados> = [];
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  valorLiberadoSend: ParceriaValoresLiberados;
  listValoresLiberadosSend: Array<ParceriaValoresLiberados>;
  modalEdicaoReference: any;
  anoEmpenho: number;
  numeroEmpenho: number;
  empenhosPagos: Array<ZPagamentoParcerias> = [];
  mascaraAno = { mask: [/\d/, /\d/, /\d/, /\d/], guide: false };
  mascaraNro = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/,/\d/], guide: false };
  //radioSelected: string;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  raiz: boolean;
  @Input()
  isAuditorExterno: boolean;
  username:string;
  usernameEmail:string;

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    private parceriaDespesaDevolApi: PublicoPrivadaApi,
    public userService: UserService,
    private modalService: NgbModal,
    private mascaras: Mascaras) {

  }

  ngOnInit() {
    this.userService.userInfo.subscribe(user => this.username = user.username);
    this.userService.userInfo.subscribe(user => this.usernameEmail = user.email);
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();
    this.setupInterface();
  }

  setupInterface() {
    this.empenhosPagos = [];
    this.listValoresLiberadosSend = [];
    this.anoEmpenho = null;
    this.numeroEmpenho = null;
    this.raiz = false;
    this.loading.next(true);

    this.route.paramMap.pipe(
      switchMap(() =>
        this.detalheParceriaPortalApi.getParceriaValoresLiberados(this.parceriaInfo.parId))
    ).subscribe((response) => {
      if (response) {
        this.valoresLiberados = response as Array<ParceriaValoresLiberados>;
        //console.log(this.valoresLiberados);
      } else {
        this.alertService.warning('Nenhum registro encontrado');
      }
      this.loading.next(false);
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

    
  }

  disableCampos() {
    return (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }

  disableApagarIcone() {
    return (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }

  visualizarBotao() {
    return ((this.isSMTC || this.isConsulta || this.isAuditorExterno));
  }

  enableEditarIcone() {
    if (this.isConsulta || this.isSMTC || this.isAuditorExterno) {
      return false;
    }
    return true;
  }

  getArquivo(parId, id, tipo, nome) {
    this.loading.next(true);
    this.parceriaDespesaDevolApi.getArquivo(parId, id, tipo, this.isPortal)
      .subscribe((response) => {
        if (response) {
          console.log(response);
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(response.body);
          a.href = url;
          a.download = nome;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          this.alertService.warning('Nenhum arquivo encontrado');
        }
      }, (response) => {
        // if (response.status >= 500) {
        //   this.alertService.danger(response.error);
        // } else {
        //   this.alertService.warning(response.error);
        // }
        this.alertService.danger('Erro ao buscar arquivo.');
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  carregaEmpenho() {
    this.loading.next(true);
     const currentYear = new Date().getFullYear();    
    this.detalheParceriaApi.buscaEmpenhosPagosSigef(this.username, this.usernameEmail, currentYear, this.parceriaInfo.zgororgaoCdorgao2,
    this.parceriaInfo.parOrgCnpj, this.raiz, 0).subscribe((response) => {
    // const currentYear = new Date().getFullYear();
    // // tslint:disable-next-line:max-line-length
    // this.detalheParceriaApi.buscaEmpenhosPagos(2, Number(this.parceriaInfo.parOrgCnpj), currentYear, this.parceriaInfo.zgororgaoCdorgao2, 0, this.raiz).subscribe((response) => {
       if (response) {
        this.empenhosPagos = response as Array<ZPagamentoParcerias>;
        this.removeEmpenhosSalvoEmValoresPagos();
        this.removeEmpenhosAposDataVigenciaFinal();
    
      } else {
        this.alertService.danger('Erro ao buscar empenhos.');
      }
      this.loading.next(false);
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }
  private removeEmpenhosSalvoEmValoresPagos() {

    const empenhoLista = this.empenhosPagos.slice();
    this.empenhosPagos.forEach(element => {
      this.valoresLiberados.forEach(valor => {
        if (element.ano === valor.libAnoEmpenho &&
          element.empenho === valor.libNroEmpenho &&
          element.nrparcliq === valor.libParcEmpenho &&
          //  element.cptliq === valor.libData &&
          element.vlpag === valor.libVlr) {
          const index = empenhoLista.findIndex(i => i.ano === element.ano && i.empenho === element.empenho
            && i.nrparcliq === element.nrparcliq && element.vlpag === element.vlpag);
          if (index > 0 || index === 0) {
            empenhoLista.splice(index, 1);
          }
        }
      });
    });
    this.empenhosPagos = empenhoLista;
  }

  private removeEmpenhosAposDataVigenciaFinal() {

    const empenhoLista = this.empenhosPagos.slice();
    this.empenhosPagos.forEach(element => {
      if (this.monthDiff(new Date(this.parceriaInfo.parOrgDtFimVigencia), new Date(element.cptpag)) > 1) {
        empenhoLista.splice(empenhoLista.indexOf(element), 1);
      }

    });
    this.empenhosPagos = empenhoLista;
  }


  buscaEmpenho() {

    if (this.anoEmpenho === undefined || !this.anoEmpenho || this.anoEmpenho.toString().length < 4) {
      this.alertService.danger('Informe o ano (4 dígitos) para buscar valores.');
      return;
    }
    if (!this.numeroEmpenho) {
      this.numeroEmpenho = 0;
    }
    this.loading.next(true);
    // tslint:disable-next-line:max-line-length

    if (this.anoEmpenho>=2024){
      this.loading.next(true);
      this.detalheParceriaApi.buscaEmpenhosPagosSigef(this.username, this.usernameEmail, this.anoEmpenho, this.parceriaInfo.zgororgaoCdorgao2,
        this.parceriaInfo.parOrgCnpj, this.raiz, this.numeroEmpenho).subscribe((response) => {
        if (response) {
          this.empenhosPagos = response as Array<ZPagamentoParcerias>;
          this.removeEmpenhosSalvoEmValoresPagos();
          this.removeEmpenhosAposDataVigenciaFinal();
        } else {
          this.alertService.danger('Erro ao buscar empenhos.');
        }
        this.loading.next(false);
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
    }
    else{
        this.detalheParceriaApi.buscaEmpenhosPagos(2, Number(this.parceriaInfo.parOrgCnpj), this.anoEmpenho, this.parceriaInfo.zgororgaoCdorgao2, this.numeroEmpenho, this.raiz).subscribe((response) => {
          if (response) {
            this.empenhosPagos = response as Array<ZPagamentoParcerias>;
            this.removeEmpenhosSalvoEmValoresPagos();
            this.removeEmpenhosAposDataVigenciaFinal();
          } else {
            this.alertService.danger('Erro ao buscar empenhos.');
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));
      }
  }

  excluir() {
    this.detalheParceriaApi.excluirValorLiberado(this.valorLiberadoSend).subscribe((response) => {
      if (response) {
        this.setupInterface();
        this.setValorLiberado(undefined);
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }


  openEdicao(content, doc) {
    this.listValoresLiberadosSend = [];
    this.setValorLiberado(doc);
    //this.carregaEmpenho();
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: "modalProcesso" });
  }

  openExcluir(content, doc) {
    this.setValorLiberado(doc);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }

  setValorLiberado(doc) {
    if (doc) {
      this.valorLiberadoSend = doc;
      // this.valorLiberadoSend.terTermoDocumentoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
      // this.dateConverter.fromDateToTimestamp(this.terTermoSend.terTermoData));
    } else {
      this.valorLiberadoSend = new ParceriaValoresLiberados();
      this.valorLiberadoSend.parId = this.parceriaInfo.parId;
      this.valorLiberadoSend.libId = -1;
      this.valorLiberadoSend.libVlr = 0;
    }
  }

  onSelectEmpenho(): boolean {
    return this.empenhosPagos.filter(e => e.selecionado == true)
      .length > 0;
  }

  monthDiff(dateFrom, dateTo) {
    return ((new Date(dateTo).getFullYear() - dateFrom.getFullYear()) * 12) + new Date(dateTo).getMonth() - dateFrom.getMonth();
  }

  salvaEmpenho() {
    this.empenhosPagos.filter(emp => emp.selecionado === true)
      .forEach(emp => {
        const empLiberado = new ParceriaValoresLiberados();
        empLiberado.parId = this.parceriaInfo.parId;
        empLiberado.libId = -1;
        empLiberado.libVlr = 0;
        empLiberado.libDtAtu = new Date();
        empLiberado.libSituacao = 0;
        empLiberado.libAnoEmpenho = emp.ano;
        empLiberado.libNroEmpenho = emp.empenho;
        empLiberado.libParcEmpenho = emp.nrparcliq;
        empLiberado.libVlr = emp.vlpag;
        empLiberado.libData = emp.cptpag;
        this.listValoresLiberadosSend.push(empLiberado);
      })

    this.detalheParceriaApi.updateValorLiberado(this.listValoresLiberadosSend).subscribe((response) => {
      if (response) {
        this.setupInterface();
        //this.setValorLiberado(undefined);
        this.modalEdicaoReference.close();
        this.alertService.success("Adionado(s) com sucesso!")
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

    /* if (this.valorLiberadoSend.libVlr === 0) {
      //this.setValorLiberado(undefined);
      return;
    }
    this.valorLiberadoSend.libDtAtu = new Date();
    this.valorLiberadoSend.libSituacao = 0; //não sei o que faz mudar, por isso removi da interface grafica
    const value = Number((this.parceriaInfo.parOrgVlTotal + this.valorLiberadoSend.libVlr).toFixed(2));

    // removida a validação solicitação Fernando dia 09/05
    // if (value > this.parceriaInfo.parVlrPrevisto) {
    //   this.alertService.danger('O total dos valores concedidos é superior ao valor previsto.');
    //   return;
    // }

    this.detalheParceriaApi.updateValorLiberado(this.valorLiberadoSend).subscribe((response) => {
      if (response) {
        this.setupInterface();
        //this.setValorLiberado(undefined);
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false)); */
  }

  aplicaMascaraCNPJ(doc) {
    return this.mascaras.aplicaMascaraDocumento(doc.toString(), 2);
  }

  setRaiz(event) {
    this.raiz = event.target.checked;
  }
}

