<div *ngIf="parceriaInfo != undefined">
    <div *ngIf="!isPortal">
        <app-info-parceria-m1 (valueChange)='updatePortal($event,"Portal")' (parInfoID)="recebeParId($event)" [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [modulo]="modulo"></app-info-parceria-m1>

    </div>

    <BR>
    <BR>
    <BR>
    <div class="container">
        <ngb-accordion #acc="ngbAccordion" [hidden]="parceriaInfo.parId ===-1">
            <ngb-panel title="Gestor">      
                <ng-template ngbPanelContent>
                    <app-gestor [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [isAuditorExterno]="isAuditorExterno"></app-gestor>
                </ng-template>
            </ngb-panel>
            <ngb-panel title="Valores pagos">
                <ng-template ngbPanelContent>
                    <app-valores-pagos-parceria [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [isAuditorExterno]="isAuditorExterno"></app-valores-pagos-parceria>
                </ng-template>
            </ngb-panel>
            <ngb-panel title="Editais">
                <ng-template ngbPanelContent>
                    <app-editais-parceria [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [isAuditorExterno]="isAuditorExterno"></app-editais-parceria>
                </ng-template>
            </ngb-panel>
            <ngb-panel title="Termos">
                <ng-template ngbPanelContent>
                    <app-termos-parceria [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [isAuditorExterno]="isAuditorExterno"></app-termos-parceria>
                </ng-template>
            </ngb-panel>
            <ngb-panel title="Planos de Trabalho">
                <ng-template ngbPanelContent>
                    <app-planos-trabalho-parceria [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [isAuditorExterno]="isAuditorExterno"></app-planos-trabalho-parceria>
                </ng-template>
            </ngb-panel>
            <!--<ngb-panel>
                <ng-template ngbPanelTitle>Grupo/Turmas
                </ng-template>
                <ng-template ngbPanelContent>
                    <app-grupo-turma [parceriaInfo]="parceriaInfo" [isPortal]="isPortal"></app-grupo-turma>
                </ng-template>
            </ngb-panel>
            -->
        </ngb-accordion>
    </div>
</div>