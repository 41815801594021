export class ParceriaValoresLiberados {
    public parId: number;
    public libId: number;
    public libVlr: number;
    public libData: Date;
    public libAnoEmpenho: number;
    public libNroEmpenho: number;
    public libParcEmpenho: number;
    public libDtAtu: Date;
    public libSituacao: number;

}
