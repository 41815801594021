export class BuscaCampos {
    constructor(
        public orgao: number,
        public dataVigenciaIncial: Date,
        public numeroTermo: string,
        public objetoFin: string,
        public nomeOrg: string,
        public cnpj: string,
        public status: number,
        public pagina: number,
        public limite: number
        ) { }
}
