import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share, filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from '../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../../apis/detalheParceriaApi';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { UserService } from '../../@core/user.service';
import { GestorParceria } from './gestoresParceria';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from 'src/app/@core/date-converter';
import { Utils } from 'src/app/@shared/utils';
import { GestorNome } from './gestoresParceria';

@Component({
  selector: 'app-gestor',
  templateUrl: './../gestor/gestores.component.html',
  styleUrls: ['./../../modulo2/detalheParceria/detalheParceria.component.scss']
})
export class GestorComponent implements OnInit {

  @Input()
  public  parceriaInfo: ParceriaInfo;
  @Input()
  private  isPortal: boolean;
  @Output() valueChange = new EventEmitter();
  gestores: Array<GestorParceria> = [];
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  mascaraNroMat = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], guide: false };
  modalEdicaoReference: any;
  showUpload: boolean = false;
  gestoresSend: GestorParceria;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  @Input()
  isAuditorExterno: boolean;
  username: string;

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private utils: Utils) {

    }

  ngOnInit() {
    this.userService.userInfo.subscribe(user => this.username = user.username);
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();
    this.setupInterface();
  }

   setupInterface() {
 
    this.loading.next(true);

    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

    this.route.paramMap.pipe(
        switchMap(() =>
         this.detalheParceriaPortalApi.getGestores(this.parceriaInfo.parId))
         ).subscribe((response) => {
          if (response) {
            this.gestores = response as Array<GestorParceria>;
            // console.log(this.valoresLiberados);
          } else {
            this.alertService.warning('Nenhum registro encontrado');
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));

  }
  disableCampos() {
    return (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }

  visualizarBotao() {
    return ((this.isSMTC || this.isConsulta || this.isAuditorExterno));
  }
  disableApagarIcone() {
    return (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }
  enableEditarIcone() {
    if (this.isConsulta || this.isSMTC || this.isAuditorExterno) {
      return false;
    }
    return true;
  }

  buscaNomeGestor(matricula){
    this.loading.next(true);
    this.detalheParceriaPortalApi.getNomeGestor(matricula.replace(/[^\d]+/g, ''))
      .subscribe((response) => {
        if (response) {
          this.gestoresSend.gestorNome = (response.body as GestorNome).nome;
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false))
  }

  setGestores(doc) {
    if (doc) {
      this.gestoresSend = doc;
      this.gestoresSend.gestorDataInicioDesignacaoFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.gestoresSend.gestorDataInicioDesignacao));
        if (this.gestoresSend.gestorDataFimDesignacao != null){
          this.gestoresSend.gestorDataFimDesignacaoFormat = this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.gestoresSend.gestorDataFimDesignacao));
        }
    } else {
      this.gestoresSend = new GestorParceria();      
      this.gestoresSend.parId = this.parceriaInfo.parId;
    }

  }

  salvaGestor() {

  
    // tslint:disable-next-line:max-line-length
    this.gestoresSend.gestorDataInicioDesignacao = this.dateConverter.fromNgbDateStructToDate(this.gestoresSend.gestorDataInicioDesignacaoFormat);
    if (!this.utils.isValidDate(this.gestoresSend.gestorDataInicioDesignacao)){
        this.alertService.danger('Data Início Designação Inválida.');
        return;
    }

    if (this.gestoresSend.gestorDataFimDesignacaoFormat != null){
      this.gestoresSend.gestorDataFimDesignacao = this.dateConverter.fromNgbDateStructToDate(this.gestoresSend.gestorDataFimDesignacaoFormat);
      if (!this.utils.isValidDate(this.gestoresSend.gestorDataFimDesignacao)){
        this.alertService.danger('Data Final Designação Inválida.');
        return;
    }
    }
    if ((this.gestoresSend.gestorMatricula == null) || (this.gestoresSend.gestorNome == null)
    || (this.gestoresSend.gestorDataInicioDesignacao == null)){
      this.alertService.danger('Campos obrigatórios devem ser preenchidos.');
      return;
    }
    this.gestoresSend.operacaoData = new Date();
    this.gestoresSend.operador = this.username;
    
    let data = JSON.stringify(this.gestoresSend, this.replacerDocComplementar);
    this.detalheParceriaApi.updateGestor(data).subscribe(async (response) => {
      if (response) {
        this.setupInterface();
        this.setGestores(undefined);
        this.modalEdicaoReference.close();
        //await this.getParceriaInfo(this.gestoresSend.parId);
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }

  excluirGestor() {
    this.detalheParceriaApi.excluirGestor(this.gestoresSend).subscribe((response) => {
      if (response) {
        this.setupInterface();
        this.setGestores(undefined);
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }

  replacerDocComplementar(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['gestorDataInicioDesignacaoFormat', 'gestorDataInicioDesignacaoFimFormat', 'gestorDataFimDesignacaoFormat'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  openEdicao(content, doc) {
    this.setGestores(doc);
    this.modalEdicaoReference = this.modalService.open(content, { windowClass: "modalProcesso" });
  }

  openExcluir(content, doc) {
    this.setGestores(doc);
    this.modalEdicaoReference = this.modalService.open(content);
  }

}