import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { PlanejamentoTermos } from "./planejamentoTermos";

export class AlteraValoresParceria {
    logParId : number;
    planejamentoTermos : PlanejamentoTermos;
    dataIncialVigencia : Date;
    dataIncialVigenciaDataFormat: NgbDateStruct;
    dataFinalVigencia : Date;
    dataFinalVigenciaDataFormat: NgbDateStruct;
    valor: Number;
    numAditivo: number;
}