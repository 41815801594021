<div w3-include-html="../../@shared/components/headerPortal/header.component.html">
  <div class="container-fluid mb-3" style="min-height: 450px;">
    <div class="infoGeral container-fluid">
      <div>
        <dl>
          <dd>
            <div>
              <H2>
                Visão Geral das Parcerias

              </H2>
            </div>
          </dd>
        </dl>
      </div>
      <br>
      <h3>Filtros de pesquisa</h3>
      <p><em>Você pode filtrar os resultados preenchendo um ou combinando mais de um dos campos abaixo:</em></p>
      <br>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="">Escolher órgão ou entidade (PMPA)</label>
            <div class="select-wrapper">
              <select [ngModel]="busca.orgao" (change)="selectOrgaoParceria($event)" class="form-control form-select custom-select">
                <option [value]="option.id" *ngFor="let option of this.utils.secretariasOptions">{{option.name}}
                </option>
							</select>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="nroTermo">Número do termo</label>
                <input style="width: 100% " id="nroTermo" name="busca.numeroTermo" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.numeroTermo " [value]="busca.numeroTermo" (blur)="selectNumeroTermo()" (keydown.enter)="selectNumeroTermo()" class="form-control"
                                    placeholder="informe o nº do termo">
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="cnpj">CNPJ</label>
                <input style="width: 100% " id="cnpj" name="busca.cnpj" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.cnpj " [value]="busca.cnpj" (blur)="selectCnpj()" (keydown.enter)="selectCnpj()" class="form-control" placeholder="informe o CNPJ" [textMask]="mascaraCNPJ">
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="objeto">Objeto/finalidade</label>
            <input style="width: 100% " id="objeto" name="busca.objetoFin " class="form-control" type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.objetoFin " [value]="busca.objetoFin " (blur)="selectObjeto()" (keydown.enter)="selectObjeto()" placeholder="digite alguma palavra-chave">
          </div>
        </div>
      </div>

      <div class="row">


        <div class="col">
          <div class="form-group">
            <label for="nome">Nome da organização (parceria)</label>
            <input style="width: 100% " id="nome" name="busca.nomeOrg " class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.nomeOrg " [value]="busca.nomeOrg " (blur)="selectNomeParceria()" (keydown.enter)="selectNomeParceria()" placeholder="digite alguma palavra-chave">
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="">Status da parceria</label>
            <div class="select-wrapper">
              <select [ngModel]="busca.status" (change)="selectStatusParceria($event)" class="form-control form-select custom-select">
								<option [value]="option.id" *ngFor="let option of this.utils.statusParceiraOptions">{{option.name}}
                </option>
							</select>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="">Data</label>
                <input placeholder="clique no calendário" maxlength="10" size="15" onkeydown="return false" name="data" #data="ngModel" [(ngModel)]="dataVigenciaInicial" ngbDatepicker #d="ngbDatepicker" (dateSelect)="onDateSelect($event)" class="form-control input_date">
                <i class="fa fa-calendar icon_input_date" (click)="d.toggle()" title="calendário "></i>
              </div>
            </div>
            <div class="col">
              <button class="btn btn-primary btn-filtro">Filtrar</button>
            </div>
          </div>
        </div>
      </div>


      <!-- Filtar Lista: <input type="text " [(ngModel)]="queryString " id="search " placeholder="digite o valor ">-->
    </div>
    <br>
    <div>
      <span style="font-weight: bold;">Total de termos correspondentes à pesquisa atual: {{countParcerias}}</span>
    </div>
    <div class="linha-select">
      <button (click)="openExportacao(contentExportacao, undefined) " id="button-exṕort" class="btn btn-primary btn-info" type="button " title="Download">
                                <i class="fa fa-download" style="color: white"><span class="texto-botao">Download</span> </i>
                            </button>
    </div>
    <div>
      <table class="table table-hover table-responsavel table45" [hidden]="(!parceriaArray) " [mfData]="parceriaArray ">
        <thead>
          <tr>
            <th></th>
            <th>Órgão</th>
            <th>Nome</th>
            <th>CNPJ</th>
            <th>Objeto/Finalidade</th>
            <th>Nº Aten.</th>
            <th>Nº Termo</th>
            <th>Data Inicial</th>
            <th>Data Final</th>
            <th>Status Atual</th>
            <th>Valor Total</th>
            <th class="inclina45">
              <div><span>Planejamento Seleção</span></div>
            </th>
            <th class="inclina45">
              <div><span>Execução</span></div>
            </th>
            <th class="inclina45">
              <div><span>Monitoramento Avaliação</span></div>
            </th>
            <th class="inclina45">
              <div><span>Prestação Contas Parcial</span></div>
            </th>
            <th class="inclina45">
              <div><span>Prestação Contas Final</span></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let parceria of parceriaArray" [routerLink]="[ '/detalheParceriaPortal', parceria.parId]">

            <td><a ngbTooltip="Acessar parceria" placement="right" [routerLink]="[ '/detalheParceriaPortal', parceria.parId]"><i class="fa fa-search " aria-hidden="true "></i></a></td>
            <td>{{parceria.nomeOrgao}}</td>
            <td>{{parceria.parOrgNome}}</td>
            <td class="tdCnpj">{{aplicaMascaraDocumento(parceria.parOrgCnpj)}}</td>
            <td>{{parceria.parOrgObjeto}}</td>
            <td>{{parceria.parOrgNmetasAtend}}</td>
            <td>{{parceria.parNum}}</td>
            <td>{{parceria.parOrgDtIniVigencia | date:'dd/MM/yyyy' }}</td>
            <td>{{parceria.parOrgDtFimVigencia | date:'dd/MM/yyyy' }}</td>
            <td>{{parceria.status}}</td>
            <td>{{parceria.parOrgVlTotal | currency: 'BRL':true}}</td>
            <td class="borda-direita borda-esquerda inclina45">
              <div [hidden]="!parceria.planejSel "><i class="fa fa-check "></i></div>
            </td>
            <td class="borda-direita inclina45">
              <div [hidden]="!parceria.execucao "><i class="fa fa-check "></i></div>
            </td>
            <td class="borda-direita inclina45">
              <div [hidden]="!parceria.monitAval "><i class="fa fa-check "></i></div>
            </td>
            <td class="borda-direita inclina45">
              <div [hidden]="!parceria.prestContasPar "><i class="fa fa-check "></i></div>
            </td>
            <td class="borda-direita inclina45">
              <div [hidden]="!parceria.prestContasTot "><i class="fa fa-check "></i></div>
            </td>
          </tr>
        </tbody>
      </table>
      <tfoot data-html2canvas-ignore="true ">
        <div *ngIf="countParcerias>25" class="resultPorPagina">
          <ngb-pagination class="d-flex justify-content-center " [collectionSize]="collectionSizeParceria" [maxSize]="10" [(page)]="currentPage " [rotate]="true " [ellipses]="true " [boundaryLinks]="true " (pageChange)="onPageChange($event)"></ngb-pagination>
        </div>

      </tfoot>

      <div [hidden]="!(loading$ | async) ">
        <div class="loading-overlay "></div>
        <div class="loading-info ">
          <i class="fa fa-spinner fa-4x fa-spin "></i>
          <div id="carregando ">Aguarde</div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #contentExportacao let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Exportação da Visão Geral das Parcerias </h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="fechar">
					<span aria-hidden="true">&times;</span>
				</button>
    </div>
    <div class="modal-body">
      <label for="data">Selecione o tipo de arquivo:&ensp;</label>
      <br>
      <br>
      <div class="row">
        <div class="col">
          <a class="link" style="margin-left: 10px" (click)="export($event, 'xlsx')" title="Exportar como Excel"><i class="fa fa-file-excel-o fa-lg"></i> Baixar arquivo Excel</a>
        </div>
        <div class="col">
          <a class="link" style="margin-left: 9px; padding: 0%" (click)="export($event,'csv')" title="Exportar como CSV"><img src="assets/img/file-csv-solid.svg"> Baixar arquivo csv</a>
        </div>
      </div>
      <br>
      <br>
      <div [hidden]="!(loadingD$ | async) ">
        <div class="loading-overlay "></div>
        <div class="loading-info ">
          <i class="fa fa-spinner fa-4x fa-spin "></i>
          <div id="carregando ">Aguarde</div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
