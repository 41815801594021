<div w3-include-html="../../@shared/components/headerPortal/header.component.html">
  <div class="container-fluid mb-3" style="min-height: 450px;">
    <div class="infoGeral container-fluid">
      <h2>Relatório de Resumo das Parcerias</h2>
      <h3>Filtros de pesquisa</h3>
      <p><em>Você pode filtrar os resultados preenchendo um ou combinando mais de um dos campos abaixo:</em></p>
      <br>
      <div class="form-row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Órgão ou entidade (PMPA)</label>
            <div class="select-wrapper">
              <select [disabled]="isFiscal && !isSMTC" [ngModel]="busca.orgao" (change)="selectOrgaoParceria($event)" class="form-control form-select">
                <option [value]="option.id" *ngFor="let option of this.utils.secretariasOptions">{{option.name}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label for="nroTermo">Número do termo</label>
            <input style="width: 100% " id="nroTermo" name="busca.numeroTermo" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.numeroTermo " [value]="busca.numeroTermo" class="form-control"
                            placeholder="informe o nº do termo">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="cnpj">CNPJ</label>
            <input style="width: 100% " id="cnpj" name="busca.cnpj" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.cnpj " [value]="busca.cnpj" class="form-control" placeholder="informe o CNPJ" [textMask]="mascaraCNPJ">
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Tipo de data</label>
            <div class="select-wrapper">
              <select [ngModel]="busca.tipoData" (change)="selectTipoData($event)" class="form-control form-select custom-select">
                                <option value="1">Emissão</option>
                                <option value="2">Pagamento</option>
                            </select>
            </div>
          </div>
        </div>
        <div class="col-md-4 cjto-datas">
          <div class="texto-linha-select-resultados">Selecione o período &ensp;&ensp;</div>
          <selector-datetime-range class="data-range" [isOnlyDataInicio]="false" [showLabelData]="false" (novoPeriodo)="novoPeriodo($event)"></selector-datetime-range>
        </div>
        <div class="col-md-4 btn-inline">
          <div class="form-group">

            <div class="select-wrapper" style="padding-top: 38px;">
              <button class="btn btn-primary" (click)="limparFiltros()">Limpar</button>
              <button class="btn btn-primary" (click)="geraArquivo('PDF')">Gerar PDF</button>
              <button class="btn btn-primary" (click)="geraArquivo('XLS')">Gerar Excel</button>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div [hidden]="!(loading$ | async) ">
        <div class="loading-overlay "></div>
        <div class="loading-info ">
          <i class="fa fa-spinner fa-4x fa-spin "></i>
          <div id="carregando ">Aguarde</div>
        </div>
      </div>
    </div>
  </div>
</div>
