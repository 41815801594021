import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class PlanejamentoEditais {

    public parId: number;
    public ediOrgId: number;
    public ediDocumentos: Blob;
    public ediDocumentosArquivo: string;
    public ediDocumentosArquivoOrigina: string;
    public ediDocumentosData: Date;
    public ediDocumentosTipo: string;
    public ediDocumentosDataFormat: NgbDateStruct;

}
