<div w3-include-html="../../@shared/components/headerPortal/header.component.html">
    <div class="container-fluid mb-3" style="min-height: 350px;">
        <div class="infoGeral container-fluid">
            <br>
            <h3>Filtros de pesquisa</h3>
            <p><em>Você pode filtrar os resultados preenchendo um ou combinando mais de um dos campos abaixo:</em></p>
            <br>
            <div class="form-row">
                <div class="col" *ngIf="isFiscal || isSMTC">
                    <div class="form-group">
                        <label for="">Escolher órgão ou entidade (PMPA)</label>
                        <div class="select-wrapper">
                            <select [disabled]="isFiscal && !isSMTC" [ngModel]="busca.orgao" (change)="selectOrgaoParceria($event)" class="form-control form-select custom-select">
								<option [value]="option.id" *ngFor="let option of this.utils.secretariasOptions">{{option.name}}
                                </option>
							</select>
                        </div>
                    </div>
                </div>
                <div class="col" *ngIf="isFiscal || isSMTC">
                    <div class="form-group">
                        <label for="objeto">Objeto/finalidade</label>
                        <input style="width: 100% " id="objeto" name="busca.objetoFin " class="form-control" type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.objetoFin " [value]="busca.objetoFin " (blur)="selectObjeto()" placeholder="digite alguma palavra-chave">
                    </div>
                </div>

                <div class="col-md-3" style="width: 100% " *ngIf="isFiscal || isSMTC">
                    <div class="form-group">
                        <label for="nroTermo">Número do termo</label>
                        <input style="width: 100% " id="nroTermo" name="busca.numeroTermo" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.numeroTermo " [value]="busca.numeroTermo" (blur)="selectNumeroTermo()" class="form-control"
                            placeholder="informe o nº do termo">
                    </div>
                </div>
                <div class="col-md-1" *ngIf="isFiscal || isSMTC">
                    <div class="form-group">
                        <label for="termosAtivos">Termos ativos</label>
                        <label class="switch">
                            <input type="checkbox" id="termosAtivos" name="busca.termosAtivos" [ngModelOptions]="{standalone: true}" [(ngModel)]="busca.termosAtivos " [value]="busca.termosAtivos" (blur)="selectTermosAtivos()">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col">
                    <div class="form-group">
                        <label for="nome">Nome da organização (parceria)</label>
                        <input style="width: 100% " id="nome" name="busca.nomeOrg" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.nomeOrg " [value]="busca.nomeOrg " (blur)="selectNomeParceria()" placeholder="digite alguma palavra-chave">
                    </div>
                </div>
                <div class="col" *ngIf="isFiscal || isSMTC">
                    <div class="form-group">
                        <label for="">Status da parceria</label>
                        <div class="select-wrapper">
                            <select [ngModel]="busca.status" (change)="selectStatusParceria($event)" class="form-control form-select custom-select">
								<option [value]="option.id" *ngFor="let option of this.utils.statusParceriaOptions">{{option.name}}
                                </option>
							</select>
                        </div>
                    </div>
                </div>

                <div class="col-md-3 cjto-datas" *ngIf="isFiscal || isSMTC">

                    <div class="texto-linha-select-resultados">Período da vigência</div>

                    <div class="form-inline">
                        <div style="width: calc( 50% - 16px );">
                            <input class="form-control" placeholder="data inicial" maxlength="10" size="10" onkeydown="return false" name="dataIni" [(ngModel)]="dataIni" (dateSelect)="onDateSelect($event,'inicial')" ngbDatepicker #dI="ngbDatepicker">
                            <i class="fa fa-calendar icon_input_date" (click)="dI.toggle()" title="calendário"></i>
                        </div>
                        <div style="width: 32px;
                        text-align: center;
                        padding-top: 8px;">
                            até
                        </div>
                        <div style="width: calc( 50% - 16px );">
                            <input class="form-control" placeholder="data final" maxlength="10" size="10" onkeydown="return false" name="dataFim" [(ngModel)]="dataFim" (dateSelect)="onDateSelect($event,'final')" ngbDatepicker #dF="ngbDatepicker">
                            <i class="fa fa-calendar icon_input_date" (click)="dF.toggle()" title="calendário"></i>
                        </div>
                    </div>
                </div>
                <div class="col-md-1">
                    <button class="btn btn-primary btn-filtro">Filtrar</button>
                </div>

                <!-- Filtar Lista: <input type="text " [(ngModel)]="queryString " id="search " placeholder="digite o valor ">-->
            </div>
            <div class="form-row">
                <div class="col">
                    <button style="width: 10%" class="btn btn-primary btn-novo" [routerLink]="[ '/planejamento/detalheParceriaM1', -1]">Novo</button>
                </div>
            </div>
        </div>
    </div>
    <br>
    <div>
        <div>
            <div class="container-fluid mb-3" style="min-height: 450px;">
                <div>
                    <br>
                    <table class="table table-hover table-responsavel" [hidden]="(!parceriaLogonArray) ">
                        <thead>
                            <tr>
                                <th></th>
                                <th>CNPJ</th>
                                <th>Nome</th>
                                <th>Nº Termo</th>
                                <th>Órgão</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let parceriaLogon of parceriaLogonArray">
                                <td><a ngbTooltip="Acessar parceria" placement="right" routerLinkActive="active" [routerLink]="['/execucao/detalheParceriaM2', parceriaLogon.parId]"><i class="fa fa-pencil" aria-hidden="true"></i></a></td>
                                <td class="tdCnpj">{{aplicaMascaraCNPJ(parceriaLogon.parLogonCnpj)}}</td>
                                <td style="text-transform:uppercase">{{parceriaLogon.parLogonNome}}</td>
                                <td>{{parceriaLogon.parNum}}</td>
                                <td style="text-transform:uppercase">{{parceriaLogon.orgao}}</td>
                                <td>{{getStatusParceira(parceriaLogon.status)}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div *ngIf="(temPermissao$ && notLoading$) | async">
                        <h3 class="mt-5 mb-3">Sem Permissão</h3>
                        <p>No momento você não tem acesso integral ao Sistema de Gestão de Parcerias. Solicitamos que entre em contato com o órgão responsável pela parceria.
                        </p>
                    </div>
                    <div *ngIf="(!temPermissaoPMPA$ && !loading$) | async">
                        <h3 class="mt-5 mb-3">Sem Permissão</h3>
                        <p>No momento você não tem acesso ao Sistema de Gestão de Parcerias. Solicitamos que entre em contato com a SMTC para cadastramento.
                        </p>
                    </div>
                    <div *ngIf="loading$ | async">
                        <div class="loading-overlay"></div>
                        <div class="loading-info">
                            <i class="fa fa-spinner fa-4x fa-spin"></i>
                            <div id="carregando">Aguarde</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div [hidden]="!(loading$ | async) ">
            <div class="loading-overlay "></div>
            <div class="loading-info ">
                <i class="fa fa-spinner fa-4x fa-spin "></i>
                <div id="carregando ">Aguarde</div>
            </div>
        </div>
    </div>
</div>