import { Produto } from "./produto";

export class NFeSgp {

	public numero: string;
	public serie: string;
	public modelo: string;
	public dataEmissao: string;
	public vlrTotalNota: string;
	public emitenteNroDocumento: string;
	public emitenteNomeRazaoSocial: string;
	public emitenteInscEstadual: string;
	public emitenteEndNro: string;
	public emitenteEndLgr: string;
	public emitenteEndCep: string;
	public emitenteEndBairro: string;
	public emitenteEndUF: string;
	public emitenteEndCidade: string;
	public emitenteTelefone: string;
	public emitenteEmail: string;
	public destinatarioNroDocumento: string;
	public destinatarioNomeRazaoSocial: string;
	public destinatarioInscEstadual: string;
	public destinatarioEndNro: string;
	public destinatarioEndLgr: string;
	public destinatarioEndCep: string;
	public destinatarioEndBairro: string;
	public destinatarioEndUF: string;
	public destinatarioEndCidade: string;
	public destinatarioTelefone: string;
	public destinatarioEmail: string;
	public valorFrete: string;
	public valorTotalDesconto: string;
	public produto: Produto[];
	public chaveDeAcesso: string;
	public informacaoAdicional: string;
}