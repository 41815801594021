export class ListaColaboradoresPlanilhaCampos {
    constructor(
    public nomeOsc: String,
    public cnpjOsc: String,
    public parNum: String,
    public modalidade: Number,
    public statusParceria: Number,
    public nomeFuncionario: String,
    public cpfFuncionario: String,
    public idOrgao: Number,
    public auditorEmail:string
    ) {}
}