import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class ParametrosAuditoria {

    public valorTotalPrevistoBaixo: number;
	public valorTotalPrevistoMedio: number;
	public valorTotalPrevistoAlto: number;
	public pesoValorTotalPrevistoBaixo: number; 
	public pesoValorTotalPrevistoMedio: number;
	public pesoValorTotalPrevistoAlto: number;
	public valorConcedidoLiberadoBaixo: number;
	public valorConcedidoLiberadoMedio: number;
	public valorConcedidoLiberadoAlto: number;
	public pesoConcedidoLiberadoBaixo: number; 
	public pesoConcedidoLiberadoMedio: number; 
	public pesoConcedidoLiberadoAlto: number;
	public valorGastoBaixo: number;
	public valorGastoMedio: number;
	public valorGastoAlto: number;
	public pesoGastoBaixo: number; 
	public pesoGastoMedio: number;
	public pesoGastoAlto: number;
	public percentualDocsConferirBaixo: number;
	public percentualDocsConferirMedio: number;
	public percentualDocsConferirAlto: number;
	public pesoPercentualDocsConferirBaixo: number;
	public pesoPercentualDocsConferirMedio: number;
	public pesoPercentualDocsConferirAlto: number;
	public percentualDocsCorrigirBaixo: number;
	public percentualDocsCorrigirMedio: number;
	public percentualDocsCorrigirAlto: number;
	public pesoPercentualDocsCorrigirBaixo: number; 
	public pesoPercentualDocsCorrigirMedio: number; 
	public pesoPercentualDocsCorrigirAlto: number; 
	public numTotalDocsBaixo: number;
	public numTotalDocsMedio: number;
	public numTotalDocsAlto: number; 
	public pesoNumTotalDocsBaixo: number;
	public pesoNumTotalDocsMedio: number; 
	public pesoNumTotalDocsAlto: number;
	public nivelTipo:string;
	public qtdAnoVisitacao: number;
	public dataInicialVigencia: Date; 
	public dataFinalVigencia: Date;
	public dataOperacao: Date;
	public usuarioOperacao: string;
	public dataInicialVigenciaFormat: NgbDateStruct;
	public dataFinalVigenciaFormat: NgbDateStruct;
}