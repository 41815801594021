import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { UserService } from '../../@core/user.service';
import { AlertService } from '../../@core/alert.service';
import { RelatoriosApi } from '../../apis/relatoriosApi';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService, AuthUser } from '@procempa/ngx-keycloak';
import { LogStatusNotificacaoCampos } from './logStatusNotificacaoCampos';
import { Mascaras } from '../../@shared/components/Mascaras';

@Component({
    selector: 'app-logs-status-notificacoes',
    templateUrl: './logStatusNotificacoes.component.html',
    styleUrls: ['../../modulo2/exibeParcerias/exibeParceriasM2.component.scss']
})
export class LogStatusNotificacoesComponent implements OnInit {
    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable().pipe(share());
    @Input()
    isPortal: boolean;
    @Input()
    modulo: string;
    isFiscal: boolean;
    isSMTC: boolean;
    isConsulta: boolean;
    mascaraCNPJ = { mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/], guide: false };
    busca = new LogStatusNotificacaoCampos();
    listaNotificacoes = [];
    isAuditorExterno: boolean;
    auditorEmail: string;

    constructor(private alertService: AlertService,
        private relatoriosApi: RelatoriosApi,
        public userService: UserService,
        private mascaras: Mascaras,
        private router: Router,
        public keycloakService: KeycloakService,
        private route:ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.modulo = 'relatorio';
        if (this.isPortal === undefined) {
            this.isPortal = false;
        }

        this.isConsulta = this.userService.isConsulta();
        this.isFiscal = this.userService.isFiscal();
        this.route.queryParams.subscribe(params => {
            this.isAuditorExterno = params['isAuditorExterno'] === undefined || params['isAuditorExterno'].toLowerCase() === 'false' ? false : true;
            this.auditorEmail = this.keycloakService['_keycloakInstance'].tokenParsed.email;
        });
      

        if (!this.isFiscal && !this.isConsulta && !this.isAuditorExterno) {
        
            this.router.navigate(['notAuthorized']);
        }
        // pegar qualificador
        this.keycloakService.getUser().subscribe((user: AuthUser) => {

            //console.log(user.attributes.sgp_orgao[0]);
            if (this.isFiscal && user.attributes.sgp_orgao !== null) {
                // tslint:disable-next-line:no-unused-expression
                if (this.userService.isSMTC()) {
                    this.isSMTC = true;
                    this.relatoriosApi.getNotificacoesComboboxLog(0, null).subscribe((response) => {
                        this.carregaCombobox(response);
                    })
                } else {
                    this.relatoriosApi.getNotificacoesComboboxLog(user.attributes.sgp_orgao[0], null).subscribe((response) => {
                        this.carregaCombobox(response);
                    })
                }
            }
        });
        if (this.isAuditorExterno){
            this.relatoriosApi.getNotificacoesComboboxLog(0, this.auditorEmail).subscribe((response) => {
                this.carregaCombobox(response);
            })
        }
    }

    carregaCombobox(data) {
        data.forEach(element => {
            var item = { id: element[0], name: this.mascaras.aplicaMascaraDocumento(element[1].toString(), 2) + " - " + element[2] + " - " + element[3] };
            this.listaNotificacoes.push(item);
        })
    }

    selectNotificacao(event) {
        const newVal = event.currentTarget.value;
        this.busca.notifId = newVal;
    }

    geraArquivo(tipoArquivo) {
        this.busca.tipoArquivo = tipoArquivo;
        this.loading.next(true);
        this.relatoriosApi.getArquivoNotificacaoLog(this.busca).subscribe((response) => {
            if (response) {
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(response);
                a.download = `relatorio_log_notificacao.${this.busca.tipoArquivo}`;
                a.href = url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);

            } else {
                this.alertService.warning('Nenhum resultado foi obtido, por favor refaça a busca com outros critérios.');
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));
    }
}