import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { UserService } from '../../../@core/user.service';
import { AlertService } from '../../../@core/alert.service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Mascaras } from 'src/app/@shared/components/Mascaras';

import { AuthUser, KeycloakService } from '@procempa/ngx-keycloak';
import { Utils } from 'src/app/@shared/utils';
import { BuscaCampos } from 'src/app/modulo1/exibeParcerias/buscaCampos';
import { LogonApi } from 'src/app/apis/logonApi';
import { ParceriaLogon } from 'src/app/modulo2/exibeParceriaPorUsuario/parceriaLogon';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'app-exibe-parceria-logon',
  templateUrl: './exibeParceriasLogon.component.html',
  styleUrls: ['../../../modulo1/exibeParcerias/exibeParceriasM1.component.scss']
})

export class ExibeParceriasLogonComponent implements OnInit {
  mascaraCNPJ = { mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/], guide: false };
  mascaraData = { mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], guide: false };
 
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  selectedParceriaId: number = null;
  parceriaArray: Array<ParceriaLogon>;
  status: number;
  countParcerias: number;
  busca: BuscaCampos = new BuscaCampos(0, null, null, '', '', '', '', true, 0, 0, 20, null);
  currentPage: number;
  dataVigenciaInicial: NgbDateStruct;
  collectionSizeParceria: number;
  @Input()
  isPortal: boolean;
  @Input()
  modulo: string;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  parLogonSend: ParceriaLogon;
  modalEdicaoReference: any;
  inValidCpfCnpj: boolean = false;
  username: string;
  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 20,
    currentPage: 1
  };

  constructor(private alertService: AlertService,
    private logonApi: LogonApi,
    public userService: UserService,
    private mascara: Mascaras,
    public keycloakService: KeycloakService,
    public utils: Utils,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
    this.userService.userInfo.subscribe(user => this.username = user.username);
    this.modulo = 'logon';
    if (this.isPortal === undefined) {
      this.isPortal = false;
    }
    this.parceriaArray = [];
    this.getParcerias(this.busca, 1);
    this.currentPage = 1;
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();

    // pegar qualificador
    this.keycloakService.getUser().subscribe((user: AuthUser) => {

      //console.log(user.attributes.sgp_orgao[0]);
      if ((this.isFiscal || this.isConsulta) && user.attributes.sgp_orgao !== null) {
        // tslint:disable-next-line:no-unused-expression
        if (this.userService.isSMTC()) {
          this.busca.orgao = 0;
          this.isSMTC = true;
          this.busca = new BuscaCampos(0, null, null, '', '', '', '', true, 0, 0, 20, null);
        } else {
          this.busca = new BuscaCampos(Number(user.attributes.sgp_orgao[0]), null, null, '', '', '', '', true, 0, 0, 20, null);
        }
      }
      this.getParcerias(this.busca, 1);
      this.currentPage = 1;
    });

  }
  disableCamposTable() {
    return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta);
  }

  aplicaMascaraCNPJ(doc) {
    return this.mascara.aplicaMascaraDocumento(doc, 2);
  }
  getParceriasPagina(pagina) {
    this.getParcerias(this.busca, pagina);
  }
  getParcerias(buscaCampos, pagina) {
    buscaCampos.pagina = pagina;
    buscaCampos.limite = 20;
    this.loading.next(true);
    //console.log(this.userService.userInfo);
    this.logonApi.getParceriasByBuscaCampos(buscaCampos)
      .subscribe(async (response) => {
        if (response) {
          this.parceriaArray = response as Array<ParceriaLogon>;
         // console.log(this.parceriaArray);
          await this.getCountParceriaLogon();
        } else {
          this.alertService.warning('Nenhum registro encontrado');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  async getCountParceriaLogon() {
    this.loading.next(true);
    //console.log(this.userService.userInfo);
    this.logonApi.getCountParceriaLogon(this.busca)
      .subscribe((response) => {
        if (response) {
          this.countParcerias = response as number;
          this.config.totalItems = this.countParcerias;
          //console.log("coParceria " + this.countParcerias);
          this.collectionSizeParceria = Math.ceil((this.countParcerias / 20) * 10);
          //console.log("collectionSizeParceria " + this.collectionSizeParceria);
        } else {
          this.alertService.warning('Nenhum registro encontrado');
          this.countParcerias = 0;
          this.collectionSizeParceria = 0;
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  pageChanged(event) {
    //console.log(event);
    this.config.currentPage = event;
    this.getParceriasPagina(this.config.currentPage);
  }
  selectOrgaoParceria(event) {
    const newVal = event.currentTarget.value;
    this.busca.orgao = newVal;
    this.getParcerias(this.busca, 1);
  }
  selectNumeroTermo() {
    this.getParcerias(this.busca, 1);
  }
  selectNomeParceria() {
    this.getParcerias(this.busca, 1);
  }
  selectCnpj() {
    this.getParcerias(this.busca, 1);
  }
  updateCnpj(cnpj){
    this.inValidCpfCnpj = !this.mascara.validaCpfCnpj(cnpj);
    if (!this.inValidCpfCnpj){
      this.loading.next(true);
    this.inValidCpfCnpj = !this.mascara.validaCpfCnpj(cnpj);
      this.logonApi.getNomeParceira(cnpj.replace(/[^\d]+/g, ''))
        .subscribe((response) => {
          if (response) {
            this.parLogonSend.parLogonNome = response as string;
          } else {
            this.alertService.warning('Nenhuma Parceira Encontrada');
            this.parLogonSend.parLogonNome = "";
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));
      this.loading.next(false);
    }
  }
 setEdicaoParLogon(par) {
  this.parLogonSend = new ParceriaLogon();
  if (par) {
    this.parLogonSend.orgao = par.orgao;
    this.parLogonSend.parId = par.parId;
    this.parLogonSend.parLogonCnpj = par.parLogonCnpj;
    this.parLogonSend.parLogonDtUltAtu = par.parLogonDtUltAtu;
    this.parLogonSend.parLogonEmail = par.parLogonEmail;
    this.parLogonSend.parLogonNome = par.parLogonNome;
    this.parLogonSend.parLogonOperAtu = par.parLogonOperAtu;    
    this.parLogonSend.parLogonId= par.parLogonId;
  } else {
    this.parLogonSend.parLogonId = -1;
    //this.parLogonSend.parId =  this.parceriaInfo.parId;
  }

}

disableIcone() {
  return (this.isSMTC || this.isPortal || this.isConsulta);
}
openExcluir(content, parLogonSend) {
  this.setEdicaoParLogon(parLogonSend);
  this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false });
}

excluir() {
  this.parLogonSend.parLogonDtUltAtu = new Date();
  this.parLogonSend.parLogonOperAtu = this.username;
    this.logonApi.excluirLogon(this.parLogonSend).subscribe(async (response) => {
      if (response) {
      //  this.setupInterface();
        await this.getParceriasPagina(this.config.currentPage);
        this.setEdicaoParLogon(undefined);
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }

  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  salva(){

      if (this.inValidCpfCnpj) {
        this.alertService.danger('CNPJ inválido');
        return;
      }

      if (this.isBlank(this.parLogonSend.parLogonEmail)) {
        this.alertService.danger('Email deve ser válido.');
        return;
      }

      if (this.isBlank(this.parLogonSend.parLogonNome)) {
        this.alertService.danger('Parceira deve ser informada, altere o cnpj para algum conveniado.');
        return;
      }
      this.parLogonSend.parLogonCnpj = this.parLogonSend.parLogonCnpj.replace(/[^\d]+/g, '');
      this.parLogonSend.parLogonDtUltAtu = new Date();
      this.parLogonSend.parLogonOperAtu = this.username;
   
      //console.log(this.despesaSend);
      this.logonApi.updateParceriaLogon(this.parLogonSend).subscribe(async (response) => {
        if (response) {

          this.setEdicaoParLogon(undefined);
          this.modalEdicaoReference.close();
          await this.getParceriasPagina(this.config.currentPage);

         // this.getDespesaParceria(this.pesquisaData.dataInicial, this.pesquisaData.dataFinal, this.tipo);
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
        this.modalEdicaoReference.close();
      }, () => this.loading.next(false));

  }

  openEdicao(content, parLogonSend) {
    this.inValidCpfCnpj = false;
    this.setEdicaoParLogon(parLogonSend);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false, windowClass: "modalProcesso" });
  }

  visualizarBotao() {
    //return ((this.isSMTC || this.isConsulta || (this.isPortal)));
    return this.isFiscal;
  }
}
