<div *ngIf="prestaContaFinal.length==0">
  <span class="mensagem-vazio">Nenhuma prestação de contas parcial/final foi encontrada.</span>
</div>
<div *ngIf="prestaContaFinal.length>0">

  <table class="table table-striped" style="margin-top:30px" id="tabelaPFinal" [mfData]="prestaContaFinal" #mtPrestaFinal="mfDataTable" [mfRowsOnPage]="5">
    <thead>
      <tr>
        <th *ngIf="!isPortal" data-html2canvas-ignore="true"></th>
        <th *ngIf="!isPortal" data-html2canvas-ignore="true"></th>
        <th>Documento</th>
        <th>Data</th>
        <th *ngIf="!isPortal"> Portal Transparência</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of mtPrestaFinal.data ; let j = index;">
        <td *ngIf="!isPortal" class="col-auto" data-html2canvas-ignore="true">
          <i ngbTooltip="editar" class="fa fa-pencil" aria-hidden="true" (click)="openEdicao(contentEdicao, data)"></i>
        </td>
        <td *ngIf="!isPortal" class="col-auto" data-html2canvas-ignore="true">
          <i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true" (click)="openExcluir(contentExcluir, data)"></i>
        </td>
        <td class="col-auto ">
          <div [hidden]="!data.prestaFinalDocumentoArquivo">
            {{data.prestaFinalDocumentoArquivo}}
            <i ngbTooltip="download" class="fa fa-download" aria-hidden="true" (click)="getArquivo(data.parId, data.prestaFinalId, 'prestaFinal', data.prestaFinalDocumentoArquivo)" alt="download"></i>
          </div>
        </td>
        <td class="col-auto">{{data.prestaFinalData | date: 'dd/MM/yyyy'}}</td>
        <td *ngIf="!isPortal" class="col-auto">{{data.prestaFinalDocumentoPublicar === 1?'Dado Sigiloso':'Dado Público'}}</td>
      </tr>
    </tbody>
    <tfoot data-html2canvas-ignore="true ">
      <tr>
        <td colspan="12 ">
          <div *ngIf="prestaContaFinal.length>5" class="resultPorPagina">Resultados por página:</div>
          <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
<div *ngIf="!isPortal">
  <button (click)="openEdicao(contentEdicao, undefined) " id="button-insert_doc " class="btn btn-primary btn-info" type="button " title="Adicionar novo">
                <i class="fa fa-plus "><span class="texto-botao">Adicionar novo</span> </i>
            </button>
</div>

<ng-template #contentEdicao let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Prestação de Contas Parcial/Final</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                    <span aria-hidden="true">&times;</span>
                </button>
  </div>
  <form (ngSubmit)="salvaPrestacaoContasFinal()" #edicao="ngForm" novalidate>
    <div class="modal-body" *ngIf="prestacaoContasFinalSend">
      <div class="form-group col-md-12">
        <label for="doc">Documento</label>
        <!-- <span [hidden]="documento.valid" class="campoObrigatorio">*</span>  -->
        <div [hidden]="showUpload">
          {{prestacaoContasFinalSend.prestaFinalDocumentoArquivo}}
          <i ngbTooltip="download" class="fa fa-times" aria-hidden="true" (click)="showUpload = true;prestacaoContasFinalSend.prestaDocumentoArquivo = undefined" alt="download"></i>
        </div>
        <div [hidden]="!showUpload">
          <input type="file" name="fileInput{{i}}" accept=".jpg, .pdf, .gif, .png" (change)="onFileDocChanged($event, j)">
          <!-- <i class="fa fa-times" aria-hidden="true" (click)="resetDevFile(j)" alt="download"></i> -->
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="data">Data</label>
        <span [hidden]="data.valid" class="campoObrigatorio">*</span>
        <input style="width:100%" class="form-control" placeholder="Selecione a data no calendário" onkeydown="return false" name="data" #data="ngModel" [(ngModel)]="prestacaoContasFinalSend.prestaFinalDocumentoDataFormat" ngbDatepicker #d="ngbDatepicker" required>
        <i class="fa fa-calendar icon" (click)="d.toggle()" title="calendário"></i>
      </div>
      <div class="form-group col-md-6">
        <label for="valor">Portal Transparência</label>
        <span [hidden]="motivo.valid" class="campoObrigatorio">*</span>
        <div class="select-wrapper" style="width:100%">
          <select style="width:100%" [ngModel]="prestacaoContasFinalSend.prestaFinalDocumentoPublicar" class="form-control form-select custom-select" name="motivo" #motivo="ngModel" (change)="updatePublica($event)" required>
                                <option value="1">Ocultar</option>
                                <option value="2">Publicar</option>
                            </select>
        </div>
      </div>

    </div>
    <div class="modal-footer col-md-12">
      <button type="button" class="btn btn-default" (click)="c('Close click');setPrestacaoContasFinal(undefined)" title="Cancelar">Cancelar</button>
      <button type="submit" class="btn btn-primary" [disabled]="!edicao.form.valid" title="Salvar">Salvar</button>
    </div>
  </form>
</ng-template>
<ng-template #contentExcluir let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Excluir Prestação de Contas Parcial/Final</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                    <span aria-hidden="true">&times;</span>
                </button>
  </div>
  <div class="modal-body">
    <p>Tem certeza que deseja excluir a prestação de contas
      <b>{{prestacaoContasFinalSend.prestaFinalData | date:'dd/MM/yyyy'}}  - {{prestacaoContasFinalSend.prestaFinalDocumentoArquivo}}</b>?
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">Fechar</button>
    <button type="button" class="btn btn-secondary" (click)="excluirPrestacaoContasFinal()" title="Excluir">Excluir</button>
  </div>
</ng-template>
