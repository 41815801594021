<div w3-include-html="../../@shared/components/headerPortal/header.component.html">
    <div class="container-fluid mb-3" style="min-height: 450px;">
        <div class="infoGeral container-fluid">
            <h2>Relatório de Notificações</h2>
            <h3>Filtros de pesquisa</h3>
            <p><em>Você pode filtrar os resultados preenchendo um ou combinando mais de um dos campos abaixo:</em></p>
            <br>
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="">Tipo de relatório</label>
                    <div class="select-wrapper">
                        <select [ngModel]="busca.tipoRelatorio" (change)="selectTipoRelatorio($event)" class="form-control form-select custom-select" style="margin-top: 0;">
                            <option value="1">Relatório de notificações</option>
                            <option value="2">Relatório de apontamentos das notificações</option>
                            <option value="3">Relatório de análises de notificações</option>
                        </select>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label for="osc">OSC</label>
                    <div class="select-wrapper" style="width:100%">
                        <ng-multiselect-dropdown name="osc" class="multiselect-dropdownSgp" [placeholder]="'escolha OSC'" [data]="dropdownOscList" #osc="ngModel" [(ngModel)]="cnpjOscs" [settings]="dropdownSettings" (onSelect)="onOscSelect($event)" (onSelectAll)="onSelectAllOsc($event)"
                            (onDeSelect)="onOscDeSelect($event)" (onDeSelectAll)="onOscDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label for="modalidade">Modalidade</label>
                    <div class="select-wrapper" style="width:100%">
                        <ng-multiselect-dropdown name="modalidade" class="multiselect-dropdownSgp" [placeholder]="'escolha modalidade'" [data]="this.utils.modalidadeOptions" #modalidade="ngModel" [(ngModel)]="modalidades" [settings]="dropdownSettings" (onSelect)="onModalidadeSelect($event)" (onSelectAll)="onSelectAllModalidade($event)"
                            (onDeSelect)="onModalidadeDeSelect($event)" (onDeSelectAll)="onModalidadeDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="termo">Termo</label>
                    <div class="select-wrapper" style="width:100%">
                        <ng-multiselect-dropdown name="termo" class="multiselect-dropdownSgp" [placeholder]="'escolha termo'" [data]="dropdownTermoList" #termo="ngModel" [(ngModel)]="parIds" [settings]="dropdownSettings" (onSelect)="onTermoSelect($event)" (onSelectAll)="onSelectAllTermo($event)"
                            (onDeSelect)="onTermoDeSelect($event)" (onDeSelectAll)="onTermoDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="form-group col-md-4" [hidden]="busca.tipoRelatorio == 3">
                    <label for="situacao">Situação</label>
                    <div class="select-wrapper" style="width:100%">
                        <ng-multiselect-dropdown name="situacao" class="multiselect-dropdownSgp" [placeholder]="'escolha situação'" [data]="dropdownSituacaoList" #situacao="ngModel" [(ngModel)]="situacoes" [settings]="dropdownSettings" (onSelect)="onSituacaoSelect($event)" (onSelectAll)="onSelectAllSituacao($event)"
                            (onDeSelect)="onSituacaoDeSelect($event)" (onDeSelectAll)="onSituacaoDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label for="notificacao">Notificação</label>
                    <div class="select-wrapper" style="width:100%">
                        <ng-multiselect-dropdown name="notificacao" class="multiselect-dropdownSgp" [placeholder]="'escolha notificação'" [data]="dropdownNotificacaoList" #notificacao="ngModel" [(ngModel)]="notificacaoIds" [settings]="dropdownSettings" (onSelect)="onNotificacaoSelect($event)" (onSelectAll)="onSelectAllNotificacao($event)"
                            (onDeSelect)="onNotificacaoDeSelect($event)" (onDeSelectAll)="onNotificacaoDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="dataIni">Data Início Situação</label>
                    <input class="form-control" style="margin-top: 0;" placeholder="Selecione a data no calendário" onkeydown="return false" name="dataIni" #dtIni="ngModel" [(ngModel)]="dataIni" ngbDatepicker #dI="ngbDatepicker" required>
                    <i class="fa fa-calendar icon" (click)="dI.toggle()" title="calendário"></i>
                </div>
                <div class="form-group col-md-4">
                    <label for="dataFim">Data Fim Situação</label>
                    <input class="form-control" style="margin-top: 0;" placeholder="Selecione a data no calendário" onkeydown="return false" name="dataFim" #dtFim="ngModel" [(ngModel)]="dataFim" ngbDatepicker #dF="ngbDatepicker" required>
                    <i class="fa fa-calendar icon" (click)="dF.toggle()" title="calendário"></i>
                </div>
                <div class="form-group col-md-4">
                    <label for="usuario">Usuário</label>
                    <div class="select-wrapper" style="width:100%">
                        <ng-multiselect-dropdown name="usuario" class="multiselect-dropdownSgp" [placeholder]="'escolha usuário'" [data]="dropdownUsuarioList" #usuario="ngModel" [(ngModel)]="usuarios" [settings]="dropdownSettings" (onSelect)="onUsuarioSelect($event)" (onSelectAll)="onSelectAllUsuario($event)"
                            (onDeSelect)="onUsuarioDeSelect($event)" (onDeSelectAll)="onUsuarioDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4 btn-inline">
                    <div class="form-group">
                        <div class="select-wrapper" style="padding-top: 38px;">
                            <button class="btn btn-primary" (click)="limparFiltros()">Limpar</button>
                            <button class="btn btn-primary" (click)="geraArquivo('PDF')">Gerar PDF</button>
                            <button class="btn btn-primary" (click)="geraArquivo('XLS')">Gerar Excel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>