<div class="panel row">

    <div class="range">
      <div class="form-group ">
        <div class="input-group">

          <input name="datepicker" class="form-control hide-range" ngbDatepicker #datepicker="ngbDatepicker"
            [autoClose]="'outside'"
            [displayMonths]="2"           
            [tabindex]="-1"
            [startDate]="calendarStartDay"
            [maxDate]="calendarEndDay"    
            [footerTemplate]="footerTemplate"
            [showWeekNumbers]="false"         
            (dateSelect)="onDateSelection(datepicker, $event)" >

          <ng-template #t let-date let-focused="focused" let-disabled="disabled">
            <span class="custom-day"
              [class.text-muted]="disabled"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
              {{ date.day }}
            </span>
          </ng-template>

          <ng-template #footerTemplate>
            <hr class="my-0">
            <button class="btn btn-primary btn-sm m-2 float-left" (click)="onDateSelection(datepicker, today); focusDate(datepicker, today)">Hoje</button>
            <button class="btn btn-secondary btn-sm m-2 float-right" (click)="limparDatas()">Limpar</button>
            <button class="btn btn-primary btn-sm m-2 float-right" (click)="datepicker.close()">Fechar</button>
          </ng-template>

        </div>
      </div>
    </div>

  <div class="col">
    <div class="form-group">
      <label hidden="!showLabelData">Data inicial</label>

      <div class="input-group">
       
        <div class="input-group-prepend">
          <button tabindex="-1" class="btn btn-outline-primary fa fa-calendar"
            (click)="datepicker.toggle()" type="button"></button>
        </div>

        <input #dpFromDate class="form-control" placeholder="dd/mm/aaaa" name="dpFromDate" autocomplete="off"
          mask="00/00/0000" [dropSpecialCharacters]="false"
          [(ngModel)]="fromDateStr" #dataInicial="ngModel" app-valid-date [appMaxDate]="calendarEndDay"
          (ngModelChange)="atualizarFromDate($event)" (blur)="emitPeriodo()"   (keydown.enter)="emitPeriodo()"/>
          
         

        <button class="btn bg-transparent input-append">
          <i class="fa fa-times" (click)="fromDate = null; fromDateStr = null"></i>
        </button>
      </div>

      <div *ngIf="dataInicial.touched && dataInicial.hasError('invalidMaxDate')" class="error-message">
        <span>Data informada é maior que o permitido</span>
      </div>
      <div *ngIf="dataInicial.touched && dataInicial.hasError('invalidMinDate')" class="error-message">
        <span>Data informada é menor que o permitido</span>
      </div>
      <div *ngIf="dataInicial.touched && dataInicial.hasError('invalidDate')" class="error-message">
        <span>Deve ser informada uma data válida</span>
      </div>
    </div>
  </div>

  <div class="col" *ngIf="!isOnlyDataInicio">
    
    <div class="form-group">
      
      <label hidden="!showLabelData">Data final</label>



      
      <div class="input-group">

        <div style="padding-right: 12px; margin-top: 14px; margin-left:-22px">a</div>
       
        <div class="input-group-prepend">
          <button tabindex="-1" class="btn btn-outline-primary fa fa-calendar"
             (click)="datepicker.toggle()" type="button"></button>
         </div>

        <input #dpToDate  class="form-control" placeholder="dd/mm/aaaa" name="dpToDate" autocomplete="off"
        mask="00/00/0000" [dropSpecialCharacters]="false"
        [(ngModel)]="toDateStr" #dataFinal="ngModel" app-valid-date [appMaxDate]="calendarEndDay"
        (ngModelChange)="atualizarToDate($event)" (blur)="emitPeriodo()" (keydown.enter)="emitPeriodo()"/>

       

       
        <button class="btn bg-transparent input-append">
          <i class="fa fa-times" (click)="toDate = null; toDateStr = null"></i>
        </button>
      </div>

      <div *ngIf="dataFinal.touched && dataFinal.hasError('invalidMaxDate')" class="error-message">
        <span>Data informada é maior que o permitido</span>
      </div>
      <div *ngIf="dataFinal.touched && dataFinal.hasError('invalidMinDate')" class="error-message">
        <span>Data informada é menor que o permitido</span>
      </div>
      <div *ngIf="dataFinal.touched && dataFinal.hasError('invalidDate')" class="error-message">
        <span>Deve ser informada uma data válida</span>
      </div>

    </div>
  </div>

</div>