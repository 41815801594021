<div *ngIf="prestacoesContas.length == 0">
    <span class="mensagem-vazio">Nenhuma prestação de contas encontrada para os critérios selecionados.</span>
</div>
<div>
    <table class="table table-striped" style="margin-top:30px" id="tabelaPrestacaoContas" [mfData]="prestacoesContas"
        #mtprestacontas="mfDataTable">
        <thead>
            <tr>
                <th data-html2canvas-ignore="true"></th>
                <th *ngIf="!disableApagarIcone()" data-html2canvas-ignore="true"></th>
                <th>Nome</th>
                <th>Início Período</th>
                <th>Fim Período</th>
                <th>Situação</th>
                <th>Saldo Inicial</th>
                <th>Saldo Final</th>
                <th>Anexo</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let prestacaoContas of mtprestacontas.data | paginate:config let j = index;">
                <td class="col-auto" data-html2canvas-ignore="true">
                    <i ngbTooltip="editar" *ngIf="enableEditarIcone(prestacaoContas)" class="fa fa-pencil" aria-hidden="true"
                        (click)="openEdicaoPrestacaoContas(contentEdicaoPrestacaoContas, prestacaoContas, false)"></i>
                    <i *ngIf="enableVisualizarIcone(prestacaoContas)" ngbTooltip="visualizar" class="fa fa-search" aria-hidden="true"
                        (click)="openEdicaoPrestacaoContas(contentEdicaoPrestacaoContas, prestacaoContas, true)"></i>
                </td>
                <td *ngIf="!disableApagarIcone()" class="col-auto" data-html2canvas-ignore="true">
                    <i ngbTooltip="apagar" *ngIf="enableApagarIcone(prestacaoContas)" class="fa fa-trash-o" aria-hidden="true"
                        (click)="openExcluirPrestacaoContas(contentExcluirPrestacaoDeContas, prestacaoContas)"></i>
                </td>
                <td class="col-auto ellipsisCampo" style="max-width: 10rem;">{{prestacaoContas.nome}}</td>
                <td class="col-auto">{{prestacaoContas.inicioPeriodo | date: 'dd/MM/yyyy'}}</td>
                <td class="col-auto">{{prestacaoContas.fimPeriodo | date: 'dd/MM/yyyy'}}</td>
                <td class="col-auto">{{prestacaoContas.strStatus}}</td>
                <td class="col-auto">{{prestacaoContas.saldoInicial | currency: 'BRL'}}</td>
                <td class="col-auto">{{prestacaoContas.saldoFinal | currency: 'BRL'}}</td>
                <td class="col-auto" style="max-width: 6rem;">
                    <div [hidden]="!prestacaoContas.arquivo" class="ellipsisCampo" >
                       {{prestacaoContas.arquivo}}      
                    </div>
                </td>
                <td td class="col-auto">
                    <div [hidden]="!prestacaoContas.arquivo">
                        <i ngbTooltip="download" class="fa fa-download " aria-hidden="true"
                        (click)="getArquivoPrestacao(prestacaoContas.id, prestacaoContas.nome)" alt="download "></i>
                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot data-html2canvas-ignore="true" *ngIf="prestacoesContas.length > config.itemsPerPage">
            <tr>
              <td colspan="15">
                <div class="d-flex justify-content-between">
                  <pagination-controls class="pagination pagination my-2" style="height: 2rem;" id="custom" (pageChange)="pageChanged($event)"
                    directionLinks="true" previousLabel="" nextLabel="">
                  </pagination-controls>
                </div>
              </td>
            </tr>
          </tfoot>
    </table>
    <div *ngIf="!disableApagarIcone()">
        <button  (click)="openEdicaoPrestacaoContas(contentEdicaoPrestacaoContas, undefined)"
            id="button-insert_doc " class="btn btn-primary btn-info" type="button " title="Adicionar nova">
            <i class="fa fa-plus "><span class="texto-botao">Adicionar nova</span> </i>
        </button>
    </div>
</div>
<ng-template #contentEdicaoPrestacaoContas let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Prestação de contas</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click', resumo=false)" title="Fechar">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding-block: 3px;">
        <div class="col-md-6">
            <form #edicao="ngForm" novalidate>
                <div class="form-group col-md-12">
                    <label class="labelInputPrestacao" style="margin: 0;" for="nome">Informe um nome para a prestação de
                        contas a ser enviada. Ex: "janeiro_2023"</label>
                    <span [hidden]="nome.valid" class="campoObrigatorio">*</span>
                    <input [disabled]="disableCampos()" style="width: 100%" id="nome" name="nome" #nome="ngModel"
                        class="form-control" type="text" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="this.prestacaoContasSend.nome" [value]="this.prestacaoContasSend.nome" required />
                    <i *ngIf="!disableCampos()" class="fa fa-times icon_input_fontawsome" aria-hidden="true"
                        (click)="prestacaoContasSend.nome=undefined;edicao.form.valid=false;" title="limpar"></i>
                </div>
                <div class="form-group col-md-12">
                    <div>
                        <label class="labelInputPrestacao" for="dataInicio">Informe o período da prestação de contas,
                            que inclua a data do repasse e realização das despesas e rendimentos.
                            Ex: 01/01/2023 a 31/01/2023
                            <span [hidden]="dataInicio.valid && dataFim.valid" class="campoObrigatorio">*</span>
                        </label>
                    </div>
                    <div style="display: flex;">
                        <div style="padding: 0; width: 50%;">
                            <input [disabled]="disableCampos()" style="width:100%;" class="form-control"
                                placeholder="Selecione a data no calendário" name="dataInicio" #dataInicio="ngModel"
                                [(ngModel)]="prestacaoContasSend.inicioPeriodoDataFormat" maxlength="10"
                                placeholder="dd/mm/aaaa" appMaskInput
                                [appMaskValue]="prestacaoContasSend.inicioPeriodoDataFormat" ngbDatepicker
                                #d1="ngbDatepicker" required>
                            <i class="fa fa-calendar icon" (click)="d1.toggle()" title="calendário"></i>
                        </div>
                        <span style="align-self: center; justify-content: center; padding-inline: 0.5rem;">até</span>
                        <div style="padding: 0; width: 50%;">
                            <input [disabled]="disableCampos()" style="width:100%;" class="form-control"
                                placeholder="Selecione a data no calendário" name="dataFim" #dataFim="ngModel"
                                [(ngModel)]="prestacaoContasSend.fimPeriodoDataFormat" maxlength="10"
                                placeholder="dd/mm/aaaa" appMaskInput
                                [appMaskValue]="prestacaoContasSend.fimPeriodoDataFormat" ngbDatepicker
                                #d2="ngbDatepicker" required>
                            <i class="fa fa-calendar icon" (click)="d2.toggle()" title="calendário"></i>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <label class="labelInputPrestacao" for="anexo">Extrato Bancário completo da conta corrente
                        da Parceria do período de prestação de contas.
                        <span [hidden]="prestacaoContasSend.arquivo" class="campoObrigatorio">*</span>
                    </label>
                    <br>
                    <div [hidden]="showUpload">
                        <span class="link" (click)="opemArquivo(contentOpemArquivo, undefined)">
                            <i class="fa fa-paperclip" aria-hidden="true"></i>
                            {{prestacaoContasSend.arquivo}}.{{prestacaoContasSend.arqTipo}}
                        </span>
                        <i *ngIf="!disableCampos()" ngbTooltip="Remover" class="fa fa-times" aria-hidden="true"
                            (click)="showUpload = true; prestacaoContasSend.arquivo = undefined" alt="Remover"></i>
                    </div>
                    <div [hidden]="!showUpload">
                        <input type="file" name="anexo" id="anexo" class="form-control-upload" style="margin-top: 3px;"
                            accept=".jpg, .pdf, .png, .jpeg" (change)="onFileChanged($event)">
                        <span class="spanSmall">Tamanho máximo: 10 MB</span>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-12">
                        <label class="labelInputPrestacao">Saldo inicial e final da conta corrente da
                            parceria no período da prestação de conta, conforme Extrato Bancário.
                        </label>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="labelInputPrestacaoOpacity" for="saldoInicial">Saldo inicial:
                        </label>
                       <!--  <span [hidden]="saldoInicial.valid" class="campoObrigatorio">*</span> -->
                        <input [disabled]="disableCampos()" style="width: 100%" name="saldoInicial"
                            #saldoInicial="ngModel" class="form-control" type="text" currencyMask
                            [(ngModel)]="prestacaoContasSend.saldoInicial" [ngModelOptions]="{standalone: true}"
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero />
                    </div>
                    <div class="form-group col-md-6">
                        <label class="labelInputPrestacaoOpacity" for="saldoFinal">Saldo final:
                        </label>
                        <!-- <span [hidden]="saldoFinal.valid" class="campoObrigatorio">*</span> -->
                        <input [disabled]="disableCampos()" style="width: 100%" id="saldoFinal" name="saldoFinal"
                            #saldoFinal="ngModel" class="form-control" currencyMask
                            [(ngModel)]="prestacaoContasSend.saldoFinal" [ngModelOptions]="{standalone: true}"
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero/>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-12">
                        <label class="labelInputPrestacao" for="poupanca">Totais depositados e resgatados de conta poupança 
                            (diferente da conta corrente) no período da prestação de contas, se houver.
                        </label>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="labelInputPrestacaoOpacity" for="creditoPoupanca">total depositado:</label>
                        <input [disabled]="disableCampos()" style="width: 100%" id="creditoPoupanca"
                            name="creditoPoupanca" #creditoPoupanca="ngModel" class="form-control" type="text"
                            currencyMask [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="prestacaoContasSend.creditoPoupanca"
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero />
                    </div>
                    <div class="form-group col-md-6">
                        <label class="labelInputPrestacaoOpacity" for="debitoPoupanca">total resgatado:</label>
                        <input [disabled]="disableCampos()" style="width: 100%" id="debitoPoupanca"
                            name="debitoPoupanca" #debitoPoupanca="ngModel" class="form-control" type="text"
                            currencyMask [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="prestacaoContasSend.debitoPoupanca"
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero />
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-12">
                        <label class="labelInputPrestacao" for="aplicacao">Totais depositados e resgatados de conta aplicação 
                            (diferente da conta corrente e poupança) no período da prestação de contas, se houver.
                        </label>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="labelInputPrestacaoOpacity" for="creditoAplicacao">total depositado:
                        </label>
                        <input [disabled]="disableCampos()" style="width: 100%" id="creditoAplicacao" name="creditoAplicacao"
                            #saldoInicial="ngModel" class="form-control" type="text" currencyMask
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="prestacaoContasSend.creditoAplicacao"
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero />
                    </div>
                    <div class="form-group col-md-6">
                        <label class="labelInputPrestacaoOpacity" for="debitoAplicacao">total resgatado:
                        </label>
                        <input [disabled]="disableCampos()" style="width: 100%" id="debitoAplicacao" name="debitoAplicacao"
                            #saldoFinal="ngModel" class="form-control" type="text" currencyMask
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="prestacaoContasSend.debitoAplicacao"
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero />
                    </div>
                </div>
                <div class="modal-footer col-md-12" [hidden]="disableBotao()">
                    <button type="button " class="btn btn-default " (click)="c( 'Close click');cancelarEdicao()"
                        title="Cancelar ">Cancelar</button>
                    <button *ngIf="disableCampos()" class="button btn btn-secondary"
                        (click)="enableEditarPrestacaoProcessada()">Editar</button>
                    <div [hidden]="disableCampos()">
                        <button class="button btn btn-secondary" (click)="limpar()">Limpar</button>
                        <button class="button btn btn-primary" [disabled]="!edicao.form.valid || !prestacaoContasSend.arquivo"
                            (click)="processarPrestacaoContas()">Processar</button>
                    </div>
                </div>
            </form>
        </div>
        <div [hidden]="!resumo && !disableCampos()" class="col-md-6 pt-3" style="border-left: 2px solid #f1f1f1;">   
            <div>
                <div style="padding-left: 15px;">
                    <span style="font-weight: bold;">
                        RESUMO DO PROCESSAMENTO DA PRESTAÇÃO DE CONTAS
                        <br>
                        {{prestacaoContasSend.nome}}
                        <br>
                        Período: {{prestacaoContasSend.inicioPeriodo | date: 'dd/MM/yyyy'}} a
                        {{prestacaoContasSend.fimPeriodo | date: 'dd/MM/yyyy'}}
                    </span>
                </div>
                <hr>
                <div class="lineResumoPrestacaoContas inicial">
                    <span class="col-md-8 text-left">Saldo inicial da Conta Corrente</span>
                    <span class="col-md-4 text-right">{{prestacaoContasSend.saldoInicial | currency: 'BRL'}}</span>
                </div>
                <div class="lineResumoPrestacaoContas">
                    <span class="col-md-8 text-left">Total do repasse no período (+)</span>
                    <span class="col-md-4 text-right soma">{{prestacaoContasSend.totalRepasses | currency: 'BRL'}}</span>
                </div>
                <div class="lineResumoPrestacaoContas">
                    <span class="col-md-8 text-left">Total resgatado de Conta Poupança (+)</span>
                    <span class="col-md-4 text-right soma">{{prestacaoContasSend.debitoPoupanca | currency: 'BRL'}}</span>
                </div>
                <div class="lineResumoPrestacaoContas">
                    <span class="col-md-8 text-left">Total resgatado de Conta Aplicação (+)</span>
                    <span class="col-md-4 text-right soma">{{prestacaoContasSend.debitoAplicacao | currency: 'BRL'}}</span>
                </div>
                <div class="lineResumoPrestacaoContas">
                    <span class="col-md-8 text-left">Total de devoluções/rendimentos (+)</span>
                    <span class="col-md-4 text-right soma">{{prestacaoContasSend.totalDevolucoes | currency: 'BRL'}}</span>
                </div>
                <div class="lineResumoPrestacaoContas">
                    <span class="col-md-8 text-left">Total aplicado em Conta Poupança (-)</span>
                    <span class="col-md-4 text-right menos">{{prestacaoContasSend.creditoPoupanca | currency: 'BRL'}}</span>
                </div>
                <div class="lineResumoPrestacaoContas">
                    <span class="col-md-8 text-left">Total aplicado em Conta Aplicação (-)</span>
                    <span class="col-md-4 text-right menos">{{prestacaoContasSend.creditoAplicacao | currency: 'BRL'}}</span>
                </div>
                <div class="lineResumoPrestacaoContas">
                    <span class="col-md-8 text-left">Total de desembolsos (-)</span>
                    <span class="col-md-4 text-right menos">{{prestacaoContasSend.totalDesembolsos | currency: 'BRL'}}</span>
                </div>
                <div class="lineResumoPrestacaoContas">
                    <span class="col-md-8 text-left">Total de devoluções de saldo final remanescente da parceria (-)</span>
                    <span class="col-md-4 text-right menos">{{prestacaoContasSend.totalDevSaldoRem | currency: 'BRL'}}</span>
                </div>
                <div class="lineResumoPrestacaoContas">
                    <span class="col-md-8 text-left saldoParcial">Saldo parcial calculado</span>
                    <span class="col-md-4 text-right saldoParcial">{{prestacaoContasSend.saldoParcial | currency: 'BRL'}} 
                        <i *ngIf="isSaldoDiferente" class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    </span>
                </div>
                <div class="lineResumoPrestacaoContas">
                    <span class="col-md-8 text-left">Saldo final informado</span>
                    <span class="col-md-4 text-right">{{prestacaoContasSend.saldoFinal | currency: 'BRL'}}</span>
                </div>
                <div class="lineResumoPrestacaoContas">
                    <span class="col-md-8 text-left">Diferença entre o saldo calculado e o informado</span>
                    <span class="col-md-4 text-right">{{(prestacaoContasSend.saldoFinal - prestacaoContasSend.saldoParcial)  | currency: 'BRL'}}</span>
                </div>
                <span class="link col-md-12 mt-2" (click)="opemArquivo(contentOpemArquivo, undefined)">
                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                    {{prestacaoContasSend.arquivo}}.{{prestacaoContasSend.arqTipo}}
                </span>
            </div>
            <div class="modal-footer col-md-12 mt-2 mb-3">
                <div [hidden]="disableBotao() || prestacaoContasSend.status == 4 || prestacaoContasSend.status == 5">
                    <button class="button btn btn-primary" (click)="salvarRascunho()">Salvar rascunho</button>
                    <button class="button btn btn-primary" [disabled]="disableEnviarPrestacao()"
                        (click)="confirmEnviarPrestacaoDeContas(contentEnviarPrestacaoDeContas, undefined)">Enviar</button>
                </div>
                <div *ngIf="(prestacaoContasSend.status == 4 || prestacaoContasSend.status == 5) && !disableBotao()">
                    <button class="button btn btn-primary" (click)="salvarReenvio()">Salvar reenvio</button>
                    <button class="button btn btn-primary" [disabled]="disableEnviarPrestacao()"
                        (click)="confirmEnviarPrestacaoDeContas(contentReenviarPrestacaoDeContas, undefined)">Reenviar</button>
                </div>
                <div class="mt-3" style="position: relative; bottom: 0;"
                    [hidden]="!isFiscal || (prestacaoContasSend.status != 2 && prestacaoContasSend.status != 6)">
                    <button class="button btn btn-danger"
                        (click)="confirmEnviarPrestacaoDeContas(contentRejeitarPrestacaoDeContas, undefined)">Rejeitar</button>
                    <button class="button btn btn-primary"
                        (click)="confirmEnviarPrestacaoDeContas(contentAceitarPrestacaoDeContas, undefined)">Aceitar</button>
                </div>
            </div>
        </div>
        <div *ngIf="prestacaoContasSend.status > 1" class="col-md-12 pb-0">
            <div class="obsAceitaRejeitada">
                <div *ngFor="let log of prestacaoContasSend.logs let j = index;">
                    Proposta {{log.strStatus}} em {{log.dataAtualizacao | date: 'dd/MM/yyyy'}}
                    <span *ngIf="log.status == 4">, motivo: {{log.motivoRejeicao}}</span>
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="!(loading$ | async) ">
        <div class="loading-overlay "></div>
        <div class="loading-info ">
            <i class="fa fa-spinner fa-4x fa-spin "></i>
            <div id="carregando ">Aguarde</div>
        </div>
    </div>
</ng-template>

<ng-template #contentExcluirPrestacaoDeContas let-c="close " let-d="dismiss ">
    <div class="modal-header ">
        <h4 class="modal-title " id="modal-basic-title ">Excluir Prestação de contas</h4>
        <button type="button " class="close " aria-label="Close " (click)="d( 'Cross click') " title="Fechar ">
            <span aria-hidden="true ">&times;</span>
        </button>
    </div>
    <div class="modal-body ">
        <p>Tem certeza que deseja excluir a Prestação de contas <b>{{prestacaoContasSend.nome}}</b>
    </div>
    <div class="modal-footer ">
        <button type="button " class="btn btn-default " (click)="c( 'Close click') " title="Fechar ">Fechar</button>
        <button type="button " class="btn btn-secondary " (click)="excluirPrestacaoDeContas(prestacaoContasSend.id) "
            title="Excluir ">Excluir</button>
    </div>
</ng-template>

<ng-template #contentOpemArquivo let-c="close " let-d="dismiss">
    <div class="modal-header ">
        <h4 class="modal-title " id="modal-basic-title ">{{prestacaoContasSend.arquivo}}.{{prestacaoContasSend.arqTipo}}
        </h4>
        <button type="button " class="close " aria-label="Close " (click)="d( 'Cross click') " title="Fechar ">
            <span aria-hidden="true ">&times;</span>
        </button>
    </div>
    <ngx-extended-pdf-viewer *ngIf="arquivo && prestacaoContasSend.arqTipo == 'pdf'" [src]="arquivo" useBrowserLocale="true" height="75vh"
        [showZoomButtons]="true">
    </ngx-extended-pdf-viewer>
    <img *ngIf="arquivo && prestacaoContasSend.arqTipo != 'pdf'" [src]="arquivo" alt="imagem" style="max-height:75vh;">
</ng-template>

<ng-template #contentEnviarPrestacaoDeContas let-c="close " let-d="dismiss">
    <div class="my-3 mx-3">
        <h6>Após enviar a prestação de contas, não será mais possível editá-la.<br><span style="line-height: 2;">Confirma o envio?</span></h6>
        <div class="col-md-12 mt-4 mb-2 pr-0" style="display: flex; align-items: end; justify-content: right;">
            <button class="button btn btn-default mr-3" (click)="d( 'Cross click')">cancelar</button>
            <button class="button btn btn-primary px-2" (click)="isSaldoDiferente ? confirmEnviarPrestacaoDeContas(contentSaldoDiferente, undefined) : enviarPrestacaoContas()">Sim</button>
        </div>
    </div>
</ng-template>
<ng-template #contentReenviarPrestacaoDeContas let-c="close " let-d="dismiss">
    <div class="my-3 mx-3">
        <h6>Após reenviar a prestação de contas, não será mais possível editá-la.<br><span style="line-height: 2;">Confirma o reenvio?</span></h6>    
        <div class="col-md-12 mt-4 mb-2 pr-0" style="display: flex; align-items: end; justify-content: right;">
            <button class="button btn btn-default mr-3" (click)="d( 'Cross click')">cancelar</button>
            <button class="button btn btn-primary px-2" (click)="isSaldoDiferente ? confirmEnviarPrestacaoDeContas(contentSaldoDiferente, undefined) : reenviarPrestacaoContas()">Sim</button>
        </div>
    </div>
</ng-template>
<ng-template #contentRejeitarPrestacaoDeContas let-c="close " let-d="dismiss">
    <div class="my-3 mx-3">
        <h6>Digite o motivo da rejeição</h6>
        <textarea ng-d style="width:100%;" id="motivo" class="form-control" #motivo="ngModel"
            [(ngModel)]="prestacaoContasSend.motivoRejeicao" [ngModelOptions]="{standalone: true}">
        </textarea>
        <i class="fa fa-times icon_input_fontawsome " aria-hidden="true "
            (click)="prestacaoContasSend.motivoRejeicao=undefined " title="limpar "></i>
        <div class="col-md-12 mt-4 mb-2 pr-0" style="display: flex; align-items: end; justify-content: right;">
            <button class="button btn btn-default mr-3" (click)="d( 'Cross click')">cancelar</button>
            <button class="button btn btn-primary" (click)="rejeitarPrestacaoContas()"
                [disabled]="!prestacaoContasSend.motivoRejeicao">Enviar</button>
        </div>
    </div>
</ng-template>
<ng-template #contentAceitarPrestacaoDeContas let-c="close " let-d="dismiss">
    <div class="my-3 mx-3">
        <h6>Confirma o aceite da Prestação de contas?</h6>
        <div class="col-md-12 mt-4 mb-2 pr-0" style="display: flex; align-items: end; justify-content: right;">
            <button class="button btn btn-default mr-3" (click)="d( 'Cross click')">cancelar</button>
            <button class="button btn btn-primary px-4" (click)="aceitarPrestacaoContas()">Sim</button>
        </div>
    </div>
</ng-template>
<ng-template #contentSaldoDiferente let-c="close" let-d="dismiss">
    <div class="my-3 mx-3">
        <i class="fa fa-exclamation-circle vermelho" aria-hidden="true"></i>
        <p> 
            Atenção: o saldo parcial calculado não confere com o saldo final informado da conta corrente da parceria. 
            Recomenda-se a revisão dos saldos, desembolsos, devoluções e rendimentos. Deseja enviar prestação de contas com divergência?
        </p>
        <b>Observação: a partir de 01/01/2024 não será possível enviar prestações de contas com divergências no processamento.</b>
        <div class="col-md-12 mt-4 mb-2 pr-0" style="display: flex; align-items: end; justify-content: right;">
            <button class="button btn btn-default mr-3" (click)="d( 'Cross click')">cancelar</button>
            <button class="button btn btn-primary px-4" (click)="(prestacaoContasSend.status == 1 || prestacaoContasSend.status == null) ? enviarPrestacaoContas() : reenviarPrestacaoContas()">Sim</button>
        </div>
    </div>
</ng-template>