import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from '../detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../../apis/detalheParceriaApi';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { Mascaras } from '../../@shared/components/Mascaras';
import { UserService } from '../../@core/user.service';
import { ParceriaPrestaContaParcial } from './parceriaPrestaContaParcial';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from 'src/app/@core/date-converter';
import { PesquisaData } from '../pesquisaData';
import { ImagensUrlData } from 'src/app/@shared/imagens-urldata';
import * as jspdf from 'jspdf';
import { ParceriaPrestaContaParcialSend } from './parceriaPrestaContaParcialSend';
import * as Json2csvParser from 'json2csv';
import { ExcelService } from 'src/app/@shared/excel-service';
import saveAs from 'file-saver';
import { DatePipe } from '@angular/common';
import { PaginationInstance } from 'ngx-pagination';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { Utils } from 'src/app/@shared/utils';
import { FiltroDocComplementar } from './filtroDocComplementar';

@Component({
  selector: 'app-prestacao-parcial-parceria',
  templateUrl: './../prestacaoDeContasParcial/prestacaoParcial.component.html',
  styleUrls: ['./../../modulo2/detalheParceria/detalheParceria.component.scss'],
  providers: [DatePipe]
})
export class PrestacaoContasParcialComponent implements OnInit, OnChanges {

  @Input()
  private parceriaInfo: ParceriaInfo;
  @Input()
  public isPortal: boolean;
  prestaContaParcial: Array<ParceriaPrestaContaParcial> = [];
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  mascaraData = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], guide: false };
  private isFiscal: boolean;
  docComplementarSend: ParceriaPrestaContaParcialSend;
  showCamposEdicao = false;
  showUpload = false;
  modalEdicaoReference: any;
  isSMTC: boolean;
  isConsulta: boolean;
  @Input()
  pesquisaData: PesquisaData;
  arquivo: string;
  dataIni: NgbDateStruct;
  dataFim: NgbDateStruct;
  p = 1;
  pagina: number;
  filtro: FiltroDocComplementar;
  @Input()
  isAuditorExterno: boolean;
  username: string;

  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 10,
    currentPage: 1
  };
  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private parceriaDespesaDevolApi: PublicoPrivadaApi,
    public userService: UserService,
    private mascaras: Mascaras,
    private excelService: ExcelService,
    private datePipe: DatePipe,
    private utils: Utils) {

  }

  ngOnInit() {
    this.userService.userInfo.subscribe(user => this.username = user.username);
    this.setupInterface();
  }

  setupInterface() {
    //console.log('Portal Doc '+ this.isPortal);
    this.loading.next(true);
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();
    if (this.pesquisaData === undefined || this.pesquisaData.dataInicial === undefined) {
      const myDate = new Date(new Date().getFullYear(), (Number(new Date().getMonth())), 1, 0, 0, 0);
      this.pesquisaData = new PesquisaData();
      this.pesquisaData.dataInicial = myDate;
      this.pesquisaData.dataFinal = new Date();
    }
    this.inicializaFiltro();
    this.getPrestaContaParcial(this.filtro);

  }

  inicializaFiltro() {
    if (this.filtro === undefined) {
      this.filtro = new FiltroDocComplementar();
      this.filtro.parId = this.parceriaInfo.parId;
      this.filtro.prestaId = null;
      this.filtro.dataInicial = this.datePipe.transform(this.pesquisaData.dataInicial, 'dd/MM/yyyy');
      this.filtro.dataFinal = this.datePipe.transform(this.pesquisaData.dataFinal, 'dd/MM/yyyy');
      this.filtro.data = null;
      this.filtro.situacao = null;
      this.filtro.tipo = null;
    }
  }

  getPrestaContaParcial(filtro) {
    const filtroD = JSON.stringify(filtro);
    this.parceriaDespesaDevolApi.getPrestaContaParcialPeriodo(filtroD, this.isPortal).subscribe((response) => {
      if (response) {
        this.prestaContaParcial = response as Array<ParceriaPrestaContaParcial>;
        console.log(this.prestaContaParcial);

        const cloneArray = this.prestaContaParcial.slice();
        this.prestaContaParcial.forEach(element => {
          if ((element.prestaJustificaExclusao !== null) && (this.isPortal || (!this.isSMTC && !this.isFiscal))) {
            cloneArray.splice(cloneArray.indexOf(element), 1);
          }
        }, []);
        this.prestaContaParcial = cloneArray;

      } else {
        this.alertService.warning('Nenhum registro encontrado');
      }
      if (this.modalEdicaoReference != undefined)
        this.modalEdicaoReference.close();
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));
  }

  disableCampos(docComplementarSend) {
    if (docComplementarSend.execConferir === 2 || docComplementarSend.execConferir === 4 || docComplementarSend.execConferir === 5) {
      return true;
    }
    return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }
  disableApagarIcone() {
    return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }

  enableEditarIcone(docComplementarSend) {
    if (this.isConsulta || this.isSMTC || this.isPortal || this.isAuditorExterno) {
      return false;
    }
    return (docComplementarSend.prestaJustificaExclusao == null && this.isFiscal) ||
      (!this.isFiscal && (docComplementarSend.execConferir == 1 || docComplementarSend.execConferir == 3)); // entidade
  }

  visualizarBotao(docComplementarSend) {
    return ((this.isSMTC || this.isConsulta || this.isAuditorExterno) || ((!this.isFiscal && (docComplementarSend.execConferir === 2
      || docComplementarSend.execConferir === 4 || docComplementarSend.execConferir === 5))));
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let property in changes) {
      if (changes.hasOwnProperty(property) && property === 'pesquisaData') {
        if (this.pesquisaData === undefined || this.pesquisaData.dataInicial === undefined) {
          const myDate = new Date(new Date().getFullYear(), (Number(new Date().getMonth())), 1, 0, 0, 0);
          this.pesquisaData = new PesquisaData();
          this.pesquisaData.dataInicial = myDate;
          this.pesquisaData.dataFinal = new Date();
        }
        if (this.pesquisaData.dataFinal === undefined) {
          this.pesquisaData.dataFinal = new Date();
        }
        //this.getPrestaContaParcial(this.filtro);
        this.config.currentPage = 1;
        this.aplicaFiltroDocCompl();
      }
    }
  }

  pageChanged(event) {
    //console.log(event);
    this.config.currentPage = event;
  }

  adicionaDocComplementar() {
    const docComp = new ParceriaPrestaContaParcialSend();
    this.prestaContaParcial.push(docComp);
  }

  updatePublica(event) {
    const newVal = event.currentTarget.value;
    this.docComplementarSend.prestaDocumentoPublicar = newVal;
  }

  setDocComplementar(docComplementar) {
    if (docComplementar) {
      this.docComplementarSend = docComplementar;
      this.showUpload = this.docComplementarSend.prestaDocumentoArquivo ? false : true;
      this.docComplementarSend.prestaDocumentoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.docComplementarSend.prestaDocumentoData));
      this.docComplementarSend.prestaOperacaoData = new Date();
      this.docComplementarSend.prestaOperacaoUsuario = this.username;

    } else {
      this.docComplementarSend = new ParceriaPrestaContaParcialSend();
      this.showUpload = true;
      this.docComplementarSend.parId = this.parceriaInfo.parId;
      this.docComplementarSend.prestaId = -1;
      this.docComplementarSend.prestaDocumentoTipo = '';
      this.docComplementarSend.prestaDevolucao = 0.0;
    }

  }

  getArquivo(parId, id, tipo, nome) {
    this.loading.next(true);
    this.parceriaDespesaDevolApi.getArquivo(parId, id, tipo, this.isPortal)
      .subscribe((response) => {
        if (response) {
          console.log(response);
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(response.body);
          a.href = url;
          a.download = nome;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          this.alertService.warning('Nenhum arquivo encontrado');
        }
      }, (response) => {
        // if (response.status >= 500) {
        //   this.alertService.danger(response.error);
        // } else {
        //   this.alertService.warning(response.error);
        // }
        this.alertService.danger('Erro ao buscar arquivo.');
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  salvaDocComplementar() {

    if (this.docComplementarSend.prestaId === -1 && (this.docComplementarSend.prestaDocumento === null
      || this.docComplementarSend.prestaDocumento === undefined)) {
      this.alertService.danger('Dado obrigatório, realize o upload do arquivo.');
      return;
    }

    if ((this.docComplementarSend.prestaDocumentoArquivo != null && this.docComplementarSend.prestaDocumentoArquivo.length > 50)) {
      this.alertService.danger('Nome do arquivo superior a 50 caracteres, por favor renomeie antes de fazer upload.');
      return;
    }



    if (!this.isFiscal) { // se não for fiscal, retorna status da situação para a conferir
      this.docComplementarSend.execConferir = 1;
    }

    if (this.isFiscal && (this.docComplementarSend.execConferir == 3 || this.docComplementarSend.execConferir == 4
      || this.docComplementarSend.execConferir == 5)) {
      if (this.isBlank(this.docComplementarSend.prestaMotivo)) {
        this.alertService.danger('Campo apontamento deve ser preenchido.');
        return;
      }

    }

    this.docComplementarSend.prestaDocumentoData = this.dateConverter.fromNgbDateStructToDate(this.docComplementarSend.prestaDocumentoDataFormat);
    if (!this.utils.isValidDate(this.docComplementarSend.prestaDocumentoData)) {
      this.alertService.danger('Data Inválida.');
      return;
    }

    this.docComplementarSend.prestaOperacaoData = new Date();
    this.docComplementarSend.prestaOperacaoUsuario = this.username;
    this.docComplementarSend.prestaDocumentoData = this.dateConverter.
      fromNgbDateStructToDate(this.docComplementarSend.prestaDocumentoDataFormat);
    let data = JSON.stringify(this.docComplementarSend, this.replacerDocComplementar);
    this.detalheParceriaApi.updateDocComplementar(data).subscribe(async (response) => {
      if (response) {
        this.setupInterface();
        this.setDocComplementar(undefined);
        this.modalEdicaoReference.close();
        //await this.getParceriaInfo(this.docComplementarSend.parId);
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }

  excluirDocComplementar() {

    const today = new Date();
    const year1 = this.docComplementarSend.prestaDocumentoDataFormat.year;
    const year2 = today.getFullYear();
    const month1 = this.docComplementarSend.prestaDocumentoDataFormat.month;
    const month2 = today.getMonth() + 1;
    const day2 = today.getDate();

    const numberOfMonths = (year2 - year1) * 12 + (month2 - month1);
    this.docComplementarSend.prestaOperacaoData = new Date();
    this.docComplementarSend.prestaOperacaoUsuario = this.username;

    if ((numberOfMonths > 1) || (numberOfMonths === 1 && day2 > 10)) {
      if (this.isBlank(this.docComplementarSend.prestaJustificaExclusao)) {
        this.alertService.danger('A justificativa é obrigatória para a exclusão do documento complementar.');
        return;
      }

      // tslint:disable-next-line:max-line-length
      this.docComplementarSend.prestaDocumentoData = this.dateConverter.fromNgbDateStructToDate(this.docComplementarSend.prestaDocumentoDataFormat);
      if (!this.utils.isValidDate(this.docComplementarSend.prestaDocumentoData)) {
        this.alertService.danger('Data Inválida.');
        return;
      }
      let data = JSON.stringify(this.docComplementarSend, this.replacerDocComplementar);
      this.detalheParceriaApi.updateDocComplementar(data).subscribe(async (response) => {
        if (response) {
          this.setupInterface();
          this.setDocComplementar(undefined);
          this.modalEdicaoReference.close();
          //await this.getParceriaInfo(this.docComplementarSend.parId);
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
        this.modalEdicaoReference.close();
      }, () => this.loading.next(false));

    } else {
      this.detalheParceriaApi.excluirDocComplementar(this.docComplementarSend).subscribe((response) => {
        if (response) {
          this.setupInterface();
          this.setDocComplementar(undefined);
          this.modalEdicaoReference.close();
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
    }
  }
  openExportacao(content, prestacoes) {
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: 'modalProcesso' });
  }

  openFiltroDocCompl(content, filtros) {
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: "modalProcesso" });
  }

  updateSituacao(event) {
    const newVal = event.currentTarget.value;
    this.docComplementarSend.execConferir = newVal;
  }

  updateTextArea(valor) {
    this.docComplementarSend.prestaMotivo = valor;
  }

  updateTipoDocumento(event) {
    const newVal = event.currentTarget.value;
    this.docComplementarSend.prestaClassificaDoc = newVal;
  }


  replacerDocComplementar(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['prestaDocumentoDataFormat'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }
  async getFile(parId, execId, tipo) {
    this.parceriaDespesaDevolApi.getArquivo(parId, execId, tipo, this.isPortal)
      .subscribe((response) => {
        if (response) {
          //console.log(response);
          if (response.body.size > 0) {
            this.arquivo = URL.createObjectURL(response.body);
          } else {
            this.arquivo = '';
          }

        }
      }, (response) => {
        // if (response.status >= 500) {
        //   this.alertService.danger(response.error);
        // } else {
        //   this.alertService.warning(response.error);
        // }
        this.alertService.danger('Erro ao buscar arquivo.');
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  updateTextAreaJustificativa(valor) {
    this.docComplementarSend.prestaJustificaExclusao = valor;
  }


  openEdicaoDocComplementar(content, docComplementar) {
    this.setDocComplementar(docComplementar);
    if (this.docComplementarSend.prestaId !== -1) {
      this.getFile(this.docComplementarSend.parId, this.docComplementarSend.prestaId, 'prestaParcial');
    }
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: "modalProcesso" });
  }

  openExcluirDocComplementar(content, docComplementar) {
    this.setDocComplementar(docComplementar);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }

  onFileDocChanged(event) {
    const fileList: FileList = event.currentTarget.files;
    console.log(fileList);
    const reader = new FileReader();


    if (fileList.length > 0) {
      const array = fileList[0].type.split('/');
      const fileSize = fileList[0].size / 1024 / 1024; // in MB
      if (fileSize > 10) {
        this.alertService.danger('Tamanho de arquivo superior ao limite de 4 MB. Esse arquivo possui ' + fileSize.toPrecision(1) + " MB");
        return;
      }
      if (array[1] === 'pdf') {

        reader.readAsDataURL(fileList[0]);
        reader.onload = () => {
          this.docComplementarSend.prestaDocumento = ((reader.result).toString().split(',')[1]) as unknown as Blob;
          this.docComplementarSend.prestaDocumentoTipo = array[1];
          this.docComplementarSend.prestaDocumentoArquivo = fileList[0].name;
        };
      } else {
        event.currentTarget.value = '';
        this.alertService.warning('Formato de arquivo não suportado!');
      }
    }
  }

  resetDevFile(i) {
    this.docComplementarSend[i].prestaDocumento = undefined;
    this.docComplementarSend[i].prestaDocumentoTipo = undefined;
    this.docComplementarSend[i].prestaDocumentoArquivo = undefined;
  }


  async exportAs(type, dtIni, dtFim) {
    let nome;
    nome = 'Dem_DocComp_' + this.parceriaInfo.parOrgCnpj;
    if (dtIni === undefined) {
      dtIni = this.pesquisaData.dataInicial;
      dtFim = this.pesquisaData.dataFinal;
    }
    else {
      dtIni = this.dateConverter.fromNgbDateStructToDate(dtIni);
      if (dtFim === undefined) {
        dtFim = new Date();
      }
      else {
        dtFim = this.dateConverter.fromNgbDateStructToDate(dtFim);
      }
    }
    this.parceriaDespesaDevolApi.getPrestaContaParcialPeriodo(this.filtro, this.isPortal).subscribe(async (response) => {
        if (response) {

          const devolucao = response as ParceriaPrestaContaParcial[];

          devolucao.forEach(element => {
            // adaptação técnica para guardar valor string em campo não exibido na tela, a fim de evitar construir um novo objeto
            //element.execValorDoc = Mascaras.aplicaMascaraReais(element.execValorDoc);
            const datePipe = new DatePipe('en-US');
            element.prestaDocArqOriginal = datePipe.transform(element.prestaDocumentoData, 'dd/MM/yyyy');
            element.prestaDocumentoTipo = this.utils.getExecConferirStr(element.execConferir);
            element.prestaOperacaoUsuario = this.utils.getPrestaClassificaDocStr(element.prestaClassificaDoc);
          });

          const devolucaoExc = new Array<ParceriaPrestaContaParcial>();
          devolucao.forEach(element => {
            if (element.prestaJustificaExclusao == null) {
              devolucaoExc.push(element);
            }
          });
          if (type === 'xlsx') {
            const newJson = devolucaoExc.map(rec => {
              return {
                'Documento': rec.prestaDocumentoArquivo,
                'Tipo': rec.prestaOperacaoUsuario,
                'Data': rec.prestaDocArqOriginal,
                'Situação': rec.prestaDocumentoTipo
              };
            });
            this.excelService.exportAsExcelFile(newJson, nome);
          } else if (type === 'csv') {
            const fields = [
              {
                label: 'Documento',
                value: 'prestaDocumentoArquivo'
              }, {
                label: 'Tipo',
                value: 'operacaoUsuario'
              }, {
                label: 'Data',
                value: 'prestaDocArqOriginal'
              }, {
                label: 'Situação',
                value: 'prestaDocumentoTipo'
              }
            ];
            const json2csvParser = new Json2csvParser.Parser({ fields });
            const csv = json2csvParser.parse(devolucaoExc);
            const blob = new Blob([csv], { type: 'text/csv' });
            saveAs(blob, nome + '.csv');
          }
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }


  public async generatePDF(dtIni, dtFim) {

    this.loading.next(true);
    let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
    pdf.setFont('helvetica');
    pdf.setLineWidth(0.5);
    let nome;
    let columns;
    let rows;

    const brasao = new ImagensUrlData().getBrasaoSMF();
    pdf.addImage(brasao, 'JPEG', 5, 4, 33, 13);
    pdf.setFontSize(12);
    pdf.setFontType('bold');
    pdf.text(50, 10, 'Demonstrativo de Documento Complementar');
    pdf.setFontSize(9);
    pdf.setFontType('normal');
    pdf.text(40, 14, this.parceriaInfo.parOrgNome.concat(' - ')
      .concat(this.mascaras.aplicaMascaraDocumento(this.parceriaInfo.parOrgCnpj.toString(), 2)));
    pdf.text(160, 18, 'Data/Hora: ' + this.datePipe.transform(Date.now(), 'dd/MM/yyyy HH:mm:ss'));
    pdf.text(40, 18, 'Termo: ' + this.parceriaInfo.parNum);

    if (dtIni === undefined) {
      dtIni = this.pesquisaData.dataInicial;
      dtFim = this.pesquisaData.dataFinal;
    }
    else {
      dtIni = this.dateConverter.fromNgbDateStructToDate(dtIni);
      if (dtFim === undefined) {
        dtFim = new Date();
      }
      else {
        dtFim = this.dateConverter.fromNgbDateStructToDate(dtFim);
      }
    }
    pdf.text(80, 18, 'Período: ' + this.datePipe.transform(dtIni, 'dd/MM/yyyy') + '  a ' + this.datePipe.transform(dtFim, 'dd/MM/yyyy'));
    let resp = await this.getColunaLinhasDocComplementar(dtIni, dtFim, rows, columns, pdf, nome);
    columns = resp.columns;
    rows = resp.rows;
    this.loading.next(false);
  }

  async getColunaLinhasDocComplementar(dtIni: any, dtFim: any, rows: any, columns: any, pdf: any, nome: any) {
    // tslint:disable-next-line:max-line-length
    this.parceriaDespesaDevolApi.getPrestaContaParcialPeriodo(this.filtro, this.isPortal).subscribe(async (response) => {
        if (response) {
          //console.log(response);
          // tslint:disable-next-line:max-line-length
          const cols = ['Documento', 'Tipo', 'Data', 'Situação'];
          rows = [];
          const presta = response as ParceriaPrestaContaParcial[];

          presta.forEach(element => {
            // adaptação técnica para guardar valor string em campo não exibido na tela, a fim de evitar construir um novo objeto
            //element.execValorDoc = Mascaras.aplicaMascaraReais(element.execValorDoc);

            if (element.prestaJustificaExclusao == null) {
              const datePipe = new DatePipe('en-US');
              element.prestaDocArqOriginal = datePipe.transform(element.prestaDocumentoData, 'dd/MM/yyyy');
              element.prestaDocumentoTipo = this.utils.getExecConferirStr(element.execConferir);
              element.prestaOperacaoUsuario = this.utils.getPrestaClassificaDocStr(element.prestaClassificaDoc);
              let temp = [element.prestaDocumentoArquivo, element.prestaOperacaoUsuario, element.prestaDocArqOriginal, element.prestaDocumentoTipo];
              rows.push(temp);
            }
          });

          columns = cols;
          pdf.autoTable(columns, rows, {
            headerStyles: { fillColor: [8, 146, 151] },
            tableWidth: 'wrap',
            margin: { horizontal: 7, vertical: 20 },
            bodyStyles: { valign: 'top' },
            styles: { cellPadding: 0.5, fontSize: 10, overflow: 'linebreak', columnWidth: 'wrap' },
            columnStyles: { text: { columnWidth: 'auto' } }
          });
          pdf.save(nome); // Generated PDF
        }
        else {
          this.alertService.warning('Nenhum registro encontrado');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        }
        else {
          this.alertService.warning(response.error);
        }
      }, () => this.loading.next(false));
    return { rows, columns };
  }


  aplicaMascaraReais(valor) {
    return this.mascaras.aplicaMascaraReais(valor);
  }

  consultaPagina(event) {
    let quociente = this.prestaContaParcial.length / this.config.itemsPerPage;
    let resto = this.prestaContaParcial.length % this.config.itemsPerPage;

    if (resto > 0) {
      quociente = quociente + 1;
    }
    this.pagina = event.currentTarget.value;
    if (this.pagina <= (quociente))
      this.config.currentPage = this.pagina;

  }

  updateTipoFiltroDocCompl(event) {
    if (event !== undefined) {
      this.filtro.tipo = event.id;
    } else {
      this.filtro.tipo = 0;
    }
  }

  updateSituacaoFiltroDocCompl(event) {
    if (event !== undefined) {
      this.filtro.situacao = event.id;
    } else {
      this.filtro.situacao = 0;
    }
  }

  aplicaFiltroDocCompl() {
    if (this.filtro == undefined) {
      this.inicializaFiltro();
    }

    this.filtro.dataInicial = this.datePipe.transform(this.pesquisaData.dataInicial, 'dd/MM/yyyy');
    this.filtro.dataFinal = this.datePipe.transform(this.pesquisaData.dataFinal, 'dd/MM/yyyy');

    this.getPrestaContaParcial(this.filtro);
  }
}

