import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class ColaboradorCargaHoraria {
    public id: number;
    public colaboradorId: number;
    public parId: number;
    public cargaHorariaSemanal: number;
    public dataInicioParceria: Date;
    public dataInicioParceriaDataFormat: NgbDateStruct;
    public dataFimParceria: Date;
    public dataFimParceriaDataFormat: NgbDateStruct;
    public localAtuacao: String;
}