<div class="container">
    <h1 class="principal">Priorização completa</h1>
    <p>Informe abaixo o órgão ou a parceria (nome ou CNPJ) e escolha entre níveis operacionais ou financeiros para
        visualizar a priorização:</p>

    <div class="form-row filtros-fixo">
        <div class="form-group col-lg-3 col-md-6">
            <label for="orgao">Órgão</label>
            <select [disabled]="(isFiscal) && !isSMTC" [ngModel]="busca.orgao" (change)="selectOrgaoParceria($event)" class="form-control form-select custom-select">
                <option [value]="option.id" *ngFor="let option of this.utils.secretariasOptions">{{option.name}}
                </option>
            </select>
        </div>
        <div class="form-group col-lg-3 col-md-6">
            <label for="cnpjParceria">CNPJ ou nome da parceria</label>
            <input type="text" class="form-control" id="cnpjParceria"  [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.cnpjNome" [value]="busca.cnpjNome" (blur)="selectCNPJNome()" (keydown.enter)="selectCNPJ()" placeholder="informe o CNPJ ou nome">
        </div>
        <div class="form-check col-lg-2 col-md-4">
            <label for="niveisFinanceiros" class="input-sem-titulo">
                <input type="radio" name="niveisFinanceiros" value="F" [checked]="busca.nivelTipo === 'F' ? true:false" (click)="selectNivelTipo('F')"/>
                Níveis financeiros</label>
        </div>
        <div class="form-check col-lg-2 col-md-4">
            <label for="niveisOperacionais" class="input-sem-titulo">
                <input type="radio" name="niveisOperacionais" value="O" [checked]="busca.nivelTipo === 'O' ? true:false" (click)="selectNivelTipo('O')"/>
                Níveis operacionais</label>
        </div>
        <div class="col-lg-2 col-md-4">
            <div class="cjto-botoes input-sem-titulo">
                <button class="btn btn-primary">Filtrar</button>
                <button class="btn btn-secondary">Limpar</button>
            </div>
        </div>
    </div>
    <div class="priorizacao">
        <div class="checkbox-inspecionadas">
            <div class="form-check">
                <input type="checkbox" id="inspecionadas" name="busca.inspecionadas" [ngModelOptions]="{standalone: true}" [(ngModel)]="busca.inspecionadas" [value]="busca.inspecionadas" (change)="selectInspecionadas()" (keydown.enter)="selectInspecionadas()"/>
                <label for="inspecionadas">mostrar apenas inspecionadas</label>
            </div>
            <div class="form-check">
                <input type="checkbox" id="naoInspecionadas" name="busca.naoInspecionadas" [ngModelOptions]="{standalone: true}" [(ngModel)]="busca.naoInspecionadas" [value]="busca.naoInspecionadas" (change)="selectNaoInspecionadas()" (keydown.enter)="selectNaoInspecionadas()"/>
                <label for="naoInspecionadas">mostrar apenas não inspecionadas</label>
            </div>
        </div>

        <!-- tabela priorização financeira -->
        <table class="table table-hover">
            <thead>
                <tr class="headerPrimeiraLinha">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th colspan="3" class="divisor">Materialidade do valor total</th>
                    <th *ngIf="busca.nivelTipo === 'F'" colspan="3" class="divisor">Valor total (R$)</th>
                    <th *ngIf="busca.nivelTipo === 'O'" colspan="3" class="divisor">% em relação ao total</th>
                </tr>
                <tr class="headerSegundaLinha">
                    <th>#</th>
                    <th>Parceria</th>
                    <th>Nº do termo</th>
                    <th *ngIf="busca.nivelTipo === 'F'">Previsto</th>
                    <th *ngIf="busca.nivelTipo === 'F'">Concedido liberado</th>
                    <th *ngIf="busca.nivelTipo === 'F'">Gasto</th>
                    <th *ngIf="busca.nivelTipo === 'F'" class="text-right">Previsto</th>
                    <th *ngIf="busca.nivelTipo === 'F'" class="text-right">Concedido liberado</th>
                    <th *ngIf="busca.nivelTipo === 'F'" class="text-right">Gasto</th>
                    <th *ngIf="busca.nivelTipo === 'O'">Total</th>
                    <th *ngIf="busca.nivelTipo === 'O'">A conferir</th>
                    <th *ngIf="busca.nivelTipo === 'O'">A corrigir</th>
                    <th *ngIf="busca.nivelTipo === 'O'" class="text-right">Total de docs</th>
                    <th *ngIf="busca.nivelTipo === 'O'" class="text-right">A conferir</th>
                    <th *ngIf="busca.nivelTipo === 'O'" class="text-right">A corrigir</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of priorizacaoCompletaArray | paginate:config; let j = index; " [ngClass]="{'linha-inspecionada': data.foiVisitada == true}">
                    <td><i *ngIf="data.foiVisitada" class="fa fa-check-circle"
                        ngbTooltip="Esta parceria já foi visitada em {{data.anoVisitacao}}, por isso não está contemplada na priorização."></i>
                        <div *ngIf="!data.foiVisitada">{{(j+1)+((config.currentPage-1)*config.itemsPerPage)}}</div>
                    </td>
                    <td class="text-capitalize">
                        <div class="ellipsis" ngbTooltip="{{data.nomeEntidade}}">{{data.nomeEntidade}}</div>
                    </td>
                    <td>{{data.numTermo}}</td>
                    <td *ngIf="busca.nivelTipo === 'F'">{{data.classePontosPrevisto}}</td>
                    <td *ngIf="busca.nivelTipo === 'F'"> {{data.classePontosConcedidoLiberado}}</td>
                    <td *ngIf="busca.nivelTipo === 'F'">{{data.classePontosGasto}}</td>
                    <td *ngIf="busca.nivelTipo === 'F'" class="text-right">{{data.valorPrevisto  | currency: 'BRL'}}</td>
                    <td *ngIf="busca.nivelTipo === 'F'" class="text-right">{{data.valorConcedidoLiberado  | currency: 'BRL'}}</td>
                    <td *ngIf="busca.nivelTipo === 'F'" class="text-right">{{data.valorGasto  | currency: 'BRL'}}</td>
                    <td *ngIf="busca.nivelTipo === 'O'">{{data.classePontosTotal}}</td>
                    <td *ngIf="busca.nivelTipo === 'O'"> {{data.classePontosAconferir}}</td>
                    <td *ngIf="busca.nivelTipo === 'O'">{{data.classePontosAcorrigir}}</td>
                    <td *ngIf="busca.nivelTipo === 'O'" class="text-right">{{data.percentualTotal}}</td>
                    <td *ngIf="busca.nivelTipo === 'O'" class="text-right">{{data.percentualAconferir}}%</td>
                    <td *ngIf="busca.nivelTipo === 'O'" class="text-right">{{data.percentualAcorrigir}}%</td>

                </tr>
                
            </tbody>
            <tfoot data-html2canvas-ignore="true" *ngIf="config.totalItems > config.itemsPerPage">
                <tr>
                  <td colspan="15">
                    <pagination-controls class="pagination pagination justify-content-end" id="custom" (pageChange)="pageChanged($event)" directionLinks="true" previousLabel="" nextLabel="">
                    </pagination-controls>
                  </td>
                </tr>
              </tfoot>
        </table>
        
        <br>
        <div class="d-flex justify-content-between">
            <button class="btn btn-primary" (click)="getFile()"><i class="fa fa-download"></i> Baixar xls</button>
        </div>

    </div>
    <div [hidden]="!(loading$ | async) ">
        <div class="loading-info ">
            <i class="fa fa-spinner fa-4x fa-spin "></i>
            <div id="carregando ">Aguarde</div>
        </div>
    </div>
</div>