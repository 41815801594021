export class Visitacao {
    public id: number;
    public parId: number;
    public anoVisitacao: number;
    public anoExpiracao: number;
    public dataOperacao: Date;
    public usuarioOperacao: String;
    public parNum: String;
    public parOrgNome: String;
    public idOrgao: number;
    public nomeOrgao: String;
}