<div w3-include-html="../../@shared/components/headerPortal/header.component.html">
    <app-filtro-busca></app-filtro-busca>
    <br>
    <div class="container-fluid">
        <table class="table table-hover table-responsavel" [hidden]="(!parceriaArray) " [mfData]="parceriaArray ">
            <thead>
                <tr>
                    <th></th>
                    <th>Nº Termo</th>
                    <th>Status Atual</th>
                    <th>Órgão</th>
                    <th>Nome</th>
                    <th>Objeto/Finalidade</th>
                    <th>Vlr Total Concedido</th>
                    <th>Execução Saldo Final</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let parceria of parceriaArray" routerLinkActive="active" [routerLink]="[ '/execucao/detalheParceriaM2', parceria.parId]">

                    <td><a ngbTooltip="Acessar parceria" placement="right"><i class="fa fa-search "
                            aria-hidden="true "></i></a></td>
                    <td>{{parceria.parNum}}</td>
                    <td>{{parceria.status}}</td>
                    <td>{{parceria.nomeOrgao}}</td>
                    <td>{{parceria.parOrgNome}}</td>
                    <td>{{parceria.parOrgObjeto}}</td>
                    <td>{{parceria.parOrgVlTotal | currency: 'BRL'}}</td>
                    <td>{{parceria.execSaldoFinal | currency: 'BRL'}}</td>
                </tr>
            </tbody>
        </table>
        <tfoot data-html2canvas-ignore="true ">
            <div *ngIf="countParcerias>25" class="resultPorPagina">
                <ngb-pagination class="d-flex justify-content-center " [collectionSize]="collectionSizeParceria" [maxSize]="10" [(page)]="currentPage" [rotate]="true " [ellipses]="true" [boundaryLinks]="true " (pageChange)="onPageChange($event)"></ngb-pagination>
            </div>

        </tfoot>

        <div [hidden]="!(loading$ | async) ">
            <div class="loading-overlay "></div>
            <div class="loading-info ">
                <i class="fa fa-spinner fa-4x fa-spin "></i>
                <div id="carregando ">Aguarde</div>
            </div>
        </div>
    </div>