import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share, filter } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from '../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../../apis/detalheParceriaApi';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { UserService } from '../../@core/user.service';
import { PlanejamentoPlanoTrabalho } from './planejamentoPlanoTrabalho';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from 'src/app/@core/date-converter';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { Utils } from 'src/app/@shared/utils';


@Component({
  selector: 'app-planos-trabalho-parceria',
  templateUrl: './../planoDeTrabalho/planoDeTrabalho.component.html',
  styleUrls: ['./../../modulo2/detalheParceria/detalheParceria.component.scss']
})

export class PlanoDeTrabalhoComponent implements OnInit {

  @Input()
  public  parceriaInfo: ParceriaInfo;
  @Input()
  private  isPortal: boolean;
  @Output() valueChange = new EventEmitter();
  planoPlanTrabalho: Array<PlanejamentoPlanoTrabalho> = [];
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  tipoPublicaPortal: Number;
  modalEdicaoReference: any;
  showUpload: boolean = false;
  planoTrabalhoSend: PlanejamentoPlanoTrabalho;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  @Input()
  isAuditorExterno: boolean;

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    private parceriaDespesaDevolApi: PublicoPrivadaApi,
    public userService: UserService,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private utils: Utils) {

    }

  ngOnInit() {
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();
    this.setupInterface();
  }

  setupInterface() {

    this.loading.next(true);

    this.route.paramMap.pipe(
        switchMap(() =>
         this.detalheParceriaPortalApi.getPlanejamentoPlanoTrabalho(this.parceriaInfo.parId))
         ).subscribe((response) => {
          if (response) {
            this.planoPlanTrabalho = response as Array<PlanejamentoPlanoTrabalho>;
            // console.log(this.valoresLiberados);
          } else {
            this.alertService.warning('Nenhum registro encontrado');
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));
        
        this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
          this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
        });
  }

  disableCampos() {
    return ( this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }
  visualizarBotao() {
    return ((this.isSMTC || this.isConsulta || this.isAuditorExterno));
  }
  disableApagarIcone() {
    return (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }
  enableEditarIcone() {
    if (this.isConsulta || this.isSMTC || this.isAuditorExterno) {
      return false;
    }
    return true;
  }
  getArquivo(parId, id, tipo, nome) {
    this.loading.next(true);
    this.parceriaDespesaDevolApi.getArquivo(parId, id, tipo, this.isPortal)
      .subscribe((response) => {
        if (response) {
          console.log(response);
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(response.body);
          a.href = url;
          a.download = nome;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          this.alertService.warning('Nenhum arquivo encontrado');
        }
      }, (response) => {
        // if (response.status >= 500) {
        //   this.alertService.danger(response.error);
        // } else {
        //   this.alertService.warning(response.error);
        // }
        this.alertService.danger('Erro ao buscar arquivo.');
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  updatePublica(event) {
    const newVal = event.currentTarget.value;
    this.planoTrabalhoSend.planoTrabalhoPublicar = newVal;
  }

  setPlanoTrabalho(doc) {
    if (doc) {
      this.planoTrabalhoSend = doc;
      this.showUpload = this.planoTrabalhoSend.planoTrabalhoArquivo ? false : true;
      if (this.planoTrabalhoSend.planoTrabalhoDocumentoDataFormat !== null)
      this.planoTrabalhoSend.planoTrabalhoDocumentoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.planoTrabalhoSend.planoTrabalhoData));
        if (this.planoTrabalhoSend.planoTrabalhoDataIni !== null)
      this.planoTrabalhoSend.planoTrabalhoDocumentoDataIniFormat = this.dateConverter.fromTimestampToNgbDateStruct(
       this.dateConverter.fromDateToTimestamp(this.planoTrabalhoSend.planoTrabalhoDataIni));
       if (this.planoTrabalhoSend.planoTrabalhoDataFim !== null)
      this.planoTrabalhoSend.planoTrabalhoDocumentoDataFimFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.planoTrabalhoSend.planoTrabalhoDataFim));
    } else {
      this.planoTrabalhoSend = new PlanejamentoPlanoTrabalho();
      this.showUpload = true;
      this.planoTrabalhoSend.parId = this.parceriaInfo.parId;
      this.planoTrabalhoSend.planoId = -1;
      this.planoTrabalhoSend.planoTrabalhoTipo = '';
    }

  }

  salvaPlanoTrabalho() {

    if (this.planoTrabalhoSend.planoTrabalhoArquivo === null || this.planoTrabalhoSend.planoTrabalhoArquivo === undefined) {
      this.alertService.danger('Dado obrigatório, realize o upload do arquivo.');
      return;
    }

    if ( (this.planoTrabalhoSend.planoTrabalhoArquivo != null && this.planoTrabalhoSend.planoTrabalhoArquivo.length > 50)) {
      this.alertService.danger('Nome do arquivo superior a 50 caracteres, por favor renomeie antes de fazer upload.');
      return;
    }
    if (this.planoTrabalhoSend.planoTrabalhoDocumentoDataFormat === undefined || 
      this.planoTrabalhoSend.planoTrabalhoDocumentoDataIniFormat  === undefined || this.planoTrabalhoSend.planoTrabalhoDocumentoDataFimFormat  === undefined){
        this.alertService.danger('Confira e preenchas o(s) campo(s) de data(s).');
      return;
      }

    // tslint:disable-next-line:max-line-length

    this.planoTrabalhoSend.planoTrabalhoData = this.dateConverter.fromNgbDateStructToDate(this.planoTrabalhoSend.planoTrabalhoDocumentoDataFormat);
    if (!this.utils.isValidDate(this.planoTrabalhoSend.planoTrabalhoData)){
      this.alertService.danger('Data Aprovação Inválida.');
      return;
    }
    this.planoTrabalhoSend.planoTrabalhoDataIni = this.dateConverter.fromNgbDateStructToDate(this.planoTrabalhoSend.planoTrabalhoDocumentoDataIniFormat);
    if (!this.utils.isValidDate(this.planoTrabalhoSend.planoTrabalhoDataIni)){
      this.alertService.danger('Data Início Vigência Inválida.');
      return;
    }
    this.planoTrabalhoSend.planoTrabalhoDataFim = this.dateConverter.fromNgbDateStructToDate(this.planoTrabalhoSend.planoTrabalhoDocumentoDataFimFormat);
    if (!this.utils.isValidDate(this.planoTrabalhoSend.planoTrabalhoDataFim)){
      this.alertService.danger('Data Fim Vigência Inválida.');
      return;
    }

    if (this.planoTrabalhoSend.planoTrabalhoDataIni > this.planoTrabalhoSend.planoTrabalhoDataFim) {
      return this.alertService.danger('Data final não pode ser inferior a data inicial.');
    }
    let data = JSON.stringify(this.planoTrabalhoSend, this.replacerDocComplementar);
    this.detalheParceriaApi.updatePlanoTrabalho(data).subscribe(async (response) => {
      if (response) {
        this.setupInterface();
        this.setPlanoTrabalho(undefined);
        this.modalEdicaoReference.close();
        //await this.getParceriaInfo(this.planoTrabalhoSend.parId);
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }

  excluirPlanoTrabalho() {
    this.detalheParceriaApi.excluirPlanoTrabalho(this.planoTrabalhoSend).subscribe((response) => {
      if (response) {
        this.setupInterface();
        this.setPlanoTrabalho(undefined);
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }

  replacerDocComplementar(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['planoTrabalhoDocumentoDataFormat','planoTrabalhoDocumentoDataIniFormat','planoTrabalhoDocumentoDataFimFormat'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  openEdicao(content, doc) {
    this.setPlanoTrabalho(doc);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false, windowClass: "modalProcesso" });
  }

  openExcluir(content, doc) {
    this.setPlanoTrabalho(doc);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false });
  }

  onFileDocChanged(event) {
    const fileList: FileList = event.currentTarget.files;
    console.log(fileList);
    const reader = new FileReader();


    if (fileList.length > 0) {
      const array = fileList[0].type.split('/');
      const fileSize = fileList[0].size / 1024 / 1024; // in MB
      if (fileSize > 2) {
        this.alertService.danger('Tamanho de arquivo superior ao limite de 2MB. Esse arquivo possui ' + fileSize.toPrecision(1)+"MB");
        return;
      }
      if (array[1] === 'jpeg' || array[1] === 'jpg' || array[1] === 'gif'
        || array[1] === 'png' || array[1] === 'pdf') {

        reader.readAsDataURL(fileList[0]);
        reader.onload = () => {
          this.planoTrabalhoSend.planoTrabalhoDocumento = ((reader.result).toString().split(',')[1]) as unknown as Blob;
          this.planoTrabalhoSend.planoTrabalhoTipo = array[1];
          this.planoTrabalhoSend.planoTrabalhoArquivo = fileList[0].name;
        };
      } else {
        event.currentTarget.value = '';
        this.alertService.warning('Formato de arquivo não suportado!');
      }
    }
  }
}
