<div class="container-fluid mb-3" style="min-height: 350px;">
    <div class="infoGeral container-fluid">
        <br>
        <h3>Filtros de pesquisa</h3>
        <p><em>Você pode filtrar os resultados preenchendo um ou combinando mais de um dos campos abaixo:</em></p>
        <br>
        <div class="form-row">
            <div class="col">
                <div class="form-group">
                    <label for="">Órgão ou entidade (PMPA)</label>
                    <div class="select-wrapper">
                        <select [disabled]="isFiscal && !isSMTC" [ngModel]="busca.orgao" (change)="selectOrgaoParceria($event)" class="form-control form-select custom-select">
                            <option [value]="option.id" *ngFor="let option of this.utils.secretariasOptions">{{option.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="form-group">
                    <label for="nroTermo">Número do termo</label>
                    <input style="width: 100% " id="nroTermo" name="busca.numeroTermo" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.numeroTermo " [value]="busca.numeroTermo" (blur)="selectNumeroTermo()" (keydown.enter)="selectNumeroTermo()" class="form-control"
                        placeholder="informe o nº do termo">
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <label for="objeto">Objeto/finalidade</label>
                    <input style="width: 100% " id="objeto" name="busca.objetoFin " class="form-control" type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.objetoFin " [value]="busca.objetoFin " (blur)="selectObjeto()" (keydown.enter)="selectObjeto()" placeholder="digite alguma palavra-chave">
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="nome">Nome da organização (parceria)</label>
                    <input style="width: 100% " id="nome" name="busca.nomeOrg " class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.nomeOrg " [value]="busca.nomeOrg " (blur)="selectNomeParceria()" (keydown.enter)="selectNomeParceria()" placeholder="digite alguma palavra-chave">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="">Status da parceria</label>
                    <div class="select-wrapper">
                        <select [ngModel]="busca.status" (change)="selectStatusParceria($event)" class="form-control form-select custom-select">
                            <option [value]="option.id" *ngFor="let option of this.utils.statusParceiraOptions">{{option.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Data</label>
                    <!--<input placeholder="clique no calendário" maxlength="10" size="15" onkeydown="return false" name="data" #data="ngModel" [(ngModel)]="dataVigenciaInicial" ngbDatepicker #d="ngbDatepicker" (dateSelect)="onDateSelect($event)" class="form-control input_date">
                    <i class="fa fa-calendar icon_input_date" (click)="d.toggle()" title="calendário "></i>
                    -->
                    <selector-datetime-range class="data-range" [isOnlyDataInicio]="true" [showLabelData]="true" (novoPeriodo)="novoPeriodo($event)"></selector-datetime-range>
                </div>
            </div>
            <div class="col-md-1">
                <button class="btn btn-primary btn-filtro">Filtrar</button>
            </div>
            <div class="col-md-1" *ngIf="isFiscal || isSMTC">
                <button class="btn btn-primary btn-filtro" (click)="gerarExcel()">Gerar Excel</button>
            </div>
        </div>
    </div>
    <div [hidden]="!(loading$ | async) ">
        <div class="loading-overlay "></div>
        <div class="loading-info ">
            <i class="fa fa-spinner fa-4x fa-spin "></i>
            <div id="carregando ">Aguarde</div>
        </div>
    </div>
</div>