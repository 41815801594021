export class NFSe {
    public codAnoNume: string;
    public codCnpj: number;
    public codInscMuni: string;
    public codListServ: string;
    public codVeri: string;
    public datComp: string;
    public datEmisNfse: string;
    public desCnttEltnDcrd: string;
    public desCnttTeleDcrd: string;
    public desDiscServ: string;
    public idnAtvd: number;
    public idnCidaGera: number;
    public idnCidaPres: number;
    public idnEmprPres: number;
    public idnEndePres: number;
    public idnNatuOper: number;
    public indSgnd: boolean;
    public nomEmpr: string;
    public opcInceCult: number;
    public opcIssReti: string;
    public opcSimpNaci: string;
    public opcStatNfse: string;
    public valAliq: number;
    public valBaseCalc: number;
    public valDescCond: number;
    public valIss: number;
    public valLiqd: number;
    public valServ: number;
    public valCofins: number;
    public valCred: number;
    public valCsll: number;
    public valDedu: number;
    public valDescInco: number;
    public valInss: number;
    public valIr: number;
    public valIssReti: number;
    public valOutrRete: number;
    public valPis: number;

    public ncIdnMotiCanc: number;
    public ncDatCanc: string;
    public ptCodDocuToma: string;
    public ptCodInscMuni: string;
    public ptDesCompEnde: string;
    public ptIdnCida: number;
    public ptNomBair: string;
    public ptNomLogr: string;
    public ptNomToma: string;
    public ptNumCep: string;
    public ptNumImovLogr: string;
    public ptSigUf: string;
    public ptTipDocuToma: string;
    public rpIdnRps: number;
    public rpNumRps: string;
    public rpNumSeriRps: string;
    public rpTipRps: number;
    public rpDatEmisRps: string;
    public prIdnPrtc: number;
    public prNumPrtc: string;
    public codListServExt: string;
    public idnNatuOperExt: string;
    public idnSituEspeExt: string;

    public datAtuz: string;
    public tipAtuz: number;
    public chaveAcesso: string;
    public notaNacional: boolean;
}
