<div w3-include-html="../../@shared/components/headerPortal/header.component.html">
    <div class="container-fluid mb-3" style="min-height: 450px;">
        <div class="infoGeral container-fluid">
            <h2>Log de Notificação</h2>
            <p><em>Selecione a notificação da qual deseja visualizar o log:</em></p>
            <div class="form-row">
                <div class="select-wrapper">
                    <select [ngModel]="busca.notifId" (change)="selectNotificacao($event)" class="form-control form-select custom-select">
                        <option [value]="option.id" *ngFor="let option of this.listaNotificacoes">{{option.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4 btn-inline">
                    <div class="form-group">
                        <div class="select-wrapper" style="padding-top: 38px;">
                            <button class="btn btn-primary" (click)="geraArquivo('PDF')">Gerar PDF</button>
                            <button class="btn btn-primary" (click)="geraArquivo('XLS')">Gerar Excel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>