import { Component } from '@angular/core'
import { AlertService, MessagePayload } from '../../@core/alert.service'
import { Observable } from 'rxjs'
import { trigger, state, style, animate, transition } from '@angular/animations'

const alertTransition = trigger('flyInOut', [
	state('in', style({
		opacity: 1,
		transform: 'translateY(0%)'
	})),
	transition('void => *', [
		style({
			opacity: 0,
			transform: 'translateY(-100%)'
		}),
		animate('0.2s ease-in')
	]),
	transition('* => void', [
		animate('0.2s 0.1s ease-out', style({
			opacity: 0,
			transform: 'translateY(-100%)'
		}))
	])
])

@Component({
	selector: 'app-alert',
	template: `
	<div *ngIf="alert$ | async; let alert" style="position: fixed; z-index:9999; left: 50%; transform: translate(-50%, 0);">
		<div class="alert alert-{{alert.type}} alert-dismissible" style="box-shadow: 4px 8px 8px rgba(0,0,0,0.3)" *ngIf="alert.message" [@flyInOut]="'in'">
		<div [innerHTML]="alert.message"></div>
			<button aria-label="Close" class="close" type="button" (click)="onClose()">
				<span aria-hidden="true">×</span>
			</button>
		</div>
	</div>
	`,
	animations: [alertTransition]
})
export class AlertComponent {
	alert$: Observable<MessagePayload>

	constructor(private alertService: AlertService) {
		this.alert$ = alertService.alert$
	}

	onClose() {
		this.alertService.alert({ success: '' })
	}
}
