import { Injectable, OnInit } from '@angular/core';
import { KeycloakService, AuthUser } from "@procempa/ngx-keycloak";
import { AlertService } from './alert.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
const listOrgaos = new Array();

@Injectable({providedIn: 'root'})
export class UserService  implements OnInit{
	
	autorizacoes = { };
	user: Subject<AuthUser> = new Subject();
	
	constructor(private keycloakService: KeycloakService, private alertService: AlertService) {
		this.loginKeycloak();
	}

	ngOnInit() {
		this.loginKeycloak();
	}

	loginKeycloak(){
		this.keycloakService.getUser().subscribe(user => { 
			this.user.next(user)
			if(user.attributes.sgp_orgao){
				user.attributes.sgp_orgao.forEach(org => listOrgaos.push(org))
			}
		});
	}

	logout() {

		const url = window.location.origin.split("//");
		let logoutUrl='';
		if (url[1] === 'localhost:4200') {
		logoutUrl = environment.keycloak.urlLogin;
		} else {
			const urlIs = url[1].split(".");
			console.log('UrlIs..............', urlIs[0]);
			if (urlIs[0] === 'sgp' || urlIs[0] === 'sgp-hom') {
				logoutUrl = environment.acessoPoa.urlLogin;
			}
			if (urlIs[0] === 'sgp-admin' || urlIs[0] === 'sgp-admin-hom') {
				logoutUrl = environment.keycloak.urlLogin;
			}
			else{
				logoutUrl = environment.acessoPoa.urlLogin;
			}
		}
		this.keycloakService.logout(logoutUrl);
	}

	get getUser() {
		return	this.keycloakService.getUser().subscribe(user => this.user.next(user));
	   }
	   
	 authenticated() {
		return this.keycloakService.authenticated();
   	}   
	
	get userInfo() {
		this.getUser;
		return this.user;
	}

	hasRole(role) {
	return this.keycloakService.hasRole(role);
	}

	isAdministrator() {
		return this.keycloakService.hasRole('Administrador');
	}
	isFiscal() {
		return this.keycloakService.hasRole('Fiscal');
		
	}
	hasTrava() {
		return this.keycloakService.hasRole('Trava');
		
	}
	async recuperaKeyloak() {
		this.loginKeycloak();
	}
	isSMTC() {
		return (this.user !== undefined && this.keycloakService.hasRole('Fiscal')) &&
			(listOrgaos !== undefined && listOrgaos[0] === '81');
	}

	getOrgao(){
		this.recuperaKeyloak();
		if (this.user !== undefined && listOrgaos !== undefined){
			return listOrgaos[0];
		}
		else{
			return null;
		}
	}
	isConsulta(): boolean {
		return this.keycloakService.hasRole('Consulta');
	}
/*
	isPMPA() {
		return this.keycloakService.getToken
	}*/
}
