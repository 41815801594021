import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";
import { share, switchMap } from "rxjs/operators";
import { AlertService } from "src/app/@core/alert.service";
import { DateConverter } from "src/app/@core/date-converter";
import { UserService } from "src/app/@core/user.service";
import { Utils } from "src/app/@shared/utils";
import { DetalheParceriaApi } from "src/app/apis/detalheParceriaApi";
import { ParametrosAuditoria } from "./parametrosAuditoria";


@Component({
    selector: 'app-auditoria-parametros',
    templateUrl: './parametrosAuditoria.component.html',
    styleUrls: ['./parametrosAuditoria.component.scss']
  })
  export class ParametrosAuditoriaComponent implements OnInit {

    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable().pipe(share());
    mascaraPercent = { mask: [/\d/, /\d/, /\d/ ], guide: false };
    mascaraNumDOC = { mask: [/\d/, /\d/, /\d/, /\d/ ], guide: false };

    isFiscal: boolean;
    isSMTC: boolean;
    isConsulta: boolean;
    parametrosAuditoria = new ParametrosAuditoria();
    parametrosAuditoriaClone;

    constructor(private route: ActivatedRoute,
        private alertService: AlertService,
        private detalheParceriaApi: DetalheParceriaApi,
        public userService: UserService,
        private dateConverter: DateConverter,
        private utils: Utils) {
    
        }
    
      async ngOnInit() {
        this.isFiscal = this.userService.isFiscal();
        this.isSMTC = this.userService.isSMTC();
        this.isConsulta = this.userService.isConsulta();
        await this.setupInterface();
      }


      async setupInterface() {
 
        this.loading.next(true);
    
        this.route.paramMap.pipe(
            switchMap(() =>
             this.detalheParceriaApi.getParametrosAuditoria())
             ).subscribe((response) => {
              if (response) {
                this.parametrosAuditoria = response as ParametrosAuditoria;
                // console.log(this.valoresLiberados);
               // this.parametrosAuditoria.pesoPercentualDocsCorrigirAlto = undefined;
                this.parametrosAuditoria.dataInicialVigenciaFormat = this.dateConverter.fromTimestampToNgbDateStruct(
                  this.dateConverter.fromDateToTimestamp(this.parametrosAuditoria.dataInicialVigencia));
                 
                this.parametrosAuditoria.dataFinalVigenciaFormat = this.dateConverter.fromTimestampToNgbDateStruct(
                  this.dateConverter.fromDateToTimestamp(this.parametrosAuditoria.dataFinalVigencia)); 
              } else {
                this.alertService.warning('Nenhuma parametrização encontrada');
                // this.parametrosAuditoria.dataInicialVigenciaFormat = undefined;
                // this.parametrosAuditoria.dataFinalVigenciaFormat = undefined;
              }
              this.loading.next(false)
            }, (response) => {
              if (response.status >= 500) {
                this.alertService.danger(response.error);
              } else {
                this.alertService.warning(response.error);
              }
              this.loading.next(false);
            }, () => this.loading.next(false));
    
      }



  replacer(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['dataInicialVigenciaFormat', 'dataFinalVigenciaFormat'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  salva(){
    if ((this.parametrosAuditoria.valorTotalPrevistoBaixo == null || this.parametrosAuditoria.valorTotalPrevistoMedio == null) ||
        (this.parametrosAuditoria.valorTotalPrevistoAlto == null)) {
        this.alertService.danger('Valores Total Previsto devem ser preenchidos.');
        return;
    }
    if ((this.parametrosAuditoria.valorTotalPrevistoBaixo >= this.parametrosAuditoria.valorTotalPrevistoMedio) ||
        (this.parametrosAuditoria.valorTotalPrevistoBaixo >= this.parametrosAuditoria.valorTotalPrevistoAlto)) {
        this.alertService.danger('Valor Total Previsto Baixo deve ser inferior ao Médio e ao Alto.');
        return;
    }
    if ((this.parametrosAuditoria.valorTotalPrevistoMedio <= this.parametrosAuditoria.valorTotalPrevistoBaixo) ||
        (this.parametrosAuditoria.valorTotalPrevistoMedio > this.parametrosAuditoria.valorTotalPrevistoAlto)) {
        this.alertService.danger('Valor Total Previsto Médio devem ser inferior ou igual ao Alto e superior ao Baixo');
        return;
    }
    if ((this.parametrosAuditoria.valorTotalPrevistoAlto < this.parametrosAuditoria.valorTotalPrevistoMedio) ||
        (this.parametrosAuditoria.valorTotalPrevistoBaixo >= this.parametrosAuditoria.valorTotalPrevistoAlto)) {
        this.alertService.danger('Valor Total Previsto Alto deve ser superior ou igual ao Medio e superior ao Baixo');
        return;
    }

    if ((this.parametrosAuditoria.pesoValorTotalPrevistoBaixo == null || this.parametrosAuditoria.pesoValorTotalPrevistoMedio == null) ||
        (this.parametrosAuditoria.pesoValorTotalPrevistoAlto == null)) {
        this.alertService.danger('Pontos da Materialidade do Valor Total Previsto devem ser preenchidos.');
        return;
    }

    if ((Number(this.parametrosAuditoria.pesoValorTotalPrevistoBaixo) >= Number(this.parametrosAuditoria.pesoValorTotalPrevistoMedio)) ||
        (Number(this.parametrosAuditoria.pesoValorTotalPrevistoBaixo) >= Number(this.parametrosAuditoria.pesoValorTotalPrevistoAlto))) {
        this.alertService.danger('Pontos da Materialidade do Valor Total Previsto Baixo devem ser inferiores ao Médio e ao Alto.');
        return;
    }
    if ((Number(this.parametrosAuditoria.pesoValorTotalPrevistoMedio) <= Number(this.parametrosAuditoria.pesoValorTotalPrevistoBaixo)) ||
        (Number(this.parametrosAuditoria.pesoValorTotalPrevistoMedio) >= Number(this.parametrosAuditoria.pesoValorTotalPrevistoAlto))) {
        this.alertService.danger('Pontos da Materialidade do Valor Total Previsto Médio devem ser inferior ao Alto e superior ao Baixo');
        return;
    }
    if ((Number(this.parametrosAuditoria.pesoValorTotalPrevistoAlto) <= Number(this.parametrosAuditoria.pesoValorTotalPrevistoMedio)) ||
        (Number(this.parametrosAuditoria.pesoValorTotalPrevistoBaixo) >= Number(this.parametrosAuditoria.pesoValorTotalPrevistoAlto))) {
        this.alertService.danger('Pontos da Materialidade do Valor Total Previsto Alto devem ser superiores ao Medio e ao Baixo');
        return;
    }

    if ((this.parametrosAuditoria.valorConcedidoLiberadoBaixo == null || this.parametrosAuditoria.valorConcedidoLiberadoMedio == null) ||
        (this.parametrosAuditoria.valorConcedidoLiberadoAlto == null)) {
        this.alertService.danger('Valores Concedido Liberado devem ser preenchidos.');
        return;
    }

    if ((this.parametrosAuditoria.valorConcedidoLiberadoBaixo >= this.parametrosAuditoria.valorConcedidoLiberadoMedio) ||
        (this.parametrosAuditoria.valorConcedidoLiberadoBaixo >= this.parametrosAuditoria.valorConcedidoLiberadoAlto)) {
        this.alertService.danger('Valor Concedido Liberado Baixo deve ser inferior ao Médio e ao Alto.');
        return;
    }
    if ((this.parametrosAuditoria.valorConcedidoLiberadoMedio <= this.parametrosAuditoria.valorConcedidoLiberadoBaixo) ||
        (this.parametrosAuditoria.valorConcedidoLiberadoMedio > this.parametrosAuditoria.valorConcedidoLiberadoAlto)) {
        this.alertService.danger('Valor Concedido Liberado Médio devem ser inferior ou igual ao Alto e superior ao Baixo');
        return;
    }
    if ((this.parametrosAuditoria.valorConcedidoLiberadoAlto < this.parametrosAuditoria.valorConcedidoLiberadoMedio) ||
        (this.parametrosAuditoria.valorConcedidoLiberadoBaixo >= this.parametrosAuditoria.valorConcedidoLiberadoAlto)) {
        this.alertService.danger('Valor Concedido Liberado Alto deve ser superior ou igual ao Medio e superior ao Baixo');
        return;
    }

    if ((Number(this.parametrosAuditoria.pesoConcedidoLiberadoBaixo == null) || Number(this.parametrosAuditoria.pesoConcedidoLiberadoMedio == null)) ||
        (Number(this.parametrosAuditoria.pesoConcedidoLiberadoAlto == null))) {
        this.alertService.danger('Pontos da Materialidade dos Valor Concedido Liberado devem ser preenchidos.');
        return;
    }

    if ((Number(this.parametrosAuditoria.pesoConcedidoLiberadoBaixo) >= Number(this.parametrosAuditoria.pesoConcedidoLiberadoMedio)) ||
      (Number(this.parametrosAuditoria.pesoConcedidoLiberadoBaixo) >= Number(this.parametrosAuditoria.pesoConcedidoLiberadoAlto))) {
      this.alertService.danger('Pontos da Materialidade do Valor Concedido Liberado devem ser inferiores ap Médio e ao Alto.');
      return;
    }
    if ((Number(this.parametrosAuditoria.pesoConcedidoLiberadoMedio) <= Number(this.parametrosAuditoria.pesoConcedidoLiberadoBaixo)) ||
      (Number(this.parametrosAuditoria.pesoConcedidoLiberadoMedio) >= Number(this.parametrosAuditoria.pesoConcedidoLiberadoAlto))) {
      this.alertService.danger('Pontos da Materialidade do Valor Concedido Liberado devem ser inferior ao Alto e superior ao Baixo');
      return;
    }
    if ((Number(this.parametrosAuditoria.pesoConcedidoLiberadoAlto) <= Number(this.parametrosAuditoria.pesoConcedidoLiberadoMedio)) ||
      (Number(this.parametrosAuditoria.pesoConcedidoLiberadoBaixo) >= Number(this.parametrosAuditoria.pesoConcedidoLiberadoAlto))) {
      this.alertService.danger('Pontos da Materialidade do Valor Concedido Liberado devem ser superiores ao Medio e ao Baixo');
      return;
    }
    
    if ((this.parametrosAuditoria.pesoGastoBaixo == null || this.parametrosAuditoria.pesoGastoMedio == null) ||
        (this.parametrosAuditoria.pesoGastoAlto == null)) {
        this.alertService.danger('Pontos da Materialidade do Valor Gasto devem ser preenchidos.');
        return;
    }

    if ((Number(this.parametrosAuditoria.pesoGastoBaixo) >= Number(this.parametrosAuditoria.pesoGastoMedio)) ||
      (Number(this.parametrosAuditoria.pesoGastoBaixo) >= Number(this.parametrosAuditoria.pesoGastoAlto))) {
      this.alertService.danger('Pontos da Materialidade do Valor Gasto Baixo devem ser inferiores ap Médio e ao Alto.');
      return;
    }
    if ((Number(this.parametrosAuditoria.pesoGastoMedio) <= Number(this.parametrosAuditoria.pesoGastoBaixo)) ||
      (Number(this.parametrosAuditoria.pesoGastoMedio) >= Number(this.parametrosAuditoria.pesoGastoAlto))) {
      this.alertService.danger('Pontos da Materialidade do Valor Gasto Médio devem ser inferior ao Alto e superior ao Baixo');
      return;
    }
    if ((Number(this.parametrosAuditoria.pesoGastoAlto) <= Number(this.parametrosAuditoria.pesoGastoMedio)) ||
      (Number(this.parametrosAuditoria.pesoGastoBaixo) >= Number(this.parametrosAuditoria.pesoGastoAlto))) {
      this.alertService.danger('Pontos da Materialidade do Valor Gasto Alto devem ser superiores ao Medio e ao Baixo');
      return;
    }

    if ((this.parametrosAuditoria.valorGastoBaixo == null || this.parametrosAuditoria.valorGastoMedio == null) ||
        (this.parametrosAuditoria.valorGastoAlto == null)) {
        this.alertService.danger('Valores Gastos devem ser preenchidos.');
        return;
    }

    if ((this.parametrosAuditoria.valorGastoBaixo >= this.parametrosAuditoria.valorGastoMedio) ||
        (this.parametrosAuditoria.valorGastoBaixo >= this.parametrosAuditoria.valorGastoAlto)) {
        this.alertService.danger('Valor Gasto Baixo deve ser inferior ao Médio e ao Alto.');
        return;
    }
    if ((this.parametrosAuditoria.valorGastoMedio <= this.parametrosAuditoria.valorGastoBaixo) ||
        (this.parametrosAuditoria.valorGastoMedio > this.parametrosAuditoria.valorGastoAlto)) {
        this.alertService.danger('Valor Gasto Médio devem ser inferior ou igual ao Alto e superior ao Baixo');
        return;
    }
    if ((this.parametrosAuditoria.valorGastoAlto < this.parametrosAuditoria.valorGastoMedio) ||
        (this.parametrosAuditoria.valorGastoBaixo >= this.parametrosAuditoria.valorGastoAlto)) {
        this.alertService.danger('Valor Gasto Alto deve ser superior ou igual ao Medio e superior ao Baixo');
        return;
    }

    if ((this.parametrosAuditoria.pesoPercentualDocsConferirBaixo == null || this.parametrosAuditoria.pesoPercentualDocsConferirMedio == null) ||
        (this.parametrosAuditoria.pesoPercentualDocsConferirAlto == null)) {
        this.alertService.danger('Pontos da Materialidade dos Documentos a conferir devem ser preenchidos.');
        return;
    }
    if ((Number(this.parametrosAuditoria.pesoPercentualDocsConferirBaixo) >= Number(this.parametrosAuditoria.pesoPercentualDocsConferirMedio)) ||
      (Number(this.parametrosAuditoria.pesoPercentualDocsConferirBaixo) >= Number(this.parametrosAuditoria.pesoPercentualDocsConferirAlto))) {
      this.alertService.danger('Pontos da Materialidade dos Documentos a conferir devem ser inferiores ap Médio e ao Alto.');
      return;
    }
    if ((Number(this.parametrosAuditoria.pesoPercentualDocsConferirMedio) <= Number(this.parametrosAuditoria.pesoPercentualDocsConferirBaixo)) ||
      (Number(this.parametrosAuditoria.pesoPercentualDocsConferirMedio) >= Number(this.parametrosAuditoria.pesoPercentualDocsConferirAlto))) {
      this.alertService.danger('Pontos da Materialidade dos Documentos a conferir devem ser inferior ao Alto e superior ao Baixo');
      return;
    }
    if ((Number(this.parametrosAuditoria.pesoPercentualDocsConferirAlto) <= Number(this.parametrosAuditoria.pesoPercentualDocsConferirMedio)) ||
      (Number(this.parametrosAuditoria.pesoPercentualDocsConferirBaixo) >= Number(this.parametrosAuditoria.pesoPercentualDocsConferirAlto))) {
      this.alertService.danger('Pontos da Materialidade dos Documentos a conferir devem ser superiores ao Medio e ao Baixo');
      return;
    }

    if ((this.parametrosAuditoria.percentualDocsConferirBaixo == null || this.parametrosAuditoria.percentualDocsConferirMedio == null) ||
        (this.parametrosAuditoria.percentualDocsConferirAlto == null)) {
        this.alertService.danger('Porcentagens dos Documentos a conferir devem ser preenchidos.');
        return;
    }

    if ((Number(this.parametrosAuditoria.percentualDocsConferirBaixo) >= Number(this.parametrosAuditoria.percentualDocsConferirMedio)) ||
        (Number(this.parametrosAuditoria.percentualDocsConferirBaixo) >= Number(this.parametrosAuditoria.percentualDocsConferirAlto))) {
        this.alertService.danger('Porcentagem dos Documentos a conferir Baixo deve ser inferior ao Médio e ao Alto.');
        return;
    }
    if ((Number(this.parametrosAuditoria.percentualDocsConferirMedio) <= Number(this.parametrosAuditoria.percentualDocsConferirBaixo)) ||
        (Number(this.parametrosAuditoria.percentualDocsConferirMedio) > Number(this.parametrosAuditoria.percentualDocsConferirAlto))) {
        this.alertService.danger('Porcentagens dos Documentos a conferir Médio devem ser inferior ou igual ao Alto e superior ao Baixo');
        return;
    }
    if ((Number(this.parametrosAuditoria.percentualDocsConferirAlto) < Number(this.parametrosAuditoria.percentualDocsConferirMedio)) ||
        (Number(this.parametrosAuditoria.percentualDocsConferirBaixo) >= Number(this.parametrosAuditoria.percentualDocsConferirAlto))) {
        this.alertService.danger('Porcentagens dos Documentos a conferir Alto deve ser superior ou igual ao Medio e superior ao Baixo');
        return;
    }

    if ((this.parametrosAuditoria.pesoPercentualDocsCorrigirBaixo == null || this.parametrosAuditoria.pesoPercentualDocsCorrigirMedio == null) ||
        (this.parametrosAuditoria.pesoPercentualDocsCorrigirAlto == null)) {
        this.alertService.danger('Pontos da Materialidade dos Documentos a corrigir devem ser preenchidos.');
        return;
    }

    if ((Number(this.parametrosAuditoria.pesoPercentualDocsCorrigirBaixo) >= Number(this.parametrosAuditoria.pesoPercentualDocsCorrigirMedio)) ||
      (Number(this.parametrosAuditoria.pesoPercentualDocsCorrigirBaixo) >= Number(this.parametrosAuditoria.pesoPercentualDocsCorrigirAlto))) {
      this.alertService.danger('Pontos da Materialidade dos Documentos a corrigir devem ser inferiores ap Médio e ao Alto.');
      return;
    }
    if ((Number(this.parametrosAuditoria.pesoPercentualDocsCorrigirMedio) <= Number(this.parametrosAuditoria.pesoPercentualDocsCorrigirBaixo)) ||
      (Number(this.parametrosAuditoria.pesoPercentualDocsCorrigirMedio) >= Number(this.parametrosAuditoria.pesoPercentualDocsCorrigirAlto))) {
      this.alertService.danger('Pontos da Materialidade dos Documentos a corrigir devem ser inferior ao Alto e superior ao Baixo');
      return;
    }
    if ((Number(this.parametrosAuditoria.pesoPercentualDocsCorrigirAlto) <= Number(this.parametrosAuditoria.pesoPercentualDocsCorrigirMedio)) ||
      (Number(this.parametrosAuditoria.pesoPercentualDocsCorrigirBaixo) >= Number(this.parametrosAuditoria.pesoPercentualDocsCorrigirAlto))) {
      this.alertService.danger('Pontos da Materialidade dos Documentos a corrigir devem ser superiores ao Medio e ao Baixo');
      return;
    }

    if ((this.parametrosAuditoria.percentualDocsCorrigirBaixo == null || this.parametrosAuditoria.percentualDocsCorrigirMedio == null) ||
        (this.parametrosAuditoria.percentualDocsCorrigirAlto == null)) {
        this.alertService.danger('Porcentagens dos Documentos a corrigir devem ser preenchidos.');
        return;
    }
    if ((Number(this.parametrosAuditoria.percentualDocsCorrigirBaixo) >= Number(this.parametrosAuditoria.percentualDocsCorrigirMedio)) ||
        (Number(this.parametrosAuditoria.percentualDocsCorrigirBaixo) >= Number(this.parametrosAuditoria.percentualDocsCorrigirAlto))) {
        this.alertService.danger('Porcentagem dos Documentos a corrigir Baixo deve ser inferior ao Médio e ao Alto.');
        return;
    }
    if ((Number(this.parametrosAuditoria.percentualDocsCorrigirMedio) <= Number(this.parametrosAuditoria.percentualDocsCorrigirBaixo)) ||
        (Number(this.parametrosAuditoria.percentualDocsCorrigirMedio) > Number(this.parametrosAuditoria.percentualDocsCorrigirAlto))) {
        this.alertService.danger('Porcentagens dos Documentos a corrigir Médio devem ser inferior ou igual ao Alto e superior ao Baixo');
        return;
    }
    if ((Number(this.parametrosAuditoria.percentualDocsCorrigirAlto) < Number(this.parametrosAuditoria.percentualDocsCorrigirMedio)) ||
        (Number(this.parametrosAuditoria.percentualDocsCorrigirBaixo) >= Number(this.parametrosAuditoria.percentualDocsCorrigirAlto))) {
        this.alertService.danger('Porcentagens dos Documentos a corrigir Alto deve ser superior ou igual ao Medio e superior ao Baixo');
        return;
    }

    if ((this.parametrosAuditoria.pesoNumTotalDocsBaixo == null || this.parametrosAuditoria.pesoNumTotalDocsMedio == null) ||
        (this.parametrosAuditoria.pesoNumTotalDocsAlto == null)) {
        this.alertService.danger('Pontos da Materialidade do Total de documentos devem ser preenchidos.');
        return;
    }

    if ((Number(this.parametrosAuditoria.pesoNumTotalDocsBaixo) >= Number(this.parametrosAuditoria.pesoNumTotalDocsMedio)) ||
      (Number(this.parametrosAuditoria.pesoNumTotalDocsBaixo) >= Number(this.parametrosAuditoria.pesoNumTotalDocsAlto))) {
      this.alertService.danger('Pontos da Materialidade do Total de documentos devem ser inferiores ap Médio e ao Alto.');
      return;
    }
    if ((Number(this.parametrosAuditoria.pesoNumTotalDocsMedio) <= Number(this.parametrosAuditoria.pesoNumTotalDocsBaixo)) ||
      (Number(this.parametrosAuditoria.pesoNumTotalDocsMedio) >= Number(this.parametrosAuditoria.pesoNumTotalDocsAlto))) {
      this.alertService.danger('Pontos da Materialidade do Total de documentos devem ser inferior ao Alto e superior ao Baixo');
      return;
    }
    if ((Number(this.parametrosAuditoria.pesoNumTotalDocsAlto) <= Number(this.parametrosAuditoria.pesoNumTotalDocsMedio)) ||
      (Number(this.parametrosAuditoria.pesoNumTotalDocsBaixo) >= Number(this.parametrosAuditoria.pesoNumTotalDocsAlto))) {
      this.alertService.danger('Pontos da Materialidade do Total de documentos devem ser superiores ao Medio e ao Baixo');
      return;
    }
    this.parametrosAuditoria.numTotalDocsAlto = 0;
    if ((this.parametrosAuditoria.numTotalDocsBaixo == null || this.parametrosAuditoria.numTotalDocsMedio == null)) {
        this.alertService.danger('Nº de documentos devem ser preenchidos.');
        return;
    }

    if ((Number(this.parametrosAuditoria.numTotalDocsBaixo) < Number(this.parametrosAuditoria.numTotalDocsMedio))) {
        this.alertService.danger('Nº de documentos Baixo deve ser inferior ou igual ao Médio');
        return;
    }
    if (this.parametrosAuditoria.dataInicialVigenciaFormat == null || this.parametrosAuditoria.dataFinalVigenciaFormat == null ){
      this.alertService.danger('Data Vigência Inválida.');
      return;
    }

    this.parametrosAuditoria.dataInicialVigencia = this.dateConverter.fromNgbDateStructToDate(this.parametrosAuditoria.dataInicialVigenciaFormat);
    if (!this.utils.isValidDate(this.parametrosAuditoria.dataInicialVigencia)){
         this.alertService.danger('Data Inicial Vigência Inválida.');
         return;
    }

    this.parametrosAuditoria.dataFinalVigencia = this.dateConverter.fromNgbDateStructToDate(this.parametrosAuditoria.dataFinalVigenciaFormat);
    if (!this.utils.isValidDate(this.parametrosAuditoria.dataFinalVigencia)){
         this.alertService.danger('Data Final Vigência Inválida.');
         return;
    }

    if (this.parametrosAuditoria.nivelTipo == null || this.parametrosAuditoria.nivelTipo == undefined){
      this.alertService.danger('Nível Financeiro, Operacional ou Somatório deve ser selecionado.');
      return;
    }

   
    if (this.parametrosAuditoria.qtdAnoVisitacao == null || this.parametrosAuditoria.qtdAnoVisitacao === 0){
      this.alertService.danger('A Duração da inspeção deve ser preenchida.');
      return;
    }
    
    this.parametrosAuditoria.dataOperacao = new Date();
    this.userService.userInfo.subscribe(user => this.parametrosAuditoria.usuarioOperacao = user.username);
    this.loading.next(true)
      const data = JSON.stringify(this.parametrosAuditoria, this.replacer);
      this.detalheParceriaApi.updateParametrosAuditoria(data).subscribe(async (response) => {
        if (response) {
          this.alertService.success("Alterações salvas com sucesso!");
          this.setupInterface();
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
        //this.modalEdicaoReference.close();
      }, () => this.loading.next(false));
  }
  cancela(){
    this.setupInterface();
  }

  selectNivelTipo(opcao) {
    this.parametrosAuditoria.nivelTipo = opcao;
}
}