<div *ngIf="parceriaInfo != undefined">
    <div *ngIf="planEditais.length==0">
        <span class="mensagem-vazio">Nenhum edital foi encontrado.</span>
    </div>
    <div *ngIf="planEditais.length>0">

        <table class="table table-striped" style="margin-top:30px" id="tabelaEditais" [mfData]="planEditais" #mtEditais="mfDataTable" [mfRowsOnPage]="5">

            <thead>
                <tr>
                    <th *ngIf="(!isPortal)" data-html2canvas-ignore="true"></th>
                    <th *ngIf="!disableApagarIcone()" data-html2canvas-ignore="true"></th>
                    <th>Documento</th>
                    <th>Data</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of mtEditais.data; let j = index;">
                    <td *ngIf="!isPortal" class="col-auto" data-html2canvas-ignore="true">
                        <i ngbTooltip="editar" *ngIf="enableEditarIcone()" class="fa fa-pencil" aria-hidden="true" (click)="openEdicao(contentEdicao, data)"></i>
                        <i ngbTooltip="visualizar" *ngIf="visualizarBotao()" class="fa fa-search" aria-hidden="true" (click)="openEdicao(contentEdicao, data)"></i>
                    </td>
                    <td *ngIf="!disableApagarIcone()" class="col-auto" data-html2canvas-ignore="true">
                        <i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true" (click)="openExcluir(contentExcluir, data)"></i>
                    </td>
                    <td class="col-auto ">
                        <div [hidden]="!data.ediDocumentosArquivo">
                            {{data.ediDocumentosArquivo}}
                            <i ngbTooltip="download" class="fa fa-download" aria-hidden="true" (click)="getArquivo(data.parId, data.ediOrgId, 'editais', data.ediDocumentosArquivo)" alt="download"></i>
                        </div>
                    </td>
                    <td class="col-auto">{{data.ediDocumentosData | date: 'dd/MM/yyyy'}}</td>
                </tr>
            </tbody>
            <tfoot data-html2canvas-ignore="true ">
                <tr>
                    <td colspan="12 ">
                        <div *ngIf="planEditais.length>5" class="resultPorPagina">Resultados por página:</div>
                        <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
    <div *ngIf="!disableCampos()">
        <button (click)="openEdicao(contentEdicao, undefined) " id="button-insert_doc " class="btn btn-primary btn-info" type="button " title="Adicionar novo" [hidden]="parceriaInfo.parId===-1">
                            <i class="fa fa-plus "><span class="texto-botao">Adicionar novo</span> </i>
                        </button>
    </div>

    <ng-template #contentEdicao let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Edital</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                                <span aria-hidden="true">&times;</span>
                            </button>
        </div>
        <form (ngSubmit)="salvarEditais()" #edicao="ngForm" novalidate>
            <div class="modal-body" *ngIf="editalSend">
                <div class="form-group col-md-12">
                    <label for="doc">Documento</label>
                    <!-- <span [hidden]="documento.valid" class="campoObrigatorio">*</span>  -->
                    <div [hidden]="showUpload">
                        {{editalSend.ediDocumentosArquivo}}
                        <i ngbTooltip="download" class="fa fa-times" aria-hidden="true" (click)="showUpload = true;editalSend.ediDocumentosDocumento = undefined" alt="download"></i>
                    </div>
                    <div [hidden]="!showUpload">
                        <input type="file" name="fileInput{{i}}" accept=".jpg, .pdf, .gif, .png" (change)="onFileDocChanged($event, j)">
                        <!-- <i class="fa fa-times" aria-hidden="true" (click)="resetDevFile(j)" alt="download"></i> -->
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="data">Data</label>
                    <span [hidden]="data.valid" class="campoObrigatorio">*</span>
                    <input style="width:100%" class="form-control" name="data" #data="ngModel" 
                    maxlength="10" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="editalSend.ediDocumentosDataFormat" 
                    [(ngModel)]="editalSend.ediDocumentosDataFormat" ngbDatepicker #d="ngbDatepicker" required>
                    <i class="fa fa-calendar icon" (click)="d.toggle()" title="calendário"></i>
                </div>

            </div>
            <div *ngIf="!visualizarBotao()" class="modal-footer col-md-12">
                <button type="button" class="btn btn-default" (click)="c('Close click');setEdiDocumentosData(undefined)" title="Cancelar">Cancelar</button>
                <button type="submit" class="btn btn-primary" [disabled]="!edicao.form.valid" title="Salvar">Salvar</button>
            </div>
        </form>
    </ng-template>
    <ng-template #contentExcluir let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Excluir Edital</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                                <span aria-hidden="true">&times;</span>
                            </button>
        </div>
        <div class="modal-body">
            <p>Tem certeza que deseja excluir o edital
                <b>{{editalSend.ediDocumentosData | date:'dd/MM/yyyy'}}  - {{editalSend.ediDocumentosArquivo}}</b>?</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">Fechar</button>
            <button type="button" class="btn btn-secondary" (click)="excluirEditais()" title="Excluir">Excluir</button>
        </div>
    </ng-template>
</div>