<div *ngIf="parceriaInfo != undefined">
    <div *ngIf="planTermos.length==0">
        <span class="mensagem-vazio">Nenhum termo foi encontrado.</span>
    </div>
    <div *ngIf="planTermos.length>0">
        <table class="table table-striped" style="margin-top:30px" id="tabelaTermos" [mfData]="planTermos"
            #mtTermmos="mfDataTable" [mfRowsOnPage]="5">

            <thead>
                <tr>
                    <th *ngIf="(!isPortal)" data-html2canvas-ignore="true"></th>
                    <th *ngIf="!disableApagarIcone()" data-html2canvas-ignore="true"></th>
                    <th>Documento</th>
                    <th>Data Assinatura</th>
                    <th>Data Início Execução</th>
                    <th>Data Publicação</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of mtTermmos.data; let j = index;">

                    <td *ngIf="!isPortal" class="col-auto" data-html2canvas-ignore="true">
                        <i ngbTooltip="editar" *ngIf="enableEditarIcone()" class="fa fa-pencil" aria-hidden="true"
                            (click)="openEdicao(contentEdicao, data)"></i>
                        <i ngbTooltip="visualizar" *ngIf="visualizarBotao()" class="fa fa-search" aria-hidden="true"
                            (click)="openEdicao(contentEdicao, data)"></i>
                    </td>
                    <td *ngIf="!disableApagarIcone()" class="col-auto" data-html2canvas-ignore="true">
                        <i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true"
                            (click)="openExcluir(contentExcluir, data)"></i>
                    </td>

                    <td class="col-auto ">
                        <div [hidden]="!data.planejamentoTermos.terTermoArquivo">
                            {{data.planejamentoTermos.terClassificacaoTexto}}
                            <i ngbTooltip="download" class="fa fa-download" aria-hidden="true"
                                (click)="getArquivo(data.planejamentoTermos.parId, data.planejamentoTermos.terId, 'termos', data.planejamentoTermos.terTermoArquivo)"
                                alt="download"></i>
                        </div>
                    </td>
                    <td class="col-auto">{{data.planejamentoTermos.terTermoData | date: 'dd/MM/yyyy'}}</td>
                    <td class="col-auto">{{data.planejamentoTermos.terDataInicioExecucao | date: 'dd/MM/yyyy'}}</td>
                    <td class="col-auto">{{data.planejamentoTermos.terDataPublicacao | date: 'dd/MM/yyyy'}}</td>
                </tr>
            </tbody>
            <tfoot data-html2canvas-ignore="true ">
                <tr>
                    <td colspan="12 ">
                        <div *ngIf="planTermos.length>5" class="resultPorPagina">Resultados por página:</div>
                        <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
    <div *ngIf="!disableCampos()">
        <button (click)="openEdicao(contentEdicao, undefined) " id="button-insert_doc " class="btn btn-primary btn-info"
            type="button " title="Adicionar novo" [hidden]="parceriaInfo.parId===-1">
            <i class="fa fa-plus "><span class="texto-botao">Adicionar novo</span> </i>
        </button>
    </div>

    <ng-template #contentEdicao let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Termo</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form (ngSubmit)="salvaTermo()" #edicao="ngForm" novalidate>
            <div class="modal-body" *ngIf="terTermoSend">
                <div class="form-group col-md-12">
                    <label for="doc">Documento</label>
                    <!-- <span [hidden]="documento.valid" class="campoObrigatorio">*</span>  -->
                    <div [hidden]="showUpload">
                        {{terTermoSend.terTermoArquivo}}
                        <i ngbTooltip="download" class="fa fa-times" aria-hidden="true"
                            (click)="showUpload = true;terTermoSend.terTermoDocumento = undefined" alt="download"></i>
                    </div>
                    <div [hidden]="!showUpload">
                        <input type="file" name="fileInput{{i}}" accept=".jpg, .pdf, .gif, .png"
                            (change)="onFileDocChanged($event, j)">
                        <!-- <i class="fa fa-times" aria-hidden="true" (click)="resetDevFile(j)" alt="download"></i> -->
                    </div>
                </div>
                    <div  class="form-group" [ngClass]="{'col-md-12': terTermoSend.terClassificacao !== 2 && terTermoSend.terClassificacao !== 3, 'col-md-9': terTermoSend.terClassificacao === 2 || terTermoSend.terClassificacao === 3}">
                        <label for="tipoTermo">Tipo de termo</label>
                        <span [hidden]="tipoTermo.valid" class="campoObrigatorio">*</span>
                        <div class="select-wrapper">
                            <ng-select name="tipoTermo" #tipoTermo="ngModel"
                            [items]="this.utils.classificacaoDocumentosLicitacon" bindLabel="nome" bindValue="id"
                            [ngModel]="terTermoSend.terClassificacao" [ngModelOptions]="{standalone: true}"
                            (change)="selectTipoTermo($event)" class="form-control form-select custom-select" required>
                        </ng-select>
                    </div>
                </div>
                <div class=" form-group col-md-3" *ngIf="terTermoSend.terClassificacao === 2 || terTermoSend.terClassificacao === 3">
                    <label for="novoValor">{{terTermoSend.terClassificacao === 2 ? "N° do Termo Aditivo:" : "N° do Apostilamento:"}}</label>
                    <span [hidden]="numAditivo.valid" class="campoObrigatorio">*</span>
                    <input  style="width:100%;" class="form-control" type="text" [textMask]="maskNum" #numAditivo="ngModel" [(ngModel)]="request.numAditivo" name="numAditivo" required>
                    <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="request.numAditivo=undefined" title="limpar"></i>
                </div>
            

                <!-- <div class="form-group col-md-6" *ngIf="terTermoSend.terClassificacao === 2 || terTermoSend.terClassificacao === 3">
                    <label for="novoValor">Novo Valor:</label>
                    <input style="width:100%;" id="novoValor" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control text-left"
                        #novoValor="ngModel" type="text" [(ngModel)]="request.valor" name="novoValor">
                    <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="request.valor=undefined" title="limpar"></i>
                </div>
                <div class="form-group col-md-6" *ngIf="terTermoSend.terClassificacao === 2 || terTermoSend.terClassificacao === 3">
                    <label for="dataInicial">Data Início Vigência</label>
                    <input style="width:100%;" class="form-control" name="dataInicial" #dataInicial="ngModel" maxlength="10"
                        placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="request.dataIncialVigenciaDataFormat"
                        [(ngModel)]="request.dataIncialVigenciaDataFormat" ngbDatepicker #d="ngbDatepicker">
                    <i class="fa fa-calendar icon" (click)="d.toggle()" title="calendário"></i>
                </div>
                <div class="form-group col-md-6" *ngIf="terTermoSend.terClassificacao == 2">
                    <label for="dataFinal">Data Fim Vigência</label>
                    <input style="width:100%" class="form-control" name="dataFinal"
                        #dataFinal="ngModel" maxlength="10" placeholder="dd/mm/aaaa" appMaskInput
                        [appMaskValue]="request.dataFinalVigenciaDataFormat"
                        [(ngModel)]="request.dataFinalVigenciaDataFormat" ngbDatepicker #di="ngbDatepicker">
                    <i class="fa fa-calendar icon" (click)="di.toggle()" title="calendário"></i>
                </div> -->
                <div class="form-group col-md-6">
                    <label for="data">Data de Assinatura</label>
                    <span [hidden]="data.valid" class="campoObrigatorio">*</span>
                    <input style="width:100%" class="form-control" name="data" #data="ngModel" maxlength="10"
                        placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="terTermoSend.terTermoDocumentoDataFormat"
                        [(ngModel)]="terTermoSend.terTermoDocumentoDataFormat" ngbDatepicker #d="ngbDatepicker"
                        required>
                    <i class="fa fa-calendar icon" (click)="d.toggle()" title="calendário"></i>
                </div>
                <div class="form-group col-md-6">
                    <label for="dataInicioExecucao">Data de Início da Execução</label>
                    <span [hidden]="dataInicioExecucao.valid" class="campoObrigatorio">*</span>
                    <input style="width:100%" class="form-control" name="dataInicioExecucao"
                        #dataInicioExecucao="ngModel" maxlength="10" placeholder="dd/mm/aaaa" appMaskInput
                        [appMaskValue]="terTermoSend.terDataInicioExecucaoDataFormat"
                        [(ngModel)]="terTermoSend.terDataInicioExecucaoDataFormat" ngbDatepicker #di="ngbDatepicker"
                        required>
                    <i class="fa fa-calendar icon" (click)="di.toggle()" title="calendário"></i>
                </div>
                <div class="form-group col-md-6">
                    <label for="dataPublicacao">Data de Publicação</label>
                    <span [hidden]="dataPublicacao.valid" class="campoObrigatorio">*</span>
                    <input style="width:100%" class="form-control" name="dataPublicacao" #dataPublicacao="ngModel"
                        maxlength="10" placeholder="dd/mm/aaaa" appMaskInput
                        [appMaskValue]="terTermoSend.terDataPublicacaoDataFormat"
                        [(ngModel)]="terTermoSend.terDataPublicacaoDataFormat" ngbDatepicker #dp="ngbDatepicker"
                        required>
                    <i class="fa fa-calendar icon" (click)="dp.toggle()" title="calendário"></i>
                </div>
            </div>
            <div *ngIf="!visualizarBotao()" class="modal-footer col-md-12">
                <button type="button" class="btn btn-default" (click)="c('Close click');setTerTermo(undefined)"
                    title="Cancelar">Cancelar</button>
                <button type="submit" class="btn btn-primary" [disabled]="!edicao.form.valid"
                    title="Salvar">Salvar</button>
            </div>
        </form>
    </ng-template>
    <ng-template #contentExcluir let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Excluir Termo</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Tem certeza que deseja excluir o termo
                <b>{{terTermoSend.terTermoData | date:'dd/MM/yyyy'}} - {{terTermoSend.terTermoArquivo}}</b>?
            </p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">Fechar</button>
            <button type="button" class="btn btn-secondary" (click)="excluirterTermo()" title="Excluir">Excluir</button>
        </div>
    </ng-template>
</div>