<div *ngIf="parMonitoraPrestaExcecaoTrava.length==0">
    <span class="mensagem-vazio">Nenhuma autorização especial (desbloqueio trava) foi encontrada.</span>
</div>
<div *ngIf="parMonitoraPrestaExcecaoTrava.length>0">

    <table class="table table-striped" style="margin-top:30px" id="tabelaEditais" [mfData]="parMonitoraPrestaExcecaoTrava" #mtMonitora="mfDataTable" [mfRowsOnPage]="5">

        <thead>
            <tr>
                <th *ngIf="!isPortal && hasTrava" data-html2canvas-ignore="true"></th>
                <th *ngIf="!isPortal && hasTrava" data-html2canvas-ignore="true"></th>
                <th>Competência</th>
                <th>Data Início</th>
                <th>Data Fim</th>
                <th>Justificativa</th>
                <th>Operador</th>
                <th>Data Inclusão</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of mtMonitora.data; let j = index;">
                <td *ngIf="!isPortal && hasTrava" class="col-auto" data-html2canvas-ignore="true">
                    <i ngbTooltip="editar" class="fa fa-pencil" aria-hidden="true" (click)="openEdicao(contentEdicao, data)"></i>
                </td>
                <td *ngIf="!isPortal && hasTrava" class="col-auto" data-html2canvas-ignore="true">
                    <i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true" (click)="openExcluir(contentExcluir, data)"></i>
                </td>
                <td *ngIf="data.excecaoTravaCompetenciaFinal != null" class="col-auto">{{data.excecaoTravaCompetencia | date: 'MM/yyyy'}} - {{data.excecaoTravaCompetenciaFinal | date: 'MM/yyyy'}}</td>
                <td *ngIf="data.excecaoTravaCompetenciaFinal == null" class="col-auto">{{data.excecaoTravaCompetencia | date: 'MM/yyyy'}} </td>
                <td class="col-auto">{{data.excecaoTravaDataInicio | date: 'dd/MM/yyyy'}}</td>
                <td class="col-auto">{{data.excecaoTravaDataFim | date: 'dd/MM/yyyy'}}</td>
                <td class="col-auto">{{data.excecaoTravaJustificativa}}</td>
                <td class="col-auto">{{data.excecaoTravaOperador}}</td>
                <td class="col-auto">{{data.excecaoTravaDataOperacao | date: 'dd/MM/yyyy'}}</td>
            </tr>
        </tbody>
        <tfoot data-html2canvas-ignore="true ">
            <tr>
                <td colspan="12 ">
                    <div *ngIf="parMonitoraPrestaExcecaoTrava.length>5" class="resultPorPagina">Resultados por página:</div>
                    <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
                </td>
            </tr>
        </tfoot>
    </table>
</div>
<div *ngIf="!isPortal && hasTrava">
    <button (click)="openEdicao(contentEdicao, undefined) " id="button-insert_doc " class="btn btn-primary btn-info" type="button " title="Adicionar novo">
                    <i class="fa fa-plus "><span class="texto-botao">Adicionar novo</span> </i>
                </button>
</div>

<ng-template #contentEdicao let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Autorização Especial (Desbloqueio Trava)</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                        <span aria-hidden="true">&times;</span>
                    </button>
    </div>
    <form (ngSubmit)="salvaMonitoraPrestaExcecaoTrava()" #edicao="ngForm" novalidate>
        <div class="modal-body" *ngIf="parMonitoraPrestaExcecaoTravaSend">
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="compIni">Competência Inicial</label>

                    <div class="row">
                        <div class="form-group col-md-7">
                            <span [hidden]="mesIni.valid" class="campoObrigatorio">*</span> Mês &nbsp;
                            <select name="mesIni" #mesIni="ngModel" [ngModel]="monthIniSelection" class="form-control custom-select" (change)="setMes($event, 'I')" required>
                                <option *ngFor="let month of months" [value]="month">{{month}}</option>
                        </select>
                        </div>
                        <div class="form-group col-md-5">
                            <span [hidden]="anoIni.valid" class="campoObrigatorio">*</span> Ano &nbsp;
                            <select name="anoIni" #anoIni="ngModel" class="form-control custom-select" [ngModel]="yearIniFromSelector" (change)="setAno($event, 'I')" required>
                             <option *ngFor="let year of years" [value]="year">{{year}}</option>
                        </select>
                        </div>
                    </div>

                </div>
                <div class="form-group col-md-6">
                    <label for="compFim">Competência Final</label>
                    <div class="row">
                        <div class="form-group col-md-7">
                            <span [hidden]="mesFim.valid" class="campoObrigatorio">*</span> Mês &nbsp;
                            <select name="mesFim" #mesFim="ngModel" [ngModel]="monthFimSelection" class="form-control custom-select" (change)="setMes($event, 'F')">
                                    <option *ngFor="let month of months" [value]="month">{{month}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-5">
                            <span [hidden]="anoFim.valid" class="campoObrigatorio">*</span> Ano &nbsp;
                            <select name="anoFim" #anoFim="ngModel" class="form-control custom-select" (change)="setAno($event, 'F')" [ngModel]="yearFimFromSelector">
                                 <option *ngFor="let year of years" [value]="year">{{year}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6">
                    <label for="dataIni">Data Início</label>
                    <span [hidden]="dataIni.valid" class="campoObrigatorio">*</span>
                    <input style="width:100%" class="form-control" name="dataIni" #dataIni="ngModel"
                    maxlength="10" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataInicioDataFormat"
                     [(ngModel)]="parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataInicioDataFormat" ngbDatepicker #di="ngbDatepicker"
                        required>
                    <i class="fa fa-calendar icon" (click)="di.toggle()" title="calendário"></i>
                </div>
                <div class="form-group col-md-6">
                    <label for="dataFim">Data Fim</label>
                    <span [hidden]="dataFim.valid" class="campoObrigatorio">*</span>
                    <input style="width:100%" class="form-control" name="dataFim" #dataFim="ngModel" 
                    maxlength="10" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataFimDataFormat"
                    [(ngModel)]="parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataFimDataFormat" ngbDatepicker #df="ngbDatepicker"
                        required>
                    <i class="fa fa-calendar icon" (click)="df.toggle()" title="calendário"></i>
                </div>
            </div>
            <div class="row">
                <div class="form-group col">
                    <label for="valor">Justificativa</label>
                    <div style="width:100%">
                        <textarea style="width:100%;" id="observacao" class="form-control" #observacao="ngModel" maxlength="200" [(ngModel)]="parMonitoraPrestaExcecaoTravaSend.excecaoTravaJustificativa" [ngModelOptions]="{standalone: true}" (change)="updateTextArea($event.target.value)">{{parMonitoraPrestaExcecaoTravaSend.excecaoTravaJustificativa}}</textarea>
                        <i class="fa fa-times icon_input_fontawsome " aria-hidden="true " (click)="parMonitoraPrestaExcecaoTravaSend.excecaoTravaJustificativa=undefined " title="limpar "></i>
                    </div>
                </div>
            </div>

        </div>
        <div class="modal-footer col-md-12">
            <button type="button" class="btn btn-default" (click)="c('Close click');setMonitoraPrestaExcecaoTrava(undefined)" title="Cancelar">Cancelar</button>
            <button type="submit" class="btn btn-primary" [disabled]="!edicao.form.valid" title="Salvar">Salvar</button>
        </div>
    </form>
</ng-template>
<ng-template #contentExcluir let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Excluir autorização especial (desbloqueio trava)</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                        <span aria-hidden="true">&times;</span>
                    </button>
    </div>
    <div class="modal-body">
        <p>Tem certeza que deseja excluir a autorização especial (desbloqueio trava) da competência
            <b>{{parMonitoraPrestaExcecaoTravaSend.excecaoTravaCompetencia | date:'MM/yyyy'}}
                - {{parMonitoraPrestaExcecaoTravaSend.excecaoTravaCompetenciaFinal| date:'MM/yyyy'}}</b>?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">Fechar</button>
        <button type="button" class="btn btn-secondary" (click)="excluirMonitoraPrestaExcecaoTrava()" title="Excluir">Excluir</button>
    </div>
</ng-template>