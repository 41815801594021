import { Component, OnInit, Input, OnDestroy, Output } from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {share} from 'rxjs/operators';
import { UserService } from '../../@core/user.service';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from '../detalheParceria/parceriaInfo';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { ActivatedRoute, OutletContext, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Mascaras } from 'src/app/@shared/components/Mascaras';
import { BuscaCampos } from '../../modulo1/exibeParcerias/buscaCampos';
import { DateConverter } from 'src/app/@core/date-converter';
import { KeycloakService, AuthUser } from '@procempa/ngx-keycloak';
import { EnvioDadoService } from 'src/app/@shared/components/filtroBusca/envioDado.service';
import { LogonApi } from 'src/app/apis/logonApi';
import { AuditorLogon } from 'src/app/liberacaoLogon/auditor/auditorLogon';

@Component({
  selector: 'app-exibe-parceria-m2',
  templateUrl: './exibeParceriasM2.component.html',
  styleUrls: ['./exibeParceriasM2.component.scss']
})

export class ExibeParceriasM2Component implements OnInit, OnDestroy {

  parceriaArray: Array<ParceriaInfo>;
  @Input()
  isPortal: boolean;
  @Input()
  modulo: string;
  isFiscal: boolean;
  isConsulta: boolean;
  isSMTC: boolean;
  subscription: Subscription;
  collectionSizeParceria: number;
  countParcerias: number;
  currentPage: number;
  @Output()
  isAuditorExterno: boolean;
  auditorLogonArray: Array<AuditorLogon>;
  orgao: string;

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());

  constructor(private logonApi: LogonApi,
    public userService: UserService,
    private router: Router,
    private envioDadoService: EnvioDadoService,
    public keycloakService: KeycloakService,
    private route: ActivatedRoute
    ) {
  }

  ngOnInit() {

    this.modulo = 'm2';
    if (this.isPortal === undefined) {
      this.isPortal = false;
    }
    this.parceriaArray = [];
    this.isFiscal = this.userService.isFiscal();
    this.isConsulta = this.userService.isConsulta();
    if (!(this.isFiscal || this.isConsulta)) {
      this.router.navigate(['notAuthorized']);
    }

    this.route.queryParams.subscribe(async params => {
      this.isAuditorExterno = params['isAuditorExterno'] === undefined || params['isAuditorExterno'].toLowerCase() === 'false' ? false : true;
      if (this.isAuditorExterno){
        this.router.navigate(['/execucao/exibeParceriaEntidadeM2/']);
      }
    });

    

    this.subscription = this.envioDadoService.enviaParceriaArray$.subscribe((message) => {
      this.parceriaArray = message;
    //  console.log(this.parceriaArray);
    });

     this.envioDadoService.enviaParceriaArraySize$.subscribe((count) => {
      this.countParcerias = count;
      this.collectionSizeParceria = Math.ceil((count / 25) * 10);
     // console.log(this.countParcerias);
    //  console.log(this.collectionSizeParceria);
    });
    this.envioDadoService.enviaCurrentPage$.subscribe((count) => {
      this.currentPage = count;
    //  console.log(this.collectionSizeParceria);
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onPageChange(pageNumber) {
    if (isNaN(pageNumber)) {
      return;
    }
    this.envioDadoService.changePagina(pageNumber);
  }

}
