import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class DevolucaoAplicacao {

    public parId: number;
    public execDevolId: number;
    public execDevolArquivo: string;
    public execDevolData: Date;
    public execDevolDataFormat: NgbDateStruct;
    //public execDevolArqOriginal: string;
    public execDevolValor: number;
    public execDevolTipo: string;
    public execDevolMotivo: number;
    public operacaoData: Date;
    public operacaoUsuario: string;
    public observacao: string;
    public execConferir: number;
    public execMotivo: string;
    public execDevolDevolucao: number;
    public execDevolJustificaExclusao: string;
    public execDevolDataStr: string;
    public prestacaoContasId: number;
	public prestacaoContasTexto: string;
}
