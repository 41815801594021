import { Component, Input, Output } from '@angular/core';
import { UserService } from 'src/app/@core/user.service';
import { DatePipe, Location } from '@angular/common';
import { Router } from '@angular/router';
import { NotificacaoTela } from 'src/app/notificacoes/notificacaoTela';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { AlertService } from '../@core/alert.service';
import { AuthUser, KeycloakService } from '@procempa/ngx-keycloak';

@Component({
    selector: 'app-ver-notificacoes',
    templateUrl: 'verNotificacoes.component.html',
    styleUrls: ['./../modulo2/detalheParceria/detalheParceria.component.scss'],
    providers: [DatePipe]
})
export class VerNotificacoesComponent {
    isLoggedIn: boolean;
    @Output()
    isPortal: boolean;
    isFiscal: boolean;
    isConsulta: boolean;
    hasTrava: boolean;
    nome: string;
    path: string;
    notificacoes: Array<NotificacaoTela> = [];
    detalheParceriaApi: DetalheParceriaApi;

    constructor(public userService: UserService, private router: Router, location: Location, detalheParceriaApi: DetalheParceriaApi, private alertService: AlertService, public keycloakService: KeycloakService, private datePipe: DatePipe) {
        this.detalheParceriaApi = detalheParceriaApi;
        if (!userService.authenticated()) {
            this.isPortal = true;
        } else {
            this.isFiscal = userService.isFiscal();
            this.isConsulta = userService.isConsulta();
            this.isPortal = false;
            this.hasTrava = userService.hasTrava();
        }

        this.buscaNotificacoes();
    }

    buscaNotificacoes() {
        if (this.userService.isFiscal()) {
                this.keycloakService.getUser().subscribe((user: AuthUser) => {
                    this.detalheParceriaApi.getNotificacoesTelaFiscal(Number(user.attributes.sgp_orgao[0])).subscribe((response) => {
                        if (response) {
                            this.notificacoes = response;
                            this.notificacoes.forEach(element => {
                                element.strData = this.datePipe.transform(element.data, 'dd/MM/yyyy')
                            })
                        }
                    }, (response) => {
                        if (response.status >= 500) {
                            this.alertService.danger(response.error);
                        } else {
                            this.alertService.warning(response.error);
                        }
                    });
                });
            } else {
                this.detalheParceriaApi.getNotificacoesTelaOSC(this.keycloakService['_keycloakInstance'].tokenParsed.email).subscribe((response) => {
                    if (response) {
                        this.notificacoes = response;
                    }
                }, (response) => {
                    if (response.status >= 500) {
                        this.alertService.danger(response.error);
                    } else {
                        this.alertService.warning(response.error);
                    }
                });
            }
    }

    ocultaNotificacao(id) {
        if (this.isFiscal) {
            this.detalheParceriaApi.ocultaNotifOrgao(id).subscribe((response) => {
                this.buscaNotificacoes();
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
            })
        } else {
            this.detalheParceriaApi.ocultaNotifOsc(id).subscribe((response) => {
                this.buscaNotificacoes();
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
            });
        }
    }
}