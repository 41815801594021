export class Licitacon{
    public nLicitacao: number;
    public anoLicitacao: number;
    public modalidadeLicitacao: string;
    public contratadoVencLicitacao: boolean;
    public justNovoContratadoLicitacao: string;
    public dotacoes: Array<Dotacao>;
}

export class Dotacao{
    public projetoAtividade: string;
    public recursoOrcamentario: string;
    public naturezaDespesa: string;
}
