import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class AuditorLogon {
    
    public idAuditor: number;
    public cpfAuditor: string;
    public nomeAuditor: string;
    public emailAuditor: string;
    public parId: number;
    public dataFimAcesso: Date;
    public dataFimAcessoDataFormat: NgbDateStruct;
    public cnpjParceria: string;
    public orgaoParceria: number;
    public dataAutorizacao: Date;
    public dataAutorizacaoFormatada: string;
    public operador: string;
    public orgaoNome: string;
    public parceriaNome: string;
    public parNum: string;
}
