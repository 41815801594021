<div w3-include-html="../../@shared/components/headerPortal/header.component.html">
  <div class="container-fluid mb-3" style="min-height: 350px;">
    <div class="infoGeral container-fluid">
      <br>
      <h3>Filtros de pesquisa</h3>
      <p><em>Você pode filtrar os resultados preenchendo um ou combinando mais de um dos campos abaixo:</em></p>
      <br>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label for="">Escolher órgão ou entidade (PMPA)</label>
            <div class="select-wrapper">
              <select [disabled]="isFiscal && !isSMTC" [ngModel]="busca.orgao" (change)="selectOrgaoParceria($event)"
                class="form-control form-select custom-select">
                <option [value]="option.id" *ngFor="let option of this.utils.secretariasOptions">{{option.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="nome">Nome da organização (parceria)</label>
            <input style="width: 100% " id="nome" name="busca.nomeOrg" class="form-control " type="text "
              [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.nomeOrg " [value]="busca.nomeOrg "
              (blur)="selectNomeParceria()" (keydown.enter)="selectNomeParceria()"
              placeholder="digite alguma palavra-chave">
          </div>
        </div>
        <div class="col-md-3" style="width: 100% ">
          <div class="form-group">
            <label for="nroTermo">Número do termo</label>
            <input style="width: 100% " id="nroTermo" name="busca.numeroTermo" class="form-control " type="text "
              [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.numeroTermo " [value]="busca.numeroTermo"
              (blur)="selectNumeroTermo()" (keydown.enter)="selectNumeroTermo()" class="form-control"
              placeholder="informe o nº do termo">
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="cnpj">CNPJ</label>
            <input style="width: 100% " id="cnpj" name="busca.cnpj" class="form-control " type="text "
              [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.cnpj " [value]="busca.cnpj"
              (blur)="selectCnpj()" (keydown.enter)="selectCnpj()" class="form-control" placeholder="informe o CNPJ"
              [textMask]="mascaraCNPJ">
          </div>
        </div>

        <div class="col-md-1">
          <button class="btn btn-primary btn-filtro">Filtrar</button>
        </div>
        <div class="col-md-1">
          <button class="btn btn-primary btn-filtro" (click)="openEdicao(contentEdicao, null)">Novo</button>
        </div>
        <!-- Filtar Lista: <input type="text " [(ngModel)]="queryString " id="search " placeholder="digite o valor ">-->
      </div>

    </div>
    <br>
    <div>
      <table class="table table-hover table-responsavel" [hidden]="(!parceriaArray) " [mfData]="parceriaArray ">
        <thead>
          <tr>
            <th></th>
            <th *ngIf="!disableIcone()" data-html2canvas-ignore="true"></th>
            <th>CNPJ</th>
            <th>E-mail</th>
            <th>Nome</th>
            <th>Data Última Alteração</th>
            <th>Operador</th>
          </tr>
        </thead>
        <tbody>

          <tr *ngFor="let parceria of parceriaArray | paginate:config let idx = index">

            <td>
              <i ngbTooltip="Editar Parceria" *ngIf="!disableIcone()" class="fa fa-pencil" aria-hidden="true"
                (click)="openEdicao(contentEdicao, parceria)"></i>
              <i ngbTooltip="VisualizarParceria" *ngIf="disableIcone()" class="fa fa-search" aria-hidden="true"
                (click)="openEdicao(contentEdicao, parceria)"></i>
            </td>
            <td *ngIf="!disableIcone()" class="col-auto" data-html2canvas-ignore="true">
              <i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true"
                (click)="openExcluir(contentExcluir, parceria)"></i>
            </td>

            <td>{{aplicaMascaraCNPJ(parceria.parLogonCnpj)}}</td>
            <td>{{parceria.parLogonEmail}}</td>
            <td>{{parceria.parLogonNome}}</td>
            <td>{{parceria.parLogonDtUltAtu | date: 'dd/MM/yyyy'}}</td>
            <td>{{parceria.parLogonOperAtu}}</td>
          </tr>
        </tbody>
      </table>
      <tfoot data-html2canvas-ignore="true" *ngIf="countParcerias > config.itemsPerPage">
        <tr>
          <td colspan="15">
            <pagination-controls class="pagination pagination justify-content-end" id="custom"
              (pageChange)="pageChanged($event)" directionLinks="true" previousLabel="" nextLabel="">
            </pagination-controls>
          </td>
        </tr>
      </tfoot>
      <ng-template #contentEdicao let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Autorização de Logon da Parceira</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form (ngSubmit)="salva()" #edicao="ngForm" novalidate>
          <div class="modal-body" *ngIf="parLogonSend">

            <div class="form-group col-md-12 col-lg-4">
              <label for="cpfCnpj">CNPJ Parceira</label>
              <span class="campoObrigatorio">* {{inValidCpfCnpj?' inválido':''}}</span>
              <input style="width: 100%" id="cpfCnpj" class="form-control" name="cpfCnpj" #cpfCnpj="ngModel" type="text"
                [(ngModel)]="parLogonSend.parLogonCnpj" [textMask]="mascaraCNPJ"
                (focusout)="updateCnpj(parLogonSend.parLogonCnpj)" required>
              <i class="fa fa-times icon_input_fontawsome" aria-hidden="true"
                (click)="parLogonSend.parLogonCnpj=undefined; showCamposEdicao=false" title="limpar"></i>
            </div>
            <div class="form-group col-md-12 col-lg-8">
              <label for="nomeLogon">Nome da Parceira</label>
              <input style="width:100%;" id="nomeLogon" class="form-control" name="credor" #credor="ngModel" type="text"
                [(ngModel)]="parLogonSend.parLogonNome" [disabled]="true">
            </div>
            <div class="form-group col-md-12 col-lg-8">
              <label for="emailF">Email</label>
              <span [hidden]="emailF.valid" class="campoObrigatorio">*</span>
              <input style="width:100%;" id="email" class="form-control" name="emailF" #emailF="ngModel" type="text"
                [(ngModel)]="parLogonSend.parLogonEmail"
                pattern="[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*" required>
              <i class="fa fa-times icon_input_fontawsome" aria-hidden="true"
                (click)="parLogonSend.parLogonEmail=undefined; showCamposEdicao=false" title="limpar"></i>
            </div>
            <div class="form-group col-md-12 col-lg-8">
              <label for="dataPublicacao">Data Última Autorização</label>
              <input style="width:100%" disabled="true" class="form-control" type="text" name="data" #data="ngModel"
                [ngModel]="parLogonSend.parLogonDtUltAtu | date:'dd/MM/yyyy'"
                (ngModelChange)="parLogonSend.parLogonDtUltAtu = $event">
            </div>
            <div class="form-group col-md-12 col-lg-8">
              <label for="nomeOperador">Operador</label>
              <input style="width:100%;" id="nomeOperador" class="form-control" name="operador" #operador="ngModel"
                type="text" [(ngModel)]="parLogonSend.parLogonOperAtu" [disabled]="true">
            </div>
          </div>
          <div *ngIf="!disableIcone()" class="modal-footer col-md-12">
            <button type="button" class="btn btn-default" (click)="c('Close click');setEdicaoParLogon(undefined)"
              title="Cancelar">Cancelar</button>
            <button type="submit" class="btn btn-primary" [disabled]="!edicao.form.valid" title="Salvar">Salvar</button>
          </div>
        </form>
      </ng-template>
      <ng-template #contentExcluir let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Excluir Acesso ao Sistema</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Tem certeza que deseja excluir o login
            <b>{{parLogonSend.parLogonEmail}}</b> da parceria <b>{{parLogonSend.parLogonNome}}</b>?
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">Fechar</button>
          <button type="button" class="btn btn-secondary" (click)="excluir()" title="Excluir">Excluir</button>
        </div>
      </ng-template>


      <div [hidden]="!(loading$ | async) ">
        <div class="loading-overlay "></div>
        <div class="loading-info ">
          <i class="fa fa-spinner fa-4x fa-spin "></i>
          <div id="carregando ">Aguarde</div>
        </div>
      </div>
    </div>