<div *ngIf="parceriaInfo != undefined">
    <div *ngIf="valoresLiberados.length==0">
        <span class="mensagem-vazio">Nenhum valor liberado encontrado.</span>
    </div>
    <div *ngIf="valoresLiberados.length>0">
        <table class="table table-striped" style="margin-top:30px" id="tabelaValoresPagos" [mfData]="valoresLiberados" #mf="mfDataTable" [mfRowsOnPage]="5">
            <thead>
                <tr>
                    <th *ngIf="(!isPortal)" data-html2canvas-ignore="true"></th>
                    <th *ngIf="!disableApagarIcone()" data-html2canvas-ignore="true"></th>
                    <th>Data do pagamento</th>
                    <th>Valor pago</th>
                    <th>Ano Empenho</th>
                    <th>Nº empenho</th>
                    <th>Nº parcela liquidação</th>
                    <!-- <th *ngIf="!isPortal">Situação</th> -->
                    <th>Data Última Atualização</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let valor of mf.data; let idx = index">

                    <td *ngIf="!isPortal" class="col-auto" data-html2canvas-ignore="true">
                        <i ngbTooltip="editar" *ngIf="enableEditarIcone()" class="fa fa-pencil" aria-hidden="true" (click)="openEdicao(contentEdicao, valor)"></i>
                    </td>
                    <td *ngIf="!disableApagarIcone()" class="col-auto" data-html2canvas-ignore="true">
                        <i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true" (click)="openExcluir(contentExcluir, valor)"></i>
                    </td>

                    <td class="col-auto">{{valor.libData | date: 'dd/MM/yyyy'}}</td>
                    <td class="col-auto">{{valor.libVlr | currency: 'BRL'}}</td>
                    <td class="col-auto">{{valor.libAnoEmpenho}}</td>
                    <td class="col-auto">{{valor.libNroEmpenho}}</td>
                    <td class="col-auto">{{valor.libParcEmpenho}}</td>
                    <!-- <td *ngIf="!isPortal" class="col-auto">{{valor.libSituacao}}</td> -->
                    <td class="col-auto">{{valor.libDtAtu | date: 'dd/MM/yyyy HH:MM'}}</td>
                </tr>
            </tbody>
            <tfoot data-html2canvas-ignore="true ">
                <tr>
                    <td colspan="12 ">
                        <div *ngIf="valoresLiberados.length>5" class="resultPorPagina">Resultados por página:</div>
                        <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>

    <div *ngIf="!disableCampos()">
        <button (click)="openEdicao(contentEdicao, undefined)" id="button-insert" class="btn btn-primary btn-info" type="button" title="adicionar novo" [hidden]="parceriaInfo.parId===-1">
                                <i class="fa fa-plus" ><span class="texto-botao"> Adicionar novo</span></i>
                            </button>
    </div>

    <ng-template #contentEdicao let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Empenhos Liquidados e Pagos</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
			<span aria-hidden="true">&times;</span>
		</button>
        </div>
        <div [hidden]="!(loading$ | async) ">
            <div class="loading-overlay "></div>
            <div class="loading-info ">
              <i class="fa fa-spinner fa-4x fa-spin "></i>
              <div id="carregando">Aguarde</div>
            </div>
          </div>
        <form (ngSubmit)="salvaEmpenho()" #edicao="ngForm" novalidate>
            <div class="modal-body">
                <div class="form-group col-md-5">
                    <label for="ano">Ano</label>
                    <input style="width:100%;" id="ano" class="form-control" name="ano" #ano="ngModel" type="text" [(ngModel)]="anoEmpenho" [textMask]="mascaraAno">
                    <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="anoEmpenho=undefined" title="limpar"></i>
                </div>
                <div class="form-group col-md-5">
                    <label for="empenho">Empenho</label>
                    <input style="width:100%;" id="empenho" class="form-control" name="empenho" #empenho="ngModel" type="text" [(ngModel)]="numeroEmpenho" [textMask]="mascaraNro">
                    <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="numeroEmpenho=undefined" title="limpar"></i>

                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="raiz">Buscar empenhos c/ mesma raiz do CNPJ</label>
                        <label class="switch">
                            <input type="checkbox" id="raiz" name="raiz" [ngModelOptions]="{standalone: true}" [(ngModel)]="raiz" [value]="raiz" (change)="setRaiz($event)">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <button type="button" class="btn btn-primary btn-info" (click)="buscaEmpenho(anoEmpenho, numeroEmpenho)" title="Buscar"><span class="texto-botao">Buscar</span></button>
                </div>

                <table class="table table-striped" style="margin-top:30px" id="tabelaEmpenhoPago" [mfData]="empenhosPagos" #mf="mfDataTable" [mfRowsOnPage]="5">
                    <thead>
                        <tr>
                            <th *ngIf="!isPortal" data-html2canvas-ignore="true"></th>
                            <th>CNPJ</th>
                            <th>Ano Empenho</th>
                            <th>Nº empenho</th>
                            <th>Nº parcela liquidada</th>
                            <th>Data do pagamento</th>
                            <th>Valor pago</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let emp of mf.data; let idx = index">
                            <td *ngIf="!isPortal" class="col-auto" data-html2canvas-ignore="true" >
                                <input type="checkbox" value="{{idx}}" name="emp{{idx}}"  [(ngModel)]="emp.selecionado" (change)="onSelectEmpenho()"/>
                            </td>
                            <td class="col-auto">{{aplicaMascaraCNPJ(emp.identpessoa)}}</td>
                            <td class="col-auto">{{emp.ano}}</td>
                            <td class="col-auto">{{emp.empenho}}</td>
                            <td class="col-auto">{{emp.nrparcliq}}</td>
                            <td class="col-auto">{{emp.cptpag | date: 'dd/MM/yyyy'}}</td>
                            <td class="col-auto">{{emp.vlpag | currency: 'BRL':true}}</td>
                        </tr>
                    </tbody>
                    <tfoot data-html2canvas-ignore="true ">
                        <tr>
                            <td colspan="12 ">
                                <div *ngIf="empenhosPagos.length>5" class="resultPorPagina">Resultados por página:</div>
                                <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="modal-footer col-md-12">
                <button type="button" class="btn btn-default" (click)="c('Close click');setupInterface()" title="Cancelar">Cancelar</button>
                <button type="submit" class="btn btn-primary" [disabled]="!edicao.form.valid || !onSelectEmpenho()" title="OK">OK</button>
            </div>
        </form>
    </ng-template>

    <ng-template #contentExcluir let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Excluir Valor Pago</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Tem certeza que deseja excluir o valor pago
                <b>{{valorLiberadoSend.libData | date:'dd/MM/yyyy'}}  - {{valorLiberadoSend.libVlr| currency: 'BRL':true}}</b>?</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">Fechar</button>
            <button type="button" class="btn btn-secondary" (click)="excluir()" title="Excluir">Excluir</button>
        </div>
    </ng-template>
</div>