export class ParceriaLogon {
    
      public parLogonId: number;
      public parLogonCnpj: string;
      public parLogonEmail: string;
      public parLogonNome: string;
      public parLogonDtUltAtu: Date;
      public parLogonOperAtu: string;
      public orgao: string;
      public parNum: string;
      public parId: number;
      public status: number;
      public modalidade: string;
  }
