import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class ParMonitoraPrestaExcecaoTrava {

    public parId: number;
    public excecaoTravaId: number;
    public excecaoTravaCompetencia: Date;
    public excecaoTravaCompetenciaFinal: Date;
    public excecaoTravaDataInicio: Date;
    public excecaoTravaDataFim: Date;
    public excecaoTravaJustificativa: string;
    public excecaoTravaOperador: string;
    public excecaoTravaDataOperacao: Date;
    public excecaoTravaDataInicioDataFormat: NgbDateStruct;
    public excecaoTravaDataFimDataFormat: NgbDateStruct;
    public excecaoTravaDataOperacaoDataFormat: NgbDateStruct;
    public excecaoTravaCompetenciaDataFormat: NgbDateStruct;
}