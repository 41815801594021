import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-page-not-authorized',
	templateUrl: 'pageNotAuthorized.component.html'
})
export class PageNotAuthorizedComponent implements OnInit {
	time: number = 5;
	constructor(private router: Router) {}

	ngOnInit() {
		const countdown = () =>
			setTimeout(() => {
				this.time--;
				if (this.time > 0) {
					countdown();
				} else {
					this.router.navigate(['/']);
				}
			}, 1000);
		countdown();
	}
}
