import { NotificacaoItem } from "./notificacaoItem";

export class Notificacao {
    public id: number;
    public parId: number;
    public nome: String;
    public situacaoId: number;
    public situacao: String;
    public situacaoData: Date;
    public situacaoDataStr: String;
    public situacaoUsuario: String;
    public diasPrazo: number;
    public notificacaoItens: Array<NotificacaoItem>;
    public justificaReabertura: String;
    public dataInicio: Date;
    public dataFim: Date;
    public textoPeriodo: String;
}