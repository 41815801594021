import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {
  constructor() { }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

    this.fixWidth(worksheet);
    if (worksheet['!cols'] != null && excelFileName.startsWith('ModeloUpload')){

      const alignmentCenter = { horizontal: "center", vertical: "center", wrapText: true };   
      const fillAlignmentBorder = {
        alignment: alignmentCenter,
      };
      worksheet['C1'].s = fillAlignmentBorder;
      worksheet['D1'].s = fillAlignmentBorder;
      worksheet['E1'].s = fillAlignmentBorder;
      worksheet['F1'].s = fillAlignmentBorder;
    }
    
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);

    }
  private fixWidth(worksheet: XLSX.WorkSheet) {
      const data = XLSX.utils.sheet_to_json<any>(worksheet)
      const colLengths = Object.keys(data[0]).map((k) => k.toString().length)
      for (const d of data) {
        Object.values(d).forEach((element: any, index) => {
          const length = element.toString().length
          if (colLengths[index] < length) {
            colLengths[index] = length
          }
        })
      }
      worksheet["!cols"] = colLengths.map((l) => {
        return {
          wch: l,
          
        }
      })
    }
}
