import { Component, OnInit, Output } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {share} from 'rxjs/operators';
import { UserService } from '../../@core/user.service';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from './../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Mascaras } from 'src/app/@shared/components/Mascaras';
import { BuscaCampos } from './buscaCampos';
import { DateConverter } from 'src/app/@core/date-converter';
import { ExcelService } from 'src/app/@shared/excel-service';
import saveAs from 'file-saver';
import * as Json2csvParser from 'json2csv';
import { DatePipe } from '@angular/common';
import { Utils } from 'src/app/@shared/utils';

@Component({
  selector: 'app-exibe-parceria-usuario',
  templateUrl: './exibeParceriasPortal.component.html',
  styleUrls: ['./exibeParceriasPortal.component.scss']
})

export class ExibeParceriasPortalComponent implements OnInit {

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  private loadingD: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loadingD$ = this.loadingD.asObservable().pipe(share());
  selectedParceriaId: number = null;
  parceriaArray: Array<ParceriaInfo>;
  status: number;

  searchableList: string[];
  countParcerias: number;
  busca: BuscaCampos = new BuscaCampos(0, null, '', '', '', '', 0, 0, 25);
  currentPage: number;
  dataVigenciaInicial: NgbDateStruct;
  collectionSizeParceria: number;
  modalEdicaoReference: any;
  mascaraCNPJ = { mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/], guide: false };
 

  constructor(private alertService: AlertService,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    private dateConverter: DateConverter,
    private modalService: NgbModal,
    private excelService: ExcelService,
    private mascara: Mascaras,
    public utils: Utils
    ) {
      this.searchableList = ['nomeOrgao', 'parOrgNome', 'parOrgCnpj', 'parOrgObjeto', 'parNum', 'parOrgDtIniVigencia', 'status', 'parOrgDtIniVigencia'];
  }

  ngOnInit() {
    this.parceriaArray = [];
    this.getParcerias(this.busca, 1);
    this.currentPage = 1;
  }

  aplicaMascaraDocumento(documento) {
    return this.mascara.aplicaMascaraDocumento(documento, 2);
  }

  onPageChange = (pageNumber) => {
    this.getParceriasPagina(pageNumber);
  }

  getParceriasPagina(pagina) {
    this.getParcerias(this.busca, pagina);
  }
  getParcerias(buscaCampos, pagina) {
    buscaCampos.pagina = pagina;
    buscaCampos.limite = 25;
    buscaCampos.nomeOrg = buscaCampos.nomeOrg.trim();
    buscaCampos.objetoFin = buscaCampos.objetoFin.trim();
    buscaCampos.numeroTermo = buscaCampos.numeroTermo.trim();
    buscaCampos.cnpj = buscaCampos.cnpj.replace(/[^\d]+/g, '');
  this.loading.next(true);
  //console.log(this.userService.userInfo);
  this.detalheParceriaPortalApi.getParceriaInfoPortal(buscaCampos)
  .subscribe(async (response) => {
    if (response) {
      this.parceriaArray = response as Array<ParceriaInfo>;
     // console.log(this.parceriaArray);
      this.parceriaArray.forEach(element => {
        element.status = this.getStatusParceira(element.parOrgStatus);
      });
      await this.getCountParceriaPortal();
    } else {
       this.alertService.warning('Nenhum registro encontrado');
    }
  }, (response) => {
    if (response.status >= 500) {
      this.alertService.danger(response.error);
    } else {
      this.alertService.warning(response.error);
    }
    this.loading.next(false);
  }, () => this.loading.next(false));
}

async getCountParceriaPortal() {
  this.loading.next(true);
  //console.log(this.userService.userInfo);
  this.detalheParceriaPortalApi.getCountParceriaPortal(this.busca)
  .subscribe((response) => {
    if (response) {
      this.countParcerias = response as number;
      this.collectionSizeParceria = Math.ceil((this.countParcerias / 25) * 10);
    } else {
      this.alertService.warning('Nenhum registro encontrado');
      this.countParcerias = 0;
      this.collectionSizeParceria = 0;
   }
 }, (response) => {
   if (response.status >= 500) {
     this.alertService.danger(response.error);
   } else {
     this.alertService.warning(response.error);
   }
   this.loading.next(false);
 }, () => this.loading.next(false));
}
  getStatusParceira(cod) {
    return this.utils.getStatusParceira(cod);
  }

  selectStatusParceria(event) {
    const newVal = event.currentTarget.value;
    this.busca.status = newVal;
    this.getParcerias(this.busca, 1);
  }
  selectOrgaoParceria(event) {
    const newVal = event.currentTarget.value;
    this.busca.orgao = newVal;
    this.getParcerias(this.busca, 1);
  }
  selectNumeroTermo() {
    this.getParcerias(this.busca, 1);
  }
  selectCnpj() {
    this.getParcerias(this.busca, 1);
  }
  selectNomeParceria() {
    this.getParcerias(this.busca, 1);
  }
  selectObjeto() {
    this.getParcerias(this.busca, 1);
  }

  onDateSelect(event) {
    const newVal = event;
    this.busca.dataVigenciaIncial = this.dateConverter.fromNgbDateStructToDate(newVal);
    this.getParcerias(this.busca, 1);
  }

  openExportacao(content, value) {
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false, windowClass: "modalProcesso" });
  }
  export(event, type) {
    //event.stopPropagation();
    event.preventDefault();
    this.exportAs(type);
  }

  async exportAs(type) {
    let nome;
    nome = 'VisaoParcerias_';
    this.loadingD.next(true);
    this.busca.limite = this.countParcerias;
    this.detalheParceriaPortalApi.getParceriaInfoPortal(this.busca).subscribe( (response) => {
      this.parceriaArray = response as Array<ParceriaInfo>;
       //console.log(this.parceriaArray);
       this.parceriaArray.forEach(element => {
         element.status = this.getStatusParceira(element.parOrgStatus);
         element.parOrgCnpj = this.mascara.aplicaMascaraDocumento(element.parOrgCnpj.toString(), 2);
         // adaptação técnica para guardar valor string em campo não exibido na tela, a fim de evitar construir um novo objeto
          const datePipe = new DatePipe('en-US');
          element.execResponsavel = datePipe.transform(element.parOrgDtIniVigencia, 'dd/MM/yyyy');
          element.execEmail = datePipe.transform(element.parOrgDtFimVigencia, 'dd/MM/yyyy');
        });

        if (type === 'xlsx') {
              const newJson = this.parceriaArray.map(rec => {
                  return {
                    'Órgão': rec.nomeOrgao,
                    'Nome': rec.parOrgNome,
                    'CNPJ': rec.parOrgCnpj,
                    'Objeto/Finalidade': rec.parOrgObjeto,
                    'Nº Aten.l': rec.parOrgNmetasAtend,
                    'Nº Termo': rec.parNum,
                    'Data Inicial': rec.execResponsavel,
                    'Data Final': rec.execEmail,
                    'Status Atual': rec.status,
                    'Valor Total': rec.execTotIngressos,
                    'Planejamento Seleção': rec.planejSel,
                    'Execução': rec.execucao,
                    'Monitoramento Avaliação': rec.monitAval,
                    'Prestação Contas Parcial': rec.prestContasPar,
                    'Prestação Contas Final': rec.prestContasTot
                  }
                });
              this.excelService.exportAsExcelFile(newJson, nome);
        } else if (type === 'csv') {
              const fields = [{
                  label: 'Órgão',
                  value: 'nomeOrgao'
                }, {
                  label: 'Nome',
                  value: 'parOrgNome'
                }, {
                  label: 'CNPJ',
                  value: 'parOrgCnpj'
                }, {
                  label: 'Objeto/Finalidade',
                  value: 'parOrgObjeto'
                }, {
                  label: 'Nº Aten.',
                  value: 'parOrgNmetasAtend'
                },
                {
                  label: 'Nº Termo',
                  value: 'parNum'
                }, {
                  label: 'Data Inicial',
                  value: 'execResponsavel'
                }, {
                  label: 'Data Final',
                  value: 'execEmail'
                }, {
                  label: 'Status Atual',
                  value: 'status'
                }, {
                  label: 'Valor Total',
                  value: 'execTotIngressos'
                }, {
                  label: 'Planejamento Seleção',
                  value: 'planejSel'
                },  {
                  label: 'Execução',
                  value: 'execucao'
                },  {
                  label: 'Monitoramento Avaliação',
                  value: 'monitAval'
                }, {
                  label: 'Prestação Contas Parcial',
                  value: 'prestContasPar'
                }, {
                  label: 'Prestação Contas Final',
                  value: 'prestContasTot'
                }];
              const json2csvParser = new Json2csvParser.Parser({ fields });
              const csv = json2csvParser.parse(response as ParceriaInfo[]);
              const blob = new Blob([csv], { type: 'text/csv' });
              saveAs(blob, nome + '.csv');
            }
            this.loadingD.next(false);
            this.modalEdicaoReference.close();
    }, (response) => {
        if (response.status >= 500) {
            this.alertService.danger(response.error);
        } else {
            this.alertService.warning(response.error);
        }
        this.loadingD.next(false);
        this.modalEdicaoReference.close();
    }, () => this.loadingD.next(false));
  }
}
