import { Component, OnInit, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UserService } from '../../@core/user.service';
import { ParceriaInfo } from '../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { DateConverter } from 'src/app/@core/date-converter';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detalhe-m1-parceria',
  templateUrl: './detalheParceriaM1.component.html',
  styleUrls: ['./../../modulo2/detalheParceria/detalheParceria.component.scss']
})

export class DetalheParceriaM1Component implements OnInit{

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  @Input()
  parceriaInfo: ParceriaInfo;
  @Input()
  isPortal: boolean;
  parId: number;
  tipoPublicaPortal: Number;
  modalEdicaoReference: any;
  @Input()
  modulo: string;

  @Output()
  isAuditorExterno: boolean;

  constructor(private route: ActivatedRoute,
    private detalheParceriaApi: DetalheParceriaApi,
    private dateConverter: DateConverter,
    public userService: UserService,
    config: NgbAccordionConfig
    ) {
        config.closeOthers = true;
  }

  ngOnInit() {
    this.modulo = 'm1';
    if (this.isPortal === undefined) {
      this.isPortal = false;
    }
    this.setupInterface();
  }

  recebeParId(parId) {
    console.log('chegou aqui no pai: ', parId.id);
    this.parceriaInfo.parId = parId.id;
  }

  setupInterface() {
    if (!this.isPortal) {
      this.loading.next(true);
      
      this.route.queryParams.subscribe(params => {
        this.isAuditorExterno = params['isAuditorExterno'] === undefined || params['isAuditorExterno'].toLowerCase() === 'false' ? false : true;
        this.parId = +params['id'];
      });

      if (this.parId === -1) {
        this.parceriaInfo = new ParceriaInfo();
        this.parceriaInfo.parId = -1;
        this.parceriaInfo.parVlrPrevisto = 0;
      } 
      else if (this.parId !== undefined) {
        this.route.paramMap.pipe(
          switchMap((params: ParamMap) =>
            this.detalheParceriaApi.getParceriaInfo(this.parId))
        ).subscribe(async d => {
          this.parceriaInfo = d;
          // console.log(this.parceriaInfo);
          this.parceriaInfo.parOrgDtIniVigenciaFormat = this.dateConverter.fromTimestampToNgbDateStruct(
            this.dateConverter.fromDateToTimestamp(this.parceriaInfo.parOrgDtIniVigencia));
          if (this.parceriaInfo.parOrgDtFimVigencia != null){
              this.parceriaInfo.parOrgDtFimVigenciaFormat = this.dateConverter.fromTimestampToNgbDateStruct(
              this.dateConverter.fromDateToTimestamp(this.parceriaInfo.parOrgDtFimVigencia));
          }
          this.loading.next(false);
        });
      }
    }
  }
}

