<footer class=" ">
    <div class="endereco-rodape">
        <div class="container">
            <img alt="" class="brasao" data-entity-type="" data-entity-uuid="" onerror="this.src='assets/img/brasao-cinza.svg';this.onerror=null;" src="/themes/pmpa/images/brasao-cinza.svg">
            <p><b>Prefeitura Municipal de Porto Alegre</b>
            <br> Secretaria de Transparência e Controladoria | Coord. Geral de Transparência Pública
            <br> Rua Siqueira Campos,1300. 10° andar, sala 1055.
            </p>
        </div>

        
    </div>
    <div class="footer-background">
         <div class="container-fluid">
            <a class="logo_procempa" href="http://www.procempa.com.br/" target="_blank" title="Procempa">
                <img src="assets/img/desenvolvimento_procempa.png" alt="Procempa">
            </a>
         </div>
    </div>
    

</footer>
