import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidatorFn, Validator, FormControl } from '@angular/forms';
import { inputZeroFactory } from './validaInputZero.validator';


@Directive({
  selector: '[validaInputZero][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: InputZeroValidator, multi: true }
  ]
})
export class InputZeroValidator implements Validator {
  validator: ValidatorFn;

  constructor() {
    this.validator = inputZeroFactory();
  }
 
  validate(c: FormControl) {
    return this.validator(c);
  }
  
}
