import { Component, OnInit, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { Mascaras } from '../../@shared/components/Mascaras';
import 'jspdf-autotable';
import { ParceriaValoresLiberados } from '../../modulo1/valoresPagos/parceriaValoresLiberados';
import { PlanejamentoEditais } from '../../modulo1/editais/planejamentoEditais';
import { PlanejamentoTermos } from '../../modulo1/termos/planejamentoTermos';
import { PlanejamentoPlanoTrabalho } from '../../modulo1/planoDeTrabalho/planejamentoPlanoTrabalho';
import { ParceriaPrestaContaParcial } from '../../modulo2/prestacaoDeContasParcial/parceriaPrestaContaParcial';
import { ParceriaMonitoraAvalia } from '../../modulo3/monitoramentoAvalicao/parceriaMonitoraAvalia';
import { ParceriaPrestaContaFinal } from '../../modulo3/prestacaoDeContasFinal/parceriaPrestaContaFinal';
import { ParceriaInfo } from './../../modulo2/detalheParceria/parceriaInfo';

@Component({
  selector: 'app-detalhe-parceria-portal',
  templateUrl: './detalheParceriaPortal.component.html',
  styleUrls: ['./detalheParceriaPortal.component.scss']
})
export class DetalheParceriaPortalComponent implements OnInit {

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  public parceriaInfo: ParceriaInfo;
  public isPortal: boolean;
  private parId: number;
  valoresLiberados: Array<ParceriaValoresLiberados> = [];
  planEditais: Array<PlanejamentoEditais> = [];
  planTermos: Array<PlanejamentoTermos> = [];
  planoPlanTrabalho: Array<PlanejamentoPlanoTrabalho> = [];
  prestaContaParcial: Array<ParceriaPrestaContaParcial> = [];
  prestaContaFinal: Array<ParceriaPrestaContaFinal> = [];
  parceriaMonitoraAvalia: Array<ParceriaMonitoraAvalia> = [];

  afuConfig2 = {
    hideProgressBar: true,
    hideResetBtn: true,
    maxSize: '1',
    uploadAPI: {
      url: 'https://slack.com/api/files.upload'
    },
    formatsAllowed: '.jpg,.png, .gif, .pdf',
    multiple: true
  };


  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    private mascaras: Mascaras) {
  }

  ngOnInit() {
    this.isPortal = true;
    this.setupInterface();

    // TODO: ver o pq está retornando antes de buscar o dado de parceriaInfo.
 }

  setupInterface() {
    this.loading.next(true);
    this.route.params.subscribe(params => {
      this.parId = +params['id'];
      console.log('detalheParceriaPortal' + this.parId);
    });

    if (this.parId !== undefined) {
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          this.detalheParceriaPortalApi.getParceriaInfo(this.parId))
      ).subscribe(async d => {
        this.parceriaInfo = d;
        this.loading.next(false);
      });
    }
  }

}

