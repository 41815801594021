import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from '../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../../apis/detalheParceriaApi';
import { UserService } from '../../@core/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from 'src/app/@core/date-converter';
import { ParMonitoraPrestaExcecaoTrava } from './monitoraPrestaExcecaoTrava';
import { NgbDatepickerNavigateEvent } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker';
import { DatePipe } from '@angular/common';
import { Utils } from 'src/app/@shared/utils';

@Component({
  selector: 'app-monitora-presta-excecao-trava',
  templateUrl: './../monitoraPrestaExcecaoTrava/monitoraPrestaExcecaoTrava.component.html',
  styleUrls: ['./../../modulo2/detalheParceria/detalheParceria.component.scss']
})
export class MonitoraPrestaExcecaoTravaComponent implements OnInit {

  @Input()
  private parceriaInfo: ParceriaInfo;
  parMonitoraPrestaExcecaoTrava: Array<ParMonitoraPrestaExcecaoTrava> = [];
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  parMonitoraPrestaExcecaoTravaSend: ParMonitoraPrestaExcecaoTrava;
  modalEdicaoReference: any;
  competMask = { mask: [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], guide: false };
  hasTrava: boolean;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;

  parId: number;
  years = ['2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025',
    '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035', '2036', '2037', '2038', '2039', '2040'];
  months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  currentYear = new Date().getFullYear();
  yearFimFromSelector: string;
  monthFimSelection: string;
  yearIniFromSelector: string;
  monthIniSelection: string;
  monthIniIndex: number;
  monthFimIndex: number;
  public isPortal: boolean;
  username: string;

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    public userService: UserService,
    private modalService: NgbModal,
    private router: Router,
    private dateConverter: DateConverter,
    private utils: Utils) {

  }

  ngOnInit() {
    this.userService.userInfo.subscribe(user => this.username = user.username);
    this.setupInterface();
  }

  setupInterface() {


    this.hasTrava = this.userService.hasTrava();
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();

    if (!this.isFiscal && !this.isConsulta &&  !this.hasTrava && !this.isSMTC ) {
      this.router.navigate(['pageNotAuthorized']);
    }

    this.loading.next(true);
    if (this.parceriaInfo === undefined || this.parceriaInfo.parId === undefined) {
      this.route.params.subscribe(params => {
        this.parId = +params['id'];
        // console.log('detalheParceria' + this.parId);
      });
    }
    else {
      this.parId = this.parceriaInfo.parId;
    }

    this.route.paramMap.pipe(
      switchMap(() =>
        this.detalheParceriaApi.getMonitoraPrestaExcecaoTrava(this.parId))
    ).subscribe((response) => {
      if (response) {
        this.parMonitoraPrestaExcecaoTrava = response as Array<ParMonitoraPrestaExcecaoTrava>;
        console.log(this.parMonitoraPrestaExcecaoTrava);
      } else {
        this.alertService.warning('Nenhum registro encontrado');
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }

  setMonitoraPrestaExcecaoTrava(doc) {
    if (doc) {
      this.parMonitoraPrestaExcecaoTravaSend = doc;

      // tslint:disable-next-line:max-line-length
      console.log(new Date(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaCompetenciaFinal).getFullYear());
      // tslint:disable-next-line:max-line-length
      let index = this.years.indexOf(new Date(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaCompetenciaFinal).getFullYear().toString());
      this.yearFimFromSelector = this.years[index];
      if (this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaCompetenciaFinal !== null) {
      this.monthFimSelection = this.months[new Date(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaCompetenciaFinal).getUTCMonth()];
      // tslint:disable-next-line:max-line-length
      this.monthFimIndex = new Date(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaCompetenciaFinal).getUTCMonth();
      }

      index = this.years.indexOf(new Date(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaCompetencia).getFullYear().toString());
      this.yearIniFromSelector = this.years[index];
      this.monthIniSelection =  this.months[new Date(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaCompetencia).getUTCMonth()];
      // tslint:disable-next-line:max-line-length
      this.monthIniIndex = new Date(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaCompetencia).getUTCMonth();
      this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataInicioDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataInicio));
        // this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaCompetenciaData = this.dateConverter.fromTimestampToNgbDateStruct(
        //   this.dateConverter.fromDateToTimestamp(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataInicio));
        // tslint:disable-next-line:max-line-length

      this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataFimDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataFim));

      this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataOperacaoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataOperacao));
    } else {
      this.parMonitoraPrestaExcecaoTravaSend = new ParMonitoraPrestaExcecaoTrava();
      this.parMonitoraPrestaExcecaoTravaSend.parId = this.parceriaInfo.parId;
      this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaId = -1;
    }

  }

  updateTextArea(valor) {
    this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaJustificativa = valor;
  }
  setAno(event, tipo) {
     if (tipo === 'I'){
       this.yearIniFromSelector = event.target.value;
     }
     if (tipo === 'F'){
      this.yearFimFromSelector = event.target.value;
    }
  }
  setMes(event, tipo) {
    if (tipo === 'I'){
      this.monthIniIndex = event.target.selectedIndex;
    }
    if (tipo === 'F'){
     this.monthFimIndex = event.target.selectedIndex;
   }
 }

  salvaMonitoraPrestaExcecaoTrava() {

    // tslint:disable-next-line:max-line-length
    this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataInicio = this.dateConverter.fromNgbDateStructToDate(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataInicioDataFormat);
    if (!this.utils.isValidDate(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataInicio )){
      this.alertService.danger('Data Início Inválida.');
      return;
    }
    // tslint:disable-next-line:max-line-length
    this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataFim = this.dateConverter.fromNgbDateStructToDate(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataFimDataFormat);
    if (!this.utils.isValidDate(this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataFim )){
      this.alertService.danger('Data Fim Inválida.');
      return;
    }

    if (this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataFim < this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataInicio) {
      this.alertService.danger('Data de fim deve ser igual ou posterior à data de início.');
      return;
    }

    // tslint:disable-next-line:max-line-length
    this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataOperacao = new Date();
    this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaOperador = this.username;

    if (this.yearIniFromSelector === undefined || this.monthIniIndex === undefined ||
      this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataInicio === undefined ||
      this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaDataFim === undefined) {
      this.alertService.danger('Campos obrigatórios não preenchidos. Verifique todas as datas e competências.');
      return;
    }

    this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaCompetencia = new Date(Number(this.yearIniFromSelector), this.monthIniIndex, 1);
    if ( this.yearFimFromSelector !== undefined && this.monthFimIndex !== undefined ) {


      // tslint:disable-next-line:max-line-length
      const numberOfMonths = ((Number(this.yearFimFromSelector)) - Number(this.yearIniFromSelector)) * 12 + (this.monthFimIndex - this.monthIniIndex);
      if (numberOfMonths < 0) {
        this.alertService.danger('Competência final deve ser superior a inicial.');
      return;
      }
      this.parMonitoraPrestaExcecaoTravaSend.excecaoTravaCompetenciaFinal = new Date(Number(this.yearFimFromSelector), this.monthFimIndex, 1);
    }

    const data = JSON.stringify(this.parMonitoraPrestaExcecaoTravaSend, this.replaceMonitoraPrestaExcecao);
    this.detalheParceriaApi.updateMonitoraPrestaExcecaoTrava(data).subscribe(async (response) => {
      if (response) {
        this.setupInterface();
        this.setMonitoraPrestaExcecaoTrava(undefined);
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }

  excluirMonitoraPrestaExcecaoTrava() {
    this.detalheParceriaApi.excluirMonitoraPrestaExcecaoTrava(this.parMonitoraPrestaExcecaoTravaSend).subscribe((response) => {
      if (response) {
        this.setupInterface();
        this.setMonitoraPrestaExcecaoTrava(undefined);
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }

  replaceMonitoraPrestaExcecao(key, value) {
    // atributos que só existem no view e não no servidor
    const ignoreList = ['excecaoTravaCompetenciaData', 'excecaoTravaDataInicioDataFormat', 'excecaoTravaDataFimDataFormat',
      'excecaoTravaDataOperacaoDataFormat', 'excecaoTravaCompetenciaFinalData'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  openEdicao(content, doc) {
    this.setMonitoraPrestaExcecaoTrava(doc);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false, windowClass: "modalProcesso" });
  }

  openExcluir(content, doc) {
    this.setMonitoraPrestaExcecaoTrava(doc);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false });
  }

  dateNavigate($event: NgbDatepickerNavigateEvent) {
    console.log($event.next.month);
    console.log($event.next.year);
    // ...
    // old value is contained in $event.current
  }

}

