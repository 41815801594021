import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { UserService } from '../../../@core/user.service';
import { AlertService } from '../../../@core/alert.service';
import { ParceriaInfo } from '../../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaPortalApi } from '../../../apis/detalheParceriaPortalApi';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Mascaras } from 'src/app/@shared/components/Mascaras';
import { BuscaCampos } from './buscaCampos';
import { DateConverter } from 'src/app/@core/date-converter';
import { AuthUser, KeycloakService } from '@procempa/ngx-keycloak';
import { ParceriaLogon } from '../../exibeParceriaPorUsuario/parceriaLogon';
import { LogonApi } from 'src/app/apis/logonApi';
import { Utils } from 'src/app/@shared/utils';

@Component({
  selector: 'app-exibe-entidades',
  templateUrl: './exibeEntidades.component.html',
  styleUrls: ['./exibeEntidades.component.scss']
})

export class ExibeEntidadesComponent implements OnInit {

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  selectedParceriaId: number = null;
  parceriaArray: Array<ParceriaInfo>;
  status: number;
  countParcerias: number;
  busca: BuscaCampos = new BuscaCampos(0, null, null, '', '', '', '', true, 0, 0, 25);
  currentPage: number;
  dataVigenciaInicial: NgbDateStruct;
  collectionSizeParceria: number;
  @Input()
  isPortal: boolean;
  @Input()
  modulo: string;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  parceriaLogonArray: Array<ParceriaLogon>;
  private notLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public notLoading$ = this.notLoading.asObservable().pipe(share());
  private temPermissao: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public temPermissao$ = this.temPermissao.asObservable().pipe(share());
  private temPermissaoPMPA: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public temPermissaoPMPA$ = this.temPermissaoPMPA.asObservable().pipe(share());

  constructor(private alertService: AlertService,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    private dateConverter: DateConverter,
    private mascara: Mascaras,
    private logonApi: LogonApi,
    public keycloakService: KeycloakService,
    private router: Router,
    private utils: Utils
  ) {
  }

  ngOnInit() {

    this.modulo = 'm1';
    if (this.isPortal === undefined) {
      this.isPortal = false;
    }
    this.parceriaArray = [];
    this.getParcerias(this.busca, 1);
    this.currentPage = 1;
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();

    // pegar qualificador
    this.keycloakService.getUser().subscribe((user: AuthUser) => {

      //console.log(user.attributes.sgp_orgao[0]);
      if ((this.isFiscal || this.isConsulta) && user.attributes.sgp_orgao !== null) {
        // tslint:disable-next-line:no-unused-expression
        if (this.userService.isSMTC()) {
          this.isSMTC = true;
          this.busca = new BuscaCampos(0, null, null, '', '', '', '', true, 0, 0, 25);
        } else {
          this.busca = new BuscaCampos(Number(user.attributes.sgp_orgao[0]), null, null, '', '', '', '', true, 0, 0, 25);
        }
      }
     // this.getParcerias(this.busca, 1);
     this.getParceriasPorLogon();
      this.currentPage = 1;
    });

  }

  getParceriasPorLogon() {
    this.loading.next(true);
      //console.log(this.userService.userInfo);
      this.logonApi.getParceriaLogon(this.keycloakService['_keycloakInstance'].tokenParsed.email)
    .subscribe((response) => {
      if (response) {
        this.parceriaLogonArray = response as Array<ParceriaLogon>;
        //console.log(this.parceriaLogonArray);
        this.temPermissao.next(false);
        if (this.parceriaLogonArray.length === 1) {
          this.router.navigate(['/beneficiario/cadastroBeneficiarios/:id', this.parceriaLogonArray[0].parId]);
        }
        this.notLoading.next(false);
       }
  
      this.loading.next(false);
    
    }, (response) => {
      this.temPermissao.next(true);
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.notLoading.next(true);
  });
  }

  disableCamposTable() {
    return (this.isFiscal || this.isSMTC || this.isPortal);
  }

  aplicaMascaraDocumento(documento) {
    return this.mascara.aplicaMascaraDocumento(documento, 2);
  }

  onPageChange = (pageNumber) => {
    this.getParceriasPagina(pageNumber);
  }

  getParceriasPagina(pagina) {
    this.getParcerias(this.busca, pagina);
  }
  getParcerias(buscaCampos, pagina) {
    buscaCampos.pagina = pagina;
    buscaCampos.limite = 25;
    this.loading.next(true);
    //console.log(this.userService.userInfo);
    this.detalheParceriaPortalApi.getParceriaInfoPortal(buscaCampos)
      .subscribe(async (response) => {
        if (response) {
          this.parceriaArray = response as Array<ParceriaInfo>;
         // console.log(this.parceriaArray);
          this.parceriaArray.forEach(element => {
            element.status = this.getStatusParceira(element.parOrgStatus);
          });
          await this.getCountParceriaPortal();
        } else {
          this.alertService.warning('Nenhum registro encontrado');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  async getCountParceriaPortal() {
    this.loading.next(true);
    //console.log(this.userService.userInfo);
    this.detalheParceriaPortalApi.getCountParceriaPortal(this.busca)
      .subscribe((response) => {
        if (response) {
          this.countParcerias = response as number;
          console.log("coParceria " + this.countParcerias);
          this.collectionSizeParceria = Math.ceil((this.countParcerias / 25) * 10);
          console.log("collectionSizeParceria " + this.collectionSizeParceria);
        } else {
          this.alertService.warning('Nenhum registro encontrado');
          this.countParcerias = 0;
          this.collectionSizeParceria = 0;
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }
  getStatusParceira(cod) {
    return this.utils.getStatusParceira(cod);
  }

  selectStatusParceria(event) {
    const newVal = event.currentTarget.value;
    this.busca.status = newVal;
    this.getParcerias(this.busca, 1);
  }
  selectOrgaoParceria(event) {
    const newVal = event.currentTarget.value;
    this.busca.orgao = newVal;
    this.getParcerias(this.busca, 1);
  }
  selectNumeroTermo() {
    this.getParcerias(this.busca, 1);
  }
  selectNomeParceria() {
    this.getParcerias(this.busca, 1);
  }
  selectObjeto() {
    this.getParcerias(this.busca, 1);
  }
  selectTermosAtivos() {
    this.getParcerias(this.busca, 1);
  }
  onDateSelect(event, tipo) {
    if (tipo === 'inicial') {
      this.busca.dataVigenciaIncial = this.dateConverter.fromNgbDateStructToDate(event);
    }
    else if (tipo === 'final') {
      this.busca.dataVigenciaIncialPeriodoFinal = this.dateConverter.fromNgbDateStructToDate(event);
    }
    this.getParcerias(this.busca, 1);
    //
  }
}
