<div style="margin-top: 15px;">
    <button (click)="openExportacao(contentExportacaoFluxo, undefined) " id="button-exṕort" class="btn btn-primary btn-info" type="button " title="Download">
		<i class="fa fa-download" style="color: white"><span class="texto-botao">Download</span> </i>
	</button>
</div>
<table id="fluxo" class="row" style="margin-top: 25px;">

    <div class="col-md-4">I - Total dos Ingressos</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; left: 95px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="azul"><b>{{aplicaMascaraReais(parceriaInfo.parOrgVlTotal)}}</b></div>
        </div>
    </div>

    <div class="col-md-4">II - Total dos Desembolsos</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; left: 95px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="vermelho"><b>{{aplicaMascaraReais(parceriaInfo.execTotalValorDoc)}}</b></div>
        </div>
    </div>

    <div class="col-md-4" style="margin-left: 25px;"> Consumo</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; right: 50px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="vermelho"><b>{{aplicaMascaraReais(parceriaInfo.execTotVlrConsumo)}}</b></div>
        </div>
    </div>

    <div class="col-md-4" style="margin-left: 25px;"> Pagamento de pessoal</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; right: 50px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="vermelho"><b>{{aplicaMascaraReais(parceriaInfo.execTotVlrPF)}}</b></div>
        </div>
    </div>

    <div class="col-md-4" style="margin-left: 25px;"> Serviços de Terceiros</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; right: 50px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="vermelho"><b>{{aplicaMascaraReais(parceriaInfo.execTotVlrPJ)}}</b></div>
        </div>
    </div>

    <div class="col-md-4" style="margin-left: 25px;"> Permanente</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; right: 50px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="vermelho"><b>{{aplicaMascaraReais(parceriaInfo.execTotVlrPerm)}}</b></div>
        </div>
    </div>

    <div class="col-md-4" style="margin-left: 25px;"> Outros</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; right: 50px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="vermelho"><b>{{aplicaMascaraReais(parceriaInfo.execTotVlrOutros)}}</b></div>
        </div>
    </div>
    <div class="col-md-4">III - Devolução para C/C parceria</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; left: 95px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="azul"><b>{{aplicaMascaraReais(parceriaInfo.execDevolParceria)}}</b></div>
        </div>
    </div>

    <!-- <div class="col-md-4">III - Devolução de Recursos</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; left: 95px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="vermelho"><b>{{aplicaMascaraReais(parceriaInfo.execDevolRecursos)}}</b></div>
        </div>
    </div> -->

    <div class="col-md-4">IV - Ganho/Aplicação e Poupança</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; left: 95px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="azul"><b>{{aplicaMascaraReais(parceriaInfo.execDevolAplic)}}</b></div>
        </div>
    </div>

    <div class="col-md-4">V - Rendimentos de conta poupança/provisão</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; left: 95px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="azul"><b>{{aplicaMascaraReais(parceriaInfo.execDevolPoupanca)}}</b></div>
        </div>
    </div>

    <div class="col-md-4">VI - Rendimentos de outras contas/aplicações financeiras</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; left: 95px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="azul"><b>{{aplicaMascaraReais(parceriaInfo.execDevolOutrasContas)}}</b></div>
        </div>
    </div>

    <!-- <div class="col-md-4">V - Devolução para C/C parceria</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; left: 95px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="azul"><b>{{aplicaMascaraReais(parceriaInfo.execDevolParceria)}}</b></div>
        </div>
    </div> -->

    <div class="col-md-4">(=) Saldo Atual (I - II + III + IV + V + VI)</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; left: 95px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="verde"><b>{{aplicaMascaraReais(parceriaInfo.execSaldoFinal)}}</b></div>
        </div>
    </div>

    <div class="col-md-4">VII - Devolução de Recursos</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; left: 95px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="vermelho"><b>{{aplicaMascaraReais(parceriaInfo.execDevolRecursos)}}</b></div>
        </div>
    </div>

    <div class="col-md-4">(=) Saldo Final (remanescente)</div>
    <div class="col-md-7">
        <div class="row" style=" position: relative; left: 95px;">
            <div style="color:#818485">R$&ensp;</div>
            <div class="verde"><b>{{aplicaMascaraReais(parceriaInfo.execSaldoFinal - parceriaInfo.execDevolRecursos)}}</b></div>
        </div>
    </div>
</table>
<!--<ng-template #contentExportacaoFluxo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Exportação do Resumo do Fluxo Financeiro da Entidade </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="fechar">
			<span aria-hidden="true">&times;</span>
		</button>
    </div>
    <div class="modal-body">
        <label for="data">Selecione o tipo de arquivo:&ensp;</label>
        <br>
        <br>
        <div class="row">
            <div class="col">
                <a class="link" (click)="generatePDF('fluxo')" title="Exportar como PDF">&ensp;<i class="fa fa-file-pdf-o fa-lg"></i> Baixar arquivo PDF</a>
            </div>
            <div class="col">
                <a class="link" style="margin-left: 10px" (click)="exportAs('xlsx', 'fluxo')" title="Exportar como Excel"><i class="fa fa-file-excel-o fa-lg"></i> Baixar arquivo Excel</a>
            </div>
            <div class="col">
                <a class="link" style="margin-left: 9px; padding: 0%" (click)="exportAs('csv', 'fluxo')" title="Exportar como CSV"><img src="assets/img/file-csv-solid.svg"> Baixar arquivo csv</a>
            </div>
        </div>
        <br>
        <br>
    </div>
</ng-template> -->
<ng-template #contentExportacaoFluxo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Exportação do Resumo do Fluxo Financeiro da Entidade </h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="fechar">
                                <span aria-hidden="true">&times;</span>
                            </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-7">
                <label for="">Selecione o período:</label>
                <div class="form-inline">
                    <div class="form-group">
                        <input class="form-control" 
                        placeholder="dd/mm/aaaa" maxlength="10" size="14" name="dataIni" 
                        [(ngModel)]="dataIni" appMaskInput [appMaskValue]="dataIni" ngbDatepicker #dI="ngbDatepicker">
                        <i class="fa fa-calendar icon_input_date" (click)="dI.toggle()" title="calendário"></i>
                    </div>
                    <div style="width:40px;">
                        até
                    </div>
                    <div class="form-group">
                        <input class="form-control" placeholder="dd/mm/aaaa" maxlength="10" size="14" name="dataFim" appMaskInput [appMaskValue]="dataFim" [(ngModel)]="dataFim"
                         ngbDatepicker #dF="ngbDatepicker">
                        <i class="fa fa-calendar icon_input_date" (click)="dF.toggle()" title="calendário"></i>
                    </div>
                </div>
            </div>
            <div class="col-md-7">
                <div class="texto-linha-select-resultados"> Tipo Data: &ensp;&ensp;</div>
                    <div class="select-wrapper">
                        <select [ngModel]="tipo" class="form-control form-select custom-select" (change)="populaTipo($event)">
                            <option value="0">Data de emissão</option>
                            <option value="1">Data de pagamento</option>
                        </select>
                    </div>
                </div>
            
            <div class="col-md-5">
                <div class="linksDownload">
                    <div>
                        <a class="link" (click)="generatePDF(dataIni, dataFim, tipo)" title="Baixar arquivo PDF"><i class="fa fa-file-pdf-o fa-lg"></i> Baixar arquivo PDF</a>
                    </div>
                    <div>
                        <a class="link" (click)="exportAs('xlsx',dataIni, dataFim, tipo)" title="Baixar arquivo Excel"><i class="fa fa-file-excel-o fa-lg"></i> Baixar arquivo Excel</a>
                    </div>
                    <div>
                        <a class="link" (click)="exportAs('csv', dataIni, dataFim, tipo)" title="Baixar arquivo CSV"><img src="assets/img/file-csv-solid.svg"> Baixar arquivo csv</a>
                    </div>
                </div>
                <br>
                <br>
            </div>
        </div>
    </div>
</ng-template>