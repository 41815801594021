import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { UserService } from '../../@core/user.service';
import { AlertService } from '../../@core/alert.service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Mascaras } from 'src/app/@shared/components/Mascaras';

import { AuthUser, KeycloakService } from '@procempa/ngx-keycloak';
import { Utils } from 'src/app/@shared/utils';
import { LogonApi } from 'src/app/apis/logonApi';
import { AuditorLogon } from './auditorLogon';
import { PaginationInstance } from 'ngx-pagination';
import { DetalheParceriaPortalApi } from 'src/app/apis/detalheParceriaPortalApi';
import { Orgao } from 'src/app/modulo1/Orgao';
import { DateConverter } from 'src/app/@core/date-converter';
import { ParceriaInfo } from 'src/app/modulo2/detalheParceria/parceriaInfo';
import { FiltroAuditorLogon } from './filtroAuditorLogon';

@Component({
    selector: 'app-auditor-logon',
    templateUrl: './auditorLogon.component.html',
    styleUrls: ['../../modulo1/exibeParcerias/exibeParceriasM1.component.scss']
})
export class AuditorLogonComponent implements OnInit {
    mascaraCPF = { mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/], guide: false };
    mascaraCNPJ = { mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/], guide: false };
    mascaraData = { mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], guide: false };

    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable().pipe(share());
    selectedAuditorId: number = null;
    auditorArray: Array<AuditorLogon>;
    status: number;
    countParcerias: number;
    currentPage: number;
    dataVigenciaInicial: NgbDateStruct;
    collectionSizeParceria: number;
    @Input()
    isPortal: boolean;
    @Input()
    modulo: string;
    isFiscal: boolean;
    isSMTC: boolean;
    isConsulta: boolean;
    auditorLogonSend: AuditorLogon;
    modalEdicaoReference: any;
    inValidCnpj: boolean = false;
    inValidCpf: boolean = false;
    orgaoList: Array<Orgao>;
    filtro: FiltroAuditorLogon;
    username: string;
    public config: PaginationInstance = {
        id: 'custom',
        itemsPerPage: 20,
        currentPage: 1
    };

    constructor(private alertService: AlertService,
        private logonApi: LogonApi,
        public userService: UserService,
        private mascara: Mascaras,
        public keycloakService: KeycloakService,
        public utils: Utils,
        private modalService: NgbModal,
        private detalheParceriaPortalApi: DetalheParceriaPortalApi,
        private dateConverter: DateConverter,
    ) {
    }

    ngOnInit() {
        this.userService.userInfo.subscribe(user => {
            this.username = user.username
        });
        this.modulo = 'logon';
        this.inicializaFiltro();
        this.getOrgaoLista();
        if (this.isPortal === undefined) {
            this.isPortal = false;
        }
        this.auditorArray = [];
        //this.getParcerias(this.busca, 1);
        this.currentPage = 1;
        this.isFiscal = this.userService.isFiscal();
        this.isSMTC = this.userService.isSMTC();
        this.isConsulta = this.userService.isConsulta();

        // pegar qualificador
        this.keycloakService.getUser().subscribe((user: AuthUser) => {
            if ((this.isFiscal || this.isConsulta) && user.attributes.sgp_orgao !== null) {
                // tslint:disable-next-line:no-unused-expression
                if (this.userService.isSMTC()) {
                    this.isSMTC = true;
                }
            }
            //this.inicializaFiltro();
            this.getAuditores(this.filtro);
            this.currentPage = 1;
        });
    }

    inicializaFiltro() {
        if (this.filtro === undefined) {
            this.filtro = new FiltroAuditorLogon();
            this.filtro.idOrgao = 0;
            this.filtro.nomeOsc = '';
            this.filtro.parNum = '';
            this.filtro.cnpjOsc = '';
            this.filtro.cpfAuditor = '';
            this.filtro.nomeAuditor = '';
            this.filtro.emailAuditor = '';
        }
    }

    getAuditores(filtro) {
        this.loading.next(true);
        this.logonApi.getAuditoresLogon(filtro).subscribe(async (response) => {
            if (response) {
                this.auditorArray = response;
                this.auditorArray.forEach(element => {
                    element.orgaoNome = this.utils.getSecretariaStr(element.orgaoParceria.toString());
                })
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false))
    }

    selectOrgaoParceria(event) {
        const newVal = event.currentTarget.value;
        this.filtro.idOrgao = newVal;
        this.getAuditores(this.filtro);
    }

    selectNomeOsc() {
        this.getAuditores(this.filtro);
    }

    selectNumTermo() {
        this.getAuditores(this.filtro);
    }

    selectCnpjOsc() {
        this.getAuditores(this.filtro);
    }

    selectNomeAuditor() {
        this.getAuditores(this.filtro);
    }

    selectCpfAuditor() {
        this.getAuditores(this.filtro);
    }

    async getOrgaoLista() {
        this.loading.next(true);
        await this.detalheParceriaPortalApi.getOrgaos()
            .subscribe((response) => {
                if (response) {
                    this.orgaoList = response as Array<Orgao>;
                    this.orgaoList.push(({ id: 0, nomeOrgao: "TODOS", abreviaturaOrgao: "TODOS", siglaOrgao: "TODOS" }));
                    this.orgaoList.sort(function (a, b) {
                        return a.id - b.id
                    });
                    //console.log(this.orgaoList);
                }
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));
    }

    aplicaMascaraCPF(doc) {
        return this.mascara.aplicaMascaraDocumento(doc, 1);
    }

    aplicaMascaraCNPJ(doc) {
        return this.mascara.aplicaMascaraDocumento(doc, 2);
    }

    disableIcone() {
        return (this.isPortal || this.isConsulta);
    }

    formatDate(d: Date): string {
        return ('0' + d.getDate()).slice(-2) + '/' + ('0' + (d.getMonth() + 1)).slice(-2) + '/' + d.getFullYear() + ' '
            + ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2) + ':' + ('0' + d.getSeconds()).slice(-2);
    }

    openEdicao(content, auditorLogonSend) {
        if (auditorLogonSend == null) {
            this.auditorLogonSend = new AuditorLogon();
            this.auditorLogonSend.orgaoParceria = 0;
        } else {
            this.auditorLogonSend = auditorLogonSend;
            this.auditorLogonSend.dataFimAcessoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
                this.dateConverter.fromDateToTimestamp(this.auditorLogonSend.dataFimAcesso));
            this.auditorLogonSend.dataAutorizacaoFormatada = this.formatDate(new Date(this.auditorLogonSend.dataAutorizacao));
        }

        this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: "modalProcesso" });
    }

    atualizaCampos() {
        this.auditorLogonSend.parceriaNome = null;
        this.auditorLogonSend.parNum = null;
        this.auditorLogonSend.cnpjParceria = null;
        this.auditorLogonSend.parId = null;
    }

    buscaTermo() {
        if (this.isBlank(this.auditorLogonSend.parNum)) {
            this.atualizaCampos();
            return;
        }
        if (this.isBlank(this.auditorLogonSend.parNum) && !this.isBlank(this.auditorLogonSend.cnpjParceria)) {
            this.alertService.danger('Informe o número do termo.');
            return;
        }

        if (this.auditorLogonSend.orgaoParceria == undefined) {
            this.alertService.danger('Selecione o órgão.');
            return;
        }

        this.detalheParceriaPortalApi.getParceriaByOrgaoParNum(this.auditorLogonSend.orgaoParceria, this.auditorLogonSend.parNum).subscribe((response) => {
            if (response) {
                const parceria = response as ParceriaInfo;
                this.auditorLogonSend.parId = parceria.parId;
                this.auditorLogonSend.cnpjParceria = parceria.parOrgCnpj;
                this.auditorLogonSend.parceriaNome = parceria.parOrgNome;
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
                this.auditorLogonSend.parNum = null
            } else {
                this.alertService.warning(response.error);
            }
        });
    }

    openExcluir(content, auditorLogonSend) {
        this.auditorLogonSend = auditorLogonSend;
        this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: "modalProcesso" });
    }

    isBlank(str) {
        return (!str || /^\s*$/.test(str));
    }

    replacer(key, value) {
        //atributos que só existem no view e não no servidor
        const ignoreList = ['dataFimAcessoDataFormat', 'dataAutorizacaoFormatada'];
        if (ignoreList.indexOf(key) > -1) {
            return undefined;
        } else {
            return value;
        }
    }

    salva() {

        if (!this.isBlank(this.auditorLogonSend.cnpjParceria) || !this.isBlank(this.auditorLogonSend.parNum)) {
            if (this.auditorLogonSend.cnpjParceria) {
                if (this.inValidCnpj) {
                    this.alertService.danger('CNPJ inválido');
                    return;
                }
                this.auditorLogonSend.cnpjParceria = this.auditorLogonSend.cnpjParceria.replace(/[^\d]+/g, '');
            }
            if (this.isBlank(this.auditorLogonSend.parNum)) {
                this.alertService.danger('Termo deve ser Preenchido');
                return;
            }

        }

        if (this.isBlank(this.auditorLogonSend.emailAuditor)) {
            this.alertService.danger('Email deve ser válido.');
            return;
        }

        if (this.isBlank(this.auditorLogonSend.parceriaNome) && !this.isBlank(this.auditorLogonSend.cnpjParceria)) {
            this.alertService.danger('Parceira deve ser informada, altere o cnpj para algum conveniado.');
            return;
        }

        if (this.auditorLogonSend.idAuditor == undefined || this.auditorLogonSend.idAuditor == null) {
            this.auditorLogonSend.idAuditor = -1;
        }
        this.loading.next(true)
        this.auditorLogonSend.cpfAuditor = this.auditorLogonSend.cpfAuditor.replace(/[^\d]+/g, '');
        this.auditorLogonSend.dataAutorizacao = new Date();
        this.auditorLogonSend.dataFimAcesso = this.dateConverter.fromNgbDateStructToDate(this.auditorLogonSend.dataFimAcessoDataFormat);
        this.auditorLogonSend.operador = this.username;
        const data = JSON.stringify(this.auditorLogonSend, this.replacer);
        this.logonApi.updateAuditorLogon(data).subscribe((response) => {
            this.auditorLogonSend = undefined;
            this.modalEdicaoReference.close();
            this.alertService.success("Salvo com sucesso!")
            this.getAuditores(this.filtro);
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
            this.modalEdicaoReference.close();
        }, () => this.loading.next(false))
    }

    excluir() {
        this.loading.next(true);
        this.auditorLogonSend.operador = this.username;
        this.logonApi.deleteAuditorLogon(this.auditorLogonSend).subscribe((response) => {
            this.auditorLogonSend = undefined;
            this.modalEdicaoReference.close();
            this.alertService.success("Excluido com sucesso!");
            this.getAuditores(this.filtro);
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
            this.modalEdicaoReference.close();
        }, () => this.loading.next(false))
    }

    updateCnpj(cnpj) {
        if (!this.isBlank(cnpj)) {
            this.inValidCnpj = !this.mascara.validaCpfCnpj(cnpj);
            if (!this.inValidCnpj) {
                this.loading.next(true);
                this.inValidCnpj = !this.mascara.validaCpfCnpj(cnpj);
                this.logonApi.getNomeParceira(cnpj.replace(/[^\d]+/g, ''))
                    .subscribe((response) => {
                        if (response) {
                            this.auditorLogonSend.parceriaNome = response as string;
                        } else {
                            this.alertService.warning('Nenhuma Parceira Encontrada');
                            this.auditorLogonSend.parceriaNome = "";
                        }
                    }, (response) => {
                        if (response.status >= 500) {
                            this.alertService.danger(response.error);
                        } else {
                            this.alertService.warning(response.error);
                        }
                        this.loading.next(false);
                    }, () => this.loading.next(false));
                this.loading.next(false);
            }
        }
    }

    updateCpf(cpf) {
        if (!this.isBlank(cpf)) {
            this.inValidCpf = !this.mascara.validaCpfCnpj(cpf);
        }
    }

    limpaCnpj() {
        this.auditorLogonSend.cnpjParceria = undefined;
        this.auditorLogonSend.parceriaNome = undefined;
    }
}