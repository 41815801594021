import { Injectable } from "@angular/core";

@Injectable()
export class Utils {

  getStatusParceira(cod) {
    let label;
    switch (cod) {
      case 1: label = 'Em execução'; break;
      case 2: label = 'Execução encerrada – contas em análise'; break;
      case 3: label = 'Prestacao de contas APROVADA'; break;
      case 4: label = 'Prestacao de contas APROVADA COM RESSALVA'; break;
      case 5: label = 'Prestacao de contas REJEITADA'; break;
    }
    return label;
  }

  getExecNaturezaStr(id) {
    switch (id) {
      case 1: return "Consumo";
      case 2: return "Pagto. de Pessoal";
      case 3: return "Serv. de Terceiros";
      case 4: return "Permanente";
      case 5: return "Outros";
      default: return "";
    }
  }

  execOperacaoOptions = [
    { id: '1', name: 'GPS' },
    { id: '2', name: 'GRF' },
    { id: '3', name: 'Nota Fiscal Manual' },
    { id: '4', name: 'NF-e' },
    { id: '5', name: 'Recibo' },
    { id: '7', name: 'NFS-e Porto Alegre' },
    { id: '8', name: 'NF-e Manual (SP, MG ou CE)' },
    { id: '9', name: 'NFS-e Outros Municípios' },
    //  { id: '10', name: 'DARF' },
    { id: '11', name: 'GRRF (Rescisório)' },
    { id: '12', name: 'DARF PIS' },
    { id: '13', name: 'DARF IR' },
    { id: '14', name: 'DARF INSS' },
    { id: '15', name: 'DARF Outros Pagamentos' },
    { id: '16', name: 'NFS-e Nacional'},
    { id: '6', name: 'Outros' }
  ];

  getExecOperacaoStr(id) {
    switch (id) {
      case 1: return "GPS";
      case 2: return "GRF";
      case 3: return "Nota Fiscal Manual";
      case 4: return "NF-e";
      case 5: return "Recibo";
      case 6: return "Outros";
      case 7: return "NFS-e Porto Alegre";
      case 8: return "NF-e Manual (SP, MG ou CE)";
      case 9: return "NFS-e Outros Municípios";
      case 10: return "DARF";
      case 11: return "GRRF (Rescisório)";
      case 12: return "DARF PIS";
      case 13: return "DARF IR";
      case 14: return "DARF INSS";
      case 15: return "DARF Outros Pagamentos";
      case 16: return "NFS-e Nacional";
      default: return "";
    }
  }

  getExecConferirStr(id) {
    switch (id) {
      case 1: return "a conferir";
      case 2: return "conferido";
      case 3: return "a corrigir";
      case 4: return "não confere";
      case 5: return "conf. c/ res.";
      case 6: return "a excluir";
      case 99: return "rascunho";
      default: return "";
    }
  }

  statusParceiraOptions = [
    { id: '0', name: 'Todos' },
    { id: '1', name: 'Em execução' },
    { id: '2', name: 'Execução encerrada – contas em análise' },
    { id: '3', name: 'Prestacao de contas APROVADA' },
    { id: '4', name: 'Prestacao de contas APROVADA COM RESSALVA' },
    { id: '5', name: 'Prestacao de contas REJEITADA' }
  ];

  naturezaOptions = [
    { id: '1', name: 'Consumo' },
    { id: '2', name: 'Pagamento de Pessoal' },
    { id: '3', name: 'Serviços de Terceiros' },
    { id: '4', name: 'Permanente' }   
  ];

  statusOptions = [
    { id: '1', name: 'a conferir' },
    { id: '2', name: 'conferido' },
    { id: '3', name: 'a corrigir' },
    { id: '4', name: 'não confere' },
    { id: '5', name: 'conf. c/ res.' },
    { id: '6', name: 'a excluir' }
  ];

  secretariasOptions = [
    { id: '0', name: 'Todos' },
    { id: '10', name: 'Secretaria Municipal da Cultura' },
    { id: '15', name: 'Secretaria Municipal de Educação' },
    { id: '18', name: 'Secretaria Municipal de Saúde' },
    { id: '31', name: 'Departamento Municipal de Habitação' },
    { id: '60', name: 'Fundação de Assistencia Social e Cidadania' },
    { id: '73', name: 'Secretaria Municipal de Desenvolvimento Social' },
    { id: '75', name: 'Secretaria Municipal do Meio Ambiente, Urbanismo e Sustentabilidade' },
    { id: '80', name: 'Secretaria Municipal de Governança Local e Coordenação Política' },
    { id: '84', name: 'Secretaria Municipal de Esporte, Lazer e Juventude'}
  ];

  isValidDate(d: Date) {
    return d !== null && d instanceof Date && typeof d.getTime === 'function' && !isNaN(+d) && d.getFullYear() > 2000;
  }

  isValidDateAdmissaoDemissao(d: Date) {
    return d !== null && d instanceof Date && typeof d.getTime === 'function' && !isNaN(+d);
  }

  getPrestaClassificaDocStr(id) {
   return this.tipoDocumentoOptions.find(O => O.id == id).name;
  }

  tipoDocumentoOptions = [
    { id: '1', name: 'Extrato Conta Corrente' },
    { id: '2', name: 'Extrato Conta Poupança' },
    { id: '3', name: 'Extrato Conta Aplicação' },
    { id: '4', name: 'SEFIP' },
    { id: '5', name: 'Guia INSS' },
    { id: '6', name: 'Guia FGTS' },
    { id: '7', name: 'DARF IR' },
    { id: '8', name: 'DARF PIS' },
    { id: '9', name: 'Orçamentos' },
    { id: '10', name: 'Justificativa' },
    { id: '11', name: 'GRRF (Rescisório)' },
    { id: '15', name: 'Justificativa – Pagamento de Pessoal' },
    { id: '16', name: 'F003 – Rateio de Pagamento de Pessoal' },
    { id: '17', name: 'F003 – Rateio de Serviços de Terceiros' },
    { id: '12', name: 'F004 – Relatório de Bens' },
    { id: '13', name: 'F008 – RPP' },
    { id: '14', name: 'F002 – REO' },
    { id: '18', name: 'Lista de Beneficários' },
    { id: '19', name: "F005 - Planilha de Fechamento do Exercício" },
    { id: '20', name: "F007 - Modelo de Declaração de Contador/Técnico Responsável" },
    { id: '21', name: "Planilha de simulação de rescisão" },
    { id: '22', name: "Certidão de Regularidade do Contador" },
    { id: '23', name: "Ata" }
  ];

  parClassificacaoOption = [
        {id: 0, name: 'Todos'},
        {id: 1, name: 'Termos de Colaboração'},
        {id: 2, name: 'Termos de Fomento'},
        {id: 3, name: 'Acordos de cooperação'}
      ]

  motivoDevolAplicOptions = [
    { id: '1', name: "Devolução de saldo final remanescente da parceria" },
		{ id: '2', name: "Rendimentos de aplicações automáticas da conta corrente da parceria" },
		{ id: '3', name: "Devoluções para a conta corrente da parceira" },
		{ id: '4', name: "Rendimentos de conta poupança/provisão" },
		{ id: '5', name: "Rendimentos de outras contas/aplicações financeiras" }
  ];

  getMotivoStr(id) {
    switch (id) {
      case 1:
        return "Devolução de saldo final remanescente da parceria";
      case 2:
        return "Rendimentos de aplicações automáticas da conta corrente da parceria";
      case 3:
        return "Devoluções para a conta corrente da parceira";
      case 4:
        return "Rendimentos de conta poupança/provisão";
      case 5:
        return "Rendimentos de outras contas/aplicações financeiras";
      default: return '';
    }
  }

  listTiposLicitacao = [
    { id: 1, tipo: 'Chamamento Público', sigla: 'CHP' },
    { id: 2, tipo: 'Dispensa', sigla: 'PRD' },
    { id: 3, tipo: 'Dispensa Eletrônica', sigla: 'PDE' },
    { id: 4, tipo: 'Inexigibilidade', sigla: 'PRI' }
  ]

  getSecretariaStr(id) {
    switch (id) {
      case "0": return 'Todos';
      case "10": return 'SMC';
      case "15": return 'SMED';
      case "18": return 'SMS';
      case "31": return 'DEMHAB';
      case "60": return 'FASC';
      case "73": return 'SMDS';
      case "75": return 'SMAMUS';
      case "80": return 'SMGOV';
      case "84": return 'SMELJ';
    }
  }
  modalidadeOptions = [
    { id: '19', name: 'Abrigo de Famílias' },
    { id: '20', name: 'ACESSUAS Trabalho' },
    { id: '1', name: 'Atividades Culturais' },
    { id: '14', name: 'Acolhimento Institucional' },
    { id: '21', name: 'Acolhimento Institucional -  PCD' },
    { id: '22', name: 'Acolhimento Institucional - Adulto em Situação de Rua' },
    { id: '23', name: 'Acolhimento Institucional - Adultos' },
    { id: '24', name: 'Acolhimento Institucional - Crianças e Adolescentes' },
    { id: '25', name: 'Acolhimento Institucional - Idosos' },
    { id: '17', name: 'Albergue' },
    { id: '26', name: 'Atividades Extracurriculares' },
    { id: '27', name: 'CAPs' },
    { id: '28', name: 'Casa de Apoio HIV/AIDS' },
    { id: '15', name: 'Casa Lar' },
    { id: '29', name: 'Casa Lar para Idosos' },
    { id: '11', name: 'Centro Dia Idoso' },
    { id: '30', name: 'Centro POP' },
    { id: '31', name: 'Comunidades Terapêuticas' },
    { id: '32', name: 'CRIM' },
    { id: '33', name: 'Currículo Complementar' },
    { id: '6', name: 'Educação Básica' },
    { id: '4', name: 'Educação Infantil' },
    { id: '5', name: 'Educação Especial' },
    { id: '3', name: 'Educação Integral' },
    { id: '34', name: 'Educação Jovens e Adultos' },
    { id: '35', name: 'Ensino Fundamental' },
    { id: '2', name: 'Equipamentos Culturais' },
    { id: '36', name: 'Família Acolhedora' },
    { id: '37', name: 'Orientação e Mobilidade' },
    { id: '9', name: 'Serviço de proteção e Atendimento Integral a Família - PAIF' },
    { id: '12', name: 'Serviço de Proteção e Atendimento Especializado a Famílias e Indivíduos - PAEFI' },
    { id: '38', name: 'PAIF/PAEFI' },
    { id: '39', name: 'PEA' },
    { id: '40', name: 'Pessoas com Deficiências' },
    { id: '41', name: 'Prato Alegre' },
    { id: '42', name: 'Programa Ação Rua' },
    { id: '43', name: 'Pronto Atendimento' },
    { id: '44', name: 'Pronto Atendimento em Saúde Mental' },
    { id: '16', name: 'República' },
    { id: '45', name: 'Residencial Inclusivo' },
    { id: '46', name: 'Residencial Terapêutico' },
    { id: '10', name: 'Serviço de Abordagem Social' },
    { id: '47', name: 'Serviço de Apoio a Rede de Atendimento - SARA' },
    { id: '8', name: 'Serviço de Atendimento Familiar - SAF' },
    { id: '7', name: 'Serviço de Convivência e Fortalecimento de Vínculos - 06 a 15 anos' },
    { id: '48', name: 'Serviço de Convivência e Fortalecimento de Vínculos - 06 a 15 anos Execução Compartilhada' },
    { id: '49', name: 'Serviço de Convivência e Fortalecimento de Vínculos - 15 a 17 anos' },
    { id: '50', name: 'Serviço de Convivência e Fortalecimento de Vínculos - Adulto' },
    { id: '51', name: 'Serviço de Convivência e Fortalecimento de Vínculos - Idosos' },
    { id: '52', name: 'Serviço de Convivência e Fortalecimento de Vínculos - Projovem' },
    { id: '13', name: 'Serviço de Habilitação e Reabilitação PCD' },
    { id: '53', name: 'Serviços de Atenção Primária em Saúde' },
    { id: '54', name: 'Serviços Hospitalares' },
    { id: '55', name: 'Trabalho Tecnico Social' },
    { id: '18', name: 'Outra' }
  ];

  listEstados = [
    {"nome": "Acre", "sigla": "AC"},
    {"nome": "Alagoas", "sigla": "AL"},
    {"nome": "Amapá", "sigla": "AP"},
    {"nome": "Amazonas", "sigla": "AM"},
    {"nome": "Bahia", "sigla": "BA"},
    {"nome": "Ceará", "sigla": "CE"},
    {"nome": "Distrito Federal", "sigla": "DF"},
    {"nome": "Espírito Santo", "sigla": "ES"},
    {"nome": "Goiás", "sigla": "GO"},
    {"nome": "Maranhão", "sigla": "MA"},
    {"nome": "Mato Grosso", "sigla": "MT"},
    {"nome": "Mato Grosso do Sul", "sigla": "MS"},
    {"nome": "Minas Gerais", "sigla": "MG"},
    {"nome": "Pará", "sigla": "PA"},
    {"nome": "Paraíba", "sigla": "PB"},
    {"nome": "Paraná", "sigla": "PR"},
    {"nome": "Pernambuco", "sigla": "PE"},
    {"nome": "Piauí", "sigla": "PI"},
    {"nome": "Rio de Janeiro", "sigla": "RJ"},
    {"nome": "Rio Grande do Norte", "sigla": "RN"},
    {"nome": "Rio Grande do Sul", "sigla": "RS"},
    {"nome": "Rondônia", "sigla": "RO"},
    {"nome": "Roraima", "sigla": "RR"},
    {"nome": "Santa Catarina", "sigla": "SC"},
    {"nome": "São Paulo", "sigla": "SP"},
    {"nome": "Sergipe", "sigla": "SE"},
    {"nome": "Tocantins", "sigla": "TO"}
]

  getStatusPrestacaoContas(id) {
    switch (id) {
      case "1": return "Pendente de envio";
      case "2": return "Enviada";
    }
  }

  classificacaoDocumentosLicitacon = [
    {"id": 1, "sigla": "CTR", "nome": "Termo de parceria"},
    {"id": 2, "sigla": "TAD", "nome": "Termo aditivo"},
    {"id": 3, "sigla": "APO", "nome": "Apostilamento"},
    {"id": 4, "sigla": "ORD", "nome": "Ordem de início"},
    {"id": 5, "sigla": "SCD", "nome": "Suspensão por determinação judicial"},
    {"id": 6, "sigla": "SCC", "nome": "Suspensão cautelar"},
    {"id": 7, "sigla": "SCO", "nome": "Suspensão de ofício"},
    {"id": 8, "sigla": "REC", "nome": "Retorno dos efeitos do contrato"},
    {"id": 9, "sigla": "TIN", "nome": "Termo de Indenização"},
    {"id": 10, "sigla": "AOC", "nome": "Anulação de ofício"},
    {"id": 11, "sigla": "ADC", "nome": "Anulação por determinação judicial"},
    {"id": 12, "sigla": "RES", "nome": "Termo de rescisão"}
  ]

  getClassificacaoDocumentosLicitaconNome(id) {
    return this.classificacaoDocumentosLicitacon.find(O => O.id == id).nome;
  }
  isSaude(id){
    if (id === 18){
      return true;
    }
    else{
      return false;
    }
  }

  getTipoAtividade(id) {
		switch (id) {
		case 1:
			return "Atividade Meio";
		case 2:
			return "Atividade Fim";
		default:
			return "";
		}
	}

  tipoAtividade = [
    {id: 1, name: 'Meio'},
    {id: 2, name: 'Fim'},
    ]

  tipoDeServicoTerceiro = [
      {id: 1, name: 'Pessoa Física - RPCI'},
      {id: 2, name: 'Pessoa Jurídica - Com Contrato'},
      {id: 3, name: 'Pessoa Jurídica - Sem Contrato'}
      ]
    
  getTipoDeServicoTerceiro(id) {
        switch (id) {
        case 1:
          return "Pessoa Física - RPCI";
        case 2:
          return "Pessoa Jurídica - Com Contrato";
        case 3:
          return "Pessoa Jurídica - Sem Contrato";
        default:
          return "";
        }
      }

}