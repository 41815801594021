<div *ngIf="parceriaInfo != undefined  && parceriaInfo.parId !=-1">
    <div class="row">
        <div class="columnBold" style="width:70%;"> E-mails do órgão responsável pela análise</div>
    </div>
    <div *ngIf="emailResponsavelAnali.length== 0">
        <span class="mensagem-vazio">Nenhum email de responsável pela análise foi cadastrado.</span>
    </div>
    <div *ngIf="emailResponsavelAnali.length > 0">

        <table class="table table-striped" style="margin-top:30px" id="tabelaEmail" [mfData]="emailResponsavelAnali" #mtEmailResp="mfDataTable" [mfRowsOnPage]="5">

            <thead>
                <tr>
                    <th *ngIf="(!isPortal)" data-html2canvas-ignore="true"></th>
                    <th *ngIf="!disableApagarIcone()" data-html2canvas-ignore="true"></th>
                    <th>Email</th>
                    <th>Nome</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of mtEmailResp.data ; let j = index;">
                    <td *ngIf="!isPortal" class="col-auto" data-html2canvas-ignore="true">
                        <i ngbTooltip="editar" *ngIf="enableEditarIcone()" class="fa fa-pencil" aria-hidden="true" (click)="openEdicao(contentEdicao, data)"></i>
                        <i ngbTooltip="visualizar" *ngIf="visualizarBotao()" class="fa fa-search" aria-hidden="true" (click)="openEdicao(contentEdicao, data)"></i>
                    </td>
                    <td *ngIf="!disableApagarIcone()" class="col-auto" data-html2canvas-ignore="true">
                        <i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true" (click)="openExcluir(contentExcluir, data)"></i>
                    </td>
                    <td class="col-auto">{{data.emailRespAnaliseEmail}}</td>
                    <td class="col-auto">{{data.emailRespAnaliseNome}}</td>
                </tr>
            </tbody>
            <tfoot data-html2canvas-ignore="true">
                <tr>
                    <td colspan="12 ">
                        <div *ngIf="emailResponsavelAnali.length>5" class="resultPorPagina">Resultados por página:</div>
                        <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
    <div *ngIf="!disableCampos()">
        <button (click)="openEdicao(contentEdicao, undefined) " id="button-insert_doc " class="btn btn-primary btn-info" type="button " title="Adicionar novo">
                        <i class="fa fa-plus "><span class="texto-botao">Adicionar novo</span> </i>
        </button>
    </div>

    <ng-template #contentEdicao let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Email Responsável Análise</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                            <span aria-hidden="true">&times;</span>
                </button>
        </div>
        <form (ngSubmit)="salvaEmailResponsavelAnali()" #edicao="ngForm" novalidate>
            <div class="modal-body" *ngIf="emailResponsavelAnaliSend">
                <div class="form-group col-md-6">
                    <label for="doc">Email</label>
                    <span [hidden]="email.valid" class="campoObrigatorio">*digite um email válido</span>
                    <input id="email " class="form-control " name="email" #email="ngModel" type="text " style="width: 100%" id="email" name="email" #email="ngModel" [(ngModel)]="emailResponsavelAnaliSend.emailRespAnaliseEmail" required emailvalidator>
                </div>
                <div class="form-group col-md-6">
                    <label for="data">Nome</label>
                    <input style="width:100%;" id="parNome" class="form-control" name="parNome" #parCidade="ngModel" type="text" [(ngModel)]="emailResponsavelAnaliSend.emailRespAnaliseNome" required>
                </div>

            </div>

            <div class="modal-footer col-md-12">
                <button type="button" class="btn btn-default" (click)="c('Close click');setE(undefined)" title="Cancelar">Cancelar</button>
                <button type="submit" class="btn btn-primary" [disabled]="!edicao.form.valid" title="Salvar">Salvar</button>
            </div>
        </form>
    </ng-template>

    <ng-template #contentExcluir let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Excluir email do responsável pela análise</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                            <span aria-hidden="true">&times;</span>
                        </button>
        </div>
        <div class="modal-body">
            <p>Tem certeza que deseja excluir o email do responsável pela análise
                <b>{{emailResponsavelAnaliSend.emailRespAnaliseEmail}}  - {{emailResponsavelAnaliSend.emailRespAnaliseNome}}</b>?</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">Fechar</button>
            <button type="button" class="btn btn-secondary" (click)="excluirEmailResponsavelAnali()" title="Excluir">Excluir</button>
        </div>
    </ng-template>
</div>