import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { UserService } from '../../@core/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from 'src/app/@core/date-converter';
import { Visitacao } from './visitacao';
import { BuscaVisitacoesCampos } from './buscaVisitacoesCampos';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { AuthUser, KeycloakService } from '@procempa/ngx-keycloak';
import { Utils } from 'src/app/@shared/utils';

@Component({
  selector: 'app-auditoria-visitacao',
  templateUrl: './visitacao.component.html',
  styleUrls: ['./visitacao.component.scss']
})
export class VisitacaoAuditoriaComponent implements OnInit {
  @Input()
  isPortal: boolean;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  visitacaoSend: Visitacao;
  modalEdicaoReference: any;
  isSMTC: boolean;
  isConsulta: boolean;
  buscaVisitacoesCampos: BuscaVisitacoesCampos = new BuscaVisitacoesCampos();
  visitacoes: Array<Visitacao>;
  orgao: number;
  parNum: String;
  anoVisitacao: number;
  username: string;

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    public userService: UserService,
    public keycloakService: KeycloakService,
    private utils: Utils,
    private detalheParceriaApi: DetalheParceriaApi,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    this.userService.userInfo.subscribe(user => this.username = user.username);
    this.visitacoes = [];
    this.keycloakService.getUser().subscribe((user: AuthUser) => {
      if (user.attributes.sgp_orgao !== null) {
        // tslint:disable-next-line:no-unused-expression
        if (this.userService.isSMTC()) {
          this.buscaVisitacoesCampos.idOrgao = null;
          this.isSMTC = true;
        } else {
          this.buscaVisitacoesCampos.idOrgao = Number(user.attributes.sgp_orgao[0]);
        }
      }
      this.setupInterface();
    });
  }

  setupInterface() {
    this.getVisitacoes();
  }

  getVisitacoes() {
    this.loading.next(true);
    this.detalheParceriaApi.getVisitacoes(this.buscaVisitacoesCampos).subscribe((response) => {
      if (response) {
        this.visitacoes = response as Array<Visitacao>;
        this.visitacoes.forEach(element => {
          element.nomeOrgao = this.utils.getSecretariaStr(element.idOrgao.toString());
        })
      } else {
        this.alertService.warning('Nenhum registro encontrado');
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));
  }

  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  salvaVisitacao() {
    this.loading.next(true);
    if (this.orgao == null) {
      this.alertService.danger('Órgão deve ser selecionado.');
      this.loading.next(false);
      return;
    }

    if (this.isBlank(this.parNum)) {
      this.alertService.danger('Número do termo deve ser informado.');
      this.loading.next(false);
      return;
    }

    if (this.isBlank(this.anoVisitacao)) {
      this.alertService.danger('Ano da visitação deve ser informado.');
      this.loading.next(false);
      return;
    }

    var visitacao = new Visitacao();
    visitacao.idOrgao = this.orgao;
    visitacao.parNum = this.parNum;
    visitacao.anoVisitacao = this.anoVisitacao;
    visitacao.dataOperacao = new Date();
    visitacao.usuarioOperacao = this.username;

    this.detalheParceriaApi.updateVisitacao(visitacao).subscribe((response) => {
      if (response) {
        this.setupInterface();
        this.alertService.info('Visitação incluída com sucesso!')
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));
  }

  openExcluir(content, doc) {
    this.visitacaoSend = doc;
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }

  excluirVisitacao() {
    this.detalheParceriaApi.excluirVisitacao(this.visitacaoSend).subscribe((response) => {
      if (response) {
        this.setupInterface();
        this.visitacaoSend = undefined;
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));
  }
}


