<div class="container-fluid">
  <div class="infoGeral container-fluid mb-3">
    <h1 class="principal">Acesso de auditores</h1>
    <h3>Filtros de pesquisa</h3>
    <p><em>Você pode filtrar os resultados preenchendo um ou combinando mais de um dos campos abaixo:</em></p>
    <br>
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label for="">Escolher órgão ou entidade (PMPA)</label>
          <div class="select-wrapper">
            <select [disabled]="isFiscal && !isSMTC" [ngModel]="filtro.idOrgao" (change)="selectOrgaoParceria($event)"
              class="form-control form-select custom-select">
              <option [value]="option.id" *ngFor="let option of this.utils.secretariasOptions">{{option.name}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="nome">Nome da organização (parceria)</label>
          <input style="width: 100% " id="nome" name="filtro.nomeOsc" class="form-control " type="text "
            [ngModelOptions]="{standalone: true} " [(ngModel)]="filtro.nomeOsc" [value]="filtro.nomeOsc"
            (blur)="selectNomeOsc()" (keydown.enter)="selectNomeOsc()" placeholder="digite alguma palavra-chave">
        </div>
      </div>
      <div class="col-md-3" style="width: 100% ">
        <div class="form-group">
          <label for="nroTermo">Número do termo</label>
          <input style="width: 100% " id="nroTermo" name="filtro.parNum" class="form-control " type="text "
            [ngModelOptions]="{standalone: true} " [(ngModel)]="filtro.parNum" [value]="filtro.parNum"
            (blur)="selectNumTermo()" (keydown.enter)="selectNumTermo()" class="form-control"
            placeholder="informe o nº do termo">
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="cnpj">CNPJ</label>
          <input style="width: 100% " id="cnpj" name="cnpj" class="form-control " type="text "
            [ngModelOptions]="{standalone: true} " [(ngModel)]="filtro.cnpjOsc" [value]="filtro.cnpjOsc"
            (blur)="selectCnpjOsc()" (keydown.enter)="selectCnpjOsc()" class="form-control" placeholder="informe o CNPJ"
            [textMask]="mascaraCNPJ">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="cpfAuditor">CPF do Auditor</label>
          <input style="width: 100% " id="cpfAuditor" name="filtro.cpfAuditor" class="form-control " type="text "
            [ngModelOptions]="{standalone: true} " [(ngModel)]="filtro.cpfAuditor" [value]="filtro.cpfAuditor"
            (blur)="selectCpfAuditor()" (keydown.enter)="selectCpfAuditor()" placeholder="informe o CPF do Auditor"
            [textMask]="mascaraCPF">
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="nome">Nome do Auditor</label>
          <input type="text" style="width: 100%" id="nomeAuditor" name="filtro.nomeAuditor" class="form-control"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="filtro.nomeAuditor" [value]="filtro.nomeAuditor"
            (blur)="selectNomeAuditor()" (keydown.enter)="selectNomeAuditor()" placeholder="informe o Nome do Auditor">
        </div>
      </div>
      <div class="col-md-3">
        <div class="col-md-6">
          <button class="btn btn-primary btn-filtro">Filtrar</button>
        </div>
        <div class="col-md-6">
          <button class="btn btn-primary btn-filtro" (click)="openEdicao(contentEdicao, null)">Novo</button>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="form-row">
    <div class="col-md-12">
      <table class="table table-hover table-responsavel" [hidden]="(!auditorArray) " [mfData]="auditorArray ">
        <thead>
          <tr>
            <th></th>
            <th *ngIf="!disableIcone()" data-html2canvas-ignore="true"></th>
            <th>CPF</th>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Órgão</th>
            <th>CNPJ Parceria</th>
            <th>Nome Parceria</th>
            <th>Nº Termo</th>
            <th>Data Autorização</th>
            <th>Data Fim Acesso</th>
            <th>Operador</th>
          </tr>
        </thead>
        <tbody>

          <tr *ngFor="let auditor of auditorArray | paginate:config let idx = index">

            <td>
              <i ngbTooltip="Editar Auditor" *ngIf="!disableIcone()" class="fa fa-pencil" aria-hidden="true"
                (click)="openEdicao(contentEdicao, auditor)"></i>
              <i ngbTooltip="VisualizarAuditor" *ngIf="disableIcone()" class="fa fa-search" aria-hidden="true"
                (click)="openEdicao(contentEdicao, auditor)"></i>
            </td>
            <td *ngIf="!disableIcone()" class="col-auto" data-html2canvas-ignore="true">
              <i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true"
                (click)="openExcluir(contentExcluir, auditor)"></i>
            </td>

            <td>{{aplicaMascaraCPF(auditor.cpfAuditor)}}</td>
            <td>{{auditor.nomeAuditor}}</td>
            <td>{{auditor.emailAuditor}}</td>
            <td>{{auditor.orgaoNome}}</td>
            <td>{{auditor.cnpjParceria ? aplicaMascaraCNPJ(auditor.cnpjParceria) : auditor.cnpjParceria}}</td>
            <td>{{auditor.parceriaNome}}</td>
            <td>{{auditor.parNum}}</td>
            <td>{{auditor.dataAutorizacao | date: 'dd/MM/yyyy'}}</td>
            <td>{{auditor.dataFimAcesso | date: 'dd/MM/yyyy'}}</td>
            <td>{{auditor.operador}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #contentEdicao let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Autorização de Acesso de Auditor ao Termo</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form (ngSubmit)="salva()" #edicao="ngForm" novalidate>
    <div class="modal-body" *ngIf="auditorLogonSend">
      <input type="hidden" id="parId" name="parId" #parId="ngModel" [(ngModel)]="auditorLogonSend.parId" />
      <div class="form-row">
        <div class="form-group col-md-9">
          <label for="orgaoParceria">Órgão</label>
          <div class="select-wrapper" style="width: 100%">
            <ng-select virtualScroll="true" class="form-control" [items]="orgaoList" [clearable]="false"
              (change)="atualizaCampos()" bindLabel="nomeOrgao" bindValue="id"
              [(ngModel)]="auditorLogonSend.orgaoParceria" name="orgaoParceria" id="orgaoParceria">
            </ng-select>
          </div>
        </div>
        <div class="form-group col-md-3">
          <label for="parNum">Nº do Termo</label>
          <!--<span [hidden]="!isBlank(auditorLogonSend.parNum) || auditorLogonSend.orgaoParceria == 0"
            class="campoObrigatorio">*</span>-->
          <input style="width:100%;" id="parNum" class="form-control" name="parNum" #parNum="ngModel" type="text"
            [(ngModel)]="auditorLogonSend.parNum" (blur)="buscaTermo()">
          <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" *ngIf="auditorLogonSend.idAuditor == null"
            (click)="auditorLogonSend.parNum=undefined" title="limpar"></i>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12 col-lg-4">
          <label for="cnpj">CNPJ Parceira</label>
          <span [hidden]="!isBlank(auditorLogonSend.cnpjParceria) || isBlank(auditorLogonSend.parNum)"
            class="campoObrigatorio">* {{inValidCnpj?' inválido':''}}</span>
          <input style="width: 100%" id="cnpj" class="form-control" name="cnpj" #cnpj="ngModel" type="text"
            [(ngModel)]="auditorLogonSend.cnpjParceria" [textMask]="mascaraCNPJ"
            (focusout)="updateCnpj(auditorLogonSend.cnpjParceria)">
          <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" *ngIf="auditorLogonSend.idAuditor == null"
            (click)="limpaCnpj()" title="limpar"></i>
        </div>
        <div class="form-group col-md-12 col-lg-8">
          <label for="parceriaNome">Nome da Parceira</label>
          <input style="width:100%;" id="parceriaNome" class="form-control" name="parceriaNome" #parceriaNome="ngModel"
            type="text" [(ngModel)]="auditorLogonSend.parceriaNome" [disabled]="true">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="cpfAuditor">CPF do Auditor</label>
          <span class="campoObrigatorio">* {{inValidCpf ? ' inválido' : ''}}</span>
          <input style="width: 100%" id="cpf" class="form-control" name="cpf" #cpf="ngModel" type="text"
            [(ngModel)]="auditorLogonSend.cpfAuditor" [textMask]="mascaraCPF"
            (focusout)="updateCpf(auditorLogonSend.cpfAuditor)" [disabled]="auditorLogonSend.idAuditor != null"
            required>
          <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" *ngIf="auditorLogonSend.idAuditor == null"
            (click)="auditorLogonSend.cpfAuditor=undefined" title="limpar"></i>
        </div>
        <div class="form-group col-md-8">
          <label for="nomeAuditor">Nome do Auditor</label>
          <span [hidden]="nomeAuditor.valid" class="campoObrigatorio">*</span>
          <input style="width:100%;" id="nomeAuditor" class="form-control" name="nomeAuditor" #nomeAuditor="ngModel"
            type="text" [(ngModel)]="auditorLogonSend.nomeAuditor" [disabled]="auditorLogonSend.idAuditor != null">
          <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" *ngIf="auditorLogonSend.idAuditor == null"
            (click)="auditorLogonSend.nomeAuditor=undefined" title="limpar"></i>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-9">
          <label for="emailAuditor">E-mail do Auditor</label>
          <span [hidden]="emailAuditor.valid" class="campoObrigatorio">*</span>
          <input style="width:100%;" id="emailAuditor" class="form-control" name="emailAuditor" #emailAuditor="ngModel"
            type="text" [(ngModel)]="auditorLogonSend.emailAuditor"
            pattern="[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*"
            [disabled]="auditorLogonSend.idAuditor != null" required>
          <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" *ngIf="auditorLogonSend.idAuditor == null"
            (click)="auditorLogonSend.emailAuditor=undefined" title="limpar"></i>
        </div>
        <div class="form-group col-md-3">
          <label for="dataFimAcesso">Data de fim do acesso</label>
          <input style="width:100%" class="form-control" name="dataFimAcesso" #dataFimAcesso="ngModel" maxlength="10"
            placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="auditorLogonSend.dataFimAcessoDataFormat"
            [(ngModel)]="auditorLogonSend.dataFimAcessoDataFormat" ngbDatepicker #dFimA="ngbDatepicker" required>
          <span [hidden]="auditorLogonSend.dataFimAcessoDataFormat" class="campoObrigatorio">*</span>
          <i class="fa fa-calendar icon icon_input_date" (click)="dFimA.toggle()" title="calendário"
            style="width: 38px;"></i>
          <span [hidden]="auditorLogonSend.dataFimAcessoDataFormat" class="campoObrigatorio">*</span>
        </div>
      </div>
      <div class="form-row" *ngIf="auditorLogonSend.idAuditor != null">
        <div class="form-group col-md-3">
          <label for="dataAutorizacao">Data Autorização</label>
          <input style="width:100%;" id="dataAutorizacao" class="form-control" name="dataAutorizacao"
            #dataAutorizacao="ngModel" [(ngModel)]="auditorLogonSend.dataAutorizacaoFormatada" [disabled]="true">
        </div>
        <div class="form-group col-md-9">
          <label for="operador">Operador</label>
          <input style="width:100%;" id="operador" class="form-control" name="operador" #operador="ngModel" type="text"
            [(ngModel)]="auditorLogonSend.operador" [disabled]="true">
        </div>
      </div>
      <div class="modal-footer col-md-12">
        <button type="button" class="btn btn-default" (click)="c('Close click');" title="Cancelar">Cancelar</button>
        <button type="submit" class="btn btn-primary" [disabled]="!edicao.form.valid" title="Salvar">Salvar</button>
      </div>
    </div>
  </form>
</ng-template>
<ng-template #contentExcluir let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Excluir Acesso de Auditor</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Tem certeza que deseja excluir o acesso de auditor abaixo?</p>
    <p>
      CPF: <b>{{aplicaMascaraCPF(auditorLogonSend.cpfAuditor)}}</b><br>
      Nome: <b>{{auditorLogonSend.nomeAuditor}}</b><br>
      E-mail: <b>{{auditorLogonSend.emailAuditor}}</b><br>
      CNPJ OSC: <b>{{auditorLogonSend.cnpjParceria ? aplicaMascaraCNPJ(auditorLogonSend.cnpjParceria) :
        auditorLogonSend.cnpjParceria}}</b><br>
      OSC: <b>{{auditorLogonSend.parceriaNome}}</b><br>
      Órgão: <b>{{auditorLogonSend.orgaoNome}}</b><br>
      Termo: <b>{{auditorLogonSend.parNum}}</b>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">Fechar</button>
    <button type="button" class="btn btn-secondary" (click)="excluir()" title="Excluir">Excluir</button>
  </div>
</ng-template>