import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from '../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../../apis/detalheParceriaApi';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { Mascaras } from '../../@shared/components/Mascaras';
import { UserService } from '../../@core/user.service';
import { ParceriasEmailResponsavelAnali } from './emailResponsavelAnali';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-email-resp-anali-parceria',
  templateUrl: './../responsavelAnalise/emailResponsavelAnali.component.html',
  styleUrls: ['./../../modulo2/detalheParceria/detalheParceria.component.scss']
})

export class EmailResponsavelAnaliComponent implements OnInit {

  @Input()
  public  parceriaInfo: ParceriaInfo;
  @Input()
  public  isPortal: boolean;
  @Output() valueChange = new EventEmitter();
  emailResponsavelAnali: Array<ParceriasEmailResponsavelAnali> = [];
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  modalEdicaoReference: any;
  emailResponsavelAnaliSend: ParceriasEmailResponsavelAnali;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  @Input()
  isAuditorExterno: boolean;

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    private modalService: NgbModal,
    private mascaras: Mascaras) {

    }

  ngOnInit() {
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();
    this.setupInterface();
  }


  logout() {
    this.userService.logout();
  }

  setupInterface() {

    this.loading.next(true);

    this.route.paramMap.pipe(
        switchMap(() =>
         this.detalheParceriaPortalApi.getParceriasEmailsResponsavelAnali(this.parceriaInfo.parId))
         ).subscribe((response) => {
          if (response) {
            this.emailResponsavelAnali = response as Array<ParceriasEmailResponsavelAnali>;
             //console.log(this.emailResponsavelAnali);
          } else {
            this.alertService.warning('Nenhum registro encontrado');
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));

        // this.route.queryParams.filter(params => params.isAuditorExterno).subscribe(params => {
        //   this.isAuditorExterno = params.isAuditorExterno;
        // });

  }

  disableCampos() {
    return (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }
  visualizarBotao() {
    return ((this.isSMTC || this.isConsulta || this.isAuditorExterno));
  }
  disableApagarIcone() {
    return (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }
  enableEditarIcone() {
    if (this.isConsulta || this.isSMTC || this.isAuditorExterno) {
      return false;
    }
    return true;
  }

  setEmailResponsavelAnali(resp) {
    if (resp) {
      this.emailResponsavelAnaliSend = resp;
      this.emailResponsavelAnaliSend.parId = this.parceriaInfo.parId;
      } else {
      this.emailResponsavelAnaliSend = new ParceriasEmailResponsavelAnali();
      this.emailResponsavelAnaliSend.parId = this.parceriaInfo.parId;
      this.emailResponsavelAnaliSend.emailRespAnaliseId = -1;
    }

  }

  replacerId(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['id'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  salvaEmailResponsavelAnali() {

    if (this.emailResponsavelAnaliSend.emailRespAnaliseEmail === null
      || this.emailResponsavelAnaliSend.emailRespAnaliseEmail === undefined) {
      this.alertService.danger('Dado obrigatório, preencha o email do responsável.');
      return;
    }

    if ( this.emailResponsavelAnaliSend.emailRespAnaliseNome === null
      || this.emailResponsavelAnaliSend.emailRespAnaliseNome === undefined) {
      this.alertService.danger('Dado obrigatório, preencha o nome do responsável..');
      return;
    }
    //this.emailResponsavelAnaliSend.parId = this.emailResponsavelAnaliSend.id.parId;
   // this.emailResponsavelAnaliSend.emailRespAnaliseId = this.emailResponsavelAnaliSend.id.emailRespAnaliseId;
    const data = JSON.stringify(this.emailResponsavelAnaliSend, this.replacerId);

    // tslint:disable-next-line:max-line-length
    this.detalheParceriaApi.updateEmailResponsavelAnali(data).subscribe(async (response) => {
      if (response) {
        this.setupInterface();
        this.setEmailResponsavelAnali(undefined);
        this.modalEdicaoReference.close();
        //await this.getParceriaInfo(this.emailResponsavelAnaliSend.parId);
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }

  excluirEmailResponsavelAnali() {
    this.detalheParceriaApi.excluirEmailResponsavelAnali(this.emailResponsavelAnaliSend).subscribe((response) => {
      if (response) {
        this.setupInterface();
        this.setEmailResponsavelAnali(undefined);
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }

   openEdicao(content, resp) {
    this.setEmailResponsavelAnali(resp);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false,  windowClass: "modalProcesso" });
  }

  openExcluir(content, resp) {
    this.setEmailResponsavelAnali(resp);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false });
  }

}
