<div *ngIf="parceriaInfo != undefined">
    <div *ngIf="notificacoes.length==0">
        <span class="mensagem-vazio">Nenhuma notificação foi encontrada.</span>
    </div>
    <div *ngIf="notificacoes.length>0">
        <table class="table table-striped" style="margin-top:30px" id="tabelaNotificacoes" [mfData]="notificacoes"
            #mtNotificacoes="mfDataTable" [mfRowsOnPage]="5">
            <thead>
                <tr>
                    <th data-html2canvas-ignore="true"></th>
                    <th>Nome notificação</th>
                    <th>Data</th>
                    <th>Situação</th>
                    <th>Prazo p/ resposta <small>(dias corridos)</small></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of mtNotificacoes.data; let j = index;">
                    <td class="col-auto" data-html2canvas-ignore="true">
                        <i ngbTooltip="editar" *ngIf="isFiscal"  class="fa fa-pencil" aria-hidden="true"
                            (click)="openEdicao(contentEdicao, data)"></i>
                         <i ngbTooltip="visualizar" *ngIf="!isAuditorExterno" 
                         class="fa fa-search" aria-hidden="true" (click)="openEdicao(contentEdicao, data)"></i>
                    </td>
                    <td>{{data.nome}}</td>
                    <td>{{data.situacaoDataStr}}</td>
                    <td>{{data.situacao}}</td>
                    <td>{{data.diasPrazo}}</td>
                </tr>
            </tbody>
            <tfoot data-html2canvas-ignore="true ">
                <tr>
                    <td colspan="12 ">
                        <div *ngIf="notificacoes.length>5" class="resultPorPagina">Resultados por página:</div>
                        <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
    <div>
        <button *ngIf="isFiscal" (click)="openEdicao(contentEdicao, undefined) " id="button-insert_doc " class="btn btn-primary btn-info" 
            type="button " title="Adicionar novo" [hidden]="parceriaInfo.parId===-1 || isConsulta">
            <i class="fa fa-plus "><span class="texto-botao">Adicionar novo</span> </i>
        </button>
    </div>
    <ng-template #contentEdicao let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Notificação de Apontamentos</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click');notificacaoSend=undefined" title="fechar"><span
                    aria-hidden="true">&times;</span></button>
        </div>
        <form #edicao="ngForm" novalidate>
            <div class="modal-body">
                <div class="form-row">
                    <span style="font-weight: bold;">&nbsp;&nbsp;Período de análise: {{notificacaoSend.textoPeriodo}}</span>
                    <br/>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-8">
                        <label for="nome">Nome da notificação</label>
                        <span [hidden]="nome.valid" class="campoObrigatorio">*</span>
                        <input type="text" [disabled]="isConsulta || !isFiscal || notificacaoSend.situacaoId == 4" class="form-control" style="width: 100%" id="nome" name="nome"
                            #nome="ngModel" class="form-control" type="text" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="notificacaoSend.nome" [value]="notificacaoSend.nome" required>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="diasPrazo">Prazo de resposta <small>(dias corridos)</small></label>
                        <span [hidden]="diasPrazo.valid" class="campoObrigatorio">*</span>
                        <input type="number" class="form-control" id="diasPrazo" [disabled]="isConsulta || !isFiscal || notificacaoSend.situacaoId == 4" style="width: 100%" name="diasPrazo"
                            #diasPrazo="ngModel" class="form-control" type="text" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="notificacaoSend.diasPrazo" [value]="notificacaoSend.diasPrazo" required>
                    </div>
                </div>
                <table class="table tabela-inputs tabela-tabela" id="tabelaNotificacaoItens" [mfData]="notificacaoSend.notificacaoItens"
                    #mtNotificacaoItens="mfDataTable">
                    <thead>
                        <tr>
                            <th style="width: 20%;">Item</th>
                            <th style="width: 20%;">Apontamento</th>
                            <th *ngIf="notificacaoSend.id != -1">Situação</th>
                            <th style="width: 10%;">Devolução</th>
                            <th style="width: 20%;" *ngIf="notificacaoSend.id != -1">Observação OSC</th>
                            <th style="width: 20%;" *ngIf="notificacaoSend.id != -1">Observação analista</th>
                            <th style="width: 1%;" *ngIf="notificacaoSend.id == -1"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="vertical-align: middle;" *ngFor="let data of mtNotificacaoItens.data">
                            <td><input type="text" class="form-control input-tabela" [(ngModel)]="data.texto" [ngModelOptions]="{standalone: true}" [disabled]="!isFiscal || notificacaoSend.situacaoId == 4" style="height: 150px;"></td>
                            <td>
                                <textarea [disabled]="isConsulta || !isFiscal || notificacaoSend.situacaoId == 4" ng-d style="width:100%; height: 150px; margin-top:0;" id="apontamento" class="form-control" #apontamento="ngModel" [(ngModel)]="data.apontamento"
                                    [ngModelOptions]="{standalone: true}" (change)="updateTextAreaApontamento($event.target.value, data)">{{data.apontamento}}</textarea>
                            </td>
                            <td *ngIf="notificacaoSend.id != -1">
                                <select class="form-control input-tabela" [(ngModel)]="data.situacaoId" [ngModelOptions]="{standalone: true}" [disabled]="isConsulta || !isFiscal || notificacaoSend.id == -1 || notificacaoSend.situacaoId == 4" style="height: 150px; margin-top:0;">
                                    <option value="1">Pendente</option>
                                    <option value="2">Atendido</option>
                                    <option value="3">Mantido</option>
                                    <option value="4">Baixado</option>
                                </select> 
                            </td>
                            <td>
                                <input class="form-control input-tabela" [(ngModel)]="data.devolucao" [ngModelOptions]="{standalone: true}" [disabled]="isConsulta || !isFiscal || notificacaoSend.situacaoId == 4" style="height: 150px;" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
                            </td>
                            <td *ngIf="notificacaoSend.id != -1">
                                <textarea [disabled]="isConsulta || isFiscal || notificacaoSend.situacaoId == 4" ng-d style="width:100%; height: 150px; margin-top:0;" id="observacaoOsc" class="form-control" #observacaoOsc="ngModel" [(ngModel)]="data.observacaoOsc"
                                    [ngModelOptions]="{standalone: true}" (change)="updateTextAreaObservacaoOsc($event.target.value, data)">{{data.observacaoOsc}}</textarea>
                            </td>
                            <td *ngIf="notificacaoSend.id != -1">
                                <textarea [disabled]="isConsulta || !isFiscal || notificacaoSend.id == -1 || notificacaoSend.situacaoId == 4" ng-d style="width:100%; height: 150px; margin-top:0;" id="observacaoAnalista" class="form-control" #observacaoAnalista="ngModel" [(ngModel)]="data.observacaoAnalista"
                                    [ngModelOptions]="{standalone: true}" (change)="updateTextAreaObservacaoAnalista($event.target.value, data)">{{data.observacaoAnalista}}</textarea>
                            </td>
                            <td *ngIf="notificacaoSend.id == -1" style="vertical-align: middle;">&nbsp;<i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true" (click)="excluirNotificacaoItem(data)"></i></td>
                        </tr>
                    </tbody>
                    <tfoot data-html2canvas-ignore="true">
                        <tr>
                            <td colspan="6"><button class="btn btn-primary btn-peq" (click)="adicionarItem()" *ngIf="isFiscal">Incluir</button></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="modal-footer" style="width: 100%;">
                <button type="button" class="btn btn-default" (click)="c('Close click');notificacaoSend=undefined" *ngIf="!isConsulta && !isAuditorExterno">Cancelar</button>
                <button type="button" class="btn btn-default" (click)="salvaNotificacao()" *ngIf="!isConsulta && !isAuditorExterno && notificacaoSend.situacaoId != 4">Salvar</button>
                <button type="button" class="btn btn-primary" (click)="enviaNotificacao()" *ngIf="!isConsulta && isFiscal && notificacaoSend.situacaoId != 4">Salvar e enviar</button>
                <button type="button" class="btn btn-primary" (click)="respondeNotificacao()" *ngIf="!isConsulta && !isFiscal && !isAuditorExterno && notificacaoSend.situacaoId != 4">Salvar e enviar</button>
                <button type="button" class="btn btn-primary" (click)="concluiNotificacao()" *ngIf="!isConsulta && isFiscal && notificacaoSend.id != -1 && notificacaoSend.situacaoId != 4">Concluir notificação</button>
                <button type="button" class="btn btn-primary" (click)="abreJustificativa()" *ngIf="!isConsulta && isFiscal && notificacaoSend.situacaoId == 4">Reabrir</button>
                <button type="button" class="btn btn-primary" (click)="abreJustificativa()" *ngIf="notificacaoSend.situacaoId == 5">Ver justificativa de reabertura</button>
            </div>
            <div [hidden]="!verJustificativa" id="divJustificativa">
                <div class="modal-body">
                    <label for="justificativa">Justificativa de reabertura</label>
                    <textarea [disabled]="(!isFiscal && !isAuditorExterno) || notificacaoSend.situacaoId != 4 || isConsulta" ng-d style="width:100%; height: 150px;" id="justificativa" class="form-control"
                        #justificativa="ngModel" [(ngModel)]="notificacaoSend.justificaReabertura"
                        [ngModelOptions]="{standalone: true}" (change)="updateTextAreaJustificativa($event.target.value)">{{notificacaoSend.justificaReabertura}}</textarea>
                </div>
                <div class="modal-footer" style="width: 100%;">
                    <button type="button" class="btn btn-default" *ngIf="!isConsulta && isFiscal && notificacaoSend.situacaoId == 4" (click)="fechaJustificativa()">Cancelar</button>
                    <button type="button" class="btn btn-default" *ngIf="(!isConsulta && !isFiscal && !isAuditorExterno) || notificacaoSend.situacaoId != 4" (click)="fechaJustificativa()">Ocultar justificativa</button>
                    <button type="button" class="btn btn-primary" (click)="reabreNotificacao()" *ngIf="!isConsulta && isFiscal && notificacaoSend.situacaoId == 4">Reabrir</button>
                </div>
            </div>
        </form>
    </ng-template>
</div>