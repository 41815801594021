import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class PlanejamentoTermos {

    public parId: number;
    public terId: number;
    public terTermoArquivo: string;
    public terTermoArquivoOriginal: string;
    public terTermoData: Date;
    public terTermoTipo: string;
    public terTermoDocumento: Blob;
    public terTermoDocumentoDataFormat: NgbDateStruct;
    public terClassificacao: number;
    public terClassificacaoTexto: string;
	public terDataInicioExecucao: Date;
    public terDataInicioExecucaoDataFormat: NgbDateStruct;
	public terDataPublicacao: Date;
    public terDataPublicacaoDataFormat: NgbDateStruct;
}
