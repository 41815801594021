<div w3-include-html="../../@shared/components/headerPortal/header.component.html">
    <div class="container-fluid mb-3" style="min-height: 450px;">
        <div class="infoGeral container-fluid">
            <h2>Planilha de Colaboradores</h2>
            <h3>Filtros de pesquisa</h3>
            <p><em>Você pode filtrar os resultados preenchendo um ou combinando mais de um dos campos abaixo:</em></p>
            <br>
            <div class="form-row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="">Órgão ou entidade (PMPA)</label>
                        <div class="select-wrapper">
                            <ng-select [items]="this.utils.secretariasOptions" class="form-control" bindLabel="name"
                                [(ngModel)]="busca.idOrgao" bindValue="id" notFoundText=" não encontrado"
                                (change)="selectOrgaoParceria($event)">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="nomeOsc">Nome da OSC</label>
                        <input type="text" [(ngModel)]="busca.nomeOsc" style="width: 100% " id="nomeOsc"
                            class="form-control " [ngModelOptions]="{standalone: true} "
                            placeholder="informe o nome da OSC" [value]="busca.nomeOsc" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="cnpjOsc">CNPJ da OSC</label>
                        <input type="text" class="form-control" id="cnpjOsc" [ngModelOptions]="{standalone: true} "
                            [(ngModel)]="busca.cnpjOsc" [value]="busca.cnpjOsc" placeholder="informe o CNPJ da OSC" [textMask]="mascaraCNPJ"/>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="parNum">Nº do termo</label>
                        <input type="text" class="form-control" id="parNum" [ngModelOptions]="{standalone: true} "
                            [(ngModel)]="busca.parNum" [value]="busca.parNum" placeholder="informe o nº do termo" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="modalidade">Modalidade</label>
                        <div class="select-wrapper" style="width: 100%">
                            <ng-select [items]="this.utils.modalidadeOptions" class="form-control" bindLabel="name"
                                [(ngModel)]="busca.modalidade" bindValue="id" notFoundText=" não encontrada"
                                (change)="selectModalidade($event)">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <label for="statusParceria">Status da Parceria</label>
                    <div class="select-wrapper" style="width: 100%">
                        <ng-select [items]="this.utils.statusParceiraOptions" [(ngModel)]="busca.statusParceria"
                            name="statusParceria" bindValue="id" notFoundText=" não encontrado" bindLabel="name"
                            class="form-control" (change)="selectStatusParceria($event)">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="nomeFuncionario">Nome do funcionário</label>
                        <input type="text" class="form-control" id="nomeFuncionario" [ngModelOptions]="{standalone: true} "
                            [(ngModel)]="busca.nomeFuncionario" [value]="busca.nomeFuncionario" placeholder="informe o nome do funcionário" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="cpfFuncionario">CPF do funcionário</label>
                        <input type="text" class="form-control" id="cpfFuncionario" [ngModelOptions]="{standalone: true} "
                            [(ngModel)]="busca.cpfFuncionario" [value]="busca.cpfFuncionario" placeholder="informe o CPF do funcionário" [textMask]="mascaraCPF" />
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4 btn-inline">
                    <div class="form-group">
                        <button class="btn btn-primary" (click)="limparFiltros()">Limpar</button>
                        <button class="btn btn-primary" (click)="geraArquivo()">Gerar Excel</button>
                    </div>
                </div>
            </div>
            <div [hidden]="!(loading$ | async) ">
                <div class="loading-overlay "></div>
                <div class="loading-info ">
                    <i class="fa fa-spinner fa-4x fa-spin "></i>
                    <div id="carregando ">Aguarde</div>
                </div>
            </div>
        </div>
    </div>
</div>