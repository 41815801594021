import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { share, switchMap, delay, tap, filter } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from './../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../../apis/detalheParceriaApi';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { Mascaras } from '../../@shared/components/Mascaras';
import { UserService } from '../../@core/user.service';
import { DateConverter } from 'src/app/@core/date-converter';
import { Orgao } from '../Orgao';
import { PessoaInfo } from "src/app/modulo2/detalheParceria/pessoaInfo";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BuscaCampos } from 'src/app/portal/exibeParceriasPortal/buscaCampos';
import { Utils } from 'src/app/@shared/utils';
import { Dotacao } from '../Licitacon';
import { Location } from '@angular/common';
import * as _ from 'lodash';


@Component({
  selector: 'app-info-parceria-m1',
  templateUrl: './informacaoParceriaM1.component.html',
  styleUrls: ['./informacaoParceriaM1.component.scss']
})
export class InformacaoParceriaM1Component implements OnInit {

  @Input()
  public parceriaInfo: ParceriaInfo = new ParceriaInfo();
  @Input()
  private isPortal: boolean;
  @Output() valueChange = new EventEmitter();
  @Input()
  private modulo: string;
  private parceriaInfoSend: ParceriaInfo;
  @Output() parInfoID = new EventEmitter();
  parId: number;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  private orgaoList: Array<Orgao>;
  //Licitacon
  maskNumLicitacao = {mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/,/\d/, /\d/, /\d/, /\d/, /\d/, /\d/,/\d/, /\d/, /\d/, /\d/, /\d/, /\d/,/\d/,/\d/], guide: false }
  maskAnoLicitacao = {mask: [/\d/,/\d/,/\d/,/\d/], guide: false}
  mascaraCNPJ = { mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/], guide: false };
  mascaraCPF = { mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/], guide: false };
  mascaraCEP = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/], guide: false };
  mascaraNro = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/], guide: false };
  mascaraSEI1 = { mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/], guide: false };
  mascaraSEI2 = { mask: [/\d/, /\d/, '.', /\d/, '.', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/], guide: false };
  // tslint:disable-next-line:max-line-length
  mascaraTelFixo = { mask: ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], guide: false };
  mascaraTelCel = { mask: ['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], guide: false };
  celularMatch = /^\([1-9]{2}\)9[7-9]{1}[0-9]{3}\-[0-9]{4}$/;
  fixoMatch = /^\([1-9]{2}\)[1-7]{1}[0-9]{3}\-[0-9]{4}$/;
  seiMatch = /^[0-9]{2}\.[0-9]{1,2}\.[0-9]{9}\-[0-9]{1}$/;
  inValidCpfCnpj: boolean = false;
  modalEdicaoReference: any;
  pessoaInfoList: Array<PessoaInfo>;
  private cnpjTemporario: ParceriaInfo;
  mascaraSei: any;
  parDataLimitePrestacao:String;

  pessoaInfo: PessoaInfo;
  parList: ParceriaInfo[];
  modalidadeSelecionada: any;
  orgaoSelecionado: any;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  isAuditorExterno: boolean;
  
  private isOrgaoSMS: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isOrgaoSMS$ = this.isOrgaoSMS.asObservable().pipe(share());

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private router: Router,
    private mascaras: Mascaras,
    private utils: Utils,
    private location: Location) {

  }

   ngOnInit() {
    this.getOrgaoLista();
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();
    this.cnpjTemporario = new ParceriaInfo();
    if (this.parceriaInfo !== undefined) {
      this.cnpjTemporario.parOrgCnpj = this.parceriaInfo.parOrgCnpj;
      this.cnpjTemporario.parOrgNome = this.parceriaInfo.parOrgNome;
      this.setupInterface();
    }
  }

  setupInterface() {
    this.loading.next(true);
    this.parceriaInfo.parOrgUF = 'RS';
    this.isOrgaoSMS.next(false);
    if (this.parceriaInfo.parId !== -1) {
      this.route.paramMap.pipe(
        switchMap(() =>
          this.detalheParceriaPortalApi.getParceriaInfo(this.parceriaInfo.parId))
      ).subscribe((response) => {
        if (response) {
          this.parceriaInfo = response as ParceriaInfo;
          // console.log(this.parceriaInfo.parOrgDtFimVigencia);
          // console.log(this.parceriaInfo.parOrgDtIniVigencia);
          this.modalidadeSelecionada = this.parceriaInfo.parOrgModalidade.toString();
          this.orgaoSelecionado = this.parceriaInfo.zgororgaoCdorgao2;
          if (this.parceriaInfo.zgororgaoCdorgao2 === 18){
            this.isOrgaoSMS.next(true);
          }
          //  = this.modalidadeOptions.map( element => {
          //     if (element.id === string.values(this.parceriaInfo.parOrgModalidade)) {
          //       console.log(element.nomeOrgao);
          //         return element.nomeOrgao; }
          //     });
          
          this.parceriaInfo.parNroSeimask = this.parceriaInfo.parNroSeimask.trim();
          this.parceriaInfo.parOrgDtIniVigenciaFormat = this.dateConverter.fromTimestampToNgbDateStruct(
            this.dateConverter.fromDateToTimestamp(this.parceriaInfo.parOrgDtIniVigencia));
          if (this.parceriaInfo.parOrgDtFimVigencia != null) {
            this.parceriaInfo.parOrgDtFimVigenciaFormat = this.dateConverter.fromTimestampToNgbDateStruct(
              this.dateConverter.fromDateToTimestamp(this.parceriaInfo.parOrgDtFimVigencia));

            // console.log(this.dateConverter.fromDateToTimestamp(this.parceriaInfo.parOrgDtFimVigencia));
            // console.log(this.parceriaInfo.parOrgDtFimVigenciaFormat);
          }
          if (this.parceriaInfo.parDataLimitePrestacao != null) {
            this.parDataLimitePrestacao = this.formatDate(new Date(this.parceriaInfo.parDataLimitePrestacao))
          }
          if (this.parceriaInfo.parDataLimiteAnalise != null) {
            this.parceriaInfo.parDataLimiteAnaliseFormat = this.dateConverter.fromTimestampToNgbDateStruct(
              this.dateConverter.fromDateToTimestamp(this.parceriaInfo.parDataLimiteAnalise));
          }
        } else {
          this.alertService.warning('Nenhum registro encontrado');
        }

      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));

      this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
        this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
      });

    }
    if (this.isFiscal || this.isConsulta || this.isSMTC) {
      this.isAuditorExterno = false;
    }
  }

  disableCampos() {
    return (this.isSMTC || this.isPortal || this.isConsulta);
  }


  aplicaMascaraTelefone(telefone) {
    return this.mascaras.aplicaMascaraTelefone(telefone, '51');
  }

  aplicaMascaraReais(valor) {
    return this.mascaras.aplicaMascaraReais(valor);
  }

  aplicaMascaraCNPJ(doc) {
    return this.mascaras.aplicaMascaraDocumento(doc, 2);
  }

  updatePortal(event) {
    const value = event.currentTarget.value;
    this.valueChange.emit({ value, tipoPublicaPortal: 'Portal' });
  }

  updateParOrgUF(event){
    this.parceriaInfo.parOrgUF = event.sigla;
  }

  updateModalidade(event) {
    if (event === undefined) {
      this.parceriaInfo.parOrgModalidade = undefined;
    }
    else {
      const value = event.id;
      this.parceriaInfo.parOrgModalidade = Number(value);
    }
  }

  updateStatus(event) {
    const value = event.currentTarget.value;
    this.parceriaInfo.parOrgStatus = value;
  }

  updateClassificacao(event) {
    const value = event.currentTarget.value;
    this.parceriaInfo.parClassificacao = value;
    // if(value == 1 || value == 2){
    //   if(!(this.parceriaInfo.dotacoes && this.parceriaInfo.dotacoes.length > 0)){
    //     this.adicionarDotacao()
    //   }
    // }
  }

  updateNomeOrgao(event) {
    const value = event.id;
    this.parceriaInfo.zgororgaoCdorgao2 = value;
    if (this.parceriaInfo.zgororgaoCdorgao2 === 18){
      this.isOrgaoSMS.next(true);
    }
    else
    this.isOrgaoSMS.next(false);
  }

  async getOrgaoLista() {
    this.loading.next(true);
    await this.detalheParceriaPortalApi.getOrgaos()
      .subscribe((response) => {
        if (response) {
          this.orgaoList = response as Array<Orgao>;
          //console.log(this.orgaoList);
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  validaCPF(cpf) {


    cpf = cpf.replace(/[\-\/\.\s-]+/g, '');

    if (!this.mascaras.validaCpfCnpj(cpf)) {
      this.alertService.danger('O CPF do Dirigente/Representante Legal é inválido');
      return false;
    }
    return true;
  }
  async buscaPessoa(documento) {
    this.loading.next(true);
    const doc = documento.replace(/[\-\/\.\s-]+/g, '');
    const num = parseInt(doc, 10);
    if (!isNaN(num)) {
      const tipo = 2;
      //this.setDespesaSend(undefined);
      this.inValidCpfCnpj = !this.mascaras.validaCpfCnpj(documento);
      if (!this.inValidCpfCnpj) {
        await this.detalheParceriaApi.getPessoa(documento.replace(/[^\d]+/g, ''), tipo)
          .subscribe((response) => {
            if (response) {
              this.pessoaInfo = response as PessoaInfo;
              this.parceriaInfo.parOrgNome = this.pessoaInfo.nome;
              this.parceriaInfo.parOrgCnpj = doc;
              this.loading.next(false);
              this.cnpjTemporario = new ParceriaInfo();
              this.cnpjTemporario.parOrgCnpj = this.parceriaInfo.parOrgCnpj;
              this.cnpjTemporario.parOrgNome = this.parceriaInfo.parOrgNome;
            } else {
              this.alertService.warning('Nenhum CNPJ encontrado');
              this.loading.next(false);
            }
          }, (response) => {
            if (response.status >= 500) {
              this.alertService.danger('O CNPJ informado não foi encontrado no cadastro de pessoas.');
              this.parceriaInfo.parOrgCnpj = undefined;
              this.parceriaInfo.parOrgNome = '';
            } else {
              this.alertService.warning('O CNPJ informado não foi encontrado no cadastro de pessoas');
              this.parceriaInfo.parOrgCnpj = undefined;
              this.parceriaInfo.parOrgNome = '';
            }
            this.loading.next(false);
          }, () => this.loading.next(false));
        this.loading.next(false);
      } else {
        this.inValidCpfCnpj = true;
        this.alertService.danger('CNPJ inválido');
        this.parceriaInfo.parOrgCnpj = undefined;
        this.loading.next(false);
      }
    }
    else {
      if (documento === undefined || documento === '') {
        this.alertService.danger('Informe o nome da Parceria desejada.');
        return;
      }
      this.detalheParceriaApi.getPessoaNome(documento, 2)
        .subscribe((response) => {
          if (response) {
            this.pessoaInfoList = response as Array<PessoaInfo>;
            this.pessoaInfoList.forEach(element =>{
              if (element.numDocumento != null){
                  element.idIdentPessoa = element.numDocumento;
                }
              }
            )
            /* this.parceriaInfo.parOrgNome = this.pessoaInfo.nomePessoa;
             this.parceriaInfo.parOrgCnpj = documento;*/
            this.loading.next(false);
            // return this.pessoaInfo.nomePessoa;
            if (this.pessoaInfoList.length === 0) {
              this.alertService.warning('Nenhum CNPJ encontrado');
            }
          } else {
            this.alertService.warning('Nenhum CNPJ encontrado');
            this.loading.next(false);
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));
      this.loading.next(false);
    }
  }

  openModal(content, doc) {
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: "modalProcesso" });
  }


  replacerParceria(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['parOrgDtIniVigenciaFormat', 'parOrgDtFimVigenciaFormat', 'parDataLimitePrestacaoFormat', 'parDataLimiteAnaliseFormat', 'idOrgaoCopiaTermo', 'parNumCopiaTermo'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  onDataChange(data) {
    // this.getSelecteditem();
    this.cnpjTemporario.parOrgCnpj = _.padStart(data.idIdentPessoa, 14, '0');
    this.cnpjTemporario.parOrgNome = data.nomePessoa;
    this.inValidCpfCnpj = !this.mascaras.validaCpfCnpj(this.cnpjTemporario.parOrgCnpj);
    if (this.inValidCpfCnpj) {
      this.inValidCpfCnpj = true;
      this.alertService.danger('CNPJ inválido');
      this.cnpjTemporario.parOrgCnpj = undefined;
      this.cnpjTemporario.parOrgNome = undefined;
    } else {
      this.parceriaInfo.parOrgCep = 0;
      this.parceriaInfo.parOrgEndereco = "";
      this.parceriaInfo.parOrgComplemento = "";
      this.parceriaInfo.parOrgNro = 0;
      this.parceriaInfo.parCidade = 'Porto Alegre';
    }
  }

  onSeiChanged() {
    if (this.parceriaInfo.parNroSeimask.length == 16 && this.mascaraSei === this.mascaraSEI1) {
      this.mascaraSei = this.mascaraSEI2;
    }
  }

  aplicaMascaraDocumento(documento) {
    return this.mascaras.aplicaMascaraDocumento(documento, 2);
  }

  async verificaExisteConvenioParceria() {
    let existe: boolean;
    if (this.parceriaInfo.parId === -1) {
        const busca = new BuscaCampos(this.parceriaInfo.zgororgaoCdorgao2, null,
          this.parceriaInfo.parNum, '', '', this.parceriaInfo.parOrgCnpj.replace(/[^\d]+/g, ''), 0, 1, 25);
          this.detalheParceriaPortalApi.getParceriaInfoPortal(busca).subscribe((response) => {
            if (response) {
              this.parList = response as Array<ParceriaInfo>;
              if (this.parList.length > 0) {
                existe = true;
              } else {
                existe = false;
              }
            }
          }, (response) => {
            if (response.status >= 500) {
              this.alertService.danger(response.error);
            } else {
              this.alertService.warning(response.error);
            }
            this.loading.next(false);
          }, () => this.loading.next(false));
          this.loading.next(false);
        }
    else {
      existe = true;
    }
    return existe;
  }

  validateEmail(email) {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  excluirDotacaoItem(item){
    const index: number = this.parceriaInfo.dotacoes.indexOf(item);
    if (index !== -1) {
        this.parceriaInfo.dotacoes.splice(index, 1);
    }
  }

  adicionarDotacao() {
    if(this.parceriaInfo.parId !== -1){
      if((this.parceriaInfo.parClassificacao == 1 || this.parceriaInfo.parClassificacao == 2) && !this.parceriaInfo.dotacoes){
        this.parceriaInfo.dotacoes = [];
      }
    }
    if(!this.parceriaInfo.dotacoes){
      this.parceriaInfo.dotacoes = [];
    }
    var dotacao = new Dotacao();
    dotacao.projetoAtividade = ''
    dotacao.recursoOrcamentario = ''
    dotacao.naturezaDespesa = ''
    this.parceriaInfo.dotacoes.push(dotacao);
}

cancelar(){
  this.location.back();
}

  async salvaParceria() {
    // if(this.parceriaInfo.parClassificacao == 1 || this.parceriaInfo.parClassificacao == 2){
    //     if(!(this.parceriaInfo.dotacoes && this.parceriaInfo.dotacoes.length > 0)){
    //       this.alertService.danger('Dotação de deve ser informada quando a classificação do termo é "Termo de colaboração" ou "Termo de Fomento".');
    //       return;
    //     }

    //      var info = this.parceriaInfo.dotacoes.filter(d => (d.projetoAtividade == '' || d.naturezaDespesa == '' || d.recursoOrcamentario == ''))

    //     if(info.length > 0){
    //       this.alertService.danger('Deve possuir pelo menos 1 dotação e todos os campos devem ser preenchidos quando a classificação do termo é "Termo de colaboração" ou "Termo de Fomento".');
    //       return;
    //     }
    // }

    if(this.parceriaInfo.dotacoes && this.parceriaInfo.dotacoes.length > 0){
      if(this.parceriaInfo.parClassificacao == 3){
        var result = confirm("A classificação do termo foi alterada, deseja remover as dotações cadastradas?")
        result ? this.parceriaInfo.dotacoes = [] : null
      }
    }

    if (this.parceriaInfo.parOrgDtIniVigenciaFormat != null) {
      this.parceriaInfo.parOrgDtIniVigencia = this.dateConverter.fromNgbDateStructToDate(this.parceriaInfo.parOrgDtIniVigenciaFormat);
      if (!this.utils.isValidDate(this.parceriaInfo.parOrgDtIniVigencia)) {
        this.alertService.danger('Data Vigência Inicial Inválida.');
        return;
      }
    }
    if (this.parceriaInfo.parOrgDtFimVigenciaFormat != null) {
      this.parceriaInfo.parOrgDtFimVigencia = this.dateConverter.fromNgbDateStructToDate(this.parceriaInfo.parOrgDtFimVigenciaFormat);
      if (!this.utils.isValidDate(this.parceriaInfo.parOrgDtFimVigencia)) {
        this.alertService.danger('Data Vigência Final Inválida.');
        return;
      }
    }
    if (this.parceriaInfo.parDataLimiteAnaliseFormat != null) {
      this.parceriaInfo.parDataLimiteAnalise = this.dateConverter.fromNgbDateStructToDate(this.parceriaInfo.parDataLimiteAnaliseFormat);
      if (!this.utils.isValidDate(this.parceriaInfo.parDataLimiteAnalise)) {
        this.alertService.danger('Data Limite Análise Inválida.');
        return;
      }
    }

    if(!this.parceriaInfo.anoLicitacao || !this.parceriaInfo.modalidadeLicitacao || !this.parceriaInfo.nLicitacao || 
      ((this.parceriaInfo.contratadoVencLicitacao == undefined || this.parceriaInfo.contratadoVencLicitacao == false)  && !this.parceriaInfo.justNovoContratadoLicitacao)){
      return this.alertService.danger('Existem campos obrigatórios da licitação que devem ser preenchidos antes de salvar.');
    }

    if(this.parceriaInfo.contratadoVencLicitacao){
      this.parceriaInfo.justNovoContratadoLicitacao = null;
    }

    if(this.parceriaInfo.anoLicitacao.toString().length < 4){
      return this.alertService.danger('Campo Ano da licitação que deve ser preenchido com 4 dígitos.');
    }

    this.parceriaInfo.zgororgaoCdorgao2 = this.orgaoSelecionado;
    if (!(this.parceriaInfo.zgororgaoCdorgao2 && this.parceriaInfo.parCidade &&
      this.parceriaInfo.parOrgBairro && this.parceriaInfo.parOrgUF &&
      this.parceriaInfo.parNroSeimask && this.parceriaInfo.parNum &&
      this.parceriaInfo.parOrgCep && this.parceriaInfo.parOrgCnpj &&
      this.parceriaInfo.parOrgEndereco && this.parceriaInfo.parOrgNome &&
      this.parceriaInfo.parOrgStatus && this.parceriaInfo.parOrgDtFimVigencia &&
      this.parceriaInfo.parOrgDtIniVigencia && this.parceriaInfo.parOrgModalidade &&
      this.parceriaInfo.parOrgEmail && this.parceriaInfo.parOrgEmailDirigente &&
      this.parceriaInfo.parOrgNomeDirigente && this.parceriaInfo.parOrgNmetasAtend && this.parceriaInfo.parOrgCpfDirigente &&
      this.parceriaInfo.parOrgDirigenteCep && this.parceriaInfo.parOrgDirigenteBairro && this.parceriaInfo.parOrgDirigenteCidade &&
      this.parceriaInfo.parOrgDirigenteEndNro && this.parceriaInfo.parOrgDirigenteEndereco &&
      (this.parceriaInfo.parOrgTelefone || this.parceriaInfo.parOrgCelular) && this.parceriaInfo.parVlrPrevisto && 
      this.parceriaInfo.parOrgVlrMensalRepasse && this.parceriaInfo.parClassificacao &&
      this.parceriaInfo.parDataLimitePrestacao && this.parceriaInfo.parDataLimiteAnalise)) {
      return this.alertService.danger('Existem campos obrigatórios que devem ser preenchidos antes de salvar.');
    }

    if (!this.validaCPF(this.parceriaInfo.parOrgCpfDirigente)) {
      return;
    }

    if (!this.validateEmail(this.parceriaInfo.parOrgEmail)) {
      return this.alertService.danger('Email da Parceira é inválido.');
    }

    if (!this.validateEmail(this.parceriaInfo.parOrgEmailDirigente)) {
      return this.alertService.danger('Email do Dirigente/Representante Legal é inválido.');
    }

    this.selecionaCNPJ();
    this.parceriaInfo.parOrgCep = Number(this.parceriaInfo.parOrgCep.toString().replace(/[^\d]+/g, ''));
    this.parceriaInfo.parNroSei = this.parceriaInfo.parNroSeimask.toString().replace(/[^\d]+/g, '');
    this.parceriaInfo.parOrgCpfDirigente = this.parceriaInfo.parOrgCpfDirigente.toString().replace(/[^\d]+/g, '');
    this.parceriaInfo.parOrgDirigenteCep = Number(this.parceriaInfo.parOrgDirigenteCep.toString().replace(/[^\d]+/g, ''));

    if (!this.parceriaInfo.parNroSeimask.trim().match(this.seiMatch)) {
      return this.alertService.danger('Número SEI inválido.');
    }
    if (this.parceriaInfo.parOrgObjeto.length > 300) {
      return this.alertService.danger('Limite de 300 caracteres para descrição do Objeto/Finalidade');
    }

    if (this.parceriaInfo.parOrgCelular && !this.parceriaInfo.parOrgCelular.match(this.celularMatch)) {
      return this.alertService.danger('Número celular inválido.');
    }
    if (this.parceriaInfo.parOrgTelefone && !this.parceriaInfo.parOrgTelefone.match(this.fixoMatch)) {
      return this.alertService.danger('Número telefone fixo inválido.');
    }

    if (this.parceriaInfo.parOrgDtIniVigencia > this.parceriaInfo.parOrgDtFimVigencia) {
      return this.alertService.danger('Data de vigência final não pode ser inferior a data de vigência inicial.');
    }



    this.parceriaInfo.parOrgDtIniVigencia = this.dateConverter.fromNgbDateStructToDate(this.parceriaInfo.parOrgDtIniVigenciaFormat);
    if (!this.utils.isValidDate(this.parceriaInfo.parOrgDtIniVigencia)) {
      this.alertService.danger('Data Vigência Inicial Inválida.');
      return;
    }

    if (!this.parceriaInfo.parOrgModalidade === undefined) {
      return this.alertService.danger('Selecione uma modalidade.');
    }

    if(!this.parceriaInfo.parOrgCnpj){
      this.alertService.danger("O campo CNPJ Parceira precisa ser preenchido.");
      return;
    }
    const existe = await this.verificaExisteConvenioParceria();
    if (existe && this.parceriaInfo.parId === -1) {
      this.alertService.danger('Parceria já cadastrada previamente.');
      return;
    } else if (this.parceriaInfo.parId === -1) {
      this.parceriaInfo.parEmailRespAnaliseSE = 0;
      this.parceriaInfo.parExcecaoTravaSerial = 0;
      this.parceriaInfo.parExcecaoTravaAnaliseSerial = 0;
      this.parceriaInfo.execTotQtdPF = 0;
      this.parceriaInfo.execTotQtdPJ = 0;
      this.parceriaInfo.execTotQtdConsumo = 0;
      this.parceriaInfo.execTotQtdPerm = 0;
      this.parceriaInfo.execTotQtdOutros = 0;
      //this.parceriaInfo.execPortDevolucaoPublicar = 0;
      this.parceriaInfo.parOrgVlTotal = 0;
    }

    //Preparação objeto Informações Licitacon
    //this.parceriaInfo.dependeDataInicio = 'S'; //sempre sim conforme info cliente
    //this.parceriaInfo.garantia = 'N'; //sempre não conforme info cliente
    //this.parceriaInfo.tipoContratado = 'J'; //sempre PJ conforme info cliente
    //this.parceriaInfo.geraDespesa = (this.parceriaInfo.parClassificacao == 1 || this.parceriaInfo.parClassificacao == 2) ? 'S' : 'N';

    const data = JSON.stringify(this.parceriaInfo, this.replacerParceria);
    this.detalheParceriaApi.updateParceria(data).subscribe(async (response) => {
      if (response && response.body) {
        const id = Number(response.body);
        if (this.parceriaInfo.parId === -1) {
          this.alertService.info('Cadastro incluído com sucesso!');
          this.parceriaInfo = new ParceriaInfo();
          this.parceriaInfo.parId = id;
          this.parInfoID.emit({ id });
          this.router.navigate(['planejamento/detalheParceriaM1'], {queryParams: {id: id}});
        }
        else {
          this.alertService.info('Cadastro atualizado com sucesso!');
          this.location.back();
        }
        this.setupInterface();
        // this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      //this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }

  selecionaCNPJ() {
    this.parceriaInfo.parOrgCnpj = this.cnpjTemporario.parOrgCnpj;
    this.parceriaInfo.parOrgNome = this.cnpjTemporario.parOrgNome;
  }

  selecionaCNPJModal() {
    this.selecionaCNPJ();
    this.modalEdicaoReference.close();
  }
  atualizaDataLimitePrestacao() {
    if (this.parceriaInfo != null && this.parceriaInfo.parOrgDtFimVigenciaFormat != null) {
      this.parceriaInfo.parOrgDtFimVigencia = this.dateConverter.fromNgbDateStructToDate(this.parceriaInfo.parOrgDtFimVigenciaFormat);
      if (this.parceriaInfo.parDataLimitePrestacao == null) {
        this.parceriaInfo.parDataLimitePrestacao = new Date();
      }
      this.parceriaInfo.parDataLimitePrestacao = this.addDays(this.parceriaInfo.parOrgDtFimVigencia, 120);
      this.parDataLimitePrestacao = this.formatDate(this.parceriaInfo.parDataLimitePrestacao);
    }
  }
  formatDate(d: Date): string {
    return ('0' + d.getDate()).slice(-2) + '/' + ('0' + (d.getMonth() + 1)).slice(-2) + '/' +
      d.getFullYear();
  }
  addDays(originalDate, days) {
    return new Date(originalDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  copiaTermo() {
    if (this.parceriaInfo.idOrgaoCopiaTermo == undefined || this.parceriaInfo.idOrgaoCopiaTermo == 0) {
      this.alertService.danger('Órgão deve ser selecionado.');
      return;
    }

    if (this.parceriaInfo.parNumCopiaTermo == undefined || this.parceriaInfo.parNumCopiaTermo == '') {
      this.alertService.danger('Número do termo deve ser informado.');
      return;
    }

    this.detalheParceriaApi.copiaTermo(this.parceriaInfo.idOrgaoCopiaTermo, this.parceriaInfo.parNumCopiaTermo).subscribe((response) => {
      this.parceriaInfo = response;
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
    }, () => this.loading.next(false));
  }

  isEdicao(){
    var id = new URLSearchParams(window.location.search).get('id');
    return id != '-1' ? true : false; 
  }
  
}

