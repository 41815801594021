<div class="container">
    <h1 class="principal">Gráfico de níveis operacionais</h1>
    <p>Informe abaixo o órgão ou a parceria (nome ou CNPJ) ou ainda o número do termo de colaboração para visualizar o
        gráfico dos quantitativos dos documentos: </p>

    <div class="form-row">
        <div class="col">
            <div class="form-group">
                <label for="orgao">Órgão</label>
                <select [ngModel]="busca.orgao" (change)="selectOrgaoParceria($event)"
                    class="form-control form-select custom-select">
                    <option [value]="option.id" *ngFor="let option of this.utils.secretariasOptions">{{option.name}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label for="cnpjParceria">Nome da parceria</label>
                <!--<input style="width: 100% " id="nome" name="busca.nomeOrg " class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.nomeOrg " [value]="busca.nomeOrg " (blur)="selectNomeParceria()" (keydown.enter)="selectNomeParceria()" placeholder="informe o nome">
            -->
                <div class="select-wrapper" style="width: 100%">
                    <ng-select [disabled]="disablePar" (clear)="limpaNomeParceria()"
                        [items]="this.dropdownNomeParceriasList" class="form-control" bindLabel="name"
                        [(ngModel)]="nomeOrg" bindValue="id" notFoundText=" nome não encontrado"
                        (change)="selectNomeParceria()" required>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label for="cnpjParceria">CNPJ da parceria</label>
                <!--<input style="width: 100% " id="cnpj" name="busca.cnpj" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.cnpj " [value]="busca.cnpj" (blur)="selectCNPJ()" (keydown.enter)="selectCNPJ()" class="form-control" placeholder="informe o CNPJ">
            -->
                <div class="select-wrapper" style="width: 100%">
                    <ng-select [disabled]="disableCnpj" (clear)="limpaCNPJ()" [items]="this.dropdownCNPJList"
                        class="form-control" bindLabel="name" [(ngModel)]="cnpj" bindValue="id"
                        notFoundText=" nome não encontrado" (change)="selectCNPJ()" required>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label for="cnpjParceria">Termo de Parceria</label>
                <!--<input style="width: 100% " id="nroTermo" name="busca.numeroTermo" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.numeroTermo " [value]="busca.numeroTermo" (blur)="selectNumeroTermo()" (keydown.enter)="selectNumeroTermo()" class="form-control"
            -->
                <div class="select-wrapper" style="width: 100%">
                    <ng-select [disabled]="disableTermo" (clear)="limpaTermo()" [items]="this.dropdownTermoList"
                        class="form-control" bindLabel="name" [(ngModel)]="numeroTermo" bindValue="id"
                        notFoundText=" termo não encontrado" (change)="selectNumeroTermo()" required>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-md-4 cjto-datas" style="margin-top: 5px;">
                <div class="texto-linha-select-resultados">Período de emissão dos documentos</div>
                <selector-datetime-range style="margin-top: 8px;" class="data-range" [isOnlyDataInicio]="false"
                    [showLabelData]="true" (novoPeriodo)="novoPeriodo($event)"></selector-datetime-range>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label for="classificacaoTermo">Classificação</label>
                    <select class="form-control form-select custom-select" [(ngModel)]="busca.parClassificacao"
                        (change)="selectParClassificacao($event)">
                        <option [value]="option.id" *ngFor="let option of this.utils.parClassificacaoOption">
                            {{option.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label for="cnpjParceria">Status da Parceria</label>
                    <select [ngModel]="busca.status" (change)="selectStatusParceria($event)"
                        class="form-control form-select custom-select">
                        <option [value]="option.id" *ngFor="let option of this.utils.statusParceiraOptions">
                            {{option.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="cjto-botoes input-sem-titulo">
                    <button class="btn btn-primary">Filtrar</button>
                    <button class="btn btn-secondary" (click)="limpar()">Limpar</button>
                    <button class="btn btn-primary" (click)="downloadImage()"><i class="fa fa-download" 
                            aria-hidden="true"></i> Baixar gráfico</button>

                </div>
            </div>
        </div>
        <BR />
        <div id="download" class="grafico">
            <div class="row">
                <div class="col-md-12" style="font-size: 20px; text-align: center;">
                    {{parceriaPeriodo}}
                    <br />
                    {{parClassificacao}}
                    <br />
                    {{parceriaStatus}}
                    <br />
                    &nbsp;
                </div>
            </div>
            <div class="row container-graficoPorOrgao" *ngFor="let pie of pieChartData; index as i">
                <div class="titulo-grafico col-md-12" *ngIf="docsTotais.length > 0 && docsTotais[0]">
                    <div class="orgao-grafico">{{docsTotais[i].orgaoNome}}</div>
                    <div class="parceria-grafico">{{parceriaNome}}</div>
                    <div class="parceria-grafico">{{parceriaCnpj}}</div>
                    <div class="parceria-grafico">{{parceriaTermo}}</div>
                </div>
                <div class="col-md-6 pl-0">
                    <div class="col-md-5 pt-4" style="padding-left: 0.67rem !important;" >
                        <canvas id="i" baseChart [data]="pie[0]" *ngIf="docsTotais.length > 0 && docsTotais[0]" 
                            [labels]="pieChartLabels1" [chartType]="pieChartType" [legend]="false"
                            [options]="chartOptions" [colors]="pieChartColors1">
                        </canvas>
                    </div>
                    <div class="col-md-7">
                        <div class="legenda-grafico" *ngIf="docsTotais.length > 0 && docsTotais[0]">
                            <div class="titulo">De um total de {{aplicaMascaraUnidade(docsTotais[i].total) | number }}
                                documentos incluidos:</div>
                            <ul>
                                <li>
                                    <div class="cor-legenda cor1"></div>
                                    {{aplicaMascaraUnidade(docsTotais[i].totalAConferir)
                                    | number}} Não analisados
                                    ({{aplicaMascaraReais((docsTotais[i].totalAConferir*100)/docsTotais[i].total)}}%)
                                </li>
                                <li>
                                    <div class="cor-legenda cor2"></div>
                                    {{aplicaMascaraUnidade(docsTotais[i].totalAnalisado)
                                    | number}} Analisados
                                    ({{aplicaMascaraReais((docsTotais[i].totalAnalisado*100)/docsTotais[i].total)}}%)
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="col-md-5 pt-4 pl-1" *ngIf="docsTotais.length > 0 && docsTotais[0]">
                        <canvas id="i" baseChart [data]="pie[1]"
                            [labels]="pieChartLabels2" [chartType]="pieChartType" [legend]="false"
                            [options]="chartOptions" [colors]="pieChartColors2">
                        </canvas>
                    </div>
                    <div class="col-md-7 pr-0" *ngIf="docsTotais.length > 0 && docsTotais[0]">
                        <div class="legenda-grafico">
                            <div class="titulo">De um total de {{aplicaMascaraUnidade(docsTotais[i].totalAnalisado)
                                | number }}
                                documentos analisados:</div>
                            <ul>
                                <li>
                                    <div class="cor-legenda cor3"></div>
                                    {{aplicaMascaraUnidade(docsTotais[i].totalConferido)
                                    | number}} Conferidos
                                    ({{aplicaMascaraReais((docsTotais[i].totalConferido*100)/docsTotais[i].totalAnalisado)}}%)
                                </li>
                                <li>
                                    <div class="cor-legenda cor4"></div>
                                    {{aplicaMascaraUnidade(docsTotais[i].totalACorrigir)
                                    | number}} A corrigir
                                    ({{aplicaMascaraReais((docsTotais[i].totalACorrigir*100)/docsTotais[i].totalAnalisado)}}%)
                                </li>
                                <li>
                                    <div class="cor-legenda cor5"></div>
                                    {{aplicaMascaraUnidade(docsTotais[i].totalNaoConfere)
                                    | number}} Não confere
                                    ({{aplicaMascaraReais((docsTotais[i].totalNaoConfere*100)/docsTotais[i].totalAnalisado)}}%)
                                </li>
                                <li>
                                    <div class="cor-legenda cor6"></div>
                                    {{aplicaMascaraUnidade(docsTotais[i].totalComRessalva)
                                    | number}} Confere com ressalva
                                    ({{aplicaMascaraReais((docsTotais[i].totalComRessalva*100)/docsTotais[i].totalAnalisado)}}%)
                                </li>
                                <li>
                                    <div class="cor-legenda cor7"></div>
                                    {{aplicaMascaraUnidade(docsTotais[i].totalAExcluir)
                                    | number}} A excluir
                                    ({{aplicaMascaraReais((docsTotais[i].totalAExcluir*100)/docsTotais[i].totalAnalisado)}}%)
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [hidden]="!(loading$ | async) ">
            <div class="loading-info ">
                <i class="fa fa-spinner fa-4x fa-spin "></i>
                <div id="carregando ">Aguarde</div>
            </div>
        </div>
    </div>
</div>