export const environment = {
  production: false,
  envName: 'hom',
  serverHost: 'https://sgp-api-hom.procempa.com.br/sgp/rest',
  realm: "pmpa",
 
  keycloak: {
    url: 'https://sso-pmpa-hom.procempa.com.br/auth',
    clientId: 'sgp',
    realm: 'pmpa',
    urlLogin:"https://sgp-admin-hom.procempa.com.br/",},
  
  acessoPoa: {
    url: 'https://acessopoa-hom.procempa.com.br/auth',
    clientId: 'sgp',
    realm: 'acessopoa',
    urlLogin:"https://sgp-hom.procempa.com.br/",}


};
