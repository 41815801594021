import { Injectable } from '@angular/core';
import { ParceriaInfo } from 'src/app/modulo2/detalheParceria/parceriaInfo';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EnvioDadoService {

  private enviaParceriaArray: BehaviorSubject<Array<ParceriaInfo>> = new BehaviorSubject(Array<ParceriaInfo>());
  public enviaParceriaArray$ = this.enviaParceriaArray.asObservable().pipe(share());
  private enviaParceriaArraySize: BehaviorSubject<number> = new BehaviorSubject(0);
  public enviaParceriaArraySize$ = this.enviaParceriaArraySize.asObservable().pipe(share());

  private enviaPagina: BehaviorSubject<number> = new BehaviorSubject(0);
  public enviaPagina$ = this.enviaPagina.asObservable().pipe(share());
  
  private enviaCurrentPage: BehaviorSubject<number> = new BehaviorSubject(0);
  public enviaCurrentPage$ = this.enviaCurrentPage.asObservable().pipe(share());
  
  constructor() { }

  changeArray(message: Array<ParceriaInfo>) {
    this.enviaParceriaArray.next(message);
  }
  changeCollectionSize(count: number) {
    this.enviaParceriaArraySize.next(count);
  }
  changePagina(count: number) {
    this.enviaPagina.next(count);
  }
  changeCurrentPage(count: number) {
    this.enviaCurrentPage.next(count);
  }
}

