<div w3-include-html="../../@shared/components/headerPortal/header.component.html">
  <div class="container-fluid mb-3" style="min-height: 450px;">
    <div class="infoGeral container-fluid">

      <h2>Relatório de Desembolsos – Natureza da Despesa</h2>
      <h3>Filtros de pesquisa</h3>
      <p><em>Você pode filtrar os resultados preenchendo um ou combinando mais de um dos campos abaixo:</em></p>
      <br>
      <div class="form-row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="">Órgão ou entidade (PMPA)</label>
            <div class="select-wrapper">
              <select [disabled]="isFiscal && !isSMTC" [ngModel]="busca.orgao" (change)="selectOrgaoParceria($event)" class="form-control form-select custom-select">
                <option [value]="option.id" *ngFor="let option of this.utils.secretariasOptions">{{option.name}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group">
            <label for="nroTermo">Número do termo</label>
            <input style="width: 100% " id="nroTermo" name="busca.numeroTermo" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.numeroTermo " [value]="busca.numeroTermo" (blur)="selectNumeroTermo()" (keydown.enter)="selectNumeroTermo()" class="form-control"
                            placeholder="informe o nº do termo">
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="cnpj">CNPJ</label>
            <input style="width: 100% " id="cnpj" name="busca.cnpj" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.cnpj " [value]="busca.cnpj" (blur)="selectCnpj()" (keydown.enter)="selectCnpj()" class="form-control" placeholder="informe o CNPJ" [textMask]="mascaraCNPJ">
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label for="" style="padding-left: 8px;padding-right: 8px;">Tipo de data</label>
            <div class="select-wrapper">
              <select [ngModel]="busca.tipoData" (change)="selectTipoData($event)" class="form-control form-select custom-select">
                            <option value="1">Emissão</option>
                            <option value="2">Pagamento</option>
                        </select>
            </div>
          </div>
        </div>
      </div>



      <div class="form-row">
        <div class="col-lg-4 col-md-6">
          <div class="form-group">
            <label for="nome">Nome da organização (parceria)</label>
            <input style="width: 100% " id="nome" name="busca.nomeOrg " class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.nomeOrg " [value]="busca.nomeOrg " (blur)="selectNomeParceria()" (keydown.enter)="selectNomeParceria()" placeholder="digite alguma palavra-chave">
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="form-group">
            <label for="">Natureza</label>
            <div class="select-wrapper">
              <select [ngModel]="busca.natureza" (change)="selectNatureza($event)" class="form-control form-select custom-select">
                <option [value]="option.id" *ngFor="let option of this.utils.naturezaOptions">{{option.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 cjto-datas">

          <div class="texto-linha-select-resultados">Selecione o período &ensp;&ensp;</div>
            <selector-datetime-range class="data-range" [isOnlyDataInicio]="false" [showLabelData]="false" (novoPeriodo)="novoPeriodo($event)"></selector-datetime-range>
          

        </div>
      </div>
      <div class="form-row">
        <div class="col btn-inline">
          <button class="btn btn-primary" (click)="limparFiltros()">Limpar</button>
          <button class="btn btn-primary" (click)="geraArquivo('PDF')">Gerar PDF</button>
          <button class="btn btn-primary" (click)="geraArquivo('XLS')">Gerar Excel</button>
          <button class="btn btn-primary" (click)="geraArquivo('INCO')" data-toggle="tooltip" data-placement="bottom" title="Utilizando filtros (exceto data) pode não listar todas as inconsistências."> Inconsistência</button>
        </div>
      </div>
      <br>
      <div>
        <table class="table table-hover table-responsavel table45" [hidden]="(!despesaArray) " [mfData]="despesaArray ">
          <thead>
            <tr>

              <th>Nº Termo</th>
              <th>Processo SEI Atual</th>
              <th>Órgão</th>
              <th>Nome</th>
              <th>CNPJ</th>
              <th>Natureza</th>
              <th>Nome Credor</th>
              <!-- <th>Tipo</th>-->
              <th>Número</th>
              <th>Data</th>
              <th>N° Doc</th>
              <th>Valor Doc</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let parceria of despesaArray | paginate:config">

              <td>{{parceria.numTermo}}</td>
              <td>{{parceria.processoSei}}</td>
              <td>{{parceria.nomeOrgao}}</td>
              <td>{{parceria.nomeEntidade}}</td>
              <td>{{aplicaMascaraDocumento(parceria.cnpjEntidade,2)}}</td>
              <td>{{getExecNaturezaStr(parceria.natureza)}}</td>
              <td>{{parceria.nomeCredor}}</td>
              <!--<td>{{parceria.tipo}}</td>-->
              <td>{{aplicaMascaraDocumento(parceria.cpfCnpj, parceria.tipo)}}</td>
              <td>{{parceria.data | date:'dd/MM/yyyy'}}</td>
              <td>{{parceria.numeroDoc}}</td>
              <td>{{parceria.valorDoc | currency: 'BRL'}}</td>
            </tr>
          </tbody>
        </table>
        <tfoot data-html2canvas-ignore="true" *ngIf="collectionSizeParceria > config.itemsPerPage">
          <tr>
            <td colspan="15">
              <!--<ngb-pagination class="d-flex justify-content-center" [collectionSize]="collectionSizeParceria" [maxSize]="10" [(page)]="currentPage" [rotate]="true " [ellipses]="true" [boundaryLinks]="true " (pageChange)="pageChanged($event)"></ngb-pagination>
                            -->
              <pagination-controls class="pagination pagination justify-content-end" id="custom" (pageChange)="pageChanged($event)" directionLinks="true" previousLabel="" nextLabel="">
              </pagination-controls>

            </td>
          </tr>
        </tfoot>


        <div [hidden]="!(loading$ | async) ">
          <div class="loading-overlay "></div>
          <div class="loading-info ">
            <i class="fa fa-spinner fa-4x fa-spin "></i>
            <div id="carregando ">Aguarde</div>
          </div>
        </div>
      </div>
    </div>
  </div>
