<div [hidden]="!(loading$ | async) ">
    <div class="loading-overlay "></div>
    <div class="loading-info ">
        <i class="fa fa-spinner fa-4x fa-spin "></i>
        <div id="carregando">Aguarde</div>
    </div>
</div>


<div class="container-fluid" [hidden]='isPortal'>
    <div class="float-right sair">
        <a (click)="logout()" class="link">Sair <i class="fa fa-sign-out" aria-hidden="true"></i> </a>
    </div>
</div>
<div *ngIf="!isPortal">
    <app-info-parceria [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [modulo]="modulo"></app-info-parceria>
</div>
<ngb-accordion #acc="ngbAccordion">
    <ngb-panel>
        <ng-template ngbPanelTitle>Monitoramento e Avaliação
        </ng-template>
        <ng-template ngbPanelContent>
            <app-monitoramento-avalia-parceria [isPortal]="isPortal" [parceriaInfo]="parceriaInfo"> </app-monitoramento-avalia-parceria>

        </ng-template>
    </ngb-panel>
    <ngb-panel>
        <ng-template ngbPanelTitle>Prestação de Contas Parcial/Final
        </ng-template>
        <ng-template ngbPanelContent>
            <app-prestacao-final-parceria [isPortal]="isPortal" [parceriaInfo]="parceriaInfo"> </app-prestacao-final-parceria>
        </ng-template>
    </ngb-panel>
    <div *ngIf="!isPortal">
        <ngb-panel>
            <ng-template ngbPanelTitle>Autorização Especial (Desbloqueio Trava)
            </ng-template>
            <ng-template ngbPanelContent>
                <app-monitora-presta-excecao-trava [parceriaInfo]="parceriaInfo"> </app-monitora-presta-excecao-trava>
            </ng-template>
        </ngb-panel>
    </div>
</ngb-accordion>