import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { UserService } from '../../@core/user.service';
import { AlertService } from '../../@core/alert.service';
import { RelatoriosApi } from '../../apis/relatoriosApi';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from 'src/app/@core/date-converter';
import { KeycloakService, AuthUser } from '@procempa/ngx-keycloak';
import { DetalheParceriaPortalApi } from 'src/app/apis/detalheParceriaPortalApi';
import { Utils } from 'src/app/@shared/utils';
import { ListaColaboradoresPlanilhaCampos } from './listaColaboradoresPlanilhaCampos';

@Component({
    selector: 'app-lista-colaboradores-planilha',
    templateUrl: './listaColaboradoresPlanilha.component.html',
    styleUrls: ['../../modulo2/exibeParcerias/exibeParceriasM2.component.scss']
})
export class ListaColaboradoresPlanilhaComponent implements OnInit {
    mascaraCNPJ = { mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/], guide: false };
    mascaraCPF = { mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/], guide: false };
    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable().pipe(share());
    busca: ListaColaboradoresPlanilhaCampos;
    @Input()
    isPortal: boolean;
    @Input()
    modulo: string;
    isFiscal: boolean;
    isSMTC: boolean;
    isConsulta: boolean;
    isAuditorExterno:boolean;
    auditorEmail:string;

    constructor(private alertService: AlertService,
        private relatoriosApi: RelatoriosApi,
        private detalheParceriaPortalApi: DetalheParceriaPortalApi,
        public userService: UserService,
        private dateConverter: DateConverter,
        private router: Router,
        public keycloakService: KeycloakService,
        public utils: Utils,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.modulo = 'relatorio';
        if (this.isPortal === undefined) {
            this.isPortal = false;
        }

        this.isConsulta = this.userService.isConsulta();
        this.isFiscal = this.userService.isFiscal();
        this.route.queryParams.subscribe(params => {
            this.isAuditorExterno = params['isAuditorExterno'] === undefined || params['isAuditorExterno'].toLowerCase() === 'false' ? false : true;
            this.auditorEmail = this.keycloakService['_keycloakInstance'].tokenParsed.email;
          });
      
      
          if (!this.isFiscal && !this.isConsulta &&!this.isAuditorExterno) {
            this.router.navigate(['notAuthorized']);
        }

        this.keycloakService.getUser().subscribe((user: AuthUser) => {

            //console.log(user.attributes.sgp_orgao[0]);
            if (this.isFiscal && user.attributes.sgp_orgao !== null) {
                // tslint:disable-next-line:no-unused-expression
                if (this.userService.isSMTC()) {
                    this.isSMTC = true;
                    this.busca = new ListaColaboradoresPlanilhaCampos(null, null, null, null, null, null, null, null, "");
                } else {
                    this.busca = new ListaColaboradoresPlanilhaCampos(null, null, null, null, null, null, null, Number(user.attributes.sgp_orgao[0]), "");
                }
            }
        });

        if (this.isAuditorExterno){
            this.busca = new ListaColaboradoresPlanilhaCampos(null, null, null, null, null, null, null, null, this.auditorEmail);
        }
    }

    selectOrgaoParceria(event) {
        if (event !== undefined) {
            this.busca.idOrgao = event.id;
        } else {
            this.busca.idOrgao = null;
        }
    }

    selectModalidade(event) {
        if (event !== undefined) {
            this.busca.modalidade = event.id;
        } else {
            this.busca.modalidade = null;
        }
    }

    selectStatusParceria(event) {
        if (event !== undefined) {
            this.busca.statusParceria = event.id;
        } else {
            this.busca.statusParceria = null;
        }
    }

    limparFiltros() {
        this.keycloakService.getUser().subscribe((user: AuthUser) => {

            //console.log(user.attributes.sgp_orgao[0]);
            if (this.isFiscal && user.attributes.sgp_orgao !== null) {
                // tslint:disable-next-line:no-unused-expression
                if (this.userService.isSMTC()) {
                    this.isSMTC = true;
                    this.busca = new ListaColaboradoresPlanilhaCampos(null, null, null, null, null, null, null, null, "");
                } else {
                    this.busca = new ListaColaboradoresPlanilhaCampos(null, null, null, null, null, null, null, Number(user.attributes.sgp_orgao[0]), "");
                }
            }
        });
        this.busca.auditorEmail = this.auditorEmail;
    }

    geraArquivo() {
        this.loading.next(true);
        if (this.busca.cnpjOsc !== null) {
            this.busca.cnpjOsc = this.busca.cnpjOsc.replace(/[^\d]+/g, '');
        }
        if (this.busca.cpfFuncionario !== null) {
            this.busca.cpfFuncionario = this.busca.cpfFuncionario.replace(/[^\d]+/g, '');
        }
        this.relatoriosApi.getListaColaboradoresPlanilha(this.busca)
            .subscribe((response) => {
                if (response) {
                    console.log(response);
                    const a = document.createElement('a');
                    const url = window.URL.createObjectURL(response);
                    a.download = `relatorio_colaboradores.xls`;
                    a.href = url;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);

                } else {
                    this.alertService.warning('Nenhum resultado foi obtido, por favor refaça a busca com outros critérios.');
                }
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));
    }
}