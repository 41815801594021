import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class ParceriaPrestaContaParcialSend {

    public parId: number;
    public prestaId: number;
    public prestaDocumento: Blob;
    public prestaDocumentoArquivo: string;
    public prestaDocArqOriginal: string;
    public prestaDocumentoData: Date;
    public prestaDocumentoDataFormat: NgbDateStruct;
    public prestaDocumentoTipo: string;
    public prestaDocumentoPublicar: number;
    public prestaOperacaoData: Date;
    public prestaOperacaoUsuario: string;
    public execConferir: number;
    public prestaMotivo: string;
    public prestaClassificaDoc: number;
    public prestaDevolucao: number;
    public prestaJustificaExclusao: string;
    public prestacaoContasId: number;
    public prestacaoContasTexto: string;
}