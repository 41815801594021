import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '../../../@core/alert.service';
import { ParceriaInfo } from './../parceriaInfo';
import { DetalheParceriaApi } from '../../../apis/detalheParceriaApi';
import { Mascaras } from '../../../@shared/components/Mascaras';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from '../../../@core/date-converter';
import * as jspdf from 'jspdf';
import 'jspdf-autotable';
import { UserService } from '../../../@core/user.service';
import { DetalheParceriaPortalApi } from 'src/app/apis/detalheParceriaPortalApi';
import { ImagensUrlData } from 'src/app/@shared/imagens-urldata';
import { PesquisaData } from '../../pesquisaData';
import * as Json2csvParser from 'json2csv';
import { ExcelService } from 'src/app/@shared/excel-service';
import saveAs from 'file-saver';
import { DatePipe } from '@angular/common';
import { PaginationInstance } from 'ngx-pagination';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { Utils } from 'src/app/@shared/utils';
import { PrestacaoDeContas } from './prestacaoDeContas';

@Component({
  selector: 'app-prestacao-contas',
  templateUrl: './prestacaoDeContas.component.html',
  styleUrls: ['./../detalheParceria.component.scss'],
  providers: [DatePipe]
})
export class PrestacaoDeContasComponent implements OnInit {
  @Input()
  private parceriaInfo: ParceriaInfo;
  @Input()
  isPortal: boolean;
  @Input()
  isAuditorExterno: boolean;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  mascaraData = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], guide: false };
  showCamposEdicao: boolean = false;
  showUpload: boolean = false;
  modalEdicaoReference: any;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  @Input()
  pesquisaData: PesquisaData;
  execDevolRecursos: number;
  execTotQtd: number;
  execDevolAplic: number;
  execDevolParceria: number;
  arquivo: string;
  dataIni: NgbDateStruct;
  dataFim: NgbDateStruct;
  p = 1;
  pagina: number;
  prestacoesContas: Array<PrestacaoDeContas> = [];
  prestacaoContasSend: PrestacaoDeContas;
  isSaldoDiferente: boolean = false;
  resumo: boolean = false;
  isVisualizar: boolean;
  usuario: string;

  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 10,
    currentPage: 1
  };

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private publicoPrivadaApi: PublicoPrivadaApi,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    public userService: UserService) {
    }
    
    ngOnInit(): void {
    this.userService.userInfo.subscribe(user => this.usuario = user.username);
    this.setupInterface();
  }

  setupInterface() {
    this.loading.next(true);
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();
    this.getPrestacoesDeContas();
    if (this.pesquisaData === undefined || this.pesquisaData.dataInicial === undefined) {
      const myDate = new Date(new Date().getFullYear(), (Number(new Date().getMonth())), 1, 0, 0, 0);
      this.pesquisaData = new PesquisaData();
      this.pesquisaData.dataInicial = myDate;
      this.pesquisaData.dataFinal = new Date();
    }
  }

  getPrestacoesDeContas() {
    this.publicoPrivadaApi.getPrestacoesDeContas(this.parceriaInfo.parId,this.isPortal).subscribe((response) => {
      if (this.disableBotao()) {
        this.prestacoesContas = response.filter(p => p.status != 1 && p.status != 5) as Array<PrestacaoDeContas>;
      }
      else {
        this.prestacoesContas = response as Array<PrestacaoDeContas>;
      }
    })
  }


   opemArquivo(content) {
    this.getFile(this.prestacaoContasSend.id);
    this.modalEdicaoReference = this.modalService.open(content, { windowClass: "modalProcesso" });
  }

  openEdicaoPrestacaoContas(content, prestacaoContas, isVisualizar) {
    this.loading.next(true);
    this.setIsVisualizar(isVisualizar);
    this.setPrestacaoContas(prestacaoContas);
    if (this.prestacaoContasSend.id !== -1) {
      this.getFile(this.prestacaoContasSend.id);
    }
    this.modalEdicaoReference = this.modalService.open(content, { windowClass: "modalProcesso" });
    this.setFormatSaldoParcial();
    this.loading.next(false);
  }

  setFormatSaldoParcial() {
    if (this.resumo) {
      if (this.prestacaoContasSend.saldoParcial == this.prestacaoContasSend.saldoFinal) {
        this.isSaldoDiferente = false;
        Array.from(document.querySelectorAll(".saldoParcial")).forEach(c => {
          c.classList.remove("isSaldoDiferente");
          c.classList.add("isSaldoIgual");
        });
      }
      else {
        this.isSaldoDiferente = true;
        Array.from(document.querySelectorAll(".saldoParcial")).forEach(c => {
          c.classList.remove("isSaldoIgual");
          c.classList.add("isSaldoDiferente");
        });
      }
    }
  }

  setPrestacaoContas(prestacaoContas) {
    if (prestacaoContas) {
      this.prestacaoContasSend = prestacaoContas
      if (this.resumo) {
        this.setFormatSaldoParcial();
      }
      this.showUpload = this.prestacaoContasSend.arquivo ? false : true;
      this.prestacaoContasSend.inicioPeriodoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.prestacaoContasSend.inicioPeriodo));
      this.prestacaoContasSend.fimPeriodoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.prestacaoContasSend.fimPeriodo));
    }
    else {
      this.prestacaoContasSend = new PrestacaoDeContas();
      this.prestacaoContasSend.parId = this.parceriaInfo.parId;
      this.showUpload = true;
      this.prestacaoContasSend.id = -1;
    }
  }

  async getFile(prestacaoId) {
    if (this.prestacaoContasSend.dadosArquivo) {
      this.prestacaoContasSend.arqTipo == 'pdf'
        ? this.arquivo = `data:application/pdf;base64,${this.prestacaoContasSend.dadosArquivo}`
        : this.arquivo = `data:image/png;base64,${this.prestacaoContasSend.dadosArquivo}`;
    }
    else {
      this.publicoPrivadaApi.getArquivoPrestacaoDeContas(prestacaoId,this.isPortal)
        .subscribe((response) => {
          if (response) {
            if (response.body.size > 0) {
              if (this.prestacaoContasSend.arqTipo == 'pdf') {
                this.arquivo = URL.createObjectURL(response.body);
              }
              else {
                const reader = new FileReader();
                reader.readAsDataURL(response.body);
                reader.onload = () => this.arquivo = `${reader.result}`;
              }
            } else {
              this.arquivo = '';
            }
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));
    }
  }

  getArquivoPrestacao(prestacaoId, nome) {
    this.loading.next(true);
    this.publicoPrivadaApi.getArquivoPrestacaoDeContas(prestacaoId,this.isPortal)
      .subscribe((response) => {
        if (response) {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(response.body);
          a.href = url;
          a.download = nome;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          this.alertService.warning('Nenhum arquivo encontrado');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  onFileChanged(event) {
    const fileList: FileList = event.currentTarget.files;
    const reader = new FileReader();

    if (fileList.length > 0) {
      const array = fileList[0].type.split('/');
      const fileSize = fileList[0].size / 1024 / 1024; // in MB
      if (fileSize > 10) {
        this.alertService.danger('Tamanho de arquivo superior ao limite de 10 MB. Esse arquivo possui ' + fileSize.toPrecision(1) + " MB");
        return;
      }
      if (array[1] === 'pdf' || array[1] === 'png' || array[1] === 'jpg' || array[1] === 'jpeg') {


        reader.readAsDataURL(fileList[0]);
        reader.onload = () => {
          this.prestacaoContasSend.dadosArquivo = ((reader.result).toString().split(',')[1]) as unknown as Blob;
          this.prestacaoContasSend.arqTipo = array[1];
          this.prestacaoContasSend.arquivo = fileList[0].name;
        };

      }
      else {
        event.currentTarget.value = '';
        this.alertService.warning('Formato de arquivo não suportado!');
      }
    }
  }

  processarPrestacaoContas() {
    this.disableCampos();
    if (this.prestacaoContasSend.id === -1 && (this.prestacaoContasSend.dadosArquivo === null
      || this.prestacaoContasSend.dadosArquivo === undefined)) {
      this.alertService.danger('Dado obrigatório, realize o upload do arquivo.');
      return;
    }
    if ((this.prestacaoContasSend.arquivo != null && this.prestacaoContasSend.arquivo.length > 50)) {
      this.alertService.danger('Nome do arquivo superior a 50 caracteres, por favor renomeie antes de fazer upload.');
      return;
    }
    this.prestacaoContasSend.inicioPeriodo = this.dateConverter.fromNgbDateStructToDate(this.prestacaoContasSend.inicioPeriodoDataFormat);
    this.prestacaoContasSend.fimPeriodo = this.dateConverter.fromNgbDateStructToDate(this.prestacaoContasSend.fimPeriodoDataFormat);
    let data = JSON.stringify(this.prestacaoContasSend, this.replacerPrestacaoContas);
    this.loading.next(true);
    this.detalheParceriaApi.processarPrestacaoDeContas(data).subscribe(async (response) => {
      if (response) {
        this.resumo = true
        this.setupInterface();
        this.setPrestacaoContas(response.body);
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));
  }

  confirmEnviarPrestacaoDeContas(content) {
    this.modalEdicaoReference = this.modalService.open(content, { windowClass: "modalAlert20" });
  }

  salvarRascunho() {
    this.prestacaoContasSend.status = 1;
    this.updatePrestacaoContas("Rascunho Salvo com sucesso!");
  }

  enviarPrestacaoContas() {
    this.prestacaoContasSend.status = 2;
    this.updatePrestacaoContas("Prestaçao de contas enviada com sucesso!");
  }

  aceitarPrestacaoContas() {
    this.prestacaoContasSend.status = 3;
    this.updatePrestacaoContas("Prestaçao de contas aceita com sucesso!")
  }

  rejeitarPrestacaoContas() {
    this.prestacaoContasSend.status = 4;
    this.updatePrestacaoContas("Prestaçao de contas rejeitada com sucesso!")
  }

  salvarReenvio() {
    this.prestacaoContasSend.status = 5;
    this.updatePrestacaoContas("Salvo com sucesso!");
  }

  reenviarPrestacaoContas() {
    this.prestacaoContasSend.status = 6;
    this.updatePrestacaoContas("Prestação reenviada com sucesso!");
  }

  updatePrestacaoContas(msg: string) {
    this.loading.next(true);
    this.resumo = false;
    this.prestacaoContasSend.operacaoData = new Date();
    this.prestacaoContasSend.operacaoUsuario = this.usuario;
    let data = JSON.stringify(this.prestacaoContasSend, this.replacerPrestacaoContas);
    this.detalheParceriaApi.updatePrestacaoDeContas(data).subscribe(async (response) => {
      if (response) {
        this.setupInterface();
        this.alertService.info(msg);
        this.modalService.dismissAll();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }

  enableEditarPrestacaoProcessada() {
    this.resumo = false;
  }

  enableEditarIcone(prestacaoContas) {
    return (prestacaoContas.status == 1 || prestacaoContas.status == 4 || prestacaoContas.status == 5) && !(this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }

  enableVisualizarIcone(prestacaoContas) {
    return (prestacaoContas.status != 1 && prestacaoContas.status != 4 && prestacaoContas.status != 5) || this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno;
  }

  enableApagarIcone(prestacaoContas) {
    return (prestacaoContas.status == 1 && !(this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno));
  }

  setIsVisualizar(value) {
    this.resumo = value;
    this.isVisualizar = value;
  }

  disableBotao() {
    if (this.isVisualizar) return true;
    return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }

  disableCampos() {
    if (this.resumo) {
      this.showUpload = false;
      return true;
    }
    return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }

  disableApagarIcone() {
    return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }

  disableEditarIcone() {
    return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }

  openExcluirPrestacaoContas(content, prestacaoContas) {
    this.prestacaoContasSend = prestacaoContas;
    this.modalEdicaoReference = this.modalService.open(content, { windowClass: "modalAlert40", backdrop: 'static', keyboard: false });
  }

  excluirPrestacaoDeContas(id) {
    this.loading.next(true);
    this.detalheParceriaApi.deletePrestacaoDeContas(id).subscribe((response) => {
      if (response) {
        this.setupInterface();
        this.alertService.info("Prestação de contas excluida com sucesso!");
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  limpar() {
    this.resumo = false;
    this.showUpload = true;
    this.prestacaoContasSend = new PrestacaoDeContas();
  }

  disableEnviarPrestacao() {
    if (new Date(Date.now()) > new Date('2024-01-01T00:00:00Z')) {
      return this.prestacaoContasSend.saldoFinal != this.prestacaoContasSend.saldoParcial
    }
    else {
      return false;
    }
  }

  cancelarEdicao() {
    this.resumo = false;
    this.setupInterface();
  }

  replacerPrestacaoContas(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['inicioPeriodoDataFormat', 'fimPeriodoDataFormat'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }
}