import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../@core/user.service';
import { ParceriaInfo } from '../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';

@Component({
  selector: 'app-detalhe-mt-parceria',
  templateUrl: './detalheParceriaMT.component.html',
  styleUrls: ['./../../modulo2/detalheParceria/detalheParceria.component.scss']
})

export class DetalheParceriaMTComponent implements OnInit {

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  @Input()
  parceriaInfo: ParceriaInfo;
  @Input()
  isPortal: boolean;
  @Input()
  modulo: string;
  parId: number;
  tipoPublicaPortal: Number;
  modalEdicaoReference: any;


  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private modalService: NgbModal,
    private detalheParceriaApi: DetalheParceriaApi,
    public userService: UserService) {
  }


  ngOnInit() {
    this.modulo = 'mt';
    this.isPortal = false;
    this.setupInterface();
  }

  setupInterface() {
    if (!this.isPortal) {
      this.loading.next(true);
      this.route.params.subscribe(params => {
        this.parId = +params['id'];
        // console.log('detalheParceria' + this.parId);
      });
      if (this.parId !== undefined) {
        this.route.paramMap.pipe(
          switchMap((params: ParamMap) =>
            this.detalheParceriaApi.getParceriaInfo(this.parId))
        ).subscribe(async d => {
          this.parceriaInfo = d;
          // console.log(this.parceriaInfo);
          this.loading.next(false);
        });
      }
    }
  }
}

