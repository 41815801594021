<div>
	<div [hidden]="!(loading$ | async) ">
		<div class="loading-overlay "></div>
		<div class="loading-info ">
			<i class="fa fa-spinner fa-4x fa-spin "></i>
			<div id="carregando">Aguarde</div>
		</div>
	</div>
	<app-info-parceria [parceriaInfo]="parceriaInfo" [isPortal]="isPortal"></app-info-parceria>
	<div>
		<div class="container">
			<B>Módulo 1 - Planejamento e Seleção</B>
			<br>
			<br>
			<app-detalhe-m1-parceria [isPortal]="isPortal" [parceriaInfo]="parceriaInfo"> </app-detalhe-m1-parceria>

			<B>Módulo 2 - Execução</B>
			<br>
			<br>
		</div>
		<app-detalhe-parceria [isPortal]="isPortal" [parceriaInfo]="parceriaInfo"> </app-detalhe-parceria>
		<br>
		<div class="container">
			<B>Módulo 3 - Monitoramento, avaliação e prestação de Contas</B>
			<br>
			<br>
			<app-detalhe-m3-parceria [isPortal]="isPortal" [parceriaInfo]="parceriaInfo"> </app-detalhe-m3-parceria>
		</div>
	</div>
</div>
