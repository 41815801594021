import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import localeBr from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { AlertService } from './alert.service';

import { UserService } from './user.service';
import { DateConverter } from './date-converter';
import { KeycloakInterceptor } from './keycloak.interceptior';

registerLocaleData(localeBr)

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    DateConverter,
    KeycloakInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakInterceptor,
      multi: true
    },

    UserService,
    AlertService,
    { provide: LOCALE_ID, useValue: 'pt' },
      ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule')
  }
}

function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`)
  }
}
