import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { PlanejamentoEditais } from '../modulo1/editais/planejamentoEditais';
import { PlanejamentoTermos } from '../modulo1/termos/planejamentoTermos';
import { PlanejamentoPlanoTrabalho } from '../modulo1/planoDeTrabalho/planejamentoPlanoTrabalho';
import { ParceriaValoresLiberados } from '../modulo1/valoresPagos/parceriaValoresLiberados';
import { ParceriaInfo } from 'src/app/modulo2/detalheParceria/parceriaInfo';
import { ParceriaPrestaContaParcial } from '../modulo2/prestacaoDeContasParcial/parceriaPrestaContaParcial';
import { ParceriaPrestaContaFinal } from '../modulo3/prestacaoDeContasFinal/parceriaPrestaContaFinal';
import { ParceriaMonitoraAvalia } from '../modulo3/monitoramentoAvalicao/parceriaMonitoraAvalia';
import { DespesaParceria } from 'src/app/modulo2/detalheParceria/demonstrativoDesembolso/despesaParceria';
import { DevolucaoAplicacao } from 'src/app/modulo2/detalheParceria/devolucaoRecursos/devolucaoAplicacao';
import { Orgao } from '../modulo1/Orgao';
import { ZPagamentoParcerias } from '../modulo1/valoresPagos/zPagamentoParcerias';
import { ParceriasEmailResponsavelAnali } from '../modulo1/responsavelAnalise/emailResponsavelAnali';
import { ResumoFluxoFinanceiro } from '../modulo2/detalheParceria/resumoFluxoFinanceiro/resumoFluxoFinanceiro';
import { GrupoTurma } from '../modulo1/turmas/grupoTurma';
import { Turno } from '../modulo1/turmas/turno';
import { DiaSemana } from '../modulo1/turmas/diaSemana';
import { CRAReferencia } from '../modulo1/turmas/craReferencia';
import { BeneficiarioVinculoLista } from '../modulo2/beneficiarios/beneficiarioVinculoLista';
import { SituacaoAtendimento } from '../modulo2/beneficiarios/frequencia/situacaoAtendimento';
import { TipoFrequencia } from '../modulo2/beneficiarios/frequencia/tipoFrequencia';
import { BeneficioSituacaoMensal } from '../modulo2/beneficiarios/frequencia/beneficioSituacaoMensal';
import { SituacaoMensalFrequencia } from '../modulo2/beneficiarios/frequencia/situacaoMensalFrequencia';
import { FrequenciaBeneficiario } from '../modulo2/beneficiarios/frequencia/frequenciaBeneficiario';
import { GestorParceria } from '../modulo1/gestor/gestoresParceria';
import { NFSe } from '../modulo2/detalheParceria/nfse';
import { NFe } from '../modulo2/detalheParceria/demonstrativoDesembolso/nfe';
import { AlteraValoresParceria } from '../modulo1/termos/AlteraValoresParceria';
import { PrestacaoDeContas } from '../modulo2/detalheParceria/prestacaoDeContas/prestacaoDeContas';
import { OcupacaoLaboral } from '../cadastroServicoTerceiros/oupacaoLaboral';
import { Cbo } from '../modulo2/colaboradores/cbo';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DetalheParceriaPortalApi {

    constructor(private http: HttpClient) {
    }

    getUp() {
        return this.http.get<void>(`${environment.serverHost}/parceriasPortal/up`);
    }

    getParceriaInfo(id) {
        return this.http.get<ParceriaInfo>(`${environment.serverHost}/parceriasPortal?id=${id}`);
    }

    getParceriaInfoPortal(busca) {
        // tslint:disable-next-line:max-line-length
        return this.http.post<Array<ParceriaInfo>>(`${environment.serverHost}/parceriasPortal/busca`, busca);
    }
    getCountParceriaPortal(busca) {
        return this.http.post(`${environment.serverHost}/parceriasPortal/countParceiras`, busca);
    }

    getPlanejamentoEditais(id) {
        return this.http.get<Array<PlanejamentoEditais>>(`${environment.serverHost}/parceriasPortal/planEditais?id=${id}`);
    }

    getPlanejamentoTermosWithAlteracoes(id) {
        return this.http.get<Array<AlteraValoresParceria>>(`${environment.serverHost}/parceriasPortal/planTermos?id=${id}`);
    }

    getPlanejamentoTermos(id) {
        return this.http.get<Array<PlanejamentoTermos>>(`${environment.serverHost}/parceriasPortal/planTermos?id=${id}`);
    }

    getPlanejamentoPlanoTrabalho(id) {
        return this.http.get<Array<PlanejamentoPlanoTrabalho>>(`${environment.serverHost}/parceriasPortal/planPlanoTrabalho?id=${id}`);
    }

    getParceriaValoresLiberados(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<ParceriaValoresLiberados>>(`${environment.serverHost}/parceriasPortal/parceriaValoresLiberados?id=${id}`);
    }

    getPrestaContaParcial(id, mes, ano) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<ParceriaPrestaContaParcial>>(`${environment.serverHost}/parceriasPortal/parceriaPrestaContalParcial?id=${id}&mes=${mes}&ano=${ano}`);
    }

    getPrestaContaParcialPeriodo(filtroDocComplementar) {
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${environment.serverHost}/parceriasPortal/parceriaPrestaContalParcialPeriodo`, filtroDocComplementar, httpOptions);
    }

    getPrestaContaFinal(id) {
        return this.http.get<Array<ParceriaPrestaContaFinal>>(`${environment.serverHost}/parceriasPortal/parceriaPrestaContalFinal?id=${id}`);
    }

    getParceriaMonitoraAvalia(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<ParceriaMonitoraAvalia>>(`${environment.serverHost}/parceriasPortal/parceriaMonitoraAvalia?id=${id}`);
    }


    getArquivo(parId, id, tipo) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/parceriasPortal/arquivo`, { params: { parId: parId, id: id, tipo: tipo }, observe: 'response', responseType: 'blob' });
    }

    getDespesaParceriaPeriodo(filtroDesembolso) {
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${environment.serverHost}/parceriasPortal/despesaMensalPeriodo`, filtroDesembolso, httpOptions);
    }

    getArquivoDes(parId, execId, tipo, isTmp) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/parceriasPortal/arquivoDes`, { params: { parId: parId, execId: execId, tipo: tipo, isTmp: isTmp}, observe: 'response', responseType: 'blob' });
    }
    getArquivoDevApl(parId, execDevolId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/parceriasPortal/arquivoDevAplic`, { params: { parId: parId, execDevolId: execDevolId }, observe: 'response', responseType: 'blob' });
    }

    getArquivoColaborador(colaboradorId, tipoArquivo) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/secure/${environment.realm}/parcerias/arquivoColaborador?colaboradorId=${colaboradorId}&tipoArquivo=${tipoArquivo}`, { observe: 'response', responseType: 'blob' });
    }

    getDevolucaoAplicacao(id, mes, ano) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<DevolucaoAplicacao>>(`${environment.serverHost}/parceriasPortal/devolucao?id=${id}&mes=${mes}&ano=${ano}`);
    }

    getDevolucaoAplicacaoPeriodo(filtroDevolucao) {
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${environment.serverHost}/parceriasPortal/devolucaoPeriodo`, filtroDevolucao, httpOptions);
    }

    getCountDespesa(parId, dataFinal, tipo) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<number>(`${environment.serverHost}/parceriasPortal/countExecIdParceira?parId=${parId}&dataFinal=${dataFinal}&tipo=${tipo}`);
    }

    getResumoFluxoFinanceiroPeriodo(id, dataInicial, dataFinal, tipo) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<ResumoFluxoFinanceiro>(`${environment.serverHost}/parceriasPortal/resumoFluxoPeriodo?id=${id}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipo=${tipo}`);
    }

    getOrgaos() {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<Orgao>>(`${environment.serverHost}/parceriasPortal/orgaos`);
    }

    getParceriasEmailsResponsavelAnali(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<ParceriasEmailResponsavelAnali>>(`${environment.serverHost}/parceriasPortal/emailsResponsavelAnali?parId=${id}`);
    }
    getGruposTurmas(id: number) {
        return this.http.get<Array<GrupoTurma>>(`${environment.serverHost}/parceriasPortal/gruposTurmas?id=${id}`);
    }
    getTurnoTurma(parId, turmaId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<Turno>>(`${environment.serverHost}/parceriasPortal/gruposTurmas/turno?parId=${parId}&turmaId=${turmaId}`);
    }

    getTurmasPorOrgaoTermo(orgao, termo) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<GrupoTurma>>(`${environment.serverHost}/parceriasPortal/gruposTurmaOrg?orgao=${orgao}&numTermo=${termo}`);
    }

    getGrupoTurmaPorCnpjTermo(cnpj, termo) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<GrupoTurma>>(`${environment.serverHost}/parceriasPortal/gruposTurmaPorTermoCNPJ?cnpj=${cnpj}&numTermo=${termo}`);
    }

    getDiaSemanaTurma(parId, turmaId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<DiaSemana>>(`${environment.serverHost}/parceriasPortal/gruposTurmas/diaSemana?parId=${parId}&turmaId=${turmaId}`);
    }

    getCRAReferenciaByID(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<CRAReferencia>(`${environment.serverHost}/parceriasPortal/crasReferencia?id=${id}`);
    }

    getCRAReferenciaAll() {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<CRAReferencia>>(`${environment.serverHost}/parceriasPortal/crasReferenciaAll`);
    }

    getVinculosBeneficiariosParceria(parId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<BeneficiarioVinculoLista>>(`${environment.serverHost}/parceriasPortal/beneficiarioVinculosPorParID?parId=${parId}`);
    }
    getVinculosBeneficiariosParceriaByParam(parId, turId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<BeneficiarioVinculoLista>>(`${environment.serverHost}/parceriasPortal/beneficiarioVinculosPesquisa?parId=${parId}&turmaId=${turId}`);
    }
    getSituacaoAtendimentoAll() {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<SituacaoAtendimento>>(`${environment.serverHost}/parceriasPortal/situacaoAtendimentoAll`);
    }
    getTipoFrequenciaAll() {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<TipoFrequencia>>(`${environment.serverHost}/parceriasPortal/tipoFrequenciaAll`);
    }
    getBeneficioSituacaoMensal(parId, turId, ano, mes) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<BeneficioSituacaoMensal>>(`${environment.serverHost}/parceriasPortal/beneficioSituacaoMensal?parId=${parId}&turId=${turId}&ano=${ano}&mes=${mes}`);
    }

    getSituacaoMensalFreq(parId, turId, ano, mes) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<SituacaoMensalFrequencia>(`${environment.serverHost}/parceriasPortal/situacaoMensalFrequencia?parId=${parId}&turId=${turId}&ano=${ano}&mes=${mes}`);
    }

    getFrequenciaBeneficiarioMensal(parId, turId, benId, ano, mes) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<FrequenciaBeneficiario>>(`${environment.serverHost}/parceriasPortal/frequenciaBeneficiarioMensal?parId=${parId}&turId=${turId}&benId=${benId}&ano=${ano}&mes=${mes}`);
    }
    getGestores(id) {
        return this.http.get<Array<GestorParceria>>(`${environment.serverHost}/parceriasPortal/gestores?parId=${id}`);
    }


    getNomeGestor(matricula) {
       return this.http.get(`${environment.serverHost}/parceriasPortal/nomeGestor?matricula=${matricula}`, {observe: 'response'});
    }


    consultaNFSe(inscricaoMunicipal, nroDoc, token) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<NFSe>(`${environment.serverHost}/parceriasPortal/consultaNFSe?inscricaoMunicipal=${inscricaoMunicipal}&nroDoc=${nroDoc}&token=${token}`);
    }

    consultaNFSeNacional(chaveAcesso, token) {
        return this.http.get<NFSe>(`${environment.serverHost}/parceriasPortal/consultaNFSeNacional?chaveDeAcesso=${chaveAcesso}&token=${token}`);
    }

    consultaNFe(chaveDeAcesso, cpfOperador, token) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<NFe>(`${environment.serverHost}/parceriasPortal/consultaNFe?chaveDeAcesso=${chaveDeAcesso}&cpfOperador=${cpfOperador}&token=${token}`);
    }
    getNomeParcerias(orgao: any) {
        return this.http.get<Array<string>>(`${environment.serverHost}/parceriasPortal/nomeParcerias?orgao=${orgao}`);
    }

    getCNPJParcerias(nome: any, orgao: any) {
        return this.http.get<Array<string>>(`${environment.serverHost}/parceriasPortal/cnpjParcerias?nome=${nome}&orgao=${orgao}`);
    }

    getTermoParcerias(cnpj: any, nome: any, orgao: any) {
        return this.http.get<Array<string>>(`${environment.serverHost}/parceriasPortal/termosParcerias?cnpj=${cnpj}&nome=${nome}&orgao=${orgao}`);
    }

    getParceriaByOrgaoParNum(idOrgao, parNum) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<ParceriaInfo>(`${environment.serverHost}/secure/${environment.realm}/parcerias/parceriaByOrgaoParNum?idOrgao=${idOrgao}&parNum=${parNum}`);
    }

    getPrestacoesDeContas(parId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<PrestacaoDeContas>>(`${environment.serverHost}/parceriasPortal/prestacoesContasByParceria?parId=${parId}`);
    }

    getArquivoPrestacaoDeContas(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/parceriasPortal/arquivoPrestacaoContas?prestacaoId=${id}`, 
            { observe: 'response', responseType: 'blob' });
    }

    getArquivoPrestadorServTer(prestadorId,tipoArquivo) {
        // tslint:disable-next-line:max-line-length
        return this.http.get(`${environment.serverHost}/secure/${environment.realm}/parcerias/arquivoPrestadorServTer?colaboradorId=${prestadorId}&tipoArquivo=${tipoArquivo}`, { observe: 'response', responseType: 'blob' });
    }

    getOcupacaoLaboralByID(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<OcupacaoLaboral>(`${environment.serverHost}/parceriasPortal/ocupacaoLaboral?id=${id}`);
    }

    getOcupacaoLaboralAll() {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<OcupacaoLaboral>>(`${environment.serverHost}/parceriasPortal/ocupacaoLaboralAll`);
    }

    getCboByID(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Cbo>(`${environment.serverHost}/parceriasPortal/cbo?id=${id}`);
    }

    getCboAll() {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<Cbo>>(`${environment.serverHost}/parceriasPortal/cboAll`);
    }
    getIsSaude(cnpjOsc) {
        return this.http.get<boolean>(`${environment.serverHost}/parceriasPortal/isSaude?cnpjOsc=${cnpjOsc}`);
     }
}
