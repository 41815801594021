export class NotificacaoItem {
    public id: number;
    public notifId: number;
    public parId: number;
    public execId: number;
    public execDevolId: number;
    public prestaId: number;
    public texto: String;
    public apontamento: String;
    public devolucao: number;
    public situacaoId: number;
    public observacaoOsc: String;
    public observacaoAnalista: String;
    public operacaoData: Date;
}