export class BuscaRelatorioGastoPessoalCampos {
    constructor(
        public orgao: number,
        public cnpjOscs: Array<String>,
        public parIds: Array<number>,
        public naturezas: Array<number>,
        public tipoArquivo: String,
        public dataInicio: Date,
        public dataFim: Date,
        public auditorEmail:string
    ) {}
}