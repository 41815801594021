import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share, filter } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from '../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../../apis/detalheParceriaApi';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { Utils } from 'src/app/@shared/utils';
import { UserService } from '../../@core/user.service';
import { PlanejamentoTermos } from './planejamentoTermos';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from 'src/app/@core/date-converter';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { AlteraValoresParceria } from './AlteraValoresParceria';


@Component({
  selector: 'app-termos-parceria',
  templateUrl: './../termos/termos.component.html',
  styleUrls: ['./../../modulo2/detalheParceria/detalheParceria.component.scss']
})
export class TermosComponent implements OnInit {

  @Input()
  public  parceriaInfo: ParceriaInfo;
  @Input()
  private  isPortal: boolean;
  @Output() valueChange = new EventEmitter();
  planTermos: Array<AlteraValoresParceria> = [];
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  maskNum = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/], guide: false };
  modalEdicaoReference: any;
  showUpload: boolean = false;
  terTermoSend: PlanejamentoTermos;
  request : AlteraValoresParceria = new AlteraValoresParceria();
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  @Input()
  isAuditorExterno: boolean;

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    private parceriaDespesaDevolApi: PublicoPrivadaApi,
    public userService: UserService,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private utils: Utils) {

    }

  ngOnInit() {
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();
    this.setupInterface();
  }

   setupInterface() {
    this.loading.next(true);
    this.route.paramMap.pipe(
        switchMap(() =>
         this.detalheParceriaPortalApi.getPlanejamentoTermosWithAlteracoes(this.parceriaInfo.parId))
         ).subscribe((response) => {
          if (response) {
  
            var termos = response as Array<AlteraValoresParceria>;
            termos.forEach(element => {
              if (element.planejamentoTermos.terClassificacao !== null && element.planejamentoTermos.terClassificacao !== undefined) {
                element.planejamentoTermos.terClassificacaoTexto = this.utils.getClassificacaoDocumentosLicitaconNome(element.planejamentoTermos.terClassificacao);
              }
            });
            this.planTermos = termos;
            // console.log(this.valoresLiberados);
          } else {
            this.alertService.warning('Nenhum registro encontrado');
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));
       
        this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
          this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
        });
  }
  disableCampos() {
    return (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }

  visualizarBotao() {
    return ((this.isSMTC || this.isConsulta || this.isAuditorExterno));
  }
  disableApagarIcone() {
    return (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }
  enableEditarIcone() {
    if (this.isConsulta || this.isSMTC || this.isAuditorExterno) {
      return false;
    }
    return true;
  }

  selectTipoTermo(event) {
    this.terTermoSend.terClassificacao = event.id;
  }

  getArquivo(parId, id, tipo, nome) {
    this.loading.next(true);
    this.parceriaDespesaDevolApi.getArquivo(parId, id, tipo, this.isPortal)
      .subscribe((response) => {
        if (response) {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(response.body);
          a.href = url;
          a.download = nome;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          this.alertService.warning('Nenhum arquivo encontrado');
        }
      }, (response) => {
        // if (response.status >= 500) {
        //   this.alertService.danger(response.error);
        // } else {
        //   this.alertService.warning(response.error);
        // }
        this.alertService.danger('Erro ao buscar arquivo.');
        this.loading.next(false);
      }, () => this.loading.next(false));
  }


  setTerTermo(doc: AlteraValoresParceria) {
    if (doc) {
      this.request = doc;
      this.terTermoSend = doc.planejamentoTermos;
      this.showUpload = this.terTermoSend.terTermoArquivo ? false : true;
      this.terTermoSend.terTermoDocumentoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.terTermoSend.terTermoData));
      if (this.terTermoSend.terDataInicioExecucao !== null) {
        this.terTermoSend.terDataInicioExecucaoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.terTermoSend.terDataInicioExecucao));
      }
      if (this.terTermoSend.terDataPublicacao !== null) {
        this.terTermoSend.terDataPublicacaoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.terTermoSend.terDataPublicacao));
      }
      if (this.request.dataIncialVigencia !== null) {
        this.request.dataIncialVigenciaDataFormat  = this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.request.dataIncialVigencia));
      }
      if (this.request.dataFinalVigencia  !== null) {
        this.request.dataFinalVigenciaDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.request.dataFinalVigencia));
      }
    } else {
      this.request = new AlteraValoresParceria();
      this.terTermoSend = new PlanejamentoTermos();
      this.showUpload = true;
      this.terTermoSend.parId = this.parceriaInfo.parId;
      this.terTermoSend.terId = -1;
      this.terTermoSend.terTermoTipo = '';
    }

  }

  salvaTermo() {
    if (this.terTermoSend.terId == -1 && (this.terTermoSend.terTermoDocumento === null || this.terTermoSend.terTermoDocumento === undefined)) {
      this.alertService.danger('Dado obrigatório, realize o upload do arquivo.');
      return;
    }

    if ( (this.terTermoSend.terTermoArquivo != null && this.terTermoSend.terTermoArquivo.length > 50)) {
      this.alertService.danger('Nome do arquivo superior a 50 caracteres, por favor renomeie antes de fazer upload.');
      return;
    }

    // tslint:disable-next-line:max-line-length
    this.terTermoSend.terTermoData = this.dateConverter.fromNgbDateStructToDate(this.terTermoSend.terTermoDocumentoDataFormat);
    if (!this.utils.isValidDate(this.terTermoSend.terTermoData)){
        this.alertService.danger('Data Assinatura Inválida.');
        return;
    }
    this.terTermoSend.terDataInicioExecucao = this.dateConverter.fromNgbDateStructToDate(this.terTermoSend.terDataInicioExecucaoDataFormat);
    if (!this.utils.isValidDate(this.terTermoSend.terDataInicioExecucao)){
      this.alertService.danger('Data Início Execução Inválida.');
      return;
    }
    this.terTermoSend.terDataPublicacao = this.dateConverter.fromNgbDateStructToDate(this.terTermoSend.terDataPublicacaoDataFormat);
    if (!this.utils.isValidDate(this.terTermoSend.terDataPublicacao)){
      this.alertService.danger('Data Publicação Inválida.');
      return;
    }
    if (this.terTermoSend.terTermoData > this.terTermoSend.terDataInicioExecucao) {
      this.alertService.danger('Data de assinatura deve ser igual ou anterior à data de início da execução.');
      return;
    }

    // comentado em atendimento a solicitação da Juliana dia 04/07
    // if (this.terTermoSend.terDataPublicacao > this.terTermoSend.terDataInicioExecucao) {
    //   this.alertService.danger('Data de publicação deve ser igual ou anterior à data de início da execução.');
    //   return;
    // }

    let hoje = new Date();

    if (this.terTermoSend.terTermoData > hoje) {
      this.alertService.danger('Data de assinatura não pode ser posterior a hoje.');
      return;
    }

    if (this.terTermoSend.terDataInicioExecucao > hoje) {
      this.alertService.danger('Data de início da execução não pode ser posterior a hoje.');
      return;
    }

    if (this.terTermoSend.terDataPublicacao > hoje) {
      this.alertService.danger('Data de publicação não pode ser posterior a hoje.');
      return;
    }

    if(this.request.dataIncialVigenciaDataFormat){
      this.request.dataIncialVigencia = this.dateConverter.fromNgbDateStructToDate(this.request.dataIncialVigenciaDataFormat);
      if (!this.utils.isValidDate(this.request.dataIncialVigencia)){
        this.alertService.danger('Data Inicial de Vigência Inválida.');
        return;
      }
    }
    if(this.request.dataFinalVigenciaDataFormat){
      this.request.dataFinalVigencia = this.dateConverter.fromNgbDateStructToDate(this.request.dataFinalVigenciaDataFormat);
      if (!this.utils.isValidDate(this.request.dataFinalVigencia)){
        this.alertService.danger('Data Final de Vigência Inválida.');
        return;
      }
    }

    if(this.terTermoSend.terClassificacao == 2 && this.request.numAditivo < 1){
      this.alertService.danger('Número do Termo Aditivo deve ser maior que zero.');
      return;
    }   

    if(this.terTermoSend.terClassificacao == 3 && this.request.numAditivo < 1){
      this.alertService.danger('Número do Apostilamento deve ser maior que zero.');
      return;
    }   

    this.request.planejamentoTermos = this.terTermoSend;
    let data = JSON.stringify(this.request, this.replacerDocComplementar);
    this.detalheParceriaApi.updateTermo(data).subscribe(async (response) => {
      if (response) {
        this.setupInterface();
        this.setTerTermo(undefined);
        this.modalEdicaoReference.close();
        //await this.getParceriaInfo(this.terTermoSend.parId);
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }

  excluirterTermo() {
    this.detalheParceriaApi.excluirTermo(this.terTermoSend).subscribe((response) => {
      if (response) {
        this.setupInterface();
        this.setTerTermo(undefined);
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }

  replacerDocComplementar(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['terTermoDocumentoDataFormat', 'terDataAssinaturaDataFormat', 'terDataInicioExecucaoDataFormat', 
      'terDataPublicacaoDataFormat', 'terClassificacaoTexto', 'dataIncialVigenciaDataFormat', 'dataFinalVigenciaDataFormat'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  openEdicao(content, doc) {
    this.setTerTermo(doc);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false, windowClass: "modalProcesso" });
  }

  openExcluir(content, doc) {
    this.setTerTermo(doc);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false });
  }

  onFileDocChanged(event) {
    const fileList: FileList = event.currentTarget.files;
    const reader = new FileReader();


    if (fileList.length > 0) {
      const array = fileList[0].type.split('/');
      const fileSize = fileList[0].size / 1024 / 1024; // in MB
      if (fileSize > 2) {
        this.alertService.danger('Tamanho de arquivo superior ao limite de 2MB. Esse arquivo possui ' + fileSize.toPrecision(1)+"MB");
        return;
      }
      if (array[1] === 'jpeg' || array[1] === 'jpg' || array[1] === 'gif'
        || array[1] === 'png' || array[1] === 'pdf') {

        reader.readAsDataURL(fileList[0]);
        reader.onload = () => {
          this.terTermoSend.terTermoDocumento = ((reader.result).toString().split(',')[1]) as unknown as Blob;
          console.log(this.terTermoSend.terTermoDocumento);
          this.terTermoSend.terTermoTipo = array[1];
          this.terTermoSend.terTermoArquivo = fileList[0].name;
        };
      } else {
        event.currentTarget.value = '';
        this.alertService.warning('Formato de arquivo não suportado!');
      }
    }
  }
}



