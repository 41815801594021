<div [hidden]="!(loading$ | async) ">
    <div class="loading-overlay "></div>
    <div class="loading-info ">
        <i class="fa fa-spinner fa-4x fa-spin "></i>
        <div id="carregando">Aguarde</div>
    </div>
</div>


<div *ngIf="!isPortal">
    <app-info-parceria [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [modulo]="modulo"></app-info-parceria>
</div>
<div class="container">
    <ngb-accordion #acc="ngbAccordion" activeIds="travaP" [closeOthers]="true" aria-expanded="true">
        <div *ngIf="!isPortal">
            <ngb-panel id="travaP">
                <ng-template ngbPanelTitle>Autorização Especial (Desbloqueio Trava)
                </ng-template>
                <ng-template ngbPanelContent>
                    <app-monitora-presta-excecao-trava [parceriaInfo]="parceriaInfo"> </app-monitora-presta-excecao-trava>
                </ng-template>
            </ngb-panel>
        </div>
    </ngb-accordion>
</div>