import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export default class DespesaParceriaSend {
 
	public parId: number;
	public execId: number;
	public execDocumento: string;
	public execDocumentoArquivo: string;
	public execDocumentoTipo: string;
	public execDocumentoData: Date;
	public execDocumentoDataFormat: NgbDateStruct;
	public execTpCpfcnpj: number;
	public execNomeCredor: string;
	public execNroCpfcnpj: number;
	public execNatureza: number;
	public execTpDoc: number;
	public execNroDoc: string;
	public execValorDoc: number;
	public execOperacao: number;
	public execChave: string;
	public execDocumento1: string;
	public execDocumentoArq1: string;
	public execDoccumentoTipo1: string;
	public cnpj9: boolean;
	public edicao: boolean;
	public execConferir: number;
	public operacaoData: Date;
	public operacaoUsuario: string;
	public dataPagamento: Date;
	public dataPagamentoFormat: NgbDateStruct;
	public valorTotalDocumento: number;
  	public chaveAcessoNFE: string;
  	public execMotivo: string;
	public execDevolucao: number;
	public execJustificaExclusao: string;
	public execInscricaoMunicipal: string;
	public descricaoServico: string;
	public statusCancelamento: string;
	public prestacaoContasId: number;
	public prestacaoContasTexto: string;
	public despesaPagamentoPessoal:number;
	public tipoDeServico: number;
	public prestadorServTer: number;
	public contratoPj: number;
	public servPrestadoPf: number;
	public valorMensalVigenteContrato:number;
	public colaborador: boolean;
	public showUpload: boolean;
	public showUpload1: boolean;
	public limpa: boolean;
}
