export class BuscaRelatorioCampos {
    constructor(
        public orgao: number,
        public dataInicial: Date,
        public dataFinal: Date,
        public numeroTermo: string,
        public nomeOrg: string,
        public cnpj: string,
        public natureza: number,
        public modalidade: number,
        public filtroOrdenacao: number,
        public tipoData: string,
        public tipoArquivo: string,
        public nomeRelatorio: string,
        public pagina: number,
        public limite: number,
        public cnpjCredor: string,
        public nomeCredor: string,
        public auditorEmail:string
        ) { }
}
