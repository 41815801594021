import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class PlanejamentoPlanoTrabalho {

    public parId: number;
    public planoId: number;
    public planoTrabalho: Blob;
    public planoTrabalhoArquivo: string;
    public planoTrabalhoArquivoOrigina: string;
    public planoTrabalhoData: Date;
    public planoTrabalhoTipo: string;
    public planoTrabalhoPublicar: number;
    public planoTrabalhoDocumento: Blob;
    public planoTrabalhoDocumentoDataFormat: NgbDateStruct;
    public planoTrabalhoDocumentoDataIniFormat: NgbDateStruct;
    public planoTrabalhoDocumentoDataFimFormat: NgbDateStruct;
    public planoTrabalhoDataIni: Date;
    public planoTrabalhoDataFim: Date;
}
