<div *ngIf="devolucoesAplicacoes.length==0">
  <span class="mensagem-vazio">Nenhuma aplicação encontrada para os critérios selecionados.</span>
</div>
<div>
  <div style="margin-top: 15px;">
    <button (click)="openExportacao(contentExportacaoDevolucao, undefined) " id="button-export"
      class="btn btn-primary btn-info" type="button " title="Download" [hidden]="devolucoesAplicacoes.length==0">
      <i class="fa fa-download" style="color: white"><span class="texto-botao">Download</span> </i>
    </button>
    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <button (click)="openFiltroDev(contentFiltroDev, undefined)" id="button-filter" class="btn btn-primary btn-info"
      type="button" title="Filtro">
      <span class="texto-botao">Filtro</span>
    </button>
  </div>
  <table class="table table-striped" style="margin-top:30px" id="tabelaDevolucao" [mfData]="devolucoesAplicacoes"
    #mtdevolucoes="mfDataTable">
    <thead>
      <tr>
        <th></th>
        <th *ngIf="(!isPortal)" data-html2canvas-ignore="true"></th>
        <th *ngIf="!disableApagarIcone()" data-html2canvas-ignore="true"></th>
        <th>Id</th>
        <th>Data</th>
        <th>Valor</th>
        <th>Motivo</th>
        <th>Situação</th>
        <th>Documento</th>
        <!-- <th>Tipo Doc</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let devolAplicacao of mtdevolucoes.data | paginate:config let j = index;"
        ngbTooltip="{{devolAplicacao.execDevolJustificaExclusao}}"
        [ngClass]="{'strikethrough':devolAplicacao.execDevolJustificaExclusao != null}">
        <td class="col-auto" data-html2canvas-ignore="true">
          <img src="../../../../assets/img/list-check.svg" alt="Prestação de contas" title="{{devolAplicacao.prestacaoContasTexto}}" *ngIf="devolAplicacao.prestacaoContasId !== null">
        </td>
        <td *ngIf="!isPortal" class="col-auto" data-html2canvas-ignore="true">
          <i ngbTooltip="editar" *ngIf="enableEditarIcone(devolAplicacao)" class="fa fa-pencil" aria-hidden="true"
            (click)="openEdicaoDevolAplicacao(contentEdicaoDevolAplicacao, devolAplicacao)"></i>
          <i ngbTooltip="visualizar" *ngIf="visualizarBotao(devolAplicacao)" class="fa fa-search" aria-hidden="true"
            (click)="openEdicaoDevolAplicacao(contentEdicaoDevolAplicacao, devolAplicacao)"></i>
        </td>
        <td *ngIf="!disableApagarIcone()" class="col-auto" data-html2canvas-ignore="true">
          <i ngbTooltip="apagar"
            *ngIf="(devolAplicacao.execConferir == 1 || devolAplicacao.execConferir == 6) && devolAplicacao.execDevolJustificaExclusao == null"
            class="fa fa-trash-o" aria-hidden="true"
            (click)="openExcluirDevolAplicacao(contentExcluirDevolAplicacao, devolAplicacao)"></i>
        </td>
        <td class="col-auto">{{devolAplicacao.execDevolId}}</td>
        <td class="col-auto">{{devolAplicacao.execDevolData | date: 'dd/MM/yyyy'}}</td>
        <td class="col-auto">{{devolAplicacao.execDevolValor | currency: 'BRL':true}}</td>
        <td class="col-auto">{{this.utils.getMotivoStr(devolAplicacao.execDevolMotivo)}}</td>
        <td class="col-auto"
          [ngClass]="{'vermelho':devolAplicacao.execConferir == 3, 'azul':devolAplicacao.execConferir == 2, 'amareloVelho':devolAplicacao.execConferir == 5, 'laranja':devolAplicacao.execConferir == 4, 'roxo':devolAplicacao.execConferir == 6}">
          {{this.utils.getExecConferirStr(devolAplicacao.execConferir)}}</td>
        <td class="col-auto ">
          <div [hidden]="!devolAplicacao.execDevolArquivo" *ngIf="devolAplicacao.execDevolJustificaExclusao == null">
            {{devolAplicacao.execDevolArquivo}}
            <i ngbTooltip="download" class="fa fa-download " aria-hidden="true "
              (click)="getArquivoDevApl(devolAplicacao.parId, devolAplicacao.execDevolId, devolAplicacao.execDevolArquivo) "
              alt="download "></i>
          </div>
        </td>
        <!-- <td>{{devolAplicacao.execDevolTipo}}</td> -->
      </tr>
    </tbody>
    <tfoot data-html2canvas-ignore="true" *ngIf="devolucoesAplicacoes.length > config.itemsPerPage">
      <tr>
        <td colspan="15" class="p-l-0 p-r-0">
          <div class="d-flex justify-content-between">
            <pagination-controls class="pagination pagination" id="custom" (pageChange)="pageChanged($event)"
              directionLinks="true" previousLabel="" nextLabel="">
            </pagination-controls>
            <div *ngIf="devolucoesAplicacoes.length > config.itemsPerPage" class="irPara">
              <label for="paginaL">Ir para</label>
              <input style="width: 100%" id="paginaL" name="paginaL" #paginaL="ngModel" [textMask]="mascaraChaveAcesso"
                class="form-control" type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="pagina" size="3"
                [value]="pagina" (focusout)="consultaPagina($event)" (keyup.enter)="consultaPagina($event)"
                validaInputZero>
              <i class="fa fa-chevron-right"></i>
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</div>

<ng-template #contentExportacaoDevolucao let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Exportação da Devolução de recursos e/ou aplicação/poupança </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-7">
        <label for="">Selecione o período:</label>
        <div class="form-inline">
          <div class="form-group">
            <input class="form-control" placeholder="dd/mm/aaaa" maxlength="10" size="14" name="dataIni"
              [(ngModel)]="dataIni" appMaskInput [appMaskValue]="dataIni" ngbDatepicker #dI="ngbDatepicker">
            <i class="fa fa-calendar icon_input_date" (click)="dI.toggle()" title="calendário"></i>
          </div>
          <div style="width:40px;">
            até
          </div>
          <div class="form-group">
            <input class="form-control" placeholder="dd/mm/aaaa" maxlength="10" size="14" name="dataFim" appMaskInput
              [appMaskValue]="dataFim" [(ngModel)]="dataFim" ngbDatepicker #dF="ngbDatepicker">
            <i class="fa fa-calendar icon_input_date" (click)="dF.toggle()" title="calendário"></i>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-10">
        <label for="data">Selecione o tipo de arquivo:&ensp;</label>
        <br>
        <br>
        <div class="row">
          <div class="col">
            <a class="link" (click)="generatePDF(dataIni, dataFim)" title="Exportar como PDF">&ensp;<i
                class="fa fa-file-pdf-o fa-lg"></i> Baixar arquivo PDF</a>
          </div>
          <div class="col">
            <a class="link" style="margin-left: 10px" (click)="exportAs('xlsx', dataIni, dataFim)"
              title="Exportar como Excel"><i class="fa fa-file-excel-o fa-lg"></i> Baixar arquivo Excel</a>
          </div>
          <div class="col">
            <a class="link" style="margin-left: 9px; padding: 0%" (click)="exportAs('csv', dataIni, dataFim)"
              title="Exportar como CSV"><img src="assets/img/file-csv-solid.svg"> Baixar arquivo csv</a>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
  </div>
</ng-template>

<ng-template #contentEdicaoDevolAplicacao let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Devolução dos recursos e/ou de aplicação/poupança - ID
      {{devolAplicacaoSend.execDevolId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form (ngSubmit)="salvaDevolAplicacao()" #edicaoDevolAplicacao="ngForm" novalidate>
    <div class="modal-body" *ngIf="devolAplicacaoSend">
      <div class="form-group col-md-6">
        <label for="data">Data</label>
        <span [hidden]="data.valid" class="campoObrigatorio">*</span>
        <input [disabled]="disableCampos(devolAplicacaoSend)" style="width: 100%" class="form-control"
          placeholder="Clique no calendário" name="data" #data="ngModel" maxlength="10"
          [(ngModel)]="devolAplicacaoSend.execDevolDataFormat" placeholder="dd/mm/aaaa" appMaskInput
          [appMaskValue]="devolAplicacaoSend.execDevolDataFormat" ngbDatepicker #dp="ngbDatepicker" required>
        <i *ngIf="!disableCampos(devolAplicacaoSend)" class="fa fa-calendar icon" (click)="d.toggle()"
          title="calendário"></i>

      </div>
      <div class="form-group col-md-6">
        <label for="valor">Valor</label>
        <span [hidden]="valor.valid" class="campoObrigatorio">*</span>
        <input [disabled]="disableCampos(devolAplicacaoSend)" style="width:100%" currencyMask class="form-control"
          name="valor" #valor="ngModel" [(ngModel)]="devolAplicacaoSend.execDevolValor"
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" required validaInputZero />
      </div>
      <div class="form-group col-md-6">
        <label for="valor">Motivo</label>
        <span [hidden]="motivo.valid" class="campoObrigatorio">*</span>
        <div class="select-wrapper" style="width:100%">
          <select [disabled]="disableCampos(devolAplicacaoSend)" style="width:100%"
            [ngModel]="devolAplicacaoSend.execDevolMotivo" [ngModelOptions]="{standalone: true}"
            class="form-control form-select custom-select" name="motivo" #motivo="ngModel"
            (change)="updateMotivoDevApl($event)" required>
            <option [value]="option.id" *ngFor="let option of this.utils.motivoDevolAplicOptions">{{option.name}}
            </option>

          </select>
        </div>
        <span style="font-size: small; font-style: italic;">Observação: informar rendimentos líquidos, descontado IR, IOF</span>
      </div>
      <div class="form-group col-md-6">
        <label for="execDevolucao">Devolução</label>
        <input
          [disabled]="(devolAplicacaoSend.execConferir != 4 && devolAplicacaoSend.execConferir != 5) || (!isFiscal || isSMTC)"
          style="width: 100%" currencyMask [(ngModel)]="devolAplicacaoSend.execDevolDevolucao"
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" name="execDevolucao" #execDevolucao="ngModel"
          class="form-control" />

      </div>
      <div class="form-group col-md-12">
        <label for="valor">Observação</label>
        <div style="width:100%">
          <textarea [disabled]="disableCampos(devolAplicacaoSend)" ng-d style="width:100%;" id="observacao"
            class="form-control" #observacao="ngModel" [(ngModel)]="devolAplicacaoSend.observacao"
            [ngModelOptions]="{standalone: true}"
            (change)="updateTextArea($event.target.value)">{{devolAplicacaoSend.observacao}}</textarea>
          <i *ngIf="!disableCampos(devolAplicacaoSend)" class="fa fa-times icon_input_fontawsome " aria-hidden="true "
            (click)="devolAplicacaoSend.observacao=undefined " title="limpar "></i>
        </div>
      </div>
      <div class="form-group col-md-12">
        <label for="doc">Documento</label>
        <!-- <span [hidden]="documento.valid" class="campoObrigatorio">*</span>  -->
        <div [hidden]="showUpload">
          {{devolAplicacaoSend.execDevolArquivo}}
          <i *ngIf="!disableCampos(devolAplicacaoSend)" ngbTooltip="download" class="fa fa-times" aria-hidden="true"
            (click)="showUpload = true;devolAplicacaoSend.execDevolArquivo = undefined" alt="download"></i>
          <ngx-extended-pdf-viewer *ngIf="arquivo" [src]="arquivo" useBrowserLocale="true" height="50vh"
            [showZoomButtons]="true"></ngx-extended-pdf-viewer>
        </div>
        <div [hidden]="!showUpload || isFiscal">
          <input type="file" name="fileInput{{i}}" accept=".pdf" (change)="onFileDevChanged($event, j)">
          <!-- <i class="fa fa-times" aria-hidden="true" (click)="resetDevFile(j)" alt="download"></i> -->
          Tamanho máximo: 10 MB
        </div>
      </div>
      <div class="form-group col-md-6" [hidden]="!isFiscal">
        <label for="situacao">Situação</label>
        <div class="select-wrapper" style="width: 100%">
          <select [disabled]="(!isFiscal || isSMTC)" style="width: 100%" [ngModel]="devolAplicacaoSend.execConferir"
            name="situacao" #tipoDoc="ngModel" class="form-control form-select custom-select"
            (change)="updateSituacao($event)" required>
            <option [value]="option.id" *ngFor="let option of this.utils.statusOptions">{{option.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group col-md-12">
        <label for="motivo">Apontamento</label>
        <textarea [disabled]="isSMTC || !isFiscal" ng-d style="width:100%;" id="motivo" class="form-control"
          #motivo="ngModel" [(ngModel)]="devolAplicacaoSend.execMotivo" [ngModelOptions]="{standalone: true}"
          (change)="updateTextAreaMotivo($event.target.value)">{{devolAplicacaoSend.execMotivo}}</textarea>
        <i *ngIf="!isSMTC && isFiscal" class="fa fa-times icon_input_fontawsome " aria-hidden="true "
          (click)="devolAplicacaoSend.execMotivo=undefined " title="limpar "></i>

      </div>
    </div>
    <div *ngIf="!visualizarBotao(devolAplicacaoSend)" class="modal-footer col-md-12">
      <h6 class="modal-title mr-auto" id="modal-basic-title">Devolução dos recursos e/ou de aplicação/poupança - ID
        {{devolAplicacaoSend.execDevolId}}</h6>

      <button type="button" class="btn btn-default" (click)="c('Close click');setDevolAplicacao(undefined)"
        title="Cancelar">Cancelar</button>
      <button type="submit" class="btn btn-primary" [disabled]="!edicaoDevolAplicacao.form.valid"
        title="Salvar">Salvar</button>
    </div>
  </form>
</ng-template>
<ng-template #contentExcluirDevolAplicacao let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Excluir demonstrativo das devolução dos recursos e/ou de
      aplicação/poupança</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Tem certeza que deseja excluir a devolução dos recursos e/ou de aplicação/poupança
      <b>{{devolAplicacaoSend.execDevolData | date:'dd/MM/yyyy'}} - {{devolAplicacaoSend.execDevolValor| currency:
        'BRL':true}}</b>?
    </p>

    <div>
      <label class="form-group col-md-4" for="motivo">Justificativa</label>
      <textarea [disabled]="isSMTC || isFiscal" ng-d style="width:100%;" id="motivo" class="form-control"
        #motivo="ngModel" [(ngModel)]="devolAplicacaoSend.execDevolJustificaExclusao"
        [ngModelOptions]="{standalone: true}"
        (change)="updateTextAreaJustificativa($event.target.value)">{{devolAplicacaoSend.execDevolJustificaExclusao}}</textarea>
      <i class="fa fa-times icon_input_fontawsome " aria-hidden="true "
        (click)="devolAplicacaoSend.execDevolJustificaExclusao=undefined " title="limpar "></i>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">Fechar</button>
    <button type="button" class="btn btn-secondary" (click)="excluirDevolucaoAplicacao()"
      title="Excluir">Excluir</button>
  </div>
</ng-template>
<ng-template #contentFiltroDev let-ca="close('a')" let-cb="close('b')" let-d="dismiss()">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Filtrar Devoluções por</h4>
    <button type="button" class="close" aria-label="Close" (click)="ca" title="fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-4">
        <label for="devolId">Id</label>
        <input style="width: 100%" id="devolId" name="devolId" #devolId="ngModel" class="form-control" type="text"
          [ngModelOptions]="{standalone: true}" [(ngModel)]="filtro.devolId" [value]="filtro.devolId">
        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true"
          (click)="filtro.devolId=undefined;" title="limpar"></i>
      </div>
      <div class="form-group col-md-4">
        <label for="motivoFiltro">Motivo</label>
        <div class="select-wrapper" style="width: 100%">
          <ng-select [items]="this.utils.motivoDevolAplicOptions" class="form-control" bindLabel="name"
            [(ngModel)]="filtro.motivo" bindValue="id" notFoundText=" não encontrado"
            (change)="updateMotivoFiltroDev($event)">
          </ng-select>
        </div>
      </div>
      <div class="form-group col-md-4">
        <label for="filtroSituacao">Situação</label>
        <div class="select-wrapper" style="width: 100%">
          <ng-select [items]="this.utils.statusOptions" class="form-control" bindLabel="name"
            [(ngModel)]="filtro.situacao" bindValue="id" notFoundText=" não encontrado"
            (change)="updateSituacaoFiltroDev($event)">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="modal-footer" style="width: 100%;">
      <button type="button" class="btn btn-primary"  (click)="aplicaFiltroDev()" title="Aplicar">Aplicar</button>
    </div>
  </div>
</ng-template>
<div *ngIf="!disableApagarIcone()">
  <button (click)="openEdicaoDevolAplicacao(contentEdicaoDevolAplicacao, undefined) " id="button-insert_dev "
    class="btn btn-primary btn-info" type="button " title="Adicionar novo">
    <i class="fa fa-plus "><span class="texto-botao">Adicionar novo</span> </i>
  </button>
</div>
<br /><br />
<div style="font-size: 15px;">
  <div class="row">
    <div class="col-md-4">Lançamentos de Devolução de Aplicações</div>
    <div class="col-md-2">
      <div class="row">
        <div style="color:#818485">R$&ensp;</div>
        <div><b>{{aplicaMascaraReais(execDevolAplic)}}</b></div>
      </div>
    </div>
    <div class="col-md-4">Lançamentos de Devolução de Recursos</div>
    <div class="col-md-2">
      <div class="row">
        <div style="color:#818485">R$&ensp;</div>
        <div><b>{{aplicaMascaraReais(execDevolRecursos)}}</b></div>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-md-4">Lançamentos de Devolução para C/C Parceria</div>
    <div class="col-md-2">
      <div class="row">
        <div style="color:#818485">R$&ensp;</div>
        <div><b>{{aplicaMascaraReais(execDevolParceria)}}</b></div>
      </div>
    </div>
    <div class="col-md-4">Quantidade</div>
    <div class="col-md-2">
      <dd>{{execTotQtd}}</dd>
    </div>
  </div>
  <br />
</div>
<br>