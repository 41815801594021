import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from './components/alert.component';
import { GraphicBarsComponent } from './components/graphic-bars.component';
import { MaxLengthComponent } from './components/max-length.component';
import { OdometerComponent } from './components/odometer.component';
import { IndexerPipe } from './pipes/indexer';
import { MaxLengthPipe } from './pipes/max-length';
import { TruncatePipe } from './pipes/truncate';
import { ObjectToArrayPipe } from './pipes/object-to-array';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AppRoutingModule } from '../app.routing';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatepickerPipe } from './pipes/datepicker';

@NgModule({
  imports: [CommonModule, NgbModule, FormsModule, NgxMaskModule.forRoot (), NgSelectModule, AppRoutingModule],
  declarations: [
    HeaderComponent,
    FooterComponent,
    OdometerComponent,
    AlertComponent,
    TruncatePipe,
    MaxLengthComponent,
    MaxLengthPipe,
    GraphicBarsComponent,
    IndexerPipe,
    ObjectToArrayPipe,
   
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    OdometerComponent,
    AlertComponent,
    TruncatePipe,
    MaxLengthComponent,
    MaxLengthPipe,
    GraphicBarsComponent,
    IndexerPipe,
    ObjectToArrayPipe
  ]
})
export class SharedModule { }
