import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Beneficiario} from '../modulo2/beneficiarios/beneficario';
import { BairroRegiaoOP } from '../modulo2/beneficiarios/bairroRegiaoOP';
import { BeneficiarioVinculo } from '../modulo2/beneficiarios/beneficiarioVinculo';
import { BeneficiarioVinculoLista } from '../modulo2/beneficiarios/beneficiarioVinculoLista';
import { TipoFrequencia } from '../modulo2/beneficiarios/frequencia/tipoFrequencia';
import { SituacaoAtendimento } from '../modulo2/beneficiarios/frequencia/situacaoAtendimento';
import { BeneficioSituacaoMensal } from '../modulo2/beneficiarios/frequencia/beneficioSituacaoMensal';
import { FrequenciaBeneficiario } from '../modulo2/beneficiarios/frequencia/frequenciaBeneficiario';
import { List } from 'lodash';
import { SituacaoMensalFrequencia } from '../modulo2/beneficiarios/frequencia/situacaoMensalFrequencia';
import { GrupoTurma } from '../modulo1/turmas/grupoTurma';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable()
export class BeneficiarioApi {

    constructor(private http: HttpClient) {
     }

    getBeneficiariosParceria(cnpjParceira) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<Beneficiario>>(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/busca?cnpjParceira=${cnpjParceira}`);
    }

    getVinculoBeneficiario(id, parId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<BeneficiarioVinculo>(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/vinculoBeneficiario?id=${id}&parId=${parId}`);
    }
    getBeneficiarioPorCPF(cpf) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Beneficiario>(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/beneficiarioCpf?cpf=${cpf}`);
    }

    atualizaBeneficiario(beneficiario) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/beneficiarios/beneficiario`,
        { observe: 'response', headers: httpOptions.headers, body : beneficiario});
    }


    atualizaBeneficiarioVinculo(vinculo) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/beneficiarios/beneficiarioVinculo`,
        { observe: 'response', headers: httpOptions.headers, body : vinculo});
    }

    getBairroRegiaoOpByID(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<BairroRegiaoOP>(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/bairroRegiaoOP?id=${id}`);
    }

    getBairroRegiaoOpAll() {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<BairroRegiaoOP>>(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/bairrosRegioesOP`);
    }

    excluiBeneficiario(cnpjParceira, id) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/beneficiarios/beneficiario/${cnpjParceira}/${id}`,
        { observe: 'response', headers: httpOptions.headers});
    }

    excluiVinculoBeneficiario(parId, id, benId, turId) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/beneficiarios/beneficiarioVinculo/${parId}/${id}/${benId}/${turId}`,
        { observe: 'response', headers: httpOptions.headers});
    }

    getArquivoRelatorio(filtroBenVinc) {
        // tslint:disable-next-line:max-line-length}
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/relatorioBenVinculo`, filtroBenVinc, {
            responseType: 'blob',
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        });
    }


    getTipoFrequenciaID(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<TipoFrequencia>(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/tipoFrequencia?id=${id}`);
    }

    getSituacaoAtendimentoByID(id) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<SituacaoAtendimento>(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/situacaoAtendimento?id=${id}`);
    }

    atualizaBeneficiariosSituacao(benSituacaoMensal) {
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/addBeneficioSituacaoMensal`, benSituacaoMensal, httpOptions);
    }

    atualizaFrequenciaBeneficiarioMensal(freqBenMensal) {
        // tslint:disable-next-line:max-line-length
        return this.http.post(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/addFrequenciaBeneficiarioMensal`, freqBenMensal, httpOptions);
    }

    atualizaSituacaoMensalFrequencia(beneficiario) {
        // tslint:disable-next-line:max-line-length
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/beneficiarios/addSituacaoMensalFrequencia`,
        { observe: 'response', headers: httpOptions.headers, body : beneficiario});
    }

    atualizaExcecaoTravaFrequenciaBeneficiario(excecaoTravaFrequenciaBeneficiario) {
        return this.http.request<HttpResponse<any>>('put', `${environment.serverHost}/secure/${environment.realm}/beneficiarios/addExcecaoTravaFrequenciaBeneficiario`,
        { observe: 'response', headers: httpOptions.headers, body : excecaoTravaFrequenciaBeneficiario});
    }

    excluirExcecaoTravaFrequenciaBeneficiario(excecaoTravaFrequenciaBeneficiario) {
        return this.http.request<HttpResponse<any>>('delete', `${environment.serverHost}/secure/${environment.realm}/beneficiarios/deleteExcecaoTravaFrequenciaBeneficiario/${excecaoTravaFrequenciaBeneficiario.id}`,
        { observe: 'response', headers: httpOptions.headers, body : excecaoTravaFrequenciaBeneficiario});
    }

    getBeneficioSituacaoMensal(parId, turId, ano, mes) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<BeneficioSituacaoMensal>>(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/beneficioSituacaoMensal?parId=${parId}&turId=${turId}&ano=${ano}&mes=${mes}`);
    }

    getSituacaoMensalFreq(parId, turId, ano, mes) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<SituacaoMensalFrequencia>(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/situacaoMensalFrequencia?parId=${parId}&turId=${turId}&ano=${ano}&mes=${mes}`);
    }

    getFrequenciaBeneficiarioMensal(parId, turId, benId, ano, mes) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<FrequenciaBeneficiario>>(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/frequenciaBeneficiarioMensal?parId=${parId}&turId=${turId}&benId=${benId}&ano=${ano}&mes=${mes}`);
    }

    getVinculosBeneficiariosParceria(parId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<BeneficiarioVinculoLista>>(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/beneficiarioVinculosPorParID?parId=${parId}`);
    }

    getVinculosBeneficiariosParceriaByParam(parId, turId) {
        // tslint:disable-next-line:max-line-length
        return this.http.get<Array<BeneficiarioVinculoLista>>(`${environment.serverHost}/secure/${environment.realm}/beneficiarios/beneficiarioVinculosPesquisa?parId=${parId}&turmaId=${turId}`);
    }
    
}
