export class DespesaParceria {

    public parId: number;
    public execId: number;
    public execDocumentoArquivo: string;
    public execDocumentoTipo: string;
    public execDocumentoData: Date;
    public execTpCpfcnpj: number;
    public execNomeCredor: string;
    public execNroCpfcnpj: string;
    public execNatureza: number;
    public execTpDoc: number;
    public execNroDoc: string;
    public execValorDoc: number;
    public execOperacao: number;
    public execChave: string;
    public execDocumento1: string;
    public execDocumentoArq1: string;
    public execDoccumentoTipo1: string;
    public execConferir: number;
    public cnpj9 = false;
    public edicao = false;
    public operacaoData: Date;
    public operacaoUsuario: string;
    public dataPagamento: Date;
    public valorTotalDocumento: number;
    public chaveAcessoNFE: string;
    public execMotivo: string;
    public execDevolucao: number;
    public execJustificaExclusao: string;
    public execDataPagamentoStr: string;
    public execOperacaoStr: string;
    public execDocumentoDataStr: string;
    public execConferirStr: String;
    public execInscricaoMunicipal: String;
    public descricaoServico: String;
	public statusCancelamento: String;
    public prestacaoContasId: number;
	public prestacaoContasTexto: string;
    public despesaPagamentoPessoal:number;
    public tipoDeServico: string;
    public prestadorServTer: number;
	public contratoPj: number;
	public servPrestadoPf: number;
	public valorMensalVigenteContrato:number;
    public colaborador: boolean;
}
