import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from '../detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../../apis/detalheParceriaApi';
import { Mascaras } from '../../@shared/components/Mascaras';
import { UserService } from '../../@core/user.service';
import { Colaborador } from './colaborador';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PessoaInfo } from "../detalheParceria/pessoaInfo";
import { LogonApi } from 'src/app/apis/logonApi';
import { AuthUser, KeycloakService } from '@procempa/ngx-keycloak';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { DateConverter } from 'src/app/@core/date-converter';
import { ColaboradorCargaHoraria } from './colaboradorCargaHoraria';
import { BuscaRelatorioCampos } from 'src/app/relatorios/buscaRelatorioCampos';
import { RelatoriosApi } from 'src/app/apis/relatoriosApi';
import { Utils } from 'src/app/@shared/utils';
import { Cbo } from './cbo';
import { DetalheParceriaPortalApi } from 'src/app/apis/detalheParceriaPortalApi';

@Component({
    selector: 'app-colaborador',
    templateUrl: './../colaboradores/colaborador.component.html',
    styleUrls: ['./../../modulo2/detalheParceria/detalheParceria.component.scss']
})
export class ColaboradorComponent implements OnInit {
    @Input()
    private parceriaInfo: ParceriaInfo;
    @Input()
    private isPortal: boolean;
    @Output() valueChange = new EventEmitter();
    colaboradores: Array<Colaborador> = [];
    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable().pipe(share());
    private temPermissao: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public temPermissao$ = this.temPermissao.asObservable().pipe(share());
    private naoTemPermissao: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public naoTemPermissao$ = this.naoTemPermissao.asObservable().pipe(share());
    mascaraNroHora = { mask: [/\d/, /\d/], guide: false };
    modalEdicaoReference: any;
    showUpload: boolean = false;
    colaboradorSend: Colaborador;
    isFiscal: boolean;
    isSMTC: boolean;
    isConsulta: boolean;
    inValidCpf: boolean = false;
    mascaraCPF = { mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/], guide: false };
    pessoaInfo: PessoaInfo = new PessoaInfo();
    cnpjs: Array<Array<String>> = [];
    cnpj: string;
    nomeOsc: string;
    cpf: string;
    termos: Array<Array<String>> = [];
    colaboradorCBO: number;
    isSaude:boolean;
    cboSelecionada: any;
    cboLista:Array<Cbo> = [];
    private isSaudeCNPJ: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isSaudeCNPJ$ = this.isSaudeCNPJ.asObservable().pipe(share());

    constructor(private route: ActivatedRoute,
        private alertService: AlertService,
        private detalheParceriaApi: DetalheParceriaApi,
        private detalheParceriaPortalApi: DetalheParceriaPortalApi,
        public userService: UserService,
        private modalService: NgbModal,
        private mascaras: Mascaras,
        public keycloakService: KeycloakService,
        private logonApi: LogonApi,
        private publicoPrivadaApi: PublicoPrivadaApi,
        private dateConverter: DateConverter,
        private relatoriosApi: RelatoriosApi,
        private utils: Utils) {
    }

    ngOnInit() {
        this.isFiscal = this.userService.isFiscal();
        this.isSMTC = this.userService.isSMTC();
        this.isConsulta = this.userService.isConsulta();
        this.temPermissao.next(false);
        this.naoTemPermissao.next(true);
        this.setupInterface();
    }

    setupInterface() {
        this.loading.next(true);
        this.colaboradorCBO = 0;
        
        if (!this.isFiscal && !this.isSMTC && !this.isConsulta && !this.isPortal) {
            this.logonApi.getListaParceriasLogon(this.keycloakService['_keycloakInstance'].tokenParsed.email).subscribe((response) => {
                if (response) {
                    this.temPermissao.next(true);
                    response.forEach(element => {
                        this.cnpjs.push([element[0], element[1]]);
                    });
                    this.naoTemPermissao.next(false);
                }
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
                this.temPermissao.next(false);
                this.naoTemPermissao.next(true);
            }, () => this.loading.next(false));

        } else {

            this.getColaboradoresPorTermo();
            this.keycloakService.getUser().subscribe((user: AuthUser) => {
                this.logonApi.getListaParceriasOrgao(Number(user.attributes.sgp_orgao[0])).subscribe((response) => {
                    if (response) {
                        this.temPermissao.next(true);
                        response.forEach(element => {
                            this.cnpjs.push([element[0], element[1]]);
                        });
                        this.naoTemPermissao.next(false);
                    }
                }, (response) => {
                    if (response.status >= 500) {
                        this.alertService.danger(response.error);
                    } else {
                        this.alertService.warning(response.error);
                    }
                    this.temPermissao.next(false);
                    this.loading.next(false);
                    this.naoTemPermissao.next(true);
                }, () => this.loading.next(false));

                
                if(this.utils.isSaude(Number(user.attributes.sgp_orgao[0]))){
                    this.isSaude = true;
                }
                else{
                   this.isSaude = false;
                }
                
            });
        }
      this.getCboLista();    
     
    }

    aplicaMascaraCNPJ(cnpj) {
        return this.mascaras.aplicaMascaraDocumento(cnpj, 2);
    }

    setCnpj(event) {
        const newVal = event.currentTarget.value;
        this.cnpj = newVal;
        this.cnpjs.forEach(element => {
            if (element[0] == newVal) {
                this.nomeOsc = element[1].valueOf();
            }
        });
        this.getColaboradores(newVal);
        this.getIsSaude(newVal);
    }
    getIsSaude(cnpj: string) {
       this.detalheParceriaPortalApi.getIsSaude(cnpj).subscribe(async (response) => {
           this.isSaudeCNPJ.next(response as boolean);           
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));
    }

    getColaboradoresPorTermo() {
        this.loading.next(true);
        this.temPermissao.next(true);
        this.detalheParceriaApi.getColaboradoresPorTermo(this.parceriaInfo.parId).subscribe((response) => {
            if (response) {
                this.colaboradores = response as Array<Colaborador>;
               
                this.colaboradores.forEach(element => {
                    element.colaboradorCpf = this.mascaras.aplicaMascaraDocumento(element.colaboradorCpf.toString(), 1);
                    switch (element.colaboradorVinculo) {
                        case 1: element.colaboradorVinculoTexto = "CLT";
                            break;
                        case 2: element.colaboradorVinculoTexto = "Voluntário";
                            break;
                        case 3: element.colaboradorVinculoTexto = "Estágio remunerado";
                            break;
                        case 4: element.colaboradorVinculoTexto = "Estágio não remunerado";
                            break;
                        case 5: element.colaboradorVinculoTexto = "Menor aprendiz";
                            break;
                        case 6: element.colaboradorVinculoTexto = element.colaboradorVinculoOutro;
                            break;
                    }
                    if (element.colaboradorCBO == undefined){
                        element.colaboradorCBO = 0;
                    }
                    
                });
            } else {
                this.alertService.warning('Nenhum registro encontrado');
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));
    }

    getColaboradores(cnpj) {
        this.loading.next(true);
        this.detalheParceriaApi.getColaboradores(cnpj).subscribe((response) => {
            if (response) {
                this.colaboradores = response as Array<Colaborador>;
                this.colaboradores.forEach(element => {
                    element.colaboradorCpf = this.mascaras.aplicaMascaraDocumento(element.colaboradorCpf.toString(), 1);
                    switch (element.colaboradorVinculo) {
                        case 1: element.colaboradorVinculoTexto = "CLT";
                            break;
                        case 2: element.colaboradorVinculoTexto = "Voluntário";
                            break;
                        case 3: element.colaboradorVinculoTexto = "Estágio remunerado";
                            break;
                        case 4: element.colaboradorVinculoTexto = "Estágio não remunerado";
                            break;
                        case 5: element.colaboradorVinculoTexto = "Menor aprendiz";
                            break;
                        case 6: element.colaboradorVinculoTexto = element.colaboradorVinculoOutro;
                            break;
                    }
                });
            } else {
                this.alertService.warning('Nenhum registro encontrado');
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));
    }

    disableCampos() {
        return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta);
    }

    visualizarBotao() {
        return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta);
    }

    disableApagarIcone() {
        return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta);
    }

    enableEditarIcone() {
        if (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta) {
            return false;
        }
        return (this.cnpj != undefined);
    }

    setColaborador(doc) {
        this.logonApi.getParceriasByCnpj(this.cnpj === undefined ?  this.parceriaInfo.parOrgCnpj : this.cnpj).subscribe((response) => {
            this.termos = [];
            response.forEach(element => {
                this.termos.push([element[0], element[3]+'-'+ this.utils.getSecretariaStr(element[4]+'')]);
            });
        })

        if (doc) {
            this.detalheParceriaApi.getColaborador(doc.id).subscribe((response) => {
                this.colaboradorSend = response;
                this.colaboradorSend.colaboradorResponsavelOSCChecked = (this.colaboradorSend.colaboradorResponsavelOSC == 1);
                this.colaboradorSend.idsCargasHorariasAExcluir = [];
                this.cboSelecionada = this.getCboByID(this.colaboradorSend.colaboradorCBO);
                this.showUpload = this.colaboradorSend.colaboradorDocumentoArquivo ? false : true;
                if (this.colaboradorSend.cargasHorarias == null || this.colaboradorSend.cargasHorarias == undefined) {
                    this.colaboradorSend.cargasHorarias = [];
                } else {
                    let cargaHoraria = this.colaboradorSend.cargasHorarias.slice();
                    this.colaboradorSend.cargasHorarias.forEach((element, index) => {
                        if (element.dataInicioParceria != null) {
                            element.dataInicioParceriaDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(element.dataInicioParceria));
                        }
                        if (element.dataFimParceria != null) {
                            element.dataFimParceriaDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(element.dataFimParceria));
                        }
                        if (this.isFiscal || this.isConsulta || this.isSMTC || this.isPortal) {
                            // remove carga horário do mesmo colaborador em outro termo que não vinculado ao parceria
                            if (element.parId != this.parceriaInfo.parId) {
                                cargaHoraria.splice(cargaHoraria.findIndex(item => item.parId === element.parId),1);
                            }
                        }
                    });
                    this.colaboradorSend.cargasHorarias = cargaHoraria;
                }
                if (this.colaboradorSend.colaboradorDataAdmissao) {
                    this.colaboradorSend.colaboradorDataAdmissaoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(this.colaboradorSend.colaboradorDataAdmissao));
                }
                if (this.colaboradorSend.colaboradorDataDemissao) {
                    this.colaboradorSend.colaboradorDataDemissaoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(this.colaboradorSend.colaboradorDataDemissao));
                }
                if (this.colaboradorSend.colaboradorDataHabilitacao) {
                    this.colaboradorSend.colaboradorDataHabilitacaoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(this.colaboradorSend.colaboradorDataHabilitacao));
                }
                if (this.colaboradorSend.colaboradorInicioMandato) {
                    this.colaboradorSend.colaboradorInicioMandatoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(this.colaboradorSend.colaboradorInicioMandato));
                }
                if (this.colaboradorSend.colaboradorFimMandato) {
                    this.colaboradorSend.colaboradorFimMandatoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(this.colaboradorSend.colaboradorFimMandato));
                }
            });
        } else {
            this.colaboradorSend = new Colaborador();
            this.cboSelecionada = undefined;
            this.colaboradorSend.colaboradorCBO=undefined;
            this.colaboradorSend.id = -1;
            this.colaboradorSend.colaboradorCnpjOsc = this.cnpj;
            this.colaboradorSend.colaboradorSalarioMensal = 0;
            this.colaboradorSend.colaboradorResponsavelOSC = 0;
            this.colaboradorSend.colaboradorResponsavelOSCChecked = false;
            this.colaboradorSend.cargasHorarias = [];
            this.colaboradorSend.idsCargasHorariasAExcluir = [];
            this.showUpload = true;

        }
    }

    openEdicao(content, doc) {
        this.setColaborador(doc);
        this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: "modalProcesso" });
    }

    openExcluir(content, doc) {
        this.setColaborador(doc);
        this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
    }

    selectVinculo(event) {
        const newVal = event.currentTarget.value;
        this.colaboradorSend.colaboradorVinculo = newVal;
        if (this.colaboradorSend.colaboradorVinculo != 1 && this.colaboradorSend.colaboradorVinculo != 2 && this.colaboradorSend.colaboradorVinculo != 6) {
            this.colaboradorSend.colaboradorResponsavelOSC = 0;
            this.colaboradorSend.colaboradorResponsavelOSCChecked = false;
        }
    }

    selectEscolaridade(event) {
        const newVal = event.currentTarget.value;
        this.colaboradorSend.colaboradorEscolaridade = newVal;
    }

    setResponsavelOSC(event) {
        const newVal = event.target.checked;
        if (newVal) {
            this.colaboradorSend.colaboradorResponsavelOSC = 1;
        } else {
            this.colaboradorSend.colaboradorResponsavelOSC = 0;
        }
    }

    selectCpf() {
        if (this.colaboradorSend.colaboradorCpf == undefined) {
            this.inValidCpf = true;
        } else {
            this.inValidCpf = !this.mascaras.validaCpfCnpj(this.colaboradorSend.colaboradorCpf);
            if (!this.inValidCpf) {
                this.loading.next(true);
                this.detalheParceriaApi.getPessoa(this.colaboradorSend.colaboradorCpf.replace(/[^\d]+/g, ''), 1)
                    .subscribe((response) => {
                        if (response) {
                            this.pessoaInfo = response as PessoaInfo;
                            this.colaboradorSend.colaboradorNome = this.pessoaInfo.nome;
                            this.colaboradorSend.colaboradorEndereco = this.pessoaInfo.nomeLogradouro + ", " + this.pessoaInfo.numeroEndereco +
                                (this.pessoaInfo.compl && this.pessoaInfo.compl != "null" ? " " + this.pessoaInfo.compl : "") + "\r\nCEP: " + this.pessoaInfo.cep;
                            this.loading.next(false);
                        }
                        // else  {
                        //     this.colaboradorSend.colaboradorNome = "";
                        //     this.colaboradorSend.colaboradorEndereco = "";
                        //     this.alertService.danger("O colaborador deve estar cadastrado no SIAT.");
                        //   }
                    }, (response) => {
                        if (response.status >= 500) {
                            this.alertService.danger(response.error);
                        } else {
                            this.alertService.warning(response.error);
                        }
                        this.loading.next(false);
                    }, () => this.loading.next(false));
                this.loading.next(false);
            }
        }
    }

    downloadColaboradorDocumento(id, nome, tipoArquivo) {
        this.loading.next(true);
        this.publicoPrivadaApi.getArquivoColaborador(id, tipoArquivo, this.isPortal).subscribe((response) => {
            if (response) {
                console.log(response);
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(response.body);
                a.href = url;
                a.download = nome;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            } else {
                this.alertService.warning('Nenhum arquivo encontrado');
            }
        }, (response) => {
            // if (response.status >= 500) {
            //     this.alertService.danger(response.error);
            // } else {
            //     this.alertService.warning(response.error);
            // }
            this.alertService.danger('Erro ao buscar arquivo de colaborador.');
            this.loading.next(false);
        }, () => this.loading.next(false));
    }

    updateTextArea(valor) {
        this.colaboradorSend.colaboradorEndereco = valor;
    }

    updateTextAreaLocalAtuacao(valor, cargaHoraria) {
        cargaHoraria.localAtuacao = valor;
    }

    replacerColaborador(key, value) {
        //atributos que só existem no view e não no servidor
        const ignoreList = ['colaboradorResponsavelOSCChecked', 'colaboradorVinculoTexto', 'colaboradorInicioMandatoDataFormat',
            'colaboradorFimMandatoDataFormat', 'colaboradorDataAdmissaoDataFormat', 'colaboradorDataDemissaoDataFormat', 'colaboradorDataHabilitacaoDataFormat',
            'dataInicioParceriaDataFormat', 'dataFimParceriaDataFormat'];
        if (ignoreList.indexOf(key) > -1) {
            return undefined;
        } else {
            return value;
        }
    }

    isBlank(str) {
        return (!str || /^\s*$/.test(str));
    }

    validaDataInicioFim(data1: Date, data2: Date): boolean{
        return data2 < data1;
    }

    salvaColaborador() {
        this.loading.next(true);
        if (this.isBlank(this.colaboradorSend.colaboradorCpf) || this.isBlank(this.colaboradorSend.colaboradorNome) || this.isBlank(this.colaboradorSend.colaboradorCargo)
            || this.colaboradorSend.colaboradorSalarioMensal == undefined || this.colaboradorSend.colaboradorVinculo == 0 || this.colaboradorSend.colaboradorVinculo == undefined
            || (this.colaboradorSend.colaboradorVinculo == 6 && this.isBlank(this.colaboradorSend.colaboradorVinculoOutro))) {
            this.alertService.danger('Verifique o preenchimento de todos os campos obrigatórios. Não foi possível salvar os dados.');
            this.loading.next(false);
            return;
        }
        if (this.inValidCpf) {
            this.alertService.danger('CPF inválido.');
            this.loading.next(false);
            return;
        }
        if (this.colaboradorSend.colaboradorDataAdmissaoDataFormat == null) {
            this.alertService.danger('Data de admissão deve ser informada.');
            return;
        }
        if (this.colaboradorSend.colaboradorDataAdmissaoDataFormat !== undefined) {
            this.colaboradorSend.colaboradorDataAdmissao = this.dateConverter.fromNgbDateStructToDate(this.colaboradorSend.colaboradorDataAdmissaoDataFormat);
            if (!this.utils.isValidDateAdmissaoDemissao(this.colaboradorSend.colaboradorDataAdmissao)) {
                this.alertService.danger('Data Admissão Inválida.');
                return;
            }
        } else {
            this.colaboradorSend.colaboradorDataAdmissao = null;
        }
        
        if (this.colaboradorSend.colaboradorDataDemissaoDataFormat !== undefined &&
            this.colaboradorSend.colaboradorDataDemissaoDataFormat != null) {
            this.colaboradorSend.colaboradorDataDemissao = this.dateConverter.fromNgbDateStructToDate(this.colaboradorSend.colaboradorDataDemissaoDataFormat);
            if (!this.utils.isValidDateAdmissaoDemissao(this.colaboradorSend.colaboradorDataDemissao) || this.validaDataInicioFim(this.colaboradorSend.colaboradorDataAdmissao, this.colaboradorSend.colaboradorDataDemissao)) {
                this.alertService.danger('Data Demissão Inválida.');
                return;
            }
        } else {
            this.colaboradorSend.colaboradorDataDemissao = null;
        }

       /*if(this.colaboradorSend.colaboradorDataDemissaoDataFormat != null && this.validaDataInicioFim(this.colaboradorSend.colaboradorDataAdmissao, this.colaboradorSend.colaboradorDataDemissao)){
            this.alertService.danger('Data de Demissão Inválida.');
                    this.loading.next(false);
                    return;
        }
        */
        if (this.colaboradorSend.colaboradorSalarioMensal <= 0 && (this.colaboradorSend.colaboradorVinculo == 1
            || this.colaboradorSend.colaboradorVinculo == 3
            || this.colaboradorSend.colaboradorVinculo == 5)) {
            this.alertService.danger('Salário mensal deve ser informado.');
            return;
        }
        if (this.colaboradorSend.colaboradorCBO == 0 || this.colaboradorSend.colaboradorCBO == undefined){
            this.alertService.danger('O CBO deve ser informado.');
            return;
        }
       
        if(this.colaboradorSend.colaboradorEscolaridade == undefined){
            this.alertService.danger('Nível de escolaridade deve ser informado.');
                return;
        }
        if (this.colaboradorSend.colaboradorVinculo != 6) {
            this.colaboradorSend.colaboradorVinculoOutro = null;
        }

        if (this.colaboradorSend.colaboradorTipoAtividade != 1 && this.colaboradorSend.colaboradorTipoAtividade != 2) {
            this.alertService.danger('O Tipo de Atividade desenvolvida deve ser informado.');
            return;
        }
       
        if (this.colaboradorSend.colaboradorEscolaridade >= 3 && this.colaboradorSend.colaboradorEscolaridade < 9) {
            if (this.isBlank(this.colaboradorSend.colaboradorNomeCurso)) {
                this.alertService.danger('Nome do curso deve ser informado.');
                return;
            }

            if (this.isBlank(this.colaboradorSend.colaboradorDocumentoArquivo)) {
                this.alertService.danger('Certificado de conclusão/Diploma deve ser anexado.');
                return;
            }

            if (this.colaboradorSend.colaboradorDocumentoArquivo.length > 50) {
                this.alertService.danger('Nome do arquivo superior a 50 caracteres, por favor renomeie antes de fazer upload.');
                return;
            }
        }

        this.colaboradorSend.cargasHorarias.forEach(element => {
            if (element.dataInicioParceriaDataFormat !== undefined &&
                element.dataInicioParceriaDataFormat != null) {
                element.dataInicioParceria = this.dateConverter.fromNgbDateStructToDate(element.dataInicioParceriaDataFormat);
            } else {
                element.dataInicioParceria = null;
            }

            if (element.dataFimParceriaDataFormat !== undefined &&
                element.dataFimParceriaDataFormat != null) {
                element.dataFimParceria = this.dateConverter.fromNgbDateStructToDate(element.dataFimParceriaDataFormat);
            } else {
                element.dataFimParceria = null;
            }
        })
        let erroFind: boolean = false;
        if (this.colaboradorSend.cargasHorarias.length < 1) {
            this.alertService.danger('É necessário informar a carga horária.');
            this.loading.next(false);
            return;
        }
        this.colaboradorSend.cargasHorarias.forEach(element => {
            if (element.parId === null) {
                this.alertService.danger('Por favor, selecione um termo. Não foi possível salvar os dados.');
                this.loading.next(false);
                erroFind = true;
                return;
            }
            if (element.cargaHorariaSemanal === null) {
                this.alertService.danger('Por favor, preencha a carga horária. Não foi possível salvar os dados.');
                this.loading.next(false);
                erroFind = true;
                return;
            }
            if (element.dataInicioParceriaDataFormat === null) {
                this.alertService.danger('Por favor, data de início da parceria. Não foi possível salvar os dados.');
                this.loading.next(false);
                erroFind = true;
                return;
            }
            else {
                if (!this.utils.isValidDate(element.dataInicioParceria)) {
                    this.alertService.danger('Data Início Parceria Inválida.');
                    this.loading.next(false);
                    erroFind = true;
                    return;
                }
            }
            if (element.dataFimParceriaDataFormat != null
                && (!this.utils.isValidDate(element.dataFimParceria) 
                || this.validaDataInicioFim(element.dataInicioParceria, element.dataFimParceria))) {
                    this.alertService.danger('Data Fim Parceria Inválida.');
                    this.loading.next(false);
                    erroFind = true;
                    return;
                }
        });

        if (erroFind) {
            return;
        }
        if (this.colaboradorSend.colaboradorDataHabilitacaoDataFormat !== undefined) {
            this.colaboradorSend.colaboradorDataHabilitacao = this.dateConverter.fromNgbDateStructToDate(this.colaboradorSend.colaboradorDataHabilitacaoDataFormat);
            if (!this.utils.isValidDateAdmissaoDemissao(this.colaboradorSend.colaboradorDataHabilitacao)) {
                this.alertService.danger('Data Habilitação Inválida.');
                return;
            }
        } else {
            this.colaboradorSend.colaboradorDataHabilitacao = null;
        }

        if (this.colaboradorSend.colaboradorResponsavelOSC == 1) {
            if (this.colaboradorSend.colaboradorInicioMandatoDataFormat !== undefined) {
                this.colaboradorSend.colaboradorInicioMandato = this.dateConverter.fromNgbDateStructToDate(this.colaboradorSend.colaboradorInicioMandatoDataFormat);
                if (!this.utils.isValidDateAdmissaoDemissao(this.colaboradorSend.colaboradorInicioMandato)) {
                    this.alertService.danger('Data Início Mandato Inválida.');
                    return;
                }
            } else {
                this.colaboradorSend.colaboradorInicioMandato = null;
            }

            if (this.colaboradorSend.colaboradorFimMandatoDataFormat) {
                this.colaboradorSend.colaboradorFimMandato = this.dateConverter.fromNgbDateStructToDate(this.colaboradorSend.colaboradorFimMandatoDataFormat);
                if (!this.utils.isValidDateAdmissaoDemissao(this.colaboradorSend.colaboradorFimMandato)) {
                    this.alertService.danger('Data Fim Mandato Inválida.');
                    return;
                }
            } else {
                this.colaboradorSend.colaboradorFimMandato = null;
            }
        } else {
            this.colaboradorSend.colaboradorInicioMandato = null;
            this.colaboradorSend.colaboradorFimMandato = null;
            this.colaboradorSend.colaboradorFuncaoDiretiva = null;
        }

        // this.colaboradorSend.cargasHorarias.forEach(element => {
        //     if (element.dataInicioParceriaDataFormat !== undefined) {
        //         element.dataInicioParceria = this.dateConverter.fromNgbDateStructToDate(element.dataInicioParceriaDataFormat);
        //     }
        //     if (element.dataFimParceriaDataFormat !== undefined) {
        //         element.dataFimParceria = this.dateConverter.fromNgbDateStructToDate(element.dataFimParceriaDataFormat);
        //     }
        // })

        if (this.colaboradorSend.colaboradorSalarioBruto < this.colaboradorSend.colaboradorSalarioMensal){
            this.alertService.danger('Salário bruto deve ser igual ou superior ao salário base.');
            return;
        }
        this.colaboradorSend.colaboradorOperacaoData = new Date();
        this.colaboradorSend.colaboradorCpf = this.colaboradorSend.colaboradorCpf.replace(/[^\d]+/g, '')
        let data = JSON.stringify(this.colaboradorSend, this.replacerColaborador);
        this.detalheParceriaApi.updateColaborador(data).subscribe(async (response) => {
            if (response) {
                this.setupInterface();
                this.setColaborador(undefined);
                this.modalEdicaoReference.close();
                this.getColaboradores(this.cnpj);
                this.alertService.info("Cadastro inserido/atualizado com sucesso!")
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
            this.modalEdicaoReference.close();
        }, () => this.loading.next(false));
    }

    verificaCargaHoraria(): boolean{
        if(this.colaboradorSend.cargasHorarias.length > 0){
            if(this.colaboradorSend.cargasHorarias[0].parId != null 
                && this.colaboradorSend.cargasHorarias[0].cargaHorariaSemanal != null 
                && this.colaboradorSend.cargasHorarias[0].dataInicioParceriaDataFormat != null){
                return true;
            }
            else{
                return false;
            }
        }
    }

    adicionarCargaHoraria() {
        var cargaHoraria = new ColaboradorCargaHoraria();
        cargaHoraria.id = -1;
        cargaHoraria.colaboradorId = null;
        cargaHoraria.parId = null;
        cargaHoraria.cargaHorariaSemanal = null;
        cargaHoraria.dataInicioParceria = null;
        cargaHoraria.dataFimParceria = null;
        cargaHoraria.localAtuacao = null;

        this.colaboradorSend.cargasHorarias.push(cargaHoraria);
    }

    excluirCargaHoraria(cargaHoraria) {
        const index: number = this.colaboradorSend.cargasHorarias.indexOf(cargaHoraria);
        if (index !== -1) {
            this.colaboradorSend.cargasHorarias.splice(index, 1);
            if (cargaHoraria.id != -1) {
                this.colaboradorSend.idsCargasHorariasAExcluir.push(cargaHoraria.id);
            }
        }
    }

    excluirColaborador() {
        this.detalheParceriaApi.excluirColaborador(this.colaboradorSend).subscribe((response) => {
            if (response) {
                this.setupInterface();
                this.setColaborador(undefined);
                this.modalEdicaoReference.close();
                this.getColaboradores(this.cnpj);
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));
    }

    onFileChanged(event, tipo) {
        const fileList: FileList = event.currentTarget.files;
        const reader = new FileReader();
        if (fileList.length > 0) {
            const array = fileList[0].type.split('/');
            const fileSize = fileList[0].size / 1024 / 1024; // in MB
            if (fileSize > 10) {
                this.alertService.danger('Tamanho de arquivo superior ao limite de 10 MB. Esse arquivo possui ' + fileSize.toPrecision(2) + " MB");
                return;
            }
            if (array[1] === 'pdf' || array[1] === 'gif' || array[1] === 'jpg' || array[1] === 'jpeg' || array[1] === 'png') {
                reader.readAsDataURL(fileList[0]);
                reader.onload = () => {
                    if (tipo ==="D"){
                        this.colaboradorSend.colaboradorDocumento = ((reader.result).toString().split(',')[1]) as unknown as Blob;
                        this.colaboradorSend.colaboradorDocumentoTipo = array[1];
                        this.colaboradorSend.colaboradorDocumentoArquivo = fileList[0].name;
                    }
                    else if (tipo ==="T"){
                        this.colaboradorSend.colaboradorTermoRescisaoArquivo = ((reader.result).toString().split(',')[1]) as unknown as Blob;
                        this.colaboradorSend.colaboradorTermoRescisaoTipo = array[1];
                        this.colaboradorSend.colaboradorTermoRescisaoNome = fileList[0].name;
                    }
                };
            } else {
                event.currentTarget.value = '';
                this.alertService.warning('Formato de arquivo não suportado!');
            }
        }
    }

    relatorioColaboradores(tipo) {
        this.loading.next(true);
        this.keycloakService.getUser().subscribe((user: AuthUser) => {
            var busca: BuscaRelatorioCampos = new BuscaRelatorioCampos(Number(user.attributes.sgp_orgao[0]), null, null, '', this.nomeOsc, this.cnpj, 0, 0, 0, '', tipo, 'colaboradores', 0, 25, null, null, null);
            this.relatoriosApi.getArquivoRelatorio(busca)
                .subscribe((response) => {
                    if (response) {
                        console.log(response);
                        const a = document.createElement('a');
                        const url = window.URL.createObjectURL(response);
                        const nomeOrgArq = busca.nomeOrg.replace('/', '-');
                        a.download = `relatorio_${busca.nomeRelatorio}_${nomeOrgArq}.${busca.tipoArquivo}`;
                        a.href = url;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);

                    } else {
                        this.alertService.warning('Nenhum resultado foi obtido, por favor refaça a busca com outros critérios.');
                    }
                }, (response) => {
                    if (response.status >= 500) {
                        this.alertService.danger(response.error);
                    } else {
                        this.alertService.warning(response.error);
                    }
                    this.loading.next(false);
                }, () => this.loading.next(false));
        });
    }
    async getCboLista() {
        this.loading.next(true);
        this.detalheParceriaPortalApi.getCboAll()
            .subscribe((response) => {
                if (response) {
                    this.cboLista = response as Array<Cbo>;
                   // console.log(this.ocupacaoLaboralLista);
                }
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));
      }
      
      async getCboByID(id) {
        this.loading.next(true);
        this.detalheParceriaPortalApi.getCboByID(id)
            .subscribe((response) => {
                if (response) {
                    this.cboSelecionada = response as Cbo;
                    console.log(this.cboSelecionada);
                }
                else{
                    this.cboSelecionada = undefined;
                }
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));
      }
    
  compareFn(item, selected) {
        console.log("ITEM", item);
        console.log("SELECTED", selected);
        return item.id === selected.id;
  }
  updateCBO(event) {
    const value = event.id;
    this.colaboradorSend.colaboradorCBO = value;
    this.colaboradorCBO = value;
  }

  updateTipoAtividade(event) {
    const value = event.currentTarget.value;
    this.colaboradorSend.colaboradorTipoAtividade = value;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.codigo.toString().indexOf(term) > -1 || 
           item.atividade.toLocaleLowerCase().indexOf(term) > -1;
    }
}