import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { UserService } from '../../@core/user.service';
import { AlertService } from '../../@core/alert.service';
import { RelatoriosApi } from '../../apis/relatoriosApi';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from 'src/app/@core/date-converter';
import { KeycloakService, AuthUser } from '@procempa/ngx-keycloak';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Utils } from 'src/app/@shared/utils';
import { BuscaRelatorioCampos } from '../buscaRelatorioCampos';
import { BuscaRelatorioGastoPessoalCampos } from './buscaRelatorioGastoPessoalCampos';

@Component({
    selector: 'app-relatorio-gasto-pessoal',
    templateUrl: './relatorioGastoPessoal.component.html',
    styleUrls: ['../../modulo2/exibeParcerias/exibeParceriasM2.component.scss']
})

export class RelatorioGastoPessoalComponent implements OnInit {
    
    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable().pipe(share());
    selectedParceriaId: number = null;
    status: number;
    mascaraCNPJ = { mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/], guide: false };
    busca = new BuscaRelatorioGastoPessoalCampos(0, null, null, null, null, null, null, "");
    @Input()
    isPortal: boolean;
    @Input()
    modulo: string;
    isFiscal: boolean;
    isSMTC: boolean;
    isConsulta: boolean;
    arquivo: string;
    isAuditorExterno: boolean;
    auditorEmail: string;
    dataIni: NgbDateStruct;
    dataFim: NgbDateStruct;
    dropdownSettings: IDropdownSettings = {
        selectAllText: "Selecionar Tudo",
        unSelectAllText: "Limpar Seleção",
        idField: 'id',
        textField: 'name',
    };
    parIds = [];
    cnpjOscs = [];
    naturezas = [];
    dropdownOscList = [];
    dropdownNaturezaList = [];
    dropdownTermoList = [];

    constructor(private alertService: AlertService,
        private relatoriosApi: RelatoriosApi,
        private detalheParceriaPortalApi: DetalheParceriaApi,
        public userService: UserService,
        private dateConverter: DateConverter,
        private router: Router,
        public keycloakService: KeycloakService,
        public utils: Utils,
        private route:ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.modulo = 'relatorio';
        if (this.isPortal === undefined) {
            this.isPortal = false;
        }

        this.isConsulta = this.userService.isConsulta();
        this.isFiscal = this.userService.isFiscal();

        this.route.queryParams.subscribe(params => {
            this.isAuditorExterno = params['isAuditorExterno'] === undefined || params['isAuditorExterno'].toLowerCase() === 'false' ? false : true;
            this.auditorEmail = this.keycloakService['_keycloakInstance'].tokenParsed.email;
        });
      

        if (!this.isFiscal && !this.isConsulta && !this.isAuditorExterno) {
            this.router.navigate(['notAuthorized']);
        }
        // pegar qualificador
        this.keycloakService.getUser().subscribe((user: AuthUser) => {

            //console.log(user.attributes.sgp_orgao[0]);
            if (this.isFiscal && user.attributes.sgp_orgao !== null) {
                // tslint:disable-next-line:no-unused-expression
                if (this.userService.isSMTC()) {
                  this.isSMTC = true;
                  this.busca = new BuscaRelatorioGastoPessoalCampos(18, [], [], [], null, null, null, "");
                } else {
                  this.busca = new BuscaRelatorioGastoPessoalCampos(18, [], [], [], null, null, null, "");
                }
              }
              if (this.isAuditorExterno){
                this.busca = new BuscaRelatorioGastoPessoalCampos(18, [], [], [], null, null, null, "");
                this.busca.auditorEmail = this.auditorEmail;
              }
            this.carregaComboboxOSCs();
           // this.carregaComboboxTermos();
        });
    }

    geraArquivo(tipo) {
        this.busca.tipoArquivo = tipo;
        if (this.dataIni) {
            this.busca.dataInicio = this.dateConverter.fromNgbDateStructToDate(this.dataIni);
        }
        if (this.dataFim) {
            this.busca.dataFim = this.dateConverter.fromNgbDateStructToDate(this.dataFim);
        }

        console.log(this.busca);
        this.relatoriosApi.getRelatorioGastoPessoal(this.busca).subscribe((response) => {
            if (response) {
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(response);
                a.download = `relatorio_gasto_pessoal.${this.busca.tipoArquivo}`;
                a.href = url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);

            } else {
                this.alertService.warning('Nenhum resultado foi obtido, por favor refaça a busca com outros critérios.');
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));
    }

    carregaComboboxOSCs() {
        this.relatoriosApi.getOscsCombobox(this.busca.orgao).subscribe((response) => {
            if (response) {
                this.dropdownOscList = [];
                response.forEach(element => {
                    this.dropdownOscList.push({ id: element[0], name: element[1] });
                });
            }
        })
    }

    onOscSelect(event) {
        this.busca.cnpjOscs.push(event.id);
     //   this.carregaComboboxTermos();
    }

    onOscDeSelect(event) {
        this.busca.cnpjOscs.forEach((element, index) => {
            if (element == event.id) {
                this.busca.cnpjOscs.splice(index, 1);
            }
        });
      //  this.carregaComboboxTermos();
    }

    onSelectAllOsc(event) {
        event.forEach((element) => {
            this.busca.cnpjOscs.push(element.id);
        })
      //  this.carregaComboboxTermos();
    }

    onOscDeSelectAll(event) {
        this.busca.cnpjOscs = [];
       // this.carregaComboboxTermos();
    }

    onNaturezaSelect(event) {
        this.busca.naturezas.push(event.id);
    }

    onNaturezaDeSelect(event) {
        this.busca.naturezas.forEach((element, index) => {
            if (element == event.id) {
                this.busca.naturezas.splice(index, 1);
            }
        });
    }

    onSelectAllNatureza(event) {
        event.forEach((element) => {
            this.busca.naturezas.push(element.id);
        })
    }

    onNaturezaDeSelectAll(event) {
        this.busca.naturezas = [];
    }

    // carregaComboboxTermos() {
    //     var buscaTermos = new BuscaTermosRelatorioNotificacoesCampos();
    //     buscaTermos.orgao = this.busca.orgao;
    //     buscaTermos.cnpjs = this.busca.cnpjOscs;

    //     this.relatoriosApi.getTermosCombobox(buscaTermos).subscribe((response) => {
    //         if (response) {
    //             this.dropdownTermoList = [];
    //             this.parIds = [];
    //             this.busca.parIds = [];
    //             response.forEach(element => {
    //                 this.dropdownTermoList.push({ id: element[0], name: element[1] });
    //             });
    //         }
    //     })
    //}

    // onTermoSelect(event) {
    //     this.busca.parIds.push(event.id);
    // }

    // onSelectAllTermo(event) {
    //     event.forEach(element => {
    //         this.busca.parIds.push(element.id);
    //     });
    // }

    // onTermoDeSelect(event) {
    //     this.busca.parIds.forEach((element, index) => {
    //         if (element == event.id) {
    //             this.busca.parIds.splice(index, 1);
    //         }
    //     })
    // }

    // onTermoDeSelectAll(event) {
    //     this.busca.parIds = [];
    // }
    limparFiltros() {
        this.busca.dataInicio = undefined;
        this.busca.dataFim = undefined;
        this.busca.naturezas = null;
        this.busca.parIds = null;
        this.busca.cnpjOscs = null;
        this.busca.orgao=null;
        this.busca.tipoArquivo = '';
        this.busca.auditorEmail= " ";
        this.parIds = [];
        this.cnpjOscs = [];
        this.naturezas = [];
        this.dropdownOscList = [];
        this.dropdownNaturezaList = [];
        this.dropdownTermoList = [];
      }
    }