<div style="margin-left:40px; margin-right:40px">
	<h2>NOTIFICAÇÕES</h2>
	<div class="lista-notificacoes">
		<ul>
			<li *ngFor="let data of notificacoes">
				<div class="primeira-linha">
					<div class="osc">
						{{data.nomeOsc}}
					</div>
					<div>
						{{data.strData}}
					</div>
				</div>
				<div class="segunda-linha">
					<div class="osc">
						<span [innerHTML]="data.texto"></span>
					</div>
					<div><i class="fa fa-trash-o" (click)="ocultaNotificacao(data.notifItemId)"></i>
					</div>
				</div>
			</li>
		</ul>
	</div>
</div>