import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from './parceriaInfo';
import { DetalheParceriaApi } from '../../apis/detalheParceriaApi';
import { Mascaras } from '../../@shared/components/Mascaras';
import { NgbModal, NgbPanelChangeEvent, NgbAccordion, NgbDateStruct, NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../@core/user.service';
import { DirigenteInfo } from './dirigenteInfo';
import { DetalheParceriaPortalApi } from 'src/app/apis/detalheParceriaPortalApi';
import { PesquisaData } from '../pesquisaData';
import { DateConverter } from 'src/app/@core/date-converter';
import { DatetimeRange } from 'src/app/@shared/components/datepicker/datatime-range';
import { Utils } from 'src/app/@shared/utils';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { LogonApi } from 'src/app/apis/logonApi';
import { AuditorLogon } from 'src/app/liberacaoLogon/auditor/auditorLogon';

@Component({
  selector: 'app-detalhe-parceria',
  templateUrl: './detalheParceria.component.html',
  styleUrls: ['./detalheParceria.component.scss']
})
export class DetalheParceriaComponent implements OnInit {

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  @Input()
  parceriaInfo: ParceriaInfo;
  @Input()
  isPortal: boolean;
  @Input()
  modulo: string;
  @Input()
  isAuditorExterno: boolean;
  years = ['2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025',
    '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035', '2036', '2037', '2038', '2039', '2040'];
  currentYear = new Date().getFullYear();
  yearFromSelector: string;
  monthSelection = new Date().getMonth();
  parId: number;
  modalEdicaoReference: any;
  @ViewChild('acc') accordion: NgbAccordion; accordionComponent: NgbAccordion;
  isFiscal: boolean;
  isConsulta: boolean;
  dataIni: NgbDateStruct;
  dataFim: NgbDateStruct;
  auditorLogonArray: Array<AuditorLogon> = [];

  private _pesquisaData = new PesquisaData();

  public get pesquisaData(): PesquisaData {
    return this._pesquisaData;
  }
  public set pesquisaData(value: PesquisaData) {
    this._pesquisaData = value;
  }

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    private modalService: NgbModal,
    public userService: UserService,
    private dateConverter: DateConverter,
    private mascaras: Mascaras,
    private utils: Utils,
    private keycloakService: KeycloakService,
    private logonApi: LogonApi,
   private config: NgbAccordionConfig
    ) {
      config.closeOthers = true;
  }
  ngAfterViewInit() {
    if (this.accordion) {
      this.accordionComponent = this.accordion;
    }
  }

  async ngOnInit() {
    //this.pesquisaData = new PesquisaData();
    this.modulo = 'm2';
    if (this.isPortal === undefined) {
      this.isPortal = false;
    }
    this.isFiscal = this.userService.isFiscal();
    this.isConsulta = this.userService.isConsulta();
    const index = this.years.indexOf(this.currentYear.toString());
    this.yearFromSelector = this.years[index];
    this.setupInterface();
    const now = new Date();
    this.dataIni = { year: now.getFullYear(), month: now.getMonth() + 1, day: 1 };
    this.pesquisaData.tipo = '1'; // alterado para data pagamento
    if (!this.isFiscal && !this.isConsulta) {
      if (this.isAuditorExterno === undefined) {
        await this.getAuditorLogon();
      }
    }
  }

  logout() {
    this.userService.logout();
  }

  acordiaoChange(props: NgbPanelChangeEvent): void {

    if (props.panelId === 'resumo') {
      this.getParceriaInfo();
    }

    if (this.accordionComponent) {
      const activeArray: Array<string> = Array.from(this.accordionComponent.activeIds);

      // fecha outros elementos do acordion quando abertos (ativos)
      activeArray.forEach(element => {
        if (element !== props.panelId) {
          this.accordionComponent.toggle(element);
        }
      });
    }
  }

  getParceriaInfo() {
    this.loading.next(true);
    this.detalheParceriaPortalApi.getParceriaInfo(this.parceriaInfo.parId)
      .subscribe((response) => {
        if (response) {
          this.parceriaInfo = response as ParceriaInfo;
          // console.log(this.parceriaInfo);
        } else {
          this.alertService.warning('Nenhum registro encontrado');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
    this.parceriaInfo.totalDesembolsos = (this.parceriaInfo.execTotVlrPerm + this.parceriaInfo.execTotVlrOutros +
      this.parceriaInfo.execTotVlrPF + this.parceriaInfo.execTotVlrPJ + this.parceriaInfo.execTotVlrConsumo);
  }

  setupInterface() {
    if (!this.isPortal) {
      this.loading.next(true);
      this.route.params.subscribe(params => {
        this.parId = +params['id'];
        console.log('detalheParceria' + this.parId);
      });
      if (this.parId !== undefined) {
        this.route.paramMap.pipe(
          switchMap((params: ParamMap) =>
            this.detalheParceriaApi.getParceriaInfo(this.parId))
        ).subscribe(async d => {
          this.parceriaInfo = d;
          // console.log(this.parceriaInfo);
          this.loading.next(false);
        });
      }
    }
  }

  replacer(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['totalDesembolsos', 'quantidadeTotal'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  atualizaParceria() {

    const dirigenteInfo = new DirigenteInfo();
    dirigenteInfo.execDtUltAlt = new Date();
    this.userService.userInfo.subscribe(user => dirigenteInfo.execOperUltAlt = user.email.split('@')[0]);
    dirigenteInfo.execEmail = this.parceriaInfo.execEmail;
    dirigenteInfo.execTelefone = this.parceriaInfo.execTelefone;
    dirigenteInfo.execResponsavel = this.parceriaInfo.execResponsavel;
    dirigenteInfo.parId = this.parceriaInfo.parId;

    this.detalheParceriaApi.updateInfoParceria(dirigenteInfo).subscribe((response) => {
      if (response) {
        this.setupInterface();
        this.modalEdicaoReference.close();
        //console.log(response);
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }

  openAtualizarParceria(content) {
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }

  getStatusParceira(cod) {
    return this.utils.getStatusParceira(cod);
  }

  aplicaMascaraTelefone(telefone) {
    return this.mascaras.aplicaMascaraTelefone(telefone, '51');
  }
  aplicaMascaraReais(valor) {
    return this.mascaras.aplicaMascaraReais(valor);
  }

  aplicaMascaraCNPJ(doc) {
    return this.mascaras.aplicaMascaraDocumento(doc, 2);
  }
  novoPeriodo(dataRangeEvent: DatetimeRange) {
    const pesq = this.pesquisaData;
    pesq.dataInicial = this.dateConverter.fromNgbDateToDate(dataRangeEvent.fromNgbDate);
    pesq.dataFinal = this.dateConverter.fromNgbDateToDate(dataRangeEvent.toNgbDate);
    this.pesquisaData = { ...this.pesquisaData };
  }

  async getAuditorLogon() {
    this.logonApi.getAuditorLogon(this.keycloakService['_keycloakInstance'].tokenParsed.email)
      .subscribe((response) => {
        if (response) {
          this.auditorLogonArray = response as Array<AuditorLogon>;
          if (this.auditorLogonArray.length > 0) {
            this.isAuditorExterno = true;
          } else {
            this.isAuditorExterno = false;
          }
        }

      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }

      });

  }
}

