import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class PrestacaoDeContas {
    public id: number;
    public parId: number;
    public nome: string;
    public inicioPeriodo: Date;
    public fimPeriodo: Date;
    public status: number;
    public saldoInicial: number = 0;
    public saldoFinal: number = 0;
    public creditoPoupanca: number = 0;
    public debitoPoupanca: number = 0;
    public creditoAplicacao: number = 0;
    public debitoAplicacao: number = 0;
    public totalRepasses: number;
	public totalDesembolsos: number;
	public totalDevolucoes: number;
	public saldoParcial: number;
    public strStatus: string;
    public arquivo: string;
	public arqTipo: string;
	public dadosArquivo: Blob;
    public inicioPeriodoDataFormat: NgbDateStruct;
    public fimPeriodoDataFormat: NgbDateStruct;
    public operacaoData: Date;
    public operacaoUsuario: string;
    public motivoRejeicao: string;
    public totalDevSaldoRem: number = 0;
    public logs: Array<LogPrestacaoContas>;
}

export class LogPrestacaoContas{
    public id: number;
    public status: number;
    public strStatus: string;
    public operacaoData: Date;
    public motivoRejeicao: string;
}

export enum status{
    'Pendente de envio' = 1,
    'Enviada' = 2,
    'Aceita' = 3,
    'Rejeitada' = 4,
    'Pendente de reenvio' = 5,
    'Reenviada' = 6,
}