import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share, filter } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from '../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../../apis/detalheParceriaApi';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { UserService } from '../../@core/user.service';
import { PlanejamentoEditais } from './planejamentoEditais';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from 'src/app/@core/date-converter';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { Utils } from 'src/app/@shared/utils';


@Component({
  selector: 'app-editais-parceria',
  templateUrl: './../editais/editais.component.html',
  styleUrls: ['./../../modulo2/detalheParceria/detalheParceria.component.scss']
})
export class EditaisComponent implements OnInit {

  @Input()
  public  parceriaInfo: ParceriaInfo;
  @Input()
  private  isPortal: boolean;
  @Output() valueChange = new EventEmitter();
  planEditais: Array<PlanejamentoEditais> = [];
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  modalEdicaoReference: any;
  showUpload: boolean = false;
  editalSend: PlanejamentoEditais;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean
  @Input()
  isAuditorExterno: boolean;

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    private parceriasDespesasDevolApi: PublicoPrivadaApi,
    public userService: UserService,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private utils: Utils) {

    }

  ngOnInit() {
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();
    this.setupInterface();
  }


  logout() {
    this.userService.logout();
  }
  setupInterface() {
 
    this.loading.next(true);

    this.route.paramMap.pipe(
        switchMap(() =>
         this.detalheParceriaPortalApi.getPlanejamentoEditais(this.parceriaInfo.parId))
         ).subscribe((response) => {
          if (response) {
            this.planEditais = response as Array<PlanejamentoEditais>;
            // console.log(this.valoresLiberados);
          } else {
            this.alertService.warning('Nenhum registro encontrado');
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));

        this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
          this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
        });
  }

  disableCampos() {
    return (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }
  visualizarBotao() {
    return ((this.isSMTC || this.isConsulta || this.isAuditorExterno));
  }
  disableApagarIcone() {
    return (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }
  enableEditarIcone() {
    if (this.isConsulta || this.isSMTC || this.isAuditorExterno) {
      return false;
    }
    return true;
  }
  
  getArquivo(parId, id, tipo, nome) {
    this.loading.next(true);
    this.parceriasDespesasDevolApi.getArquivo(parId, id, tipo, this.isPortal)
      .subscribe((response) => {
        if (response) {
          console.log(response);
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(response.body);
          a.href = url;
          a.download = nome;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          this.alertService.warning('Nenhum arquivo encontrado');
        }
      }, (response) => {
        // if (response.status >= 500) {
        //   this.alertService.danger(response.error);
        // } else {
        //   this.alertService.warning(response.error);
        // }
        this.alertService.danger('Erro ao buscar arquivo.');
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

 setEdiDocumentos(doc) {
    if (doc) {
      this.editalSend = doc;
      this.showUpload = this.editalSend.ediDocumentosArquivo ? false : true;
      this.editalSend.ediDocumentosDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.editalSend.ediDocumentosData));
    } else {
      this.editalSend = new PlanejamentoEditais();
      this.showUpload = true;
      this.editalSend.parId = this.parceriaInfo.parId;
      this.editalSend.ediOrgId = -1;
      this.editalSend.ediDocumentosTipo = '';
    }

  }

  salvarEditais() {


    if (this.editalSend.ediDocumentos === null || this.editalSend.ediDocumentos === undefined) {
      this.alertService.danger('Dado obrigatório, realize o upload do arquivo.');
      return;
    }

    if ( (this.editalSend.ediDocumentosArquivo != null && this.editalSend.ediDocumentosArquivo.length > 50)) {
      this.alertService.danger('Nome do arquivo superior a 50 caracteres, por favor renomeie antes de fazer upload.');
      return;
    }

    // tslint:disable-next-line:max-line-length
    this.editalSend.ediDocumentosData = this.dateConverter.fromNgbDateStructToDate(this.editalSend.ediDocumentosDataFormat);
    if (!this.utils.isValidDate(this.editalSend.ediDocumentosData)){
      this.alertService.danger('Data Inválida.');
      return;
    }
    let data = JSON.stringify(this.editalSend, this.replacerDocComplementar);
    this.detalheParceriaApi.updateEditais(data).subscribe(async (response) => {
      if (response) {
        this.setupInterface();
        this.setEdiDocumentos(undefined);
        this.modalEdicaoReference.close();
        //await this.getParceriaInfo(this.editalSend.parId);
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }

  excluirEditais() {
    this.detalheParceriaApi.excluirEditais(this.editalSend).subscribe((response) => {
      if (response) {
        this.setupInterface();
        this.setEdiDocumentos(undefined);
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }

  replacerDocComplementar(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['ediDocumentosDataFormat'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  openEdicao(content, doc) {
    this.setEdiDocumentos(doc);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false, windowClass: "modalProcesso" });
  }

  openExcluir(content, doc) {
    this.setEdiDocumentos(doc);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false });
  }

  onFileDocChanged(event) {
    const fileList: FileList = event.currentTarget.files;
    console.log(fileList);
    const reader = new FileReader();


    if (fileList.length > 0) {
      const array = fileList[0].type.split('/');
      const fileSize = fileList[0].size / 1024 / 1024; // in MB
      if (fileSize > 2) {
        this.alertService.danger('Tamanho de arquivo superior ao limite de 2MB. Esse arquivo possui ' + fileSize.toPrecision(1)+"MB");
        return;
      }
      if (array[1] === 'jpeg' || array[1] === 'jpg' || array[1] === 'gif'
        || array[1] === 'png' || array[1] === 'pdf') {

        reader.readAsDataURL(fileList[0]);
        reader.onload = () => {
          this.editalSend.ediDocumentos = ((reader.result).toString().split(',')[1]) as unknown as Blob;
          this.editalSend.ediDocumentosTipo = array[1];
          this.editalSend.ediDocumentosArquivo = fileList[0].name;
        };
      } else {
        event.currentTarget.value = '';
        this.alertService.warning('Formato de arquivo não suportado!');
      }
    }
  }
}

