import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class ContratosPj {
    public id: number;
    public prestadorPJTerceiroId: number;
    public numeroContrato:number;
    public tipoDeServico:string;
    public valorMensalVigente: number;
    public inicioVigenciaContrato: Date;
    public inicioVigenciaContratoDataFormat: NgbDateStruct;
    public fimVigenciaContrato: Date;
    public fimVigenciaContratoDataFormat: NgbDateStruct;
    public inicioVigenciaValorMensal: Date;
    public inicioVigenciaValorMensalDataFormat: NgbDateStruct;
    public fimVigenciaValorMensal: Date;
    public fimVigenciaValorMensalDataFormat: NgbDateStruct;
    public ocupacaoLaboralSelecionada:any;
    public tipoAtividade: number;
    public tipoAtividadeSelecionada:any;
    public contratoNome: string;
    public contratoTipo: string;
    public contratoArquivo:Blob;
    public orcamento1Nome: string;
    public orcamento1Tipo: string;
    public orcamento1Arquivo:Blob;
    public orcamento2Nome: string;
    public orcamento2Tipo: string;
    public orcamento2Arquivo:Blob;
    public orcamento3Nome: string;
    public orcamento3Tipo: string;
    public orcamento3Arquivo:Blob;
    
    //public tipoDeServicoNome:string;
    
}