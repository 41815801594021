export class BuscaPriorizacaoCompletaCampos{
  
        public orgao: number;
        public numeroTermo: string;
        public cnpjNome: string;
        public inspecionadas: boolean;
        public naoInspecionadas: boolean;
        public nivelTipo:string;
        public nivelOperacional: boolean;
        public pagina: number;
        public limite: number
    
}
