<div *ngIf="parceriaInfo != undefined">
    <div class="infoGeral container">
        <dl>
            <dd>
                <div>
                    <h2>
                        <div *ngIf="!isPortal && modulo === 'm3'">
                            <a *ngIf="true" ngbTooltip="Voltar" routerLink="/exibeParceriaM3" class="link">
                                <i class="fa fa-arrow-circle-left fa-lg" aria-hidden="true"></i> </a> {{parceriaInfo.parOrgNome}}
                        </div>
                        <div *ngIf="!isPortal && modulo === 'm2'">
                            <div *ngIf="isFiscal">
                                <a ngbTooltip="Voltar" routerLink="/execucao/exibeParceriasM2" class="link">
                                    <i class="fa fa-arrow-circle-left fa-lg" aria-hidden="true"></i> </a> {{parceriaInfo.parOrgNome}}
                            </div>
                            <div *ngIf="!isFiscal">
                                <a ngbTooltip="Voltar" routerLink="/execucao/exibeParceriaEntidadeM2" [queryParams]="{ isAuditorExterno: isAuditorExterno}" class="link">
                                    <i class="fa fa-arrow-circle-left fa-lg" aria-hidden="true"></i> </a> {{parceriaInfo.parOrgNome}}
                            </div>

                        </div>
                        <div *ngIf="!isPortal && modulo === 'mt'">
                            <a *ngIf="true" ngbTooltip="Voltar" routerLink="/trava/exibeParceriaMT" class="link">
                                <i class="fa fa-arrow-circle-left fa-lg" aria-hidden="true"></i> </a> {{parceriaInfo.parOrgNome}}
                        </div>
                        <div *ngIf="isPortal && parceriaInfo != undefined">
                            <a *ngIf="true" ngbTooltip="Voltar" routerLink="/exibeParceriasPortal" class="link">
                                <i class="fa fa-arrow-circle-left fa-lg" aria-hidden="true"></i> </a> {{parceriaInfo.parOrgNome}}
                        </div>
                    </h2>
                </div>
            </dd>
        </dl>
        <dl class="linha-cnpj"><dt class="titulo-cnpj">CNPJ </dt>
            <dd class="conteudo-cnpj"> {{aplicaMascaraCNPJ(parceriaInfo.parOrgCnpj)}}</dd>
        </dl>
        <hr class="class-hr">
        <div class="row">
            <div class="columnBold">Órgão ou Entidade PMPA:</div>
            <div class="column">{{parceriaInfo.nomeOrgao}}</div>
            <div class="columnBold">Objeto/Finalidade:</div>
            <div class="column">{{parceriaInfo.parOrgObjeto}}</div>
        </div>

        <div class="row">
            <div class="columnBold">Endereço:</div>
            <div class="column">{{parceriaInfo.parOrgEndereco}}</div>
            <div class="columnBold">Número:</div>
            <div class="column">{{parceriaInfo.parOrgNro}}</div>
            <div class="columnBold">CEP:</div>
            <div class="column">{{aplicaMascaraCep(parceriaInfo.parOrgCep)}}</div>
            <div class="columnBold">Cidade:</div>
            <div class="column">{{parceriaInfo.parCidade}}</div>

        </div>


        <hr class="class-hr-espaco">
        <div class="row">
            <div class="columnBold">Nº Termo:</div>
            <div class="column">{{parceriaInfo.parNum}} </div>
            <div class="columnBold">Dirigente/Representante Legal: </div>
            <div class="column">{{parceriaInfo.parOrgNomeDirigente}}</div>
        </div>

        <div class="row">
            <div class="columnBold">Nº SEI:</div>
            <div class="column">{{parceriaInfo.parNroSeimask}} </div>
            <div class="columnBold">Telefones do Dirigente/Representante Legal: </div>
            <div class="column">
                <div class="row">
                    {{aplicaMascaraTelefone(parceriaInfo.parOrgTelefone)}}
                    <div class="column" [hidden]="!parceriaInfo.parOrgCelular"> / {{aplicaMascaraTelefone(parceriaInfo.parOrgCelular)}}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="columnBold">Nº de Atendidos:</div>
            <div class="column">{{parceriaInfo.parOrgNmetasAtend}} </div>
            <div class="columnBold">Modalidade: </div>
            <div class="column">{{getModalidadeStr(parceriaInfo.parOrgModalidade)}}</div>
        </div>

        <hr>
        <div class="row">
            <div class="column-conjunto-valores">
                <div class="columnBold column-espaco">Valor total previsto</div>
                <div class="column column-valores" style="font-size: 40px; font-weight:bold">
                    <div class="row">
                        <div style="color:#818485; margin-left: 10px;">R$&ensp;</div>
                        <div>{{aplicaMascaraReais(parceriaInfo.parVlrPrevisto)}}</div>
                    </div>
                </div>
            </div>
            <div class="column-conjunto-valores">
                <div class="columnBold column-espaco">Valores totais concedidos liberados</div>
                <div class="column column-valores" style="font-size: 40px; font-weight:bold; color:#0aaaaf">
                    <div class="row">
                        <div style="color:#818485; margin-left: 10px;">R$&ensp;</div>
                        <div>{{aplicaMascaraReais(parceriaInfo.parOrgVlTotal)}} </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 40px">
            <div class="columnBold">Data Inicial da Vigência</div>
            <div class="column">{{parceriaInfo.parOrgDtIniVigencia | date:'dd/MM/yyyy' }} </div>
            <div class="columnBold">Status da Parceria</div>
            <div class="column">{{getLabelStatusParceira(parceriaInfo.parOrgStatus)}} </div>
        </div>
        <div class="row">
            <div class="columnBold">Data Final da Vigência</div>
            <div class="column">{{parceriaInfo.parOrgDtFimVigencia | date:'dd/MM/yyyy' }}</div>
        </div>
        <br /> <br />
        <div class="row" [hidden]="!parceriaInfo.parObservacao">
            <div class="columnBold">Observação</div>
            <div class="col-md-10">{{parceriaInfo.parObservacao}}</div>
        </div>
        <br>
        <br>
    </div>
</div>