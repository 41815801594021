import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class FiltroDesembolso {
    public execId: number;
    public parId: number;
    public execNomeCredor: string;
    public execNroCpfcnpj: number;
    public execNatureza: number;
    public execTpDoc: number;
    public execNroDoc: string;
    public execValorDoc: number;
    public execOperacao: number;
    public dataPagamento: Date;
    public valorTotalDocumento: number;
    public execDocumentoData: Date;
    public execDocumentoDataFormat: NgbDateStruct;
    public dataPagamentoFormat: NgbDateStruct;
    public dataInicial: string;
    public dataFinal: string; 
    public execConferir: number;
    public orderBy: number;
    public tipoDataFiltro: number;
}