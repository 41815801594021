<div class="container">
    <h1 class="principal">Parametrização</h1>
    <!-- <h4>Filtros de pesquisa</h4> -->
    <!-- <p><em>Você pode filtrar os resultados preenchendo um ou combinando mais de um dos campos abaixo:</em></p> -->
    <div class="geral">
        <h2>&nbsp;</h2>
        <div class="row">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="vig1" class="label2linhas">Termos com início de vigência anterior a</label>
                            <input class="form-control" placeholder="data inicial" maxlength="10" size="10"
                                name="dataIni" #dataIni="ngModel" [(ngModel)]="parametrosAuditoria.dataInicialVigenciaFormat" 
                                appMaskInput [appMaskValue]="parametrosAuditoria.dataInicialVigenciaFormat"
                                ngbDatepicker #dI="ngbDatepicker"/>
                           <i class="fa fa-calendar icon_input_date" (click)="dI.toggle()" title="calendário"></i>
                    </div>

			    </div>
		        <div class="col">
                    <div class="form-group">
                                <label for="vig1" class="label2linhas">Termos com término de vigência após</label>
                               <input class="form-control" placeholder="data final" maxlength="10" size="10"
                                    name="dataFim" #dataFim="ngModel" [(ngModel)]="parametrosAuditoria.dataFinalVigenciaFormat"
                                    appMaskInput  [appMaskValue]="parametrosAuditoria.dataFinalVigenciaFormat" ngbDatepicker #dp="ngbDatepicker"
                                    ngbDatepicker #dF="ngbDatepicker"/>
                                <i class="fa fa-calendar icon_input_date" (click)="dF.toggle()" title="calendário"></i>
                   </div>
                </div>		
                <div class="col">
                    <div class="form-group">
                        <label for="amostragem1" class="label2linhas">Apenas níveis financeiros</label>
                        <input type="radio" name="niveisFinanceiros" value="F" [checked]="parametrosAuditoria.nivelTipo === 'F' ? true:false" (click)="selectNivelTipo('F')"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="amostragem2" class="label2linhas">Apenas níveis operacionais</label>
                        <input type="radio" name="niveisOperacionais" value="O" [checked]="parametrosAuditoria.nivelTipo === 'O' ? true:false" (click)="selectNivelTipo('O')"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="amostragem3" class="label2linhas">Somatório de níveis financeiros e operacionais</label>
                        <input type="radio" name="niveisTotais" value="S" [checked]="parametrosAuditoria.nivelTipo === 'S' ? true:false" (click)="selectNivelTipo('S')"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="inspecao" class="label2linhas">Duração da inspeção (anos)</label>
                        <input type="text" class="form-control" id="inspecao" placeholder="informe em anos"
                        [textMask]="mascaraPercent" name="qtdAnoVisitacao" #qtdAnoVisitacao="ngModel" 
                        [(ngModel)]="parametrosAuditoria.qtdAnoVisitacao" validaInputZero />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="niveis-financeiros">
        <h2>Níveis financeiros</h2>
        <h3>Priorização quantitativa</h3>
        <br>
        <div class="row">
            <div class="col-md-4">
                <h4>Valor total previsto</h4>
                <table class="table table-input">
                    <tr>
                        <th>Valor (R$)</th>
                        <th>Materialidade</th>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="valor1" type="text" placeholder="informe o valor" 
                                currencyMask name="valorTPA" #valorTPA="ngModel" [(ngModel)]="parametrosAuditoria.valorTotalPrevistoAlto" 
                                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero/>
                                <label for="valor1">acima de</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="pontos1" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoValorTPA" #pesoValorTPA="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoValorTotalPrevistoAlto" validaInputZero />
                                <label for="pontos1">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoValorTotalPrevistoAlto === undefined? 'badge': 'badge badge-vermelho'">alto</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="valor2" type="text" placeholder="informe o valor" 
                                 name="valorTPM" #valorTPM="ngModel" [(ngModel)]="parametrosAuditoria.valorTotalPrevistoMedio" 
                                 currencyMask  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero/>
                                <label for="valor2">até</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="pontos2" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoValorTPM" #pesoValorTPM="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoValorTotalPrevistoMedio" validaInputZero/>
                                <label for="pontos2">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoValorTotalPrevistoMedio === undefined? 'badge': 'badge badge-amarelo'">médio</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="valor3" type="text" placeholder="informe o valor" 
                                name="valorTPB" #valorTPB="ngModel" [(ngModel)]="parametrosAuditoria.valorTotalPrevistoBaixo" 
                                currencyMask  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero />
                                <label for="valor3">até</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="pontos3" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoValorTPB" #pesoValorTPB="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoValorTotalPrevistoBaixo" validaInputZero/>
                                <label for="pontos3">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoValorTotalPrevistoBaixo === undefined? 'badge': 'badge badge-verde'">baixo</div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-4">
                <h4>Valor concedido liberado</h4>
                <table class="table table-input">
                    <tr>
                        <th>Valor (R$)</th>
                        <th>Materialidade</th>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="b-valor1" type="text" placeholder="informe o valor" 
                                name="valorCLA" #valorCLA="ngModel" [(ngModel)]="parametrosAuditoria.valorConcedidoLiberadoAlto" 
                                currencyMask  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero />
                                <label for="b-valor1">acima de</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="b-pontos1" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoValorCLA" #pesoValorCLA="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoConcedidoLiberadoAlto" validaInputZero />
                                <label for="b-pontos1">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoConcedidoLiberadoAlto === undefined? 'badge': 'badge badge-vermelho'">alto</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="b-valor2" type="text" placeholder="informe o valor" 
                                name="valorCLM" #valorCLM="ngModel" [(ngModel)]="parametrosAuditoria.valorConcedidoLiberadoMedio" 
                                currencyMask  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero/>
                                <label for="b-valor2">até</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="b-pontos2" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoValorCLM" #pesoValorCLM="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoConcedidoLiberadoMedio" validaInputZero />
                                <label for="b-pontos2">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoConcedidoLiberadoMedio === undefined? 'badge': 'badge badge-amarelo'">médio</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="b-valor3" type="text" placeholder="informe o valor" 
                                name="valorCLB" #valorCLB="ngModel" [(ngModel)]="parametrosAuditoria.valorConcedidoLiberadoBaixo" 
                                currencyMask  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero/>
                                <label for="b-valor3">até</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="b-pontos3" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoValorCLB" #pesoValorCLB="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoConcedidoLiberadoBaixo" validaInputZero />
                                <label for="b-pontos3">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoConcedidoLiberadoBaixo === undefined? 'badge': 'badge badge-verde'">baixo</div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-4">
                <h4>Valor gasto</h4>
                <table class="table table-input">
                    <tr>
                        <th>Valor (R$)</th>
                        <th>Materialidade</th>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="c-valor1" type="text" placeholder="informe o valor" 
                                name="valorGA" #valorGA="ngModel" [(ngModel)]="parametrosAuditoria.valorGastoAlto" 
                                currencyMask  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero/>
                                <label for="c-valor1">acima de</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="c-pontos1" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoGastoAlto" #pesoGastoAlto="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoGastoAlto" validaInputZero />
                                <label for="c-pontos1">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoGastoAlto === undefined? 'badge': 'badge badge-vermelho'">alto</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="c-valor2" type="text" placeholder="informe o valor" 
                                name="valorGM" #valorGM="ngModel" [(ngModel)]="parametrosAuditoria.valorGastoMedio" 
                                currencyMask  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero/>
                                <label for="c-valor2">até</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="c-pontos2" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoGastoMedio" #pesoGastoMedio="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoGastoMedio" validaInputZero />
                                <label for="c-pontos2">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoGastoMedio === undefined? 'badge': 'badge badge-amarelo'">médio</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="c-valor3" type="text" placeholder="informe o valor" 
                                name="valorGB" #valorGB="ngModel" [(ngModel)]="parametrosAuditoria.valorGastoBaixo" 
                                currencyMask  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero/>
                                <label for="c-valor3">até</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="pontos3" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoGastoBaixo" #pesoGastoBaixo="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoGastoBaixo" validaInputZero />
                                <label for="pontos3">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoGastoBaixo === undefined? 'badge': 'badge badge-verde'">baixo</div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <hr>
    <div class="niveis-operaionais">
        <h2>Níveis operaionais</h2>
        <h3>Priorização qualitativa</h3>
        <br>
        <div class="row">
            <div class="col-md-4">
                <h4>Documentos a conferir<i class="fa fa-info-circle help" ngbTooltip="Porcentagem de documentos a conferir em relação ao total de documentos"></i></h4>
                <h5>Em relação ao total</h5>
                <table class="table table-input">
                    <tr>
                        <th>Porcentagem</th>
                        <th>Materialidade</th>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="d-valor1" type="text" placeholder="informe a porcentagem" 
                                [textMask]="mascaraPercent" name="valorDocConfA" #valorDocConfA="ngModel" 
                                [(ngModel)]="parametrosAuditoria.percentualDocsConferirAlto" validaInputZero/>
                                <label for="d-valor1">acima de</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="d-pontos1" type="text"
                                    placeholder="informe a pontuação"
                                    [textMask]="mascaraPercent" name="pesoPercentualDocsConfA" #pesoPercentualDocsConfA="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoPercentualDocsConferirAlto" validaInputZero 
                                    />
                                <label for="d-pontos1">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoPercentualDocsConferirAlto === undefined? 'badge': 'badge badge-vermelho'">alto</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="d-valor2" type="text" placeholder="informe a porcentagem" 
                                [textMask]="mascaraPercent" name="valorDocConfM" #valorDocConfM="ngModel" 
                                [(ngModel)]="parametrosAuditoria.percentualDocsConferirMedio" validaInputZero/>
                                <label for="d-valor2">até</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="d-pontos2" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoPercentualDocsConfM" #pesoPercentualDocsConfM="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoPercentualDocsConferirMedio" validaInputZero />
                                <label for="d-pontos2">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoPercentualDocsConferirMedio === undefined? 'badge': 'badge badge-amarelo'">médio</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="d-valor3" type="text" placeholder="informe a porcentagem" 
                                [textMask]="mascaraPercent" name="valorDocConfB" #valorDocConfB="ngModel" 
                                [(ngModel)]="parametrosAuditoria.percentualDocsConferirBaixo" validaInputZero/>
                                <label for="d-valor3">até</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="d-pontos3" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoPercentualDocsConfB" #pesoPercentualDocsConfB="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoPercentualDocsConferirBaixo" validaInputZero/>
                                <label for="d-pontos3">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoPercentualDocsConferirBaixo === undefined? 'badge': 'badge badge-verde'">baixo</div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-4">
                <h4>Documentos a corrigir<i class="fa fa-info-circle help" ngbTooltip="Porcentagem de documentos a corrigir em relação ao total de documentos analisados"></i></h4>
                <h5>Em relação ao total de analisados</h5>

                <table class="table table-input">
                    <tr>
                        <th>Porcentagem</th>
                        <th>Materialidade</th>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="e-valor1" type="text" placeholder="informe a porcentagem" 
                                [textMask]="mascaraPercent" name="valorDocCorriA" #valorDocCorriA="ngModel" 
                                [(ngModel)]="parametrosAuditoria.percentualDocsCorrigirAlto"/>
                                <label for="e-valor1">acima de</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="e-pontos1" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoPercentualDocsCorrA" #pesoPercentualDocsCorrA="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoPercentualDocsCorrigirAlto" validaInputZero />
                                <label for="e-pontos1">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoPercentualDocsCorrigirAlto === undefined? 'badge': 'badge badge-vermelho'">alto</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="e-valor2" type="text" placeholder="informe a porcentagem" 
                                [textMask]="mascaraPercent" name="valorDocCorriM" #valorDocCorriM="ngModel" 
                                [(ngModel)]="parametrosAuditoria.percentualDocsCorrigirMedio" validaInputZero />
                                <label for="e-valor2">até</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="e-pontos2" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoPercentualDocsCorrM" #pesoPercentualDocsCorrM="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoPercentualDocsCorrigirMedio" validaInputZero/>
                                <label for="e-pontos2">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoPercentualDocsCorrigirMedio === undefined? 'badge': 'badge badge-amarelo'">médio</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="e-valor3" type="text" placeholder="informe a porcentagem" 
                                [textMask]="mascaraPercent" name="valorDocCorriB" #valorDocCorriB="ngModel" 
                                [(ngModel)]="parametrosAuditoria.percentualDocsCorrigirBaixo" validaInputZero/>
                                <label for="e-valor3">até</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="e-pontos3" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoPercentualDocsCorrB" #pesoPercentualDocsCorrB="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoPercentualDocsCorrigirBaixo" validaInputZero />
                                <label for="e-pontos3">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoPercentualDocsCorrigirBaixo === undefined? 'badge': 'badge badge-verde'">baixo</div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-4">
                <h4>Total de documentos<i class="fa fa-info-circle help" ngbTooltip="Total de documentos apresentados na prestação de contas"></i></h4>
                <h5>&nbsp;</h5>
                <table class="table table-input">
                    <tr>
                        <th>Nº de documentos</th>
                        <th>Materialidade</th>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="f-valor1" type="text" placeholder="0" value="-"
                                [textMask]="mascaraNumDOC" name="numTotalDocsAlto" #numTotalDocsAlto="ngModel" 
                                [(ngModel)]="parametrosAuditoria.numTotalDocsAlto" validaInputZero />
                                <label for="f-valor1">sem documentos</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="f-pontos1" type="text"
                                    placeholder="informe a pontuação"
                                    [textMask]="mascaraPercent" name="pesoNumTotalDocsAlto" #pesoNumTotalDocsAlto="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoNumTotalDocsAlto" validaInputZero />
                                <label for="f-pontos1">pontos</label>
                            </div>
                            <div  [ngClass]="parametrosAuditoria.pesoNumTotalDocsAlto === undefined? 'badge': 'badge badge-vermelho'">alto</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="f-valor2" type="text" placeholder="informe a quantidade" 
                                [textMask]="mascaraNumDOC" name="numTotalDocsMedio" #numTotalDocsMedio="ngModel" 
                                [(ngModel)]="parametrosAuditoria.numTotalDocsMedio" validaInputZero />
                                <label for="f-valor2">até</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="f-pontos2" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoNumTotalDocsMedio" #pesoNumTotalDocsMedio="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoNumTotalDocsMedio" validaInputZero/>
                                <label for="f-pontos2">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoNumTotalDocsMedio === undefined? 'badge': 'badge badge-amarelo'">médio</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="f-valor3" type="text" placeholder="informe a quantidade" 
                                [textMask]="mascaraNumDOC" name="numTotalDocsBaixo" #numTotalDocsBaixo="ngModel" 
                                [(ngModel)]="parametrosAuditoria.numTotalDocsBaixo" validaInputZero />
                                <label for="f-valor3">acima de</label>
                            </div>
                        </td>
                        <td>
                            <div class="has-float-label">
                                <input class="form-control" id="f-pontos3" type="text"
                                    placeholder="informe a pontuação" 
                                    [textMask]="mascaraPercent" name="pesoNumTotalDocsBaixo" #pesoNumTotalDocsBaixo="ngModel" 
                                    [(ngModel)]="parametrosAuditoria.pesoNumTotalDocsBaixo" validaInputZero />
                                <label for="f-pontos3">pontos</label>
                            </div>
                            <div [ngClass]="parametrosAuditoria.pesoNumTotalDocsBaixo === undefined? 'badge': 'badge badge-verde'">baixo</div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <hr>
    <div class="cjto-botoes">
        <button class="btn btn-primary" (click)="salva()">Salvar</button>
        <button class="btn btn-secondary" (click)="cancela()">Cancelar</button>
    </div>
    <div [hidden]="!(loading$ | async) ">
        <div class="loading-info ">
            <i class="fa fa-spinner fa-4x fa-spin "></i>
            <div id="carregando ">Aguarde</div>
        </div>
    </div>
</div>