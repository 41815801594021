<div>
   
    <div class="infoGeral container" [hidden]="(dropdownOrgaoList.length==0)">
        <BR>
            <div>
                <label for="">Selecione o Órgão da Administração Pública</label>
                <div class="select-wrapper">
                  <select name="selCnpj" #selCnpj="ngModel" class="form-control custom-select" [ngModel]="orgao" (change)="selectOrgao($event)" required>
                    <option *ngFor="let orgaoNome of dropdownOrgaoList" [value]="orgaoNome">{{orgaoNome}}</option>
                  </select>
                </div>
                  </div>
        <BR>   
            <div  *ngIf="(dropdownOSCList.length>0)">
               <label for="">Selecione a parceria</label>
                <div class="select-wrapper">
                  <select name="selOSC" #selCnpj="ngModel" class="form-control custom-select" [ngModel]="oscs" (change)="selectOSC($event)" required>
                    <option *ngFor="let osc of dropdownOSCList" [value]="osc.id">{{osc.name}}</option>
                  </select>
                </div>
            </div>
        </div>
            <br>
    <div class="container-fluid mb-3" style="min-height: 450px;">
        <div>     
            <div *ngIf="(temPermissao$ && notLoading$) | async">
                <h3 class="mt-5 mb-3">Sem Permissão</h3>
                <p>No momento você não tem acesso integral ao Sistema de Gestão de Parcerias. Solicitamos que entre em contato com o órgão responsável pela parceria ou com a SMTC para cadastramento de acesso.
                </p>
            </div>
            <div *ngIf="(!temPermissaoPMPA$ && !loading$) | async">
                <h3 class="mt-5 mb-3">Sem Permissão</h3>
                <p>No momento você não tem acesso ao Sistema de Gestão de Parcerias. Solicitamos que entre em contato com a SMTC para cadastramento.
                </p>
            </div>
            <div *ngIf="loading$ | async">
                <div class="loading-overlay"></div>
                <div class="loading-info">
                    <i class="fa fa-spinner fa-4x fa-spin"></i>
                    <div id="carregando">Aguarde</div>
                </div>
            </div>
        </div>
    </div>
</div>