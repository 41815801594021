import { Component, OnInit, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { UserService } from '../../@core/user.service';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from '../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Mascaras } from 'src/app/@shared/components/Mascaras';
import { BuscaCampos } from './buscaCampos';
import { DateConverter } from 'src/app/@core/date-converter';
import { AuthUser, KeycloakService } from '@procempa/ngx-keycloak';
import { DatetimeRange } from 'src/app/@shared/components/datepicker/datatime-range';
import { Utils } from 'src/app/@shared/utils';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
//import 'rxjs/add/operator/filter';
import { AuditorLogon } from 'src/app/liberacaoLogon/auditor/auditorLogon';
import { LogonApi } from 'src/app/apis/logonApi';

@Component({
  selector: 'app-exibe-parceria-m1',
  templateUrl: './exibeParceriasM1.component.html',
  styleUrls: ['./exibeParceriasM1.component.scss']
})

export class ExibeParceriasM1Component implements OnInit {

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  selectedParceriaId: number = null;
  parceriaArray: Array<ParceriaInfo>;
  status: number;
  countParcerias: number;
  busca: BuscaCampos = new BuscaCampos(0, null, null, '', '', '', '', true, 0, 0, 25, null);
  currentPage: number;
  dataVigenciaInicial: NgbDateStruct;
  collectionSizeParceria: number;
  @Input()
  isPortal: boolean;
  @Input()
  modulo: string;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  @Output()
  isAuditorExterno: boolean;
  dropdownOrgaoList = [];
  auditorLogonArray: Array<AuditorLogon>;
  orgao: string;
  selectOrgaoList = [];

  constructor(private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    private dateConverter: DateConverter,
    private mascara: Mascaras,
    public keycloakService: KeycloakService,
    public utils: Utils,
    private route: ActivatedRoute,
    private logonApi: LogonApi
  ) {
  }

  ngOnInit() {

    this.modulo = 'm1';
    if (this.isPortal === undefined) {
      this.isPortal = false;
    }
    this.parceriaArray = [];
    //this.getParceriasM1(this.busca, 1);
    this.currentPage = 1;
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();

    //console.log("user: "+this.userService.user.attributes.sgp_orgao[0]);
    this.userService.userInfo.subscribe(user => {
      if ((this.isFiscal || this.isConsulta) && user.attributes.sgp_orgao !== null) {
        // tslint:disable-next-line:no-unused-expression
        if (this.userService.isSMTC()) {
          this.busca.orgao = 0;
          this.isSMTC = true;
          this.busca = new BuscaCampos(0, null, null, '', '', '', '', true, 0, 0, 25, null);
        } else {
          this.busca = new BuscaCampos(Number(user.attributes.sgp_orgao[0]), null, null, '', '', '', '', true, 0, 0, 25, null);
        }
      }
      this.route.queryParams.subscribe(params => {
        this.isAuditorExterno = params['isAuditorExterno'] === undefined || params['isAuditorExterno'].toLowerCase() === 'false' ? false : true;
        this.busca.auditorEmail = this.keycloakService['_keycloakInstance'].tokenParsed.email;
      });
  
      if (this.isFiscal || this.isConsulta || this.isSMTC) {
        this.isAuditorExterno = false;
      }
  
      if (this.isAuditorExterno) {
        this.getParceriasPorAuditorExternoLogon();
      } else {
        this.selectOrgaoList = this.utils.secretariasOptions;
        this.busca.auditorEmail = null;
      }
      this.getParceriasM1(this.busca, 1);
      this.currentPage = 1;
    });
  }

  disableCamposTable() {
    return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }

  aplicaMascaraDocumento(documento) {
    return this.mascara.aplicaMascaraDocumento(documento, 2);
  }

  onPageChange = (pageNumber) => {
    this.getParceriasPagina(pageNumber);
  }

  getParceriasPagina(pagina) {
    this.getParceriasM1(this.busca, pagina);
  }

  getParceriasM1(buscaCampos, pagina) {
    buscaCampos.pagina = pagina;
    buscaCampos.limite = 25;
    this.loading.next(true);
    //console.log(buscaCampos.orgao);
    this.detalheParceriaPortalApi.getParceriaInfoPortal(buscaCampos)
      .subscribe(async (response) => {
        if (response) {
          this.parceriaArray = response as Array<ParceriaInfo>;
          //  console.log(this.parceriaArray);
          this.parceriaArray.forEach(element => {
            element.status = this.getStatusParceira(element.parOrgStatus);
          });
          await this.getCountParceriaPortal();
        } else {
          this.alertService.warning('Nenhum registro encontrado');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  gerarExcel() {
    var buscaCampos = this.busca;
    buscaCampos.limite = 2147483647;
    buscaCampos.pagina = 1;
    this.detalheParceriaApi.getBuscaParceriasPlanilha(buscaCampos).subscribe((response) => {
      if (response) {
        console.log(response);
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(response);
        a.download = `lista_parcerias_termos.xls`;
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        this.alertService.warning('Nenhum resultado foi obtido, por favor refaça a busca com outros critérios.');
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));
  }

  async getCountParceriaPortal() {
    this.loading.next(true);
    //console.log(this.userService.userInfo);
    this.detalheParceriaPortalApi.getCountParceriaPortal(this.busca)
      .subscribe((response) => {
        if (response) {
          this.countParcerias = response as number;
          //console.log("coParceria " + this.countParcerias);
          this.collectionSizeParceria = Math.ceil((this.countParcerias / 25) * 10);
          //console.log("collectionSizeParceria " + this.collectionSizeParceria);
        } else {
          this.alertService.warning('Nenhum registro encontrado');
          this.countParcerias = 0;
          this.collectionSizeParceria = 0;
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  getStatusParceira(cod) {
    return this.utils.getStatusParceira(cod);
  }

  selectStatusParceria(event) {
    const newVal = event.currentTarget.value;
    this.busca.status = newVal;
    this.getParceriasM1(this.busca, 1);
  }

  selectOrgaoParceria(event) {
    const newVal = event.currentTarget.value;
    this.busca.orgao = newVal;
    this.getParceriasM1(this.busca, 1);
  }

  selectNumeroTermo() {
    this.getParceriasM1(this.busca, 1);
  }

  selectNomeParceria() {
    this.getParceriasM1(this.busca, 1);
  }

  selectObjeto() {
    this.getParceriasM1(this.busca, 1);
  }

  selectTermosAtivos() {
    this.getParceriasM1(this.busca, 1);
  }

  novoPeriodo(dataRangeEvent: DatetimeRange) {

    this.busca.dataVigenciaIncial = this.dateConverter.fromNgbDateToDate(dataRangeEvent.fromNgbDate);
    this.busca.dataVigenciaIncialPeriodoFinal = this.dateConverter.fromNgbDateToDate(dataRangeEvent.toNgbDate);
    this.getParceriasM1(this.busca, 1);
  }

  carregaComboOrgao() {
    this.dropdownOrgaoList = [];
    this.auditorLogonArray.forEach((element) => {
      if (!this.dropdownOrgaoList.includes(element.orgaoNome)) {
        this.dropdownOrgaoList.push(element.orgaoNome);
      }
    });
    //console.log(this.dropdownOrgaoList);
    if (this.dropdownOrgaoList.length === 1) {
      this.orgao = this.dropdownOrgaoList[0].toString();
    }

    this.selectOrgaoList = [];
    this.utils.secretariasOptions.forEach(element => {
      this.dropdownOrgaoList.forEach(dropElement => {
        if (element.name.toUpperCase() == dropElement) {
          this.selectOrgaoList.push(element);
        }
      });
    });
  }

  getParceriasPorAuditorExternoLogon() {
    this.loading.next(true);
    //console.log(this.userService.userInfo);
    this.logonApi.getAuditorLogon(this.keycloakService['_keycloakInstance'].tokenParsed.email)
      .subscribe((response) => {
        if (response) {
          this.auditorLogonArray = response as Array<AuditorLogon>;
          //console.log(this.auditorLogonArray);
          this.isAuditorExterno = true;
          this.carregaComboOrgao();
        }
        this.loading.next(false);
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
      });
  }
}
