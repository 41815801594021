export class DirigenteInfo {

public parId: number;
public execOperUltAlt: string;
public execDtUltAlt: Date;
public execResponsavel: string;
public execTelefone: string;
public execEmail: string;

}
