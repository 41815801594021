<div *ngIf="prestaContaParcial.length==0">
  <span class="mensagem-vazio">Nenhum documento complementar foi encontrado.</span>
</div>
<div>
  <div style="margin-top: 15px;">
    <button (click)="openExportacao(contentExportacao, undefined) " id="button-exṕort" class="btn btn-primary btn-info"
      type="button " title="Download" [hidden]="prestaContaParcial.length==0">
      <i class="fa fa-download" style="color: white"><span class="texto-botao">Download</span> </i>
    </button>
    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <button (click)="openFiltroDocCompl(contentFiltroDocCompl, undefined)" id="button-filter" class="btn btn-primary btn-info"
      type="button" title="Filtro">
      <span class="texto-botao">Filtro</span>
    </button>
  </div>
  <table class="table table-striped" style="margin-top:30px" id="tabelaPParcial" [mfData]="prestaContaParcial"
    #mtPrestaParcial="mfDataTable">

    <thead>
      <tr>
        <th *ngIf="!isPortal" data-html2canvas-ignore="true"></th>
        <th *ngIf="!isPortal" data-html2canvas-ignore="true"></th>
        <th *ngIf="!disableApagarIcone()" data-html2canvas-ignore="true"></th>
        <th>Id</th>
        <th>Documento</th>
        <th>Tipo</th>
        <th>Data</th>
        <th>Situação</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of mtPrestaParcial.data | paginate:config let j = index;"
        ngbTooltip="{{data.prestaJustificaExclusao}}"
        [ngClass]="{'strikethrough':data.prestaJustificaExclusao != null}">
        <td *ngIf="!isPortal" data-html2canvas-ignore="true">
          <img src="../../../../assets/img/list-check.svg" alt="Prestação de contas" title="{{data.prestacaoContasTexto}}" *ngIf="data.prestacaoContasId !== null">
        </td>
        <td *ngIf="!isPortal" class="col-auto" data-html2canvas-ignore="true">
          <i ngbTooltip="editar" *ngIf="enableEditarIcone(data)" class="fa fa-pencil" aria-hidden="true"
            (click)="openEdicaoDocComplementar(contentEdicaoDocComplementar, data)"></i>
          <i ngbTooltip="visualizar" *ngIf="visualizarBotao(data)" class="fa fa-search " aria-hidden="true "
            (click)="openEdicaoDocComplementar(contentEdicaoDocComplementar, data)"></i>
        </td>
        <td *ngIf="!disableApagarIcone()" class="col-auto" data-html2canvas-ignore="true">
          <i ngbTooltip="apagar"
            *ngIf="(data.execConferir == 1 || data.execConferir == 6) && data.prestaJustificaExclusao == null"
            class="fa fa-trash-o" aria-hidden="true"
            (click)="openExcluirDocComplementar(contentExcluirDocComplementar, data)"></i>
        </td>
        <td class="col-auto">{{data.prestaId}}</td>
        <td class="col-auto ">
          <div [hidden]="!data.prestaDocumentoArquivo">
            {{data.prestaDocumentoArquivo}}
            <i ngbTooltip="download" *ngIf="data.prestaJustificaExclusao == null" class="fa fa-download"
              aria-hidden="true"
              (click)="getArquivo(data.parId, data.prestaId, 'prestaParcial', data.prestaDocumentoArquivo)"
              alt="download"></i>
          </div>
        </td>
        <td class="col-auto">{{data.prestaClassificaDoc != null && data.prestaClassificaDoc != undefined ? this.utils.getPrestaClassificaDocStr(data.prestaClassificaDoc) : ""}}</td>
        <td class="col-auto">{{data.prestaDocumentoData | date: 'dd/MM/yyyy'}}</td>
        <td class="col-auto"
          [ngClass]="{'vermelho':data.execConferir == 3, 'azul':data.execConferir == 2, 'amareloVelho':data.execConferir == 5, 'laranja':data.execConferir == 4, 'roxo':data.execConferir == 6}">
          {{this.utils.getExecConferirStr(data.execConferir)}}</td>

      </tr>
    </tbody>
    <tfoot data-html2canvas-ignore="true" *ngIf="prestaContaParcial.length > config.itemsPerPage">
      <tr>
        <td colspan="15" class="p-l-0 p-r-0">
          <div class="d-flex justify-content-between">
            <pagination-controls class="pagination pagination" id="custom" (pageChange)="pageChanged($event)"
              directionLinks="true" previousLabel="" nextLabel="">
            </pagination-controls>
            <div *ngIf="prestaContaParcial.length > config.itemsPerPage" class="irPara">
              <label for="paginaL">Ir para</label>
              <input style="width: 100%" id="paginaL" name="paginaL" #paginaL="ngModel" [textMask]="mascaraChaveAcesso"
                class="form-control" type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="pagina" size="3"
                [value]="pagina" (focusout)="consultaPagina($event)" (keyup.enter)="consultaPagina($event)"
                validaInputZero>
              <i class="fa fa-chevron-right"></i>
            </div>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
<br>
<div *ngIf="!disableApagarIcone()">
  <button (click)="openEdicaoDocComplementar(contentEdicaoDocComplementar, undefined) " id="button-insert_doc "
    class="btn btn-primary btn-info" type="button " title="Adicionar novo">
    <i class="fa fa-plus "><span class="texto-botao">Adicionar novo</span> </i>
  </button>
</div>

<ng-template #contentEdicaoDocComplementar let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Documentos Complementares - ID {{docComplementarSend.prestaId}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form (ngSubmit)="salvaDocComplementar()" #edicaoDocComplementar="ngForm" novalidate>
    <div class="modal-body" *ngIf="docComplementarSend">
      <div class="form-group col-md-12">
        <label for="doc">Documento</label>
        <!-- <span [hidden]="documento.valid" class="campoObrigatorio">*</span>  -->
        <div [hidden]="showUpload">
          {{docComplementarSend.prestaDocumentoArquivo}}
          <i *ngIf="!disableCampos(docComplementarSend)" ngbTooltip="download" class="fa fa-times" aria-hidden="true"
            (click)="showUpload = true;docComplementarSend.prestaDocumentoArquivo = undefined" alt="download"></i>
        </div>
        <div [hidden]="!showUpload">
          <input type="file" name="fileInput{{i}}" accept=".pdf" (change)="onFileDocChanged($event, j)" required>
          <!-- <i class="fa fa-times" aria-hidden="true" (click)="resetDevFile(j)" alt="download"></i> -->
          Tamanho máximo: 10 MB
        </div>
        <ngx-extended-pdf-viewer *ngIf="arquivo" [src]="arquivo" useBrowserLocale="true" height="50vh"
          [showZoomButtons]="true"></ngx-extended-pdf-viewer>
      </div>
      <div class="form-group col-md-6">
        <label for="data">Data</label>
        <span [hidden]="data.valid" class="campoObrigatorio">*</span>
        <input [disabled]="disableCampos(docComplementarSend)" style="width:100%" class="form-control"
          placeholder="Selecione a data no calendário" name="data" #data="ngModel"
          [(ngModel)]="docComplementarSend.prestaDocumentoDataFormat" maxlength="10" placeholder="dd/mm/aaaa"
          appMaskInput [appMaskValue]="docComplementarSend.prestaDocumentoDataFormat" ngbDatepicker #d="ngbDatepicker"
          required>
        <i class="fa fa-calendar icon" (click)="d.toggle()" title="calendário"></i>
      </div>
      <div class="form-group col-md-6">
        <label for="classifica">Tipo Documento</label>
        <div class="select-wrapper" style="width: 100%">
          <select [disabled]="disableCampos(docComplementarSend)" style="width: 100%"
            [ngModel]="docComplementarSend.prestaClassificaDoc" name="classifica" #tipoDoc="ngModel"
            class="form-control form-select custom-select" (change)="updateTipoDocumento($event)" required>
            <option [value]="option.id" *ngFor="let option of this.utils.tipoDocumentoOptions">{{option.name}}
          </select>
        </div>
      </div>
      <div class="form-group col-md-6" [hidden]="!isFiscal">
        <label for="situacao">Situação</label>
        <div class="select-wrapper" style="width: 100%">
          <select [disabled]="(!isFiscal || isSMTC)" style="width: 100%" [ngModel]="docComplementarSend.execConferir"
            name="situacao" #tipoDoc="ngModel" class="form-control form-select custom-select"
            (change)="updateSituacao($event)" required>
            <option [value]="option.id" *ngFor="let option of this.utils.statusOptions">{{option.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="execDevolucao">Devolução</label>
        <input
          [disabled]="(docComplementarSend.execConferir != 4 && docComplementarSend.execConferir != 5) || (!isFiscal || isSMTC)"
          style="width: 100%" currencyMask [(ngModel)]="docComplementarSend.prestaDevolucao"
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" name="execDevolucao" #execDevolucao="ngModel"
          class="form-control" />

      </div>
      <div class="form-group col-md-12">
        <label for="motivo">Apontamento</label>
        <textarea [disabled]="isSMTC || !isFiscal" ng-d style="width:100%;" id="motivo" class="form-control"
          #motivo="ngModel" [(ngModel)]="docComplementarSend.prestaMotivo" [ngModelOptions]="{standalone: true}"
          (change)="updateTextArea($event.target.value)">{{docComplementarSend.prestaMotivo}}</textarea>
        <i *ngIf="!isSMTC && isFiscal" class="fa fa-times icon_input_fontawsome " aria-hidden="true "
          (click)="docComplementarSend.prestaMotivo=undefined " title="limpar "></i>

      </div>
    </div>
    <div *ngIf="!visualizarBotao(docComplementarSend) " class="modal-footer col-md-12 ">
      <h6 class="modal-title mr-auto" id="modal-basic-title">Documentos Complementares - ID
        {{docComplementarSend.prestaId}}</h6>

      <button type="button " class="btn btn-default " (click)="c( 'Close click');setDocComplementar(undefined) "
        title="Cancelar ">Cancelar</button>
      <button type="submit " class="btn btn-primary " [disabled]="!edicaoDocComplementar.form.valid "
        title="Salvar ">Salvar</button>
    </div>
  </form>
</ng-template>
<ng-template #contentExcluirDocComplementar let-c="close " let-d="dismiss ">
  <div class="modal-header ">
    <h4 class="modal-title " id="modal-basic-title ">Excluir Documento Complementar</h4>
    <button type="button " class="close " aria-label="Close " (click)="d( 'Cross click') " title="Fechar ">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body ">
    <p>Tem certeza que deseja excluir o documento complementar
      <b>{{docComplementarSend.prestaDocumentoData | date:'dd/MM/yyyy'}} -
        {{docComplementarSend.prestaDocumentoArquivo}}</b>?
    </p>

    <div>
      <label class="form-group col-md-4" for="motivo">Justificativa</label>
      <textarea [disabled]="isSMTC || isFiscal" ng-d style="width:100%;" id="motivo" class="form-control"
        #motivo="ngModel" [(ngModel)]="docComplementarSend.prestaJustificaExclusao"
        [ngModelOptions]="{standalone: true}"
        (change)="updateTextAreaJustificativa($event.target.value)">{{docComplementarSend.prestaJustificaExclusao}}</textarea>
      <i class="fa fa-times icon_input_fontawsome " aria-hidden="true "
        (click)="docComplementarSend.prestaJustificaExclusao=undefined " title="limpar "></i>
    </div>
  </div>
  <div class="modal-footer ">
    <button type="button " class="btn btn-secondary " (click)="c( 'Close click') " title="Fechar ">Fechar</button>
    <button type="button " class="btn btn-secondary " (click)="excluirDocComplementar() "
      title="Excluir ">Excluir</button>
  </div>
</ng-template>

<ng-template #contentExportacao let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Exportação de Documentos Complementares </h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-7">
        <label for="">Selecione o período:</label>
        <div class="form-inline">
          <div class="form-group">
            <input class="form-control" placeholder="data inicial" maxlength="10" size="14" placeholder="dd/mm/aaaa"
              maxlength="10" size="14" name="dataIni" [(ngModel)]="dataIni" appMaskInput [appMaskValue]="dataIni"
              ngbDatepicker #dI="ngbDatepicker">
            <i class="fa fa-calendar icon_input_date" (click)="dI.toggle()" title="calendário"></i>
          </div>
          <div style="width:40px;">
            até
          </div>
          <div class="form-group">
            <input class="form-control" placeholder="dd/mm/aaaa" maxlength="10" size="14" name="dataFim" appMaskInput
              [appMaskValue]="dataFim" [(ngModel)]="dataFim" ngbDatepicker #dF="ngbDatepicker">
            <i class="fa fa-calendar icon_input_date" (click)="dF.toggle()" title="calendário"></i>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-10">
        <label for="data">Selecione o tipo de arquivo:&ensp;</label>
        <br>
        <br>
        <div class="row">
          <div class="col">
            <a class="link" (click)="generatePDF(dataIni, dataFim)" title="Exportar como PDF">&ensp;<i
                class="fa fa-file-pdf-o fa-lg"></i> Baixar arquivo PDF</a>
          </div>
          <div class="col">
            <a class="link" style="margin-left: 10px" (click)="exportAs('xlsx', dataIni, dataFim)"
              title="Exportar como Excel"><i class="fa fa-file-excel-o fa-lg"></i> Baixar arquivo Excel</a>
          </div>
          <div class="col">
            <a class="link" style="margin-left: 9px; padding: 0%" (click)="exportAs('csv', dataIni, dataFim)"
              title="Exportar como CSV"><img src="assets/img/file-csv-solid.svg"> Baixar arquivo csv</a>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
  </div>
</ng-template>
<ng-template #contentFiltroDocCompl let-c="close " let-d="dismiss ">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Filtrar Documentos Complementares por</h4>
    <button type="button" class="close" aria-label="Close" (click)="ca" title="fechar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-4">
        <label for="prestaIdFiltro">Id</label>
        <input style="width: 100%" id="prestaId" name="prestaId" #prestaId="ngModel" class="form-control" type="text"
          [ngModelOptions]="{standalone: true}" [(ngModel)]="filtro.prestaId" [value]="filtro.prestaId">
        <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="filtro.prestaId=undefined;"
          title="limpar"></i>
      </div>
      <div class="form-group col-md-4">
        <label for="tipoFiltro">Tipo do Documento</label>
        <div class="select-wrapper" style="width: 100%">
          <ng-select [items]="this.utils.tipoDocumentoOptions" class="form-control" bindLabel="name"
            [(ngModel)]="filtro.tipo" bindValue="id" notFoundText=" não encontrado"
            (change)="updateTipoFiltroDocCompl($event)">
          </ng-select>
        </div>
      </div>
      <div class="form-group col-md-4">
        <label for="filtroSituacao">Situação</label>
        <div class="select-wrapper" style="width: 100%">
          <ng-select [items]="this.utils.statusOptions" class="form-control" bindLabel="name"
            [(ngModel)]="filtro.situacao" bindValue="id" notFoundText=" não encontrado"
            (change)="updateSituacaoFiltroDocCompl($event)">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="modal-footer" style="width: 100%;">
      <button type="button" class="btn btn-primary" (click)="aplicaFiltroDocCompl()" title="Aplicar">Aplicar</button>
    </div>
  </div>
</ng-template>