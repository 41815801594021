import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class ParceriaPrestaContaFinal {

    public parId: number;
    public prestaFinalId: number;
    public prestaFinalDocumentoArquivo: string;
    public prestaFinalDocArqOriginal: string;
    public prestaFinalData: Date;
    public prestaFinalDocumentoTipo: string;
    public prestaFinalDocumentoPublicar: number;
    public prestaFinalDocumento: Blob;
    public prestaFinalDocumentoDataFormat: NgbDateStruct;
}