import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Licitacon } from "src/app/modulo1/Licitacon";

export class ParceriaInfo extends Licitacon{

      public parId: number;
      public parNum: string;
      public zgororgaoCdorgao2: number;
      public parOrgCnpj: string;
      public parOrgNome: string;
      public parOrgEndereco: string;
      public parOrgCep: number;
      public parOrgNmetasAtend: number;
      public parOrgObjeto: string;
      public parOrgDtIniVigencia: Date;
      public parOrgDtFimVigencia: Date;
      public parOrgVlTotal: number;
      public parOrgNomeDirigente: string;
      public parOrgCpfDirigente: string;
      public parOrgTelefone: string;
      public parOrgTelefoneOSC: string;
      public parOrgStatus: number;
      public parOrgPortal: number;
      public parOrgModalidade: number;
      public parOrgNro: number;
      public parOrgComplemento: string;
      public parOrgBairro: string;
      public parOrgUF: string;
      public parCidade: string;
      public codlogr: number;
      public parOrgCelular: string;
      public parOperUltAlt: string;
      public parDtUltAtu: Date;
      public execOperUltAlt: string;
      public execDtUltAlt: Date;
      public execQtdDoc: number;
      public execTotalValorDoc: number;
      public execResponsavel: string;
      public execTelefone: string;
      public execEmail: string;
      public execTotIngressos: number;
      public execDevolRecursos: number;
      public execSaldoFinal: number;
      public execQtdDevol: number;
      public execPortalPublicar: number;
      public parVlrPrevisto: number;
      public parNroSei: string;
      public parNroSeimask: string;
      public execPortDevolucaoPublicar: number;
      public execDevolAplic: number;
      public parObservacao: string;
      public execTotVlrConsumo: number;
      public execTotQtdConsumo: number;
      public execPortalConsumo: number;
      public execTotVlrPF: number;
      public execTotQtdPF: number;
      public execPortalPF: number;
      public execTotVlrPJ: number;
      public execTotQtdPJ: number;
      public execPortalPJ: number;
      public execTotVlrPerm: number;
      public execTotQtdPerm: number;
      public execPortalPerm: number;
      public execTotVlrOutros: number;
      public execTotVlrOutrasDP: number;
      public execTotQtdOutros: number;
      public execTotQtdOutrasDP: number;
      public execPortalOutros: number;
      public execPortalOutrasDP: number;
      public totalDesembolsos: number;
      public quantidadeTotal: number;
      public nomeOrgao: string;
      public status: string;
      public planejSel: boolean;
      public execucao: boolean;
      public monitAval: boolean;
      public prestContasPar: boolean;
      public prestContasTot: boolean;
      public parOrgDtIniVigenciaFormat: NgbDateStruct;
      public parOrgDtFimVigenciaFormat: NgbDateStruct;
      public execDevolParceria: number;
      public execDevolPoupanca: number;
      public execDevolOutrasContas: number;
      public parEmailRespAnaliseSE: number;
      public parExcecaoTravaSerial: number;
      public parExcecaoTravaAnaliseSerial: number;
      public parOrgVlrMensalRepasse: number;
      public parOrgEmailDirigente: string;
      public parOrgEmail: string;
      public parOrgEmail2: string;
      public parOrgDirigenteEndereco: string;
      public parOrgDirigenteBairro: string;
      public parOrgDirigenteCidade: string;
      public parOrgDirigenteEndNro: number;
      public parOrgDirigenteCompl: string;
      public parOrgDirigenteCep: number;
      public parClassificacao: number;
      public parDataLimitePrestacao: Date;
      public parDataLimitePrestacaoFormat: NgbDateStruct;
      public parDataLimiteAnalise: Date;
      public parDataLimiteAnaliseFormat: NgbDateStruct;
      public idOrgaoCopiaTermo: number;
      public parNumCopiaTermo: string;
  }
