export class BuscaCampos {
    constructor(
        public orgao: number,
        public dataVigenciaIncial: Date,
        public dataVigenciaIncialPeriodoFinal: Date,
        public numeroTermo: string,
        public objetoFin: string,
        public nomeOrg: string,
        public cnpj: string,
        public termosAtivos: boolean,
        public status: number,
        public pagina: number,
        public limite: number,
        public auditorEmail:string,
        public parClassificacao?: number
        ) { }
}
