import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from '../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../../apis/detalheParceriaApi';
import { UserService } from '../../@core/user.service';
import { ParceriaMonitoraAvalia } from '../monitoramentoAvalicao/parceriaMonitoraAvalia';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from 'src/app/@core/date-converter';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { Utils } from 'src/app/@shared/utils';


@Component({
  selector: 'app-monitoramento-avalia-parceria',
  templateUrl: './../monitoramentoAvalicao/monitoramento.component.html',
  styleUrls: ['./../../modulo2/detalheParceria/detalheParceria.component.scss']
})
export class MonitoramentoComponent implements OnInit {

  @Input()
  public  parceriaInfo: ParceriaInfo;
  @Input()
  public  isPortal: boolean;
  @Output() valueChange = new EventEmitter();
  parceriaMonitoraAvalia: Array<ParceriaMonitoraAvalia> = [];
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  parceriaMonitoraAvaliaSend: ParceriaMonitoraAvalia;
  modalEdicaoReference: any;
  showUpload: boolean = false;

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private parceriaDespesaDevolApi: PublicoPrivadaApi,
    public userService: UserService,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private utils: Utils) {

    }

  ngOnInit() {
    this.setupInterface();
  }

  setupInterface() {
 
    this.loading.next(true);

    this.route.paramMap.pipe(
        switchMap(() =>
         this.parceriaDespesaDevolApi.getParceriaMonitoraAvalia(this.parceriaInfo.parId, this.isPortal))
         ).subscribe((response) => {
          if (response) {
            this.parceriaMonitoraAvalia = response as Array<ParceriaMonitoraAvalia>;
            // console.log(this.valoresLiberados);
          } else {
            this.alertService.warning('Nenhum registro encontrado');
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));

  }
  
  getArquivo(parId, id, tipo, nome) {
    this.loading.next(true);
    this.parceriaDespesaDevolApi.getArquivo(parId, id, tipo, this.isPortal)
      .subscribe((response) => {
        if (response) {
          console.log(response);
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(response.body);
          a.href = url;
          a.download = nome;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          this.alertService.warning('Nenhum arquivo encontrado');
        }
      }, (response) => {
        // if (response.status >= 500) {
        //   this.alertService.danger(response.error);
        // } else {
        //   this.alertService.warning(response.error);
        // }
        this.alertService.danger('Erro ao buscar arquivo.');
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  updatePublica(event) {
    const newVal = event.currentTarget.value;
    this.parceriaMonitoraAvaliaSend.monitoraDocumentoPublicar = newVal;
  }

  setMonitoraAvalia(doc) {
    if (doc) {
      this.parceriaMonitoraAvaliaSend = doc;
      this.showUpload = this.parceriaMonitoraAvaliaSend.monitoraDocumentoArquivo ? false : true;
      this.parceriaMonitoraAvaliaSend.monitoraDocumentoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.parceriaMonitoraAvaliaSend.monitoraDocumentoData));
    } else {
      this.parceriaMonitoraAvaliaSend = new ParceriaMonitoraAvalia();
      this.showUpload = true;
      this.parceriaMonitoraAvaliaSend.parId = this.parceriaInfo.parId;
      this.parceriaMonitoraAvaliaSend.monitoraId = -1;
      this.parceriaMonitoraAvaliaSend.monitoraDocumentoTipo = '';
    }

  }

  salvaMonitoraAvalia() {

    if (this.parceriaMonitoraAvaliaSend.monitoraDocumento === null || this.parceriaMonitoraAvaliaSend.monitoraDocumento === undefined) {
      this.alertService.danger('Dado obrigatório, realize o upload do arquivo.');
      return;
    }
    // tslint:disable-next-line:max-line-length
    if ( (this.parceriaMonitoraAvaliaSend.monitoraDocumentoArquivo != null && this.parceriaMonitoraAvaliaSend.monitoraDocumentoArquivo.length > 50)) {
      this.alertService.danger('Nome do arquivo superior a 50 caracteres, por favor renomeie antes de fazer upload.');
      return;
    }
    // tslint:disable-next-line:max-line-length
    this.parceriaMonitoraAvaliaSend.monitoraDocumentoData = this.dateConverter.fromNgbDateStructToDate(this.parceriaMonitoraAvaliaSend.monitoraDocumentoDataFormat);
    if (!this.utils.isValidDate(this.parceriaMonitoraAvaliaSend.monitoraDocumentoData)){
      this.alertService.danger('Data Inválida.');
      return;
    } 
    let data = JSON.stringify(this.parceriaMonitoraAvaliaSend, this.replacerDocComplementar);
    this.detalheParceriaApi.updateMonitoracaoAvaliacao(data).subscribe(async (response) => {
      if (response) {
        this.setupInterface();
        this.setMonitoraAvalia(undefined);
        this.modalEdicaoReference.close();
        //await this.getParceriaInfo(this.parceriaMonitoraAvaliaSend.parId);
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }

  excluirMonitoraAvalia() {
    this.detalheParceriaApi.excluirMonitoracaoAvaliacao(this.parceriaMonitoraAvaliaSend).subscribe((response) => {
      if (response) {
        this.setupInterface();
        this.setMonitoraAvalia(undefined);
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }

  replacerDocComplementar(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['monitoraDocumentoDataFormat'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  openEdicao(content, doc) {
    this.setMonitoraAvalia(doc);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false, windowClass: "modalProcesso" });
  }

  openExcluir(content, doc) {
    this.setMonitoraAvalia(doc);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop : 'static', keyboard : false });
  }

  onFileDocChanged(event) {
    const fileList: FileList = event.currentTarget.files;
    console.log(fileList);
    const reader = new FileReader();

    if (fileList.length > 0) {
      const array = fileList[0].type.split('/');
      const fileSize = fileList[0].size / 1024 / 1024; // in MB
      if (fileSize > 1) {
        this.alertService.danger('Tamanho de arquivo superior ao limite de 1MB. Esse arquivo possui ' + fileSize.toPrecision(1)+"MB");
        return;
      }
      if (array[1] === 'jpeg' || array[1] === 'jpg' || array[1] === 'gif'
        || array[1] === 'png' || array[1] === 'pdf') {

        reader.readAsDataURL(fileList[0]);
        reader.onload = () => {
          this.parceriaMonitoraAvaliaSend.monitoraDocumento = ((reader.result).toString().split(',')[1]) as unknown as Blob;
          this.parceriaMonitoraAvaliaSend.monitoraDocumentoTipo = array[1];
          this.parceriaMonitoraAvaliaSend.monitoraDocumentoArquivo = fileList[0].name;

        };
      } else {
        event.currentTarget.value = '';
        this.alertService.warning('Formato de arquivo não suportado!');
      }
    }
  }

}

