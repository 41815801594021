import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DateConverter {

  constructor(private ngbDateParserFormatter: NgbDateParserFormatter) { }

  fromNgbDateStructToDate(ngbDateStruct: NgbDateStruct): Date {
    const date = new Date();
    date.setFullYear(ngbDateStruct.year, ngbDateStruct.month - 1, ngbDateStruct.day);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  fromNgbDateToDate(ngbDate: NgbDate): Date {
    const date = new Date();
    date.setFullYear(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    date.setHours(0, 0, 0, 0);
    return date;
  }

  fromDateToNgbDate(date: Date): NgbDate {
    const ngbDate = new NgbDate(date.getFullYear(), date.getMonth(), date.getDay());
    return ngbDate;
  }

  fromDateToNgbDateStruct(date: Date): NgbDateStruct {
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  fromDateToTimestamp(date: Date): number {
    return Math.round(date.valueOf());
  }

  fromNgbDateStructToTimestamp(ngbDateStruct: NgbDateStruct): number {
    return this.fromDateToTimestamp(this.fromNgbDateStructToDate(ngbDateStruct));
  }

  fromNgbDateStructToCompetencia(ngbDateStruct: NgbDateStruct): string {
    const model = this.ngbDateParserFormatter.format(ngbDateStruct).split('-').reverse().join('/').split('');
    //model.shift();
    return model.join('');
  }

  fromNgbDateStructToString(ngbDateStruct: NgbDateStruct): string {
    return this.ngbDateParserFormatter.format(ngbDateStruct).split('-').reverse().join('/');
  }

  fromStringToNgbDateStruct(date: string): NgbDateStruct {
    return this.ngbDateParserFormatter.parse(date.split('/').reverse().join('-'));
  }

  fromTimestampToNgbDateStruct(timestamp: number): NgbDateStruct {
    if (typeof timestamp === 'number') {
      const date: Date = new Date(timestamp);
      return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    }
  }
}
