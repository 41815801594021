import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from "@angular/core";
//import { E } from "@angular/core/src/render3";
import { ActivatedRoute } from "@angular/router";
import { AuthUser, KeycloakService } from "@procempa/ngx-keycloak";
import html2canvas from "html2canvas";
import { List } from "lodash";
import { BehaviorSubject } from "rxjs";
import { share, switchMap } from "rxjs/operators";
import { AlertService } from "src/app/@core/alert.service";
import { DateConverter } from "src/app/@core/date-converter";
import { UserService } from "src/app/@core/user.service";
import { DatetimeRange } from "src/app/@shared/components/datepicker/datatime-range";
import { Mascaras } from "src/app/@shared/components/Mascaras";
import { Utils } from "src/app/@shared/utils";
import { DetalheParceriaApi } from "src/app/apis/detalheParceriaApi";
import { DetalheParceriaPortalApi } from "src/app/apis/detalheParceriaPortalApi";
import { LogonApi } from "src/app/apis/logonApi";
import { BuscaCampos } from "src/app/modulo1/exibeParcerias/buscaCampos";
import { DocumentosStatusTotais } from "./documentosStatusTotais";


@Component({
  selector: 'app-auditoria-graficos',
  templateUrl: './graficos.component.html',
  styleUrls: ['./graficos.component.scss']
})
export class GraficosAuditoriaComponent implements OnInit {
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  busca: BuscaCampos = new BuscaCampos(0, null, null, '', '', '', '', true, 0, 0, 0, null, 0);

  docsTotais: Array<DocumentosStatusTotais>;
  orgaoNome: string;
  parceriaNome: string;
  parceriaCnpj: string;
  parceriaTermo: string;
  parceriaPeriodo: string;
  parceriaStatus: string;
  pieChartLabels1 = ['Não analisados', 'Analisados'];
  pieChartLabels2 = ['Conferido', 'A corrigir', 'Não confere', 'Confere com ressalva', 'A excluir'];
  pieChartData: Array<Array<any>> = [[120, 0, 3]];
  pieChartType = 'pie';
  chartOptions = { responsive: true, aspectRatio: 1 };
  pieChartColors1: Array<any> = [{
    backgroundColor: ['#CC0000', '#00CC00'],
    hoverBackgroundColor: ['#CC0000', '#00CC00']
    //borderColor: ['rgba(135,206,250,1)', 'rgba(106,90,205,1)', 'rgba(148,159,177,1)']
  }];
  pieChartColors2: Array<any> = [{
    backgroundColor: ['#70AD47', '#7030A0', '#C00000', '#FFC000', '#BFBFBF'],
    hoverBackgroundColor: ['#70AD47', '#7030A0', '#C00000', '#FFC000', '#BFBFBF']
    //borderColor: ['rgba(135,206,250,1)', 'rgba(106,90,205,1)', 'rgba(148,159,177,1)']
  }];
  dropdownNomeParceriasList = [];
  dropdownCNPJList = [];
  dropdownTermoList = [];
  cnpj: number;
  nomeOrg: number;
  numeroTermo: number;
  disablePar: boolean;
  disableCnpj: boolean;
  disableTermo: boolean;
  parClassificacao: string;

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    public keycloakService: KeycloakService,
    private mascara: Mascaras,
    private logonApi: LogonApi,
    private dateConverter: DateConverter,
    public utils: Utils) {

  }

  async ngOnInit() {
    this.parceriaNome = "";
    this.busca = new BuscaCampos(0, null, null, '', '', '', '', false, 0, 0, 25, null, 0);
    this.orgaoNome = this.utils.getSecretariaStr("0");
    this.disablePar = true;
    this.disableCnpj = true;
    this.disableTermo = true;
    this.pieChartData = new Array(this.utils.secretariasOptions.length);
    this.docsTotais = new Array(this.utils.secretariasOptions.length);
    this.setupInterface();
  }

  setupInterface() {
    this.getBuscaDocumentosStatusTotais(this.busca);
    this.getNomeParcerias(0);
  }

  async getNomeParcerias(codOrgao) {
    this.loading.next(true);
    this.detalheParceriaPortalApi.getNomeParcerias(codOrgao)
      .subscribe(async (response) => {
        if (response) {
          this.dropdownNomeParceriasList = [];
          let idx = 0;
          response.forEach(element => {
            this.dropdownNomeParceriasList.push({ id: idx, name: element });
            idx++;
          });

        } else {
          this.alertService.warning('Nenhum nome encontrado');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => {this.docsTotais.length > 0 && this.docsTotais[0]? this.loading.next(false): null});
  }

  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  async getCNPJParcerias(nome, codOrgao) {
    this.loading.next(true);
    this.detalheParceriaPortalApi.getCNPJParcerias(nome, codOrgao)
      .subscribe(async (response) => {
        if (response) {
          this.dropdownCNPJList = [];
          let idx = 0;
          response.forEach(element => {
            this.dropdownCNPJList.push({ id: idx, name: this.aplicaMascaraCNPJ(element) });
            idx++;
          });

        } else {
          this.alertService.warning('Nenhum nome encontrado');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  async getTermoParcerias(cnpj, nome, codOrgao) {
    this.loading.next(true);
    this.detalheParceriaPortalApi.getTermoParcerias(cnpj, nome, codOrgao)
      .subscribe(async (response) => {
        if (response) {
          this.dropdownTermoList = [];
          let idx = 0;
          response.forEach(element => {
            this.dropdownTermoList.push({ id: idx, name: element });
            idx++;
          });
        } else {
          this.alertService.warning('Nenhum nome encontrado');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  async getBuscaDocumentosStatusTotais(buscaCampos) {
    this.loading.next(true);
    this.detalheParceriaApi.getBuscaDocumentosStatusTotais(buscaCampos)
      .subscribe(async (response) => {
        if (response) {
          this.docsTotais = response as Array<DocumentosStatusTotais>;
          this.docsTotais.forEach((el, i) => {
            el.totalAnalisado = el.totalConferido + el.totalACorrigir + el.totalNaoConfere + el.totalComRessalva + el.totalAExcluir;
            this.pieChartData[i] = [
              [Math.round(((el.totalAConferir * 100) / el.total) * 100) / 100,
              Math.round(((el.totalAnalisado * 100) / el.total) * 100) / 100,
              ],
              [
                Math.round(((el.totalConferido * 100) / el.totalAnalisado) * 100) / 100,
                Math.round(((el.totalACorrigir * 100) / el.totalAnalisado) * 100) / 100,
                Math.round(((el.totalNaoConfere * 100) / el.totalAnalisado) * 100) / 100,
                Math.round(((el.totalComRessalva * 100) / el.totalAnalisado) * 100) / 100,
                Math.round(((el.totalAExcluir * 100) / el.totalAnalisado) * 100) / 100]
            ]
          });

        } else {
          this.alertService.warning('Nenhum documento encontrado');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  selectOrgaoParceria(event) {
    const newVal = event.currentTarget.value;
    this.busca.orgao = newVal;
    this.orgaoNome = this.utils.getSecretariaStr(newVal);
    this.dropdownCNPJList = [];
    this.dropdownTermoList = [];
    this.dropdownNomeParceriasList = [];
    this.disableCnpj = true;
    this.disableTermo = true;
    this.busca.cnpj = "";
    this.parceriaCnpj = "";
    this.cnpj = -1;
    this.busca.nomeOrg = "";
    this.nomeOrg = -1;
    this.busca.numeroTermo = "";
    this.numeroTermo = -1;
    this.parceriaNome = "";
    this.parceriaTermo = "";
    this.getBuscaDocumentosStatusTotais(this.busca);
    if (newVal == '0') {
      this.pieChartData = new Array(this.utils.secretariasOptions.length);
      this.docsTotais = new Array(this.utils.secretariasOptions.length);
      this.disablePar = true;
    }
    else {
      this.pieChartData = new Array(1);
      this.docsTotais = new Array(1);
      this.disablePar = false;
      this.getNomeParcerias(newVal);
    }
  }

  async selectParClassificacao(event) {
    const parClassificacao = event.currentTarget.value;
    this.busca.parClassificacao = parClassificacao;
    if (parClassificacao != 0) {
      this.parClassificacao = "Classificação: " + this.utils.parClassificacaoOption[parClassificacao].name;
    } else {
      this.parClassificacao = "";
    }
    this.getBuscaDocumentosStatusTotais(this.busca);
  }



  async selectNumeroTermo() {
    if (this.isBlank(this.busca.nomeOrg))
      return this.alertService.warning('Preencha um nome válido.');
    if (this.isBlank(this.busca.cnpj))
      return this.alertService.warning('Preencha um cnpj válido.');
    this.busca.numeroTermo = this.dropdownTermoList[this.numeroTermo].name;
    this.parceriaTermo = "Termo: " + this.dropdownTermoList[this.numeroTermo].name;
    // this.getParcerias(this.busca.numeroTermo, this.busca.cnpj, 
    //  this.busca.nomeOrg, this.busca.orgao);
    this.getBuscaDocumentosStatusTotais(this.busca);
  }

  selectNomeParceria() {
    this.busca.nomeOrg = this.dropdownNomeParceriasList[this.nomeOrg].name;
    this.busca.numeroTermo = "";
    this.busca.cnpj = "";
    this.parceriaCnpj = "";
    this.parceriaTermo = ""
    this.getBuscaDocumentosStatusTotais(this.busca);
    this.dropdownTermoList = [];
    this.cnpj = -1;
    this.numeroTermo = -1;
    this.disableTermo = true;
    if (this.isBlank(this.busca.nomeOrg)) {
      this.disableCnpj = true;
      return this.alertService.warning('Preencha um nome válido.');
    } else {
      this.disableCnpj = false;
      this.parceriaNome = this.busca.nomeOrg;
      this.getCNPJParcerias(this.busca.nomeOrg, this.busca.orgao);
    }
  }

  limpaNomeParceria() {
    this.busca.nomeOrg = "";
    this.busca.cnpj = "";
    this.busca.numeroTermo = "";
    this.disableCnpj = true;
    this.parceriaNome = "";
    this.parceriaCnpj = "";
    this.parceriaTermo = "";
    this.dropdownTermoList = [];
    this.dropdownCNPJList = [];
    this.cnpj = -1;
    this.numeroTermo = -1;
    this.disableTermo = true;
    this.getBuscaDocumentosStatusTotais(this.busca);
  }

  selectCNPJ() {
    this.busca.cnpj = this.dropdownCNPJList[this.cnpj].name;
    this.parceriaCnpj = "CNPJ: " + this.dropdownCNPJList[this.cnpj].name;
    this.busca.numeroTermo = "";
    this.parceriaTermo = "";
    this.numeroTermo = -1;
    this.getBuscaDocumentosStatusTotais(this.busca);
    if (this.isBlank(this.busca.cnpj)) {
      this.disableTermo = true;
      return this.alertService.warning('Preencha um termo válido.');
    }
    else {
      this.disableTermo = false;
      this.getTermoParcerias(this.busca.cnpj, this.busca.nomeOrg, this.busca.orgao);
    }
  }

  limpaCNPJ() {
    this.busca.cnpj = "";
    this.parceriaCnpj = "";
    this.busca.numeroTermo = "";
    this.parceriaTermo = "";
    this.dropdownTermoList = [];
    this.numeroTermo = -1;
    this.disableTermo = true;
    this.getBuscaDocumentosStatusTotais(this.busca);
  }

  limpaTermo() {
    this.busca.numeroTermo = "";
    this.parceriaTermo = "";
    this.getBuscaDocumentosStatusTotais(this.busca);
  }
  aplicaMascaraReais(valor) {
    if (valor != undefined || valor != null) {
      return this.mascara.aplicaMascaraReais(valor);
    }
    else return '0,00';
  }
  aplicaMascaraUnidade(valor) {
    return this.mascara.aplicaMascaraUnidade2(valor);
  }

  getNomeParceria(cnpj) {
    this.logonApi.getNomeParceira(cnpj.replace(/[^\d]+/g, ''))
      .subscribe((response) => {
        if (response) {
          this.parceriaNome = response as string;
        } else {
          this.alertService.warning('Nenhuma Parceira Encontrada');
          this.parceriaNome = "";
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
    this.loading.next(false);
  }

  downloadImage() {
    let docElem = document.getElementById("download");
    html2canvas(docElem).then(canvas => {
      let generatedImage = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      let a = document.createElement('a');
      a.href = generatedImage;
      a.download = `grafico_` + this.orgaoNome + `.png`;
      a.click();
    });
  }

  novoPeriodo(dataRangeEvent: DatetimeRange) {

    this.busca.dataVigenciaIncial = this.dateConverter.fromNgbDateToDate(dataRangeEvent.fromNgbDate);
    this.busca.dataVigenciaIncialPeriodoFinal = this.dateConverter.fromNgbDateToDate(dataRangeEvent.toNgbDate);
    this.parceriaPeriodo = "Período de emissão dos documentos: de " + this.busca.dataVigenciaIncial.toLocaleDateString() + " a " + this.busca.dataVigenciaIncialPeriodoFinal.toLocaleDateString();
    this.getBuscaDocumentosStatusTotais(this.busca);
  }

  getStatusParceira(cod) {
    return this.utils.getStatusParceira(Number(cod));
  }

  selectStatusParceria(event) {
    const newVal = event.currentTarget.value;
    this.busca.status = newVal;
    if (newVal != 0) {
      this.parceriaStatus = "Status da parceria: " + this.getStatusParceira(newVal);
    } else {
      this.parceriaStatus = "";
    }
    this.getBuscaDocumentosStatusTotais(this.busca);
  }

  aplicaMascaraCNPJ(doc) {
    return this.mascara.aplicaMascaraDocumento(doc, 2);
  }
  limpar() {
    this.dropdownCNPJList = [];
    this.dropdownTermoList = [];
    this.dropdownNomeParceriasList = [];
    this.disableCnpj = true;
    this.disableTermo = true;
    this.busca.cnpj = "";
    this.cnpj = -1;
    this.busca.nomeOrg = "";
    this.nomeOrg = -1;
    this.busca.numeroTermo = "";
    this.numeroTermo = -1;
    this.parceriaNome = "";
    this.parceriaCnpj = "";
    this.parceriaTermo = "";
    this.busca.orgao = 0;
    this.busca.status = 0;
    this.busca.parClassificacao = 0;
    this.busca.dataVigenciaIncial = null;
    this.busca.dataVigenciaIncialPeriodoFinal = null;
    this.parceriaPeriodo = "";
    this.parceriaStatus = "";
    this.parClassificacao = "";
    this.getBuscaDocumentosStatusTotais(this.busca);
  }
}

