export class BuscaRelatorioNotificacaoCampos {
    constructor(
        public tipoRelatorio: number,
        public orgao: number,
        public cnpjOscs: Array<String>,
        public parIds: Array<number>,
        public notificacaoIds: Array<number>,
        public situacoes: Array<number>,
        public modalidades: Array<number>,
        public usuarios: Array<String>,
        public tipoArquivo: String,
        public dataInicio: Date,
        public dataFim: Date,
        public auditorEmail:string
    ) {}
}