import { AtividadeCnaeIsqs } from "./atividadeCnaeIsq";
import { Enderecos } from "./logrfora";

export class PessoaInfo {

    public idIdentPessoa: string;
    public numDocumento: string;
    public nome: string;
    public nomePessoa: string;
    public nomeFantasia: string;
    public situacao: String;
    public idCodTipoPes: number;
    public nomeLogradouro: string;
    public numeroEndereco: number;
    public cep: string;
    public apSlCasa: number;
    public compl: string;
    public enderecos: Array<Enderecos>;
    public atividadeCnaeIsqs : Array<AtividadeCnaeIsqs>;
    public numeroRg:string;
}
