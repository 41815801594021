import { Directive, Input } from '@angular/core'
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms'
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap'
import { NgbDateBRParserFormatter } from '../datepicker/ ngbdate-br-parser-formatter.service'

@Directive({
  selector: '[app-valid-date]',
  providers: [ { provide: NG_VALIDATORS, useExisting: DateValidatorDirective, multi: true },
    { provide: NgbDateParserFormatter, useClass: NgbDateBRParserFormatter} ]
})
export class DateValidatorDirective implements Validator {
  @Input('appMaxDate') maxDate: NgbDate | null = NgbDate.from( { year: 2030, month: 1, day: 1} ) // max date inclusive
  @Input('appMinDate') minDate: NgbDate | null = NgbDate.from( { year: 2010, month: 1, day: 1} ) // min date inclusive

  constructor(
    private readonly calendar: NgbCalendar,
    private formatter: NgbDateParserFormatter) {}

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null
    }

    const inputParsed = this.parseInput(control.value)

    if (!inputParsed) {
      return { 'invalidDate': { value: control.value } }
    }

    if (NgbDate.from(inputParsed).after(this.maxDate)) {
      return { 'invalidMaxDate': { value: control.value } }
    }

    if (NgbDate.from(inputParsed).before(this.minDate)) {
      return { 'invalidMinDate': { value: control.value } }
    }

    return null
  }

  private parseInput(value: string): NgbDateStruct | null {
    const parsed = this.formatter.parse(value)

    if (parsed && this.calendar.isValid(NgbDate.from(parsed))) {
      return parsed
    }

    return null
  }

}
