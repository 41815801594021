import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { UserService } from '../../../@core/user.service';
import { AlertService } from '../../../@core/alert.service';
import { ParceriaInfo } from '../../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { DetalheParceriaPortalApi } from '../../../apis/detalheParceriaPortalApi';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BuscaCampos } from '../../../modulo1/exibeParcerias/buscaCampos';
import { DateConverter } from 'src/app/@core/date-converter';
import { KeycloakService, AuthUser } from '@procempa/ngx-keycloak';
import { EnvioDadoService } from './envioDado.service';
import { DatetimeRange } from '../datepicker/datatime-range';
import { Mascaras } from '../Mascaras';
import { Utils } from '../../utils';

@Component({
    selector: 'app-filtro-busca',
    templateUrl: './filtroBusca.component.html',
    styleUrls: ['./filtroBusca.component.scss']
})

export class FiltroBuscaComponent implements OnInit {

    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable().pipe(share());
    selectedParceriaId: number = null;
    parceriaArrayParent: Array<ParceriaInfo>;
    status: number;
    countParcerias: number;
    busca: BuscaCampos = new BuscaCampos(0, null, null, '', '', '', '', true, 0, 0, 25, null);
    currentPage: number;
    dataVigenciaInicial: NgbDateStruct;
    collectionSizeParceria: number;
    dataRange: DatetimeRange;
    isFiscal: boolean;
    isConsulta: boolean;
    isSMTC: boolean;
    constructor(private alertService: AlertService,
        private detalheParceriaApi: DetalheParceriaApi,
        private detalheParceriaPortalApi: DetalheParceriaPortalApi,
        public userService: UserService,
        private dateConverter: DateConverter,
        private envioDadoService: EnvioDadoService,
        public keycloakService: KeycloakService,
        private mascaraService: Mascaras,
        public utils: Utils
    ) {

    }

    ngOnInit() {

        this.parceriaArrayParent = [];
        this.isFiscal = this.userService.isFiscal();
        this.isConsulta = this.userService.isConsulta();

        // pegar qualificador
        this.keycloakService.getUser().subscribe((user: AuthUser) => {

            //console.log(`orgao: ${user.attributes.sgp_orgao[0]}`);
            if ((this.isFiscal || this.isConsulta) && user.attributes.sgp_orgao !== null) {
                // tslint:disable-next-line:no-unused-expression
                if (this.userService.isSMTC()) {
                    this.isSMTC = true;
                    this.busca = new BuscaCampos(0, null, null, '', '', '', '', false, 0, 0, 25, null);
                } else {
                    this.busca = new BuscaCampos(Number(user.attributes.sgp_orgao[0]), null, null, '', '', '', '', false, 0, 0, 25, null);
                }
            }
            this.getParcerias(this.busca, 1);
            this.currentPage = 1;
        });
        this.envioDadoService.enviaPagina$.subscribe((count) => {
            this.getParceriasPagina(count);
          });
    }

    getParceriasPagina(pagina) {
        this.getParcerias(this.busca, pagina);
    }
    getParcerias(buscaCampos, pagina) {
        if (pagina === 0) {
            return;
        }
        buscaCampos.pagina = pagina;
        buscaCampos.limite = 25;
        this.currentPage = pagina;

        this.loading.next(true);
        //console.log("Forgao: "+buscaCampos.orgao);
        this.detalheParceriaPortalApi.getParceriaInfoPortal(buscaCampos)
            .subscribe(async (response) => {
                if (response) {
                    this.parceriaArrayParent = response as Array<ParceriaInfo>;
                   // console.log(this.parceriaArrayParent);
                    this.parceriaArrayParent.forEach(element => {
                        element.status = this.getStatusParceira(element.parOrgStatus);
                    });
                    await this.getCountParceriaPortal();
                    this.envioDadoService.changeArray(this.parceriaArrayParent);
                    this.envioDadoService.changeCurrentPage(this.currentPage);
                } else {
                    this.alertService.warning('Nenhum registro encontrado');
                }
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));
    }

    async getCountParceriaPortal() {
        this.loading.next(true);
        //console.log(this.userService.userInfo);
        this.detalheParceriaPortalApi.getCountParceriaPortal(this.busca)
            .subscribe((response) => {
                if (response) {
                    this.countParcerias = response as number;
                    this.collectionSizeParceria = Math.ceil((this.countParcerias / 25) * 10);
                    this.envioDadoService.changeCollectionSize(response as number);
                } else {
                    this.alertService.warning('Nenhum registro encontrado');
                    this.countParcerias = 0;
                    this.collectionSizeParceria = 0;
                    this.envioDadoService.changeCollectionSize(this.countParcerias);
                }

            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));
    }
    getStatusParceira(cod) {
        return this.utils.getStatusParceira(cod);
    }

    selectStatusParceria(event) {
        const newVal = event.currentTarget.value;
        this.busca.status = newVal;
        this.getParcerias(this.busca, 1);
    }
    selectOrgaoParceria(event) {
        const newVal = event.currentTarget.value;
        this.busca.orgao = newVal;
        this.getParcerias(this.busca, 1);
    }
    selectNumeroTermo() {
        this.getParcerias(this.busca, 1);
    }
    selectNomeParceria() {
        this.getParcerias(this.busca, 1);
    }
    selectObjeto() {
        this.getParcerias(this.busca, 1);
    }

    novoPeriodo(dataRangeEvent: DatetimeRange) {
        this.dataRange = dataRangeEvent
        this.busca.dataVigenciaIncial = this.dateConverter.fromNgbDateToDate(this.dataRange.fromNgbDate);
        //this.busca.dataVigenciaFinal = this.dateConverter.fromNgbDateToDate(this.dataRange.fromNgbDate);
        this.getParcerias(this.busca, 1);
    }
    
    gerarExcel() {
        var buscaCampos = this.busca;
        buscaCampos.limite = 2147483647;
        buscaCampos.pagina = 1;
        this.detalheParceriaApi.getBuscaParceriasPlanilha(buscaCampos).subscribe((response) => {
            if (response) {
                console.log(response);
                const a = document.createElement('a');
                const url = window.URL.createObjectURL(response);
                a.download = `lista_parcerias_termos.xls`;
                a.href = url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);

            } else {
                this.alertService.warning('Nenhum resultado foi obtido, por favor refaça a busca com outros critérios.');
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));
    }
}
