<div w3-include-html="../../@shared/components/headerPortal/header.component.html">
  <div class="container-fluid mb-3" style="min-height: 350px;">
    <div class="infoGeral container-fluid">
      <br>
      <h3>Filtros de pesquisa</h3>
      <p><em>Você pode filtrar os resultados preenchendo um ou combinando mais de um dos campos abaixo:</em></p>
      <br>
      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label for="">Escolher órgão ou entidade (PMPA)</label>
            <div class="select-wrapper">
              <select [disabled]="isFiscal  && !isSMTC" [ngModel]="busca.orgao" (change)="selectOrgaoParceria($event)" class="form-control form-select custom-select">
								<option [value]="option.id" *ngFor="let option of selectOrgaoList">{{option.name}}
                </option>
							</select>
            </div>
          </div>
        </div>
        <div class="col-md-3" style="width: 100% ">
          <div class="form-group">
            <label for="nroTermo">Número do termo</label>
            <input style="width: 100% " id="nroTermo" name="busca.numeroTermo" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.numeroTermo " [value]="busca.numeroTermo" (blur)="selectNumeroTermo()" (keydown.enter)="selectNumeroTermo()" class="form-control"
                            placeholder="informe o nº do termo">
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="objeto">Objeto/finalidade</label>
            <input style="width: 100% " id="objeto" name="busca.objetoFin " class="form-control" type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.objetoFin " [value]="busca.objetoFin " (blur)="selectObjeto()" (keydown.enter)="selectObjeto()" placeholder="digite alguma palavra-chave">
          </div>
        </div>
        <div class="col-md-1">
          <div class="form-group">
            <label for="termosAtivos">Termos ativos</label>
            <label class="switch">
                            <input type="checkbox" id="termosAtivos" name="busca.termosAtivos" [ngModelOptions]="{standalone: true}" [(ngModel)]="busca.termosAtivos " [value]="busca.termosAtivos" (blur)="selectTermosAtivos()" (keydown.enter)="selectTermosAtivos()">
                            <span class="slider round"></span>
                        </label>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col">
          <div class="form-group">
            <label for="nome">Nome da organização (parceria)</label>
            <input style="width: 100% " id="nome" name="busca.nomeOrg" class="form-control " type="text " [ngModelOptions]="{standalone: true} " [(ngModel)]="busca.nomeOrg " [value]="busca.nomeOrg " (blur)="selectNomeParceria()" (keydown.enter)="selectNomeParceria()" placeholder="digite alguma palavra-chave">
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="">Status da parceria</label>
            <div class="select-wrapper">
              <select [ngModel]="busca.status" (change)="selectStatusParceria($event)" class="form-control form-select custom-select">
								<option [value]="option.id" *ngFor="let option of this.utils.statusParceiraOptions">{{option.name}}
                </option>
							</select>
            </div>
          </div>
        </div>

        <div class="col-md-3 cjto-datas">
          <div class="texto-linha-select-resultados">Período da vigência</div>
          <selector-datetime-range class="data-range" [isOnlyDataInicio]="false" [showLabelData]="true" (novoPeriodo)="novoPeriodo($event)"></selector-datetime-range>
        </div>
        <div class="col-md-1">
          <button class="btn btn-primary btn-filtro">Filtrar</button>
        </div>
        <div class="col-md-1" *ngIf="isFiscal || isSMTC">
          <button class="btn btn-primary btn-filtro" (click)="gerarExcel()">Gerar Excel</button>
        </div>
        <!-- Filtar Lista: <input type="text " [(ngModel)]="queryString " id="search " placeholder="digite o valor ">-->
      </div>
      <div class="form-row">
        <div class="col">
          <button style="width: 10%" class="btn btn-primary btn-novo" [routerLink]="[ '../detalheParceriaM1']" [queryParams]="{id: -1, isAuditorExterno: isAuditorExterno}" *ngIf="!isAuditorExterno && !isSMTC">Novo</button>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div>
    <table class="table table-hover table-responsavel" [hidden]="(!parceriaArray) " [mfData]="parceriaArray ">
      <thead>
        <tr>
          <th></th>
          <th>Nº Termo</th>
          <th>Status Atual</th>
          <th>Órgão</th>
          <th>Nome</th>
          <th>Objeto/Finalidade</th>
          <th>Vlr Total Previsto</th>
          <th>Vlr Total Concedido</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let parceria of parceriaArray" [routerLink]="['/planejamento/detalheParceriaM1']" [queryParams]="{id: parceria.parId, isAuditorExterno: isAuditorExterno}" >

          <td>
            <i ngbTooltip="Editar Parceria" *ngIf="!disableCamposTable()" class="fa fa-pencil" aria-hidden="true"></i>
            <i ngbTooltip="VisualizarParceria" *ngIf="disableCamposTable()" class="fa fa-search" aria-hidden="true"></i>

          </td>

          <td>{{parceria.parNum}}</td>
          <td>{{parceria.status}}</td>
          <td>{{parceria.nomeOrgao}}</td>
          <td>{{parceria.parOrgNome}}</td>
          <td>{{parceria.parOrgObjeto}}</td>
          <td>{{parceria.parVlrPrevisto | currency: 'BRL'}}</td>
          <td>{{parceria.parOrgVlTotal | currency: 'BRL'}}</td>
        </tr>
      </tbody>
    </table>
    <tfoot data-html2canvas-ignore="true ">
      <div *ngIf="countParcerias>25" class="resultPorPagina">
        <ngb-pagination class="d-flex justify-content-center " [collectionSize]="collectionSizeParceria" [maxSize]="10" [(page)]="currentPage " [rotate]="true" [ellipses]="true" [boundaryLinks]="true " (pageChange)="onPageChange($event)"></ngb-pagination>
      </div>

    </tfoot>

    <div [hidden]="!(loading$ | async) ">
      <div class="loading-overlay "></div>
      <div class="loading-info ">
        <i class="fa fa-spinner fa-4x fa-spin "></i>
        <div id="carregando ">Aguarde</div>
      </div>
    </div>
  </div>
</div>
