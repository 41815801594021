import { Directive, ElementRef, Renderer2, OnInit, Input, HostListener } from '@angular/core'; 
@Directive({
  selector: '[appMaskInput]'
})
export class MaskInputDirective implements OnInit {
  @Input() appMaskValue: string; constructor(
    private elRef: ElementRef,
    private renderer: Renderer2
  ) { } ngOnInit(): void {
  }
  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace) {
    if (!(event instanceof Object)) {
      let newVal;
      if (event != null){
        newVal = event.replace(/\D/g, '');
      }
      
      if (backspace && newVal.length <= 8) {
        newVal = newVal.substring(0, newVal.length - 1);
      }
      if (newVal == null || newVal.length === 0) {
        newVal = null;
      } else if (newVal.length < 3) {
        if (newVal > 3 && newVal.length === 1) {
          newVal = '';
        } else if ((newVal > 31 || newVal === '00') && newVal.length === 2) {
          newVal = newVal.substring(0, newVal.length - 1);
        } else newVal = newVal.replace(/^(0[1-9]|[12][0-9]|3[01])/, '$1/');
      } else if (newVal.length <= 4) {
        if (newVal.length === 3) {
          let digit = newVal.substring(2, newVal.length);
          if (digit > 1) {
            newVal = newVal.substring(0, 2);
          }
          newVal = newVal.replace(/^(0[1-9]|[12][0-9]|3[01])/, '$1/');
        }
        else if (newVal.length === 4) {
          let digit = newVal.substring(3, newVal.length);
          let firstdigit = newVal.substring(2, 3);
          if ((firstdigit == 1 && digit > 2) || (firstdigit == 0 && digit == 0)) {
            newVal = newVal.substring(0, 3);
          }
          newVal = newVal.replace(/^(0[1-9]|[12][0-9]|3[01])/, '$1/');
        }
        //newVal = newVal.replace(/^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[012])/,'$1/$2');
      } else if (newVal.length <= 8) {
        if (newVal.length === 5) {
          let digit = newVal.substring(4, newVal.length);
          if (digit < 2 || digit > 2) {
            newVal = newVal.substring(0, 4);
          }
        }
        else if (newVal.length === 6) {
          let digit = newVal.substring(5, newVal.length);
          if (digit != 0) {
            newVal = newVal.substring(0, 5);
          }
        }
        newVal = newVal.replace(
          /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[012])/,
          '$1/$2/'
        );
      } else {
        newVal = newVal.substring(0, 8);
        newVal = newVal.replace(
          /^(0[1-9]|[12][0-9]|3[01])(0[1-9]|1[012])((19|20)\d{2})/,
          '$1/$2/$3'
        );
      }

      this.appMaskValue = newVal;
      this.renderer.setProperty(this.elRef.nativeElement, 'value', this.appMaskValue);
    } else {
      let data = event;
      let stringDate: string = '';
      if (data) {
        stringDate += this.isNumber(data.day) ? this.padNumber(data.day) + '/' : '';
        stringDate += this.isNumber(data.month) ? this.padNumber(data.month) + '/' : '';
        stringDate += data.year;
      }
      this.appMaskValue = stringDate;
      this.renderer.setProperty(this.elRef.nativeElement, 'value', this.appMaskValue);
    }

  }
  isNumber(value: any): boolean {
    return !isNaN(this.toInteger(value));
  }
  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }
  padNumber(value: number) {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }

}