<div w3-include-html="../../@shared/components/headerPortal/header.component.html">
    <div class="container-fluid mb-3" style="min-height: 450px;">
        <div class="infoGeral container-fluid">
            <h2>Relatório de Gastos de Pessoal – SMS</h2>
            <h3>Filtros de pesquisa</h3>
            <p><em>Você pode filtrar os resultados preenchendo um ou combinando mais de um dos campos abaixo:</em></p>
            <br>
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="osc">OSC</label>
                    <div class="select-wrapper" style="width:100%">
                        <ng-multiselect-dropdown name="osc" class="multiselect-dropdownSgp" [placeholder]="'escolha OSC'" [data]="dropdownOscList" #osc="ngModel" [(ngModel)]="cnpjOscs" [settings]="dropdownSettings" (onSelect)="onOscSelect($event)" (onSelectAll)="onSelectAllOsc($event)"
                            (onDeSelect)="onOscDeSelect($event)" (onDeSelectAll)="onOscDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <label for="natureza">Natureza</label>
                    <div class="select-wrapper" style="width:100%">
                        <ng-multiselect-dropdown name="natureza" class="multiselect-dropdownSgp" [placeholder]="'escolha natureza'" [data]="this.utils.naturezaOptions" #natureza="ngModel" [(ngModel)]="naturezas" [settings]="dropdownSettings" (onSelect)="onNaturezaSelect($event)" (onSelectAll)="onSelectAllNatureza($event)"
                            (onDeSelect)="onNaturezaDeSelect($event)" (onDeSelectAll)="onNaturezaDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
            <div class="form-row">
               <!-- <div class="form-group col-md-4">
                    <label for="termo">Termo</label>
                    <div class="select-wrapper" style="width:100%">
                        <ng-multiselect-dropdown name="termo" class="multiselect-dropdownSgp" [placeholder]="'escolha termo'" [data]="dropdownTermoList" #termo="ngModel" [(ngModel)]="parIds" [settings]="dropdownSettings" (onSelect)="onTermoSelect($event)" (onSelectAll)="onSelectAllTermo($event)"
                            (onDeSelect)="onTermoDeSelect($event)" (onDeSelectAll)="onTermoDeSelectAll($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>-->
               <div class="form-group cjto-datas col-md-4">
                    <label for="dataIni">Data Início</label>
                    <input class="form-control" placeholder="Selecione a data no calendário" onkeydown="return false" name="dataIni" #dtIni="ngModel" [(ngModel)]="dataIni" ngbDatepicker #dI="ngbDatepicker" required>
                    <i class="fa fa-calendar icon"  (click)="dI.toggle()" title="calendário"></i>
                </div>
                <div class="form-group cjto-datas col-md-4">
                    <label for="dataFim">Data Fim</label>
                    <input class="form-control" placeholder="Selecione a data no calendário" onkeydown="return false" name="dataFim" #dtFim="ngModel" [(ngModel)]="dataFim" ngbDatepicker #dF="ngbDatepicker" required>
                    <i class="fa fa-calendar icon"  (click)="dF.toggle()" title="calendário"></i>
                </div>
                
            </div>
            <div class="form-row">
                <div class="col-md-4 btn-inline">
                    <div class="form-group">
                        <div class="select-wrapper" style="padding-top: 38px;">
                            <button class="btn btn-primary" (click)="limparFiltros()">Limpar</button>
                            <button class="btn btn-primary" (click)="geraArquivo('PDF')">Gerar PDF</button>
                            <button class="btn btn-primary" (click)="geraArquivo('XLS')">Gerar Excel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>