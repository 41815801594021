import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakService, BaseKeycloakGuard, GuardParams, KeycloakAuthGuard } from '@procempa/ngx-keycloak';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
	constructor(protected keycloakSrv: KeycloakService, private router: Router) {
		super(keycloakSrv);
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		const can = super.canActivate(route, state);
		this.keycloakSrv.hasRole("sgp");
		if (!can) {
			// @ts-ignore
			return this.router.parseUrl('/not-authorized');
		}
		return can;
	}
}
