import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class ServicoPrestadoPf {
    public id: number;
    public prestadorPFTerceiroId: number;
    public inicioExecucao: Date;
    public inicioExecucaoDataFormat: NgbDateStruct;
    public fimExecucao: Date;
    public fimExecucaoDataFormat: NgbDateStruct;
    public descricaoServico: String;
    public tipoDeServico: number;
    public ocupacaoLaboralSelecionada:any;
    public tipoAtividade: number;
    public tipoAtividadeSelecionada:any;
    public tipoDeServicoNome:string;
    public responsavelContratacao:string;
    public razaoSocialOsc:string;
    public comprovanteRGPS:boolean;
    public cnpjOsc:string;
    public enderecoOsc:string;
    public cepOsc:string;
    public operacaoData: Date;
    public usuarioOperacao: string;

    public valorBrutoServico: number;
    public numeroDependentes: number;
    public baseCalcIRRF: number;
    public retencaoIRRF: number;
    public outros: number;
    public valorLiquido: number;
    public baseCalcRGPS: number;
    public retencaoRGPS: number;
    
    public nomeBanco:string;
    public agenciaConta:string;
    public numeroConta:string;   
}